import React, { useContext, useState, useEffect } from 'react';
import Layout from '../../../components/Layout';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextWithDivider from '../../../components/TextWithDivider';
import { AuthContext } from '../../../components/AuthContextProvider';
import SecondaryLayout from '../../../components/SecondaryLayout';
import useFetch from '../../../hooks/useFetch';
import CustomTabs from '../../../components/CustomTabs';
import {
  fetchUtilizationDataPOC,
  fetchActivityAnalytics,
  fetchEventsByEnterpriseId,
  fetchApprovalCompletionStatus,
  fetchRAGAssignmentStatus,
} from '../../../operations/poc';
import TableWrapper from '../../Manager/components/TableWrapper';
import { fetchAllEnterprises } from '../../../operations/enterprise';
import PieChartWrapper from '../../../components/PieChartWrapper';

const AdminAnalytics = () => {
  let { user } = useContext(AuthContext);
  // let { eventId, setEvent } = useContext(EventContext);
  const [eventId, setEvent] = useState('');
  const [companyName, setCompanyName] = useState('');
  const [utilizationData, setUtilizationData] = useState([]);
  const [activityData, setActivityData] = useState([]);
  const [, setApprovalCompletionData] = useState([]);
  const [, setRagAssignmentData] = useState([]);
  const [layoutLoading, setLayoutLoading] = useState(false);
  const [piechartData, setPieChartData] = useState([]);
  const [pieFilter, setPieFilter] = useState('');

  // const { data: utilizationData = [], loading: utilizationLoading } = useFetch(
  //   fetchUtilizationDataPOC,
  //   {
  //     eventId: eventId || user.eventId,
  //     enterpriseId: user.enterpriseId,
  //   }
  // );

  // const { data: activityData = [], loading: activityLoading } = useFetch(
  //   fetchActivityAnalytics,
  //   {
  //     eventId: eventId || user.eventId,
  //   }
  // );

  const utilizationDataSetter = async () => {
    return new Promise(async (resolve, reject) => {
      const data = await fetchUtilizationDataPOC({
        eventId: eventId,
        enterpriseId: companyName,
      });
      setUtilizationData(data);
      resolve();
    });
  };

  const activityDataSetter = async () => {
    return new Promise(async (resolve, reject) => {
      const data = await fetchActivityAnalytics({
        eventId: eventId,
      });

      setActivityData(data);

      if (data.length) {
        let totalHours = data.reduce((a, b) => a + b.totalHours, 0);
        let pieData = ['RED', 'AMBER', 'GREEN'].map((item) => {
          let itemHours = data
            .filter((e) => e.ragValue === item)
            .reduce((a, b) => a + b.totalHours, 0);

          return {
            name: item,
            value: itemHours / totalHours,
          };
        });
        setPieChartData(pieData);
      }

      resolve();
    });
  };

  const approvalCompletionDataSetter = async () => {
    return new Promise(async (resolve, reject) => {
      const data = fetchApprovalCompletionStatus(eventId);
      setApprovalCompletionData(data);
      resolve();
    });
  };

  const ragAssignmentDataSetter = async () => {
    return new Promise(async (resolve, reject) => {
      const data = fetchRAGAssignmentStatus(eventId);
      setRagAssignmentData(data);
      resolve();
    });
  };

  // const {
  //   // data: approvalCompletionData = [],
  //   // loading: approvalLoading,
  //   fetchData: fetchApprovalData,
  // } = useFetch(fetchApprovalCompletionStatus, eventId || user.eventId);

  // const {
  //   // data: ragAssignmentData = [],
  //   // loading: assignmentLoading,
  //   fetchData: fetchRagAssignmentData,
  // } = useFetch(fetchRAGAssignmentStatus, eventId || user.eventId);

  const {
    data: enterprises = [],
    // loading: enterpriseLoading
  } = useFetch(fetchAllEnterprises);

  const {
    data: events = [],
    // loading: eventsLoading,
    fetchData: fetchDataEventsByEnterpriseId,
  } = useFetch(fetchEventsByEnterpriseId, user.enterpriseId || '');

  // const { data: utilizationStats = {}, loading: statsLoading } = useFetch(
  //   getRAGStats,
  //   {
  //     teamId: user.teamId,
  //     enterpriseId: user.enterpriseId,
  //   }
  // );

  const handleEventChange = async (event) => {
    if (event !== eventId) {
      setEvent(event);
      // await fetchApprovalData(event);
      // await fetchRagAssignmentData(event);
    }
  };

  useEffect(() => {
    (async () => {
      await fetchDataEventsByEnterpriseId(companyName);
    })();

    // eslint-disable-next-line
  }, [companyName]);

  useEffect(() => {
    if (eventId && eventId !== '') {
      setPieChartData([]);
      (async () => {
        setLayoutLoading(true);
        await utilizationDataSetter();
        await activityDataSetter();
        await approvalCompletionDataSetter();
        await ragAssignmentDataSetter();
        setLayoutLoading(false);
      })();
    }
    // eslint-disable-next-line
  }, [eventId]);

  const handleCompanyChange = (value) => {
    setCompanyName(value);
  };

  return (
    <Layout loading={layoutLoading}>
      <SecondaryLayout>
        <Grid container className="pl-4 pr-2">
          <Grid item lg={9} md={8} className="pr-4 pt-4">
            <TextWithDivider>
              <Typography variant="h3" color="textPrimary">
                Analytics - RAG
              </Typography>
            </TextWithDivider>

            <Grid container spacing={2} item md={8}>
              <Grid item xs={6}>
                {enterprises.length ? (
                  <FormControl fullWidth>
                    <InputLabel id="selectEnterprise">
                      Select Enterprise
                    </InputLabel>

                    <Select
                      labelId="selectEnterprise"
                      value={companyName}
                      fullWidth
                      placeholder="Select Enterprise"
                      onChange={(evt) => handleCompanyChange(evt.target.value)}>
                      {enterprises.map((item) => (
                        <MenuItem value={item.id} key={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  ''
                )}
              </Grid>

              <Grid item xs={6}>
                {companyName ? (
                  <FormControl fullWidth>
                    <InputLabel id="selectEvent">Select Event</InputLabel>
                    <Select
                      fullWidth
                      labelId="selectEvent"
                      disabled={companyName === ''}
                      value={eventId || ''}
                      onChange={(evt) => handleEventChange(evt.target.value)}>
                      <MenuItem disabled value={''} />
                      {events.length === 0 && (
                        <MenuItem disabled value={'No Events present'} />
                      )}
                      {events.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>

            {piechartData.length ? (
              <div
                style={{ display: 'block', width: '350px', height: '300px' }}>
                <PieChartWrapper
                  onClick={(ragValue) => {
                    if (ragValue === pieFilter) {
                      setPieFilter('');
                    } else {
                      setPieFilter(ragValue);
                    }
                  }}
                  data={piechartData}
                />
              </div>
            ) : (
              ''
            )}

            <Grid container justifyContent="space-between" className="mt-2">
              <Grid
                item
                container
                justifyContent="space-between"
                className="mt-3 pl-4 pr-4"></Grid>
            </Grid>
            <Grid className="mt-3">
              <CustomTabs
                tabs={[
                  {
                    label: 'Utilization',
                    children: (
                      <TableWrapper
                        data={utilizationData}
                        id={'utilizationTable'}
                        tableHeads={[
                          { id: 'name', label: 'Name' },
                          { id: 'red', label: 'Red', align: 'center' },
                          { id: 'amber', label: 'Amber', align: 'center' },
                          { id: 'green', label: 'Green', align: 'center' },
                          { id: 'index', label: 'Index', align: 'center' },
                        ]}
                        dataKeys={[
                          { id: 'name' },
                          { id: 'redIndex', align: 'center' },
                          { id: 'amberIndex', align: 'center' },
                          { id: 'greenIndex', align: 'center' },
                          { id: 'index', align: 'center' },
                        ]}
                        isSelectable={false}
                      />
                    ),
                  },
                  {
                    label: 'Activity',
                    children: (
                      <TableWrapper
                        data={activityData.filter((e) =>
                          pieFilter === '' ? true : e.ragValue === pieFilter
                        )}
                        id={'activityAnalyticsTable'}
                        tableHeads={[
                          { id: 'activityName', label: 'Activity' },
                          { id: 'process', label: 'Process' },
                          { id: 'subProcess', label: 'Sub-Process' },
                          {
                            id: 'totalHours',
                            label: 'Total Hours',
                            align: 'center',
                          },
                          { id: 'ragValue', label: 'RAG Value' },
                        ]}
                        dataKeys={[
                          { id: 'activityName' },
                          { id: 'process' },
                          { id: 'subProcess' },
                          { id: 'totalHours', align: 'center' },
                          { id: 'ragValue' },
                        ]}
                        isSelectable={false}
                      />
                    ),
                  },
                ]}
              />
            </Grid>
          </Grid>
        </Grid>
      </SecondaryLayout>
    </Layout>
  );
};

export default AdminAnalytics;
