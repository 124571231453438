export const IndustriesList = [
  {
    label: 'Accounting',
    value: 'Accounting',
  },
  {
    label: 'Aerospace & Aviation',
    value: 'Aerospace & Aviation',
  },
  {
    label: 'Agriculture',
    value: 'Agriculture',
  },
  {
    label: 'Alternative Dispute Resolution',
    value: 'Alternative Dispute Resolution',
  },
  {
    label: 'Alternative Medicine',
    value: 'Alternative Medicine',
  },
  {
    label: 'Animation',
    value: 'Animation',
  },
  {
    label: 'Apparel & Fashion',
    value: 'Apparel & Fashion',
  },
  {
    label: 'Apparel Retail',
    value: 'Apparel Retail',
  },
  {
    label: 'Architecture',
    value: 'Architecture',
  },
  {
    label: 'Arts & Crafts',
    value: 'Arts & Crafts',
  },
  {
    label: 'Arts and Crafts',
    value: 'Arts and Crafts',
  },
  {
    label: 'Automotive',
    value: 'Automotive',
  },
  {
    label: 'Automotive Retail',
    value: 'Automotive Retail',
  },
  {
    label: 'Banking',
    value: 'Banking',
  },
  {
    label: 'Biotechnology',
    value: 'Biotechnology',
  },
  {
    label: 'Broadcast Media',
    value: 'Broadcast Media',
  },
  {
    label: 'Building Materials',
    value: 'Building Materials',
  },
  {
    label: 'Business Supplies & Equipment',
    value: 'Business Supplies & Equipment',
  },
  {
    label: 'Capital Markets',
    value: 'Capital Markets',
  },
  {
    label: 'Chemical Manufacturing',
    value: 'Chemical Manufacturing',
  },
  {
    label: 'Chemicals',
    value: 'Chemicals',
  },
  {
    label: 'Civil Engineering',
    value: 'Civil Engineering',
  },
  {
    label: 'Commercial Real Estate',
    value: 'Commercial Real Estate',
  },
  {
    label: 'Commercial Real Estate Services',
    value: 'Commercial Real Estate Services',
  },
  {
    label: 'Computer & Network Security',
    value: 'Computer & Network Security',
  },
  {
    label: 'Computer Games',
    value: 'Computer Games',
  },
  {
    label: 'Computer Hardware',
    value: 'Computer Hardware',
  },
  {
    label: 'Computer Networking',
    value: 'Computer Networking',
  },
  {
    label: 'Computer Software',
    value: 'Computer Software',
  },
  {
    label: 'Construction',
    value: 'Construction',
  },
  {
    label: 'Construction Services',
    value: 'Construction Services',
  },
  {
    label: 'Consumer Electronics',
    value: 'Consumer Electronics',
  },
  {
    label: 'Consumer Goods',
    value: 'Consumer Goods',
  },
  {
    label: 'Consumer Services',
    value: 'Consumer Services',
  },
  {
    label: 'Cosmetics',
    value: 'Cosmetics',
  },
  {
    label: 'Dairy',
    value: 'Dairy',
  },
  {
    label: 'Defense & Space',
    value: 'Defense & Space',
  },
  {
    label: 'Design',
    value: 'Design',
  },
  {
    label: 'E-learning',
    value: 'E-learning',
  },
  {
    label: 'Education Management',
    value: 'Education Management',
  },
  {
    label: 'Electrical & Electronic',
    value: 'Electrical & Electronic',
  },
  {
    label: 'Manufacturing',
    value: 'Manufacturing',
  },
  {
    label: 'Entertainment',
    value: 'Entertainment',
  },
  {
    label: 'Environmental Services',
    value: 'Environmental Services',
  },
  {
    label: 'Events',
    value: 'Events',
  },
  {
    label: 'Events Services',
    value: 'Events Services',
  },
  {
    label: 'Executive Office',
    value: 'Executive Office',
  },
  {
    label: 'Facilities Services',
    value: 'Facilities Services',
  },
  {
    label: 'Farming',
    value: 'Farming',
  },
  {
    label: 'Financial Services',
    value: 'Financial Services',
  },
  {
    label: 'Fine Art',
    value: 'Fine Art',
  },
  {
    label: 'Fishery',
    value: 'Fishery',
  },
  {
    label: 'Food & Beverage Production',
    value: 'Food & Beverage Production',
  },
  {
    label: 'Food Production',
    value: 'Food Production',
  },
  {
    label: 'Fundraising',
    value: 'Fundraising',
  },
  {
    label: 'Furniture',
    value: 'Furniture',
  },
  {
    label: 'Gambling & Casinos',
    value: 'Gambling & Casinos',
  },
  {
    label: 'Glass, Ceramics, & Concrete Manufacturing',
    value: 'Glass, Ceramics, & Concrete Manufacturing',
  },
  {
    label: 'Government Administration',
    value: 'Government Administration',
  },
  {
    label: 'Graphic Design',
    value: 'Graphic Design',
  },
  {
    label: 'Health, Wellness & Fitness',
    value: 'Health, Wellness & Fitness',
  },
  {
    label: 'Higher Education',
    value: 'Higher Education',
  },
  {
    label: 'Hospital & Health Care',
    value: 'Hospital & Health Care',
  },
  {
    label: 'Hospitality',
    value: 'Hospitality',
  },
  {
    label: 'Human Resources',
    value: 'Human Resources',
  },
  {
    label: 'Import & Export',
    value: 'Import & Export',
  },
  {
    label: 'Individual & Family Services',
    value: 'Individual & Family Services',
  },
  {
    label: 'Industrial Automation',
    value: 'Industrial Automation',
  },
  {
    label: 'Information Services',
    value: 'Information Services',
  },
  {
    label: 'Information Technology & Services',
    value: 'Information Technology & Services',
  },
  {
    label: 'Insurance',
    value: 'Insurance',
  },
  {
    label: 'International Affairs',
    value: 'International Affairs',
  },
  {
    label: 'International Trade & Development',
    value: 'International Trade & Development',
  },
  {
    label: 'Internet',
    value: 'Internet',
  },
  {
    label: 'Investment Banking',
    value: 'Investment Banking',
  },
  {
    label: 'Investment Management',
    value: 'Investment Management',
  },
  {
    label: 'Judiciary',
    value: 'Judiciary',
  },
  {
    label: 'Law Enforcement',
    value: 'Law Enforcement',
  },
  {
    label: 'Law Practice',
    value: 'Law Practice',
  },
  {
    label: 'Legal Services',
    value: 'Legal Services',
  },
  {
    label: 'Legislative Offices',
    value: 'Legislative Offices',
  },
  {
    label: 'Libraries',
    value: 'Libraries',
  },
  {
    label: 'Logistics & Supply Chain',
    value: 'Logistics & Supply Chain',
  },
  {
    label: 'Luxury Goods & Jewelry',
    value: 'Luxury Goods & Jewelry',
  },
  {
    label: 'Machinery',
    value: 'Machinery',
  },
  {
    label: 'Management Consulting',
    value: 'Management Consulting',
  },
  {
    label: 'Maritime',
    value: 'Maritime',
  },
  {
    label: 'Market Research',
    value: 'Market Research',
  },
  {
    label: 'Mechanical or Industrial Engineering',
    value: 'Mechanical or Industrial Engineering',
  },
  {
    label: 'Media Production',
    value: 'Media Production',
  },
  {
    label: 'Medical Device',
    value: 'Medical Device',
  },
  {
    label: 'Medical Practice',
    value: 'Medical Practice',
  },
  {
    label: 'Mental Health Care',
    value: 'Mental Health Care',
  },
  {
    label: 'Military',
    value: 'Military',
  },
  {
    label: 'Mining & Metals',
    value: 'Mining & Metals',
  },
  {
    label: 'Mobile Games',
    value: 'Mobile Games',
  },
  {
    label: 'Motion Pictures & Film',
    value: 'Motion Pictures & Film',
  },
  {
    label: 'Museums',
    value: 'Museums',
  },
  {
    label: 'Music',
    value: 'Music',
  },
  {
    label: 'Nanotechnology',
    value: 'Nanotechnology',
  },
  {
    label: 'Newspapers',
    value: 'Newspapers',
  },
  {
    label: 'Non-profit Organization Management',
    value: 'Non-profit Organization Management',
  },
  {
    label: 'Oil & Energy',
    value: 'Oil & Energy',
  },
  {
    label: 'Online Media',
    value: 'Online Media',
  },
  {
    label: 'Outsourcing',
    value: 'Outsourcing',
  },
  {
    label: 'Packaging',
    value: 'Packaging',
  },
  {
    label: 'Paper & Forest Products',
    value: 'Paper & Forest Products',
  },
  {
    label: 'Performing Arts',
    value: 'Performing Arts',
  },
  {
    label: 'Pharmaceuticals',
    value: 'Pharmaceuticals',
  },
  {
    label: 'Philanthropy',
    value: 'Philanthropy',
  },
  {
    label: 'Photography',
    value: 'Photography',
  },
  {
    label: 'Plastics Manufacturing',
    value: 'Plastics Manufacturing',
  },
  {
    label: 'Political Organizations',
    value: 'Political Organizations',
  },
  {
    label: 'Primary/Secondary Education',
    value: 'Primary/Secondary Education',
  },
  {
    label: 'Printing',
    value: 'Printing',
  },
  {
    label: 'Professional Training & Coaching',
    value: 'Professional Training & Coaching',
  },
  {
    label: 'Program Development',
    value: 'Program Development',
  },
  {
    label: 'Public Policy',
    value: 'Public Policy',
  },
  {
    label: 'Public Relations & Communications',
    value: 'Public Relations & Communications',
  },
  {
    label: 'Public Safety',
    value: 'Public Safety',
  },
  {
    label: 'Publishing',
    value: 'Publishing',
  },
  {
    label: 'Railroad Manufacturing',
    value: 'Railroad Manufacturing',
  },
  {
    label: 'Ranching',
    value: 'Ranching',
  },
  {
    label: 'Real Estate',
    value: 'Real Estate',
  },
  {
    label: 'Recreational Facilities & Services',
    value: 'Recreational Facilities & Services',
  },
  {
    label: 'Religious Institutions',
    value: 'Religious Institutions',
  },
  {
    label: 'Renewables & Environment',
    value: 'Renewables & Environment',
  },
  {
    label: 'Research',
    value: 'Research',
  },
  {
    label: 'Restaurants',
    value: 'Restaurants',
  },
  {
    label: 'Retail',
    value: 'Retail',
  },
  {
    label: 'Security Services',
    value: 'Security Services',
  },
  {
    label: 'Semiconductors',
    value: 'Semiconductors',
  },
  {
    label: 'Shipbuilding',
    value: 'Shipbuilding',
  },
  {
    label: 'Sporting Goods Retail',
    value: 'Sporting Goods Retail',
  },
  {
    label: 'Sports',
    value: 'Sports',
  },
  {
    label: 'Staffing & Recruiting',
    value: 'Staffing & Recruiting',
  },
  {
    label: 'Supermarkets',
    value: 'Supermarkets',
  },
  {
    label: 'Telecommunications',
    value: 'Telecommunications',
  },
  {
    label: 'Textiles Manufacturing',
    value: 'Textiles Manufacturing',
  },
  {
    label: 'Think Tanks',
    value: 'Think Tanks',
  },
  {
    label: 'Tobacco',
    value: 'Tobacco',
  },
  {
    label: 'Translation & Localization',
    value: 'Translation & Localization',
  },
  {
    label: 'Transportation/Trucking',
    value: 'Transportation/Trucking',
  },
  {
    label: 'Utilities',
    value: 'Utilities',
  },
  {
    label: 'Venture Capital & Private Equity',
    value: 'Venture Capital & Private Equity',
  },
  {
    label: 'Veterinary',
    value: 'Veterinary',
  },
  {
    label: 'Warehousing',
    value: 'Warehousing',
  },
  {
    label: 'Wholesale',
    value: 'Wholesale',
  },
  {
    label: 'Wine & Spirits',
    value: 'Wine & Spirits',
  },
  {
    label: 'Wireless',
    value: 'Wireless',
  },
  {
    label: 'Writing & Editing',
    value: 'Writing & Editing',
  },
];
