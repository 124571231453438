import makeStyles from '@material-ui/core/styles/makeStyles';

export const commonStyles = {
  deleteRoot: {
    backgroundColor: 'rgba(255, 99, 99, 0.1)',
  },
  deleteIcon: {
    color: 'rgba(255, 99, 99)',
  },
};

export default makeStyles((theme) => commonStyles);
