import Message from '../../components/Message';
import { firestore } from '../../firebase';
import { COLLECTIONS } from '../constants';
import {
  currentServerTime,
  GenericError,
  transformFirebaseResponse,
  UnableToDelete,
} from '../utils';
import firebase from 'firebase';

/**
 * Adds file to a particular Enterprise
 * @returns {Promise<{[p: string]: any, id: string}>}
 * @param enterpriseId
 * @param userId
 * @param data = { templateName, url }
 */
const addFileToEnterprise = async (enterpriseId, userId, data) => {
  return new Promise(async (resolve, reject) => {
    try {
      const fileId = firestore.collection('tmp').doc().id;

      await firestore
        .collection(COLLECTIONS.ENTERPRISE)
        .doc(enterpriseId)
        .update({
          [`files.${fileId}`]: {
            templateName: data.templateName,
            name: data.fileName,
            url: data.url,
            createdTime: currentServerTime(),
            uploadedBy: userId,
          },
        });

      resolve(true);
    } catch (e) {
      Message.error(`Unable to upload file ${data.templateName}`);
    }
  });
};

const deleteAFileFromEnterprise = async (enterpriseId, fileId) => {
  try {
    await firestore
      .collection(COLLECTIONS.ENTERPRISE)
      .doc(enterpriseId)
      .update({
        ['files.' + fileId]: firebase.firestore.FieldValue.delete(),
      });

    return true;
  } catch (e) {
    UnableToDelete();
  }
};

const fetchEnterpriseFiles = async (enterpriseId) => {
  try {
    const data = await firestore
      .collection(COLLECTIONS.ENTERPRISE)
      .doc(enterpriseId)
      .get();

    return data.data().files;
  } catch (e) {}
};

// const fetchAllEnterprisesWithBatchData = async () => {
//   try {
//     let enterprises = transformFirebaseResponse(
// await firestore.collection(COLLECTIONS.ENTERPRISE).get();
//     );
//     let events = transformFirebaseResponse(
//       await firestore.collection(COLLECTIONS.EVENTS).get()
//     );

//     let data = [];
//     data = enterprises.map((item) => {
//       let totalEvents = events.filter(
//         (event) => event.enterpriseId === item.id
//       );

//       return {
//         ...item,
//         eventsCompleted: events.filter(
//           (event) =>
//             event.enterpriseId === item.id && event.status === 'FINISHED'
//         ),
//         eventsInProgress: totalEvents.filter((e) => e.status === 'INPROGRESS'),
//         totalEvents: totalEvents,
//       };
//     });
//     return data;
//   } catch (e) {
//     console.log(e);
//   }
// };

const fetchAllEnterprises = async () => {
  try {
    let data = await firestore.collection(COLLECTIONS.ENTERPRISE).get();

    return transformFirebaseResponse(data);
  } catch (e) {
    console.log(e);
  }
};

// const createEnterprise = async (data) => {
//   try {
//     const enterprise = await firestore.collection(COLLECTIONS.ENTERPRISE).add({
//       ...data,
//       isActive: true,
//     });

//     return enterprise;
//   } catch (e) {
//     console.log(e);
//     GenericError();
//   }
// };

const fetchEnterpriseById = async (enterpriseId) => {
  if (enterpriseId) {
    try {
      const enterprise = await firestore
        .collection(COLLECTIONS.ENTERPRISE)
        .doc(enterpriseId)
        .get();

      return { ...enterprise.data(), id: enterprise.id };
    } catch (e) {
      console.log(e);
    }
  }
};

const updateTotalHoursEnterprise = async (enterpriseId, diffTotalHours = 0) => {
  try {
    const enterpriseQuery = await firestore
      .collection('Enterprise')
      .doc(enterpriseId)
      .get();

    let enterprise = enterpriseQuery.data();

    await firestore
      .collection(COLLECTIONS.ENTERPRISE)
      .doc(enterpriseId)
      .update({
        totalHours: enterprise.totalHours
          ? enterprise.totalHours + diffTotalHours
          : diffTotalHours,
      });
  } catch (e) {
    console.log(e);
  }
};

export {
  addFileToEnterprise,
  fetchEnterpriseFiles,
  deleteAFileFromEnterprise,
  fetchAllEnterprises,
  // createEnterprise,
  fetchEnterpriseById,
  // fetchAllEnterprisesWithBatchData,
  updateTotalHoursEnterprise,
};
