import React, { useEffect, useState } from 'react';
import SecondaryLayout from '../../../components/SecondaryLayout';
import Layout from '../../../components/Layout';
import { Button, IconButton } from '@material-ui/core';
import { ArrowBack } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';
import TextWithDivider from '../../../components/TextWithDivider';
import Typography from '@material-ui/core/Typography';
import { Form } from 'reactstrap';
import { useFormik } from 'formik';
import * as Yup from 'yup';
import CommonForm from '../../../components/CommonForm';
import { Grid } from '@mui/material';
import { useParams } from 'react-router-dom';
import {
  createSingleManager,
  getManagerById,
  updateSingleManager,
} from '../../../helper/backendHelper/manager';
import { managerArray } from '../../../assets/FormControl/addManager.array';
import { getEnterpriseDataById } from '../../../helper/backendHelper/poc';
import { getAuthToken } from '../../../helper/auth_helper';
import { removeEmptyKey, removeKeyByName } from '../../../utils/objectUtils';
import { useLocation } from 'react-router-dom/cjs/react-router-dom.min';
import Message from '../../../components/Message';

const AddEditManagers = () => {
  const history = useHistory();
  const params = useParams();
  const location = useLocation();
  const collectionEvent = location.state?.collectionEvent
  const [isEdit, setIsEdit] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [eventId, setEventId] = useState(null);
  const [enterpriseId, setEnterpriseId] = useState(null);
  const [updateData, setUpdateData] = useState(null);

  useEffect(() => {
    if (params) {
      setEventId(params.collectionEventId);
      if (params.type === 'edit') {
        getManager(params.id);
      } else {
        getEnterpriseByPoc();
      }
    } else {
      history.goBack();
    }
  }, []);

  // Get Manager for Update 
  const getManager = async (id) => {
    try {
      const res = await getManagerById(id);
      if (res.success) {
        setUpdateData(res.payload);
        setIsEdit(true);
        setinputValue(res.payload);

        if (res.payload.isCollectionStarted) {
          managerArray.map(formField => {
            if (formField.type === "email") {
              formField.disabled = true
            }
            return formField;
          })
        }
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error.response?.data?.message);
    }
  };

  const setinputValue = (updateArray) => {
    managerArray.forEach((element, index) => {
      validation.setFieldValue(element.name, updateArray[element.name], false);
    });
  };

  // Get enterprise
  const getEnterpriseByPoc = async () => {
    try {
      const res = await getEnterpriseDataById(JSON.parse(getAuthToken()).pocId);
      if (res.success) {
        setEnterpriseId(res?.payload?.enterpriseId._id);
        setIsLoading(false);
      }
    } catch (error) {
      console.log(error.response?.data?.message);
    }
  };

  // create initialValues for formik
  const initialValues = () => {
    let Obj = {};
    managerArray.forEach((x, i) => {
      Obj[x.name] = '';
    });
    return Obj;
  };


  // Create formik form
  const validation = useFormik({
    initialValues: { ...initialValues() },
    validationSchema: Yup.object({
      email: Yup.string()
        .email('Invalid email address')
        .required('Please enter your email address'),
      incumbentName: Yup.string().required('Please enter your incumbent name '),
      employeeCode: Yup.string().required('Please enter your employee code '),
    }),
    onSubmit: (values) => {
      if (isEdit) {
        updateManager(values, updateData._id)
      } else {
        createManager(values);
      }
    },
  });

  // create manager
  const createManager = async (values) => {
    try {
      const req = {
        ...removeEmptyKey(values),
        collectionEventId: eventId,
        enterpriseId: enterpriseId,
        isCollectionStarted: false,
      };
      const response = await createSingleManager(req);
      if (response.success) {
        Message.success('Manager created successfully');
        history.goBack()
      }
    } catch (error) {
      Message.error('Please enter valid manager data');
    }
  };

  // update manager
  const updateManager = async (value, id) => {
    try {
      const req = removeKeyByName(value, ['email'])
      const response = await updateSingleManager(req, id);
      if (response.success) {
        Message.success('Manager updated successfully');
        history.goBack()
      }
    } catch (error) {
      Message.error('Please enter valid manager data');
    }
  };

  return (
    <Layout loading={isLoading}>
      {isLoading ? (
        <></>
      ) : (
        <SecondaryLayout>
          <div className="p-5">
            <Grid alignItems="center" className=" d-flex" item>
              <IconButton
                onClick={() => {
                  history.goBack();
                }}>
                <ArrowBack />
              </IconButton>
              <Grid container justifyContent="space-between">
                <Grid>
                  <TextWithDivider>
                    <Typography variant="h3" color="textPrimary">
                      Add Manager
                    </Typography>
                  </TextWithDivider>
                </Grid>
                <Grid sx={{ marginRight: '50px' }}>
                  <TextWithDivider>
                    <Typography
                      variant="h3"
                      color="textPrimary">
                      Collection Event
                    </Typography>
                    <Typography variant="body1" color="textPrimary">
                      {collectionEvent.name}
                    </Typography>
                  </TextWithDivider>
                </Grid>
              </Grid>
            </Grid>

            <Form className="px-3 pt-3" onSubmit={validation.handleSubmit}>
              <CommonForm FormArray={managerArray} FormikGroup={validation} />
              <div className="text-center mt-4">
                <Button
                  className="mt-3"
                  disabled={validation.isSubmitting}
                  disableElevation
                  color="primary"
                  type="submit"
                  variant="contained">
                  {isEdit ? 'Update' : 'Create'} Manager
                </Button>
              </div>
            </Form>
          </div>
        </SecondaryLayout>
      )}
    </Layout>
  );
};

export default AddEditManagers;
