import React, { useContext, useState } from 'react';
import Layout from '../../../../components/Layout';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextWithDivider from '../../../../components/TextWithDivider';
import { AuthContext } from '../../../../components/AuthContextProvider';
import SecondaryLayout from '../../../../components/SecondaryLayout';
import TableWrapper from '../../../POC/components/TableWrapper';
import useFetch from '../../../../hooks/useFetch';
import CommentDialog from '../../../../components/CommentDialog';
import ConfirmationDialog from '../../../../components/ConfirmationDialog';
import { useParams } from 'react-router-dom';
import { getRagDataPOC, updateRAGApproval } from '../../../../operations/poc';
import { addALiveLog } from '../../../../operations/misc';
import { fetchTeamById } from '../../../../operations/session';

const AdminRagTeam = () => {
  let { user } = useContext(AuthContext);
  let [resetSelected, setResetSelected] = useState(false);
  let [loading, setLoading] = useState(false);
  let [selectedIds, setSelectedIds] = useState([]);
  let [ragValue, setApprovalValue] = useState('');
  let [commentDialogOpen, setCommentDialogOpen] = useState(false);
  let [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);

  const { id: teamId, organisationId, eventId } = useParams();

  const {
    data: ragStatus = [],
    loading: ragLoading,
    fetchData,
  } = useFetch(getRagDataPOC, {
    teamId,
  });

  const { data: teamData } = useFetch(fetchTeamById, teamId);
  const isActionable = (row) => {
    return true;
  };

  const handleRagStatusUpdate = (Ids = [], val = '') => {
    setSelectedIds(Ids);
    setApprovalValue(val);
    if (['REJECTED'].includes(val)) {
      setCommentDialogOpen(true);
    } else {
      setConfirmDialogOpen(true);
    }
  };

  const assignRagStatus = async (comment = '') => {
    setLoading(true);
    if (['APPROVED'].includes(ragValue)) {
      comment = '';
    }
    setConfirmDialogOpen(false);
    setCommentDialogOpen(false);

    await updateRAGApproval({
      activityIds: selectedIds,
      ragStatus: ragValue,
      approvalComment: comment,
      userId: user.uid,
    });
    setResetSelected(true);
    await fetchData({ teamId });
    await addALiveLog({
      type: ragValue,
      createdById: user.uid,
      createdByName: 'Admin',
      enterpriseId: organisationId,
      createdForId: teamId,
      eventId: eventId,
      createdForName: teamData.name,
    });
    setLoading(false);
    setResetSelected(false);
    setSelectedIds([]);
    setApprovalValue('');
  };

  return (
    <Layout loading={ragLoading || loading}>
      <SecondaryLayout>
        <Grid container className="pl-4 pr-2">
          <Grid item lg={9} md={8} className="pr-4 pt-4">
            <TextWithDivider>
              <Typography variant="h3" color="textPrimary">
                Approve RAG
              </Typography>
            </TextWithDivider>
            <Grid className="mt-4">
              <TableWrapper
                data={ragStatus}
                id={'approveRagTable'}
                tableHeads={[
                  { id: 'name', label: 'Name' },
                  { id: 'count', label: 'No Of Employees', align: 'center' },
                  { id: 'comment', label: 'Comment' },
                  { id: 'ragValue', label: 'RAG Value' },
                  { id: 'ragStatus', label: 'RAG Status' },
                ]}
                dataKeys={[
                  { id: 'name' },
                  { id: 'count', align: 'center' },
                  { id: 'approvalComment' },
                  { id: 'ragValue', align: 'center' },
                  { id: 'ragStatus', align: 'center' },
                ]}
                handlePrimaryButtonClick={(ids) =>
                  handleRagStatusUpdate(ids, 'APPROVED')
                }
                handleSecondaryButtonClick={(ids) =>
                  handleRagStatusUpdate(ids, 'REJECTED')
                }
                checkId={'id'}
                isActionable={isActionable}
                resetSelected={resetSelected}
                variant={'tableLong'}
                primaryButtonText={'Approve'}
                secondaryButtonText={'Reject'}
              />
            </Grid>

            <CommentDialog
              open={commentDialogOpen}
              handleClose={() => {
                setCommentDialogOpen(false);
                setSelectedIds([]);
                setApprovalValue('');
              }}
              handleSubmit={assignRagStatus}
              title="Add comment"
            />

            <ConfirmationDialog
              open={isConfirmDialogOpen}
              handleClose={() => {
                setConfirmDialogOpen(false);
                setSelectedIds([]);
                setApprovalValue('');
              }}
              title="Are you sure?"
              message="Are you sure you want to continue?"
              handleSubmit={assignRagStatus}
            />
          </Grid>
        </Grid>
      </SecondaryLayout>
    </Layout>
  );
};

export default AdminRagTeam;
