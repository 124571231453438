import React, { useContext, useEffect, useState } from 'react';
import {
  Drawer,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DashboardIcon from '@material-ui/icons/Dashboard';
import GroupIcon from '@material-ui/icons/Group';
import DescriptionOutlinedIcon from '@material-ui/icons/DescriptionOutlined';
import PollOutlinedIcon from '@material-ui/icons/PollOutlined';
import SettingsIcon from '@material-ui/icons/Settings';
import PeopleOutlineIcon from '@material-ui/icons/PeopleOutline';
import AssignmentOutlinedIcon from '@material-ui/icons/AssignmentOutlined';
import EventAvailableIcon from '@mui/icons-material/EventAvailable';
import { useHistory, useLocation } from 'react-router-dom';
import { Paths } from '../../routes/paths';
import { AuthContext } from '../AuthContextProvider';
import { USER_ROLES } from '../../operations/constants';

const drawerWidth = 80;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    backgroundColor: theme.palette.primary.main,
    marginTop: theme.spacing(8.1),
  },
  listItem: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
  },
  listItemIcon: {
    minWidth: 0,
    color: theme.palette.common.white,
  },
  listItemText: {
    fontSize: 12,
    color: theme.palette.common.white,
    letterSpacing: '0.6px',
    textAlign: 'center',
  },
  content: {
    flexGrow: 1,
    marginLeft: drawerWidth,
    marginTop: '60px',
  },
}));

const ManagerList = [
  {
    name: 'Dashboard',
    icon: <DashboardIcon />,
    pathName: Paths.managerDashboard,
  },
  {
    name: 'Employees',
    icon: <GroupIcon />,
    pathName: Paths.managerEmployees,
  },
  {
    name: 'Assign RAG',
    icon: <DescriptionOutlinedIcon />,
    pathName: Paths.managerAssignRag,
  },
  {
    name: 'Events',
    icon: <EventAvailableIcon />,
    pathName: Paths.managerEvents,
  },
  {
    name: 'Analytics',
    icon: <PollOutlinedIcon />,
    pathName: Paths.managerAnalytics,
  },
];

const POCList = [
  {
    name: 'Dashboard',
    icon: <DashboardIcon />,
    pathName: Paths.pocDashboard,
  },
  {
    name: 'Managers',
    icon: <GroupIcon />,
    pathName: Paths.pocManagers,
  },
  {
    name: 'Events',
    icon: <EventAvailableIcon />,
    pathName: Paths.pocEvents,
  },
  {
    name: 'RAG',
    icon: <DescriptionOutlinedIcon />,
    pathName: Paths.pocRag,
  },
  {
    name: 'Analytics',
    icon: <PollOutlinedIcon />,
    pathName: Paths.pocAnalytics,
  },
  {
    name: 'Organization-information',
    icon: <SettingsIcon />,
    pathName: Paths.pocSettings,
  },
];

const AdminList = [
  {
    name: 'Dashboard',
    icon: <DashboardIcon />,
    pathName: Paths.adminDashboard,
  },
  {
    name: 'Organisation',
    icon: <PeopleOutlineIcon />,
    pathName: Paths.adminOrganisation,
  },
  {
    name: 'Events',
    icon: <EventAvailableIcon />,
    pathName: Paths.adminEvents,
  },
  {
    name: 'Survey',
    icon: <AssignmentOutlinedIcon />,
    pathName: Paths.adminSurveys,
  },

  {
    name: 'RAG',
    icon: <DescriptionOutlinedIcon />,
    pathName: Paths.adminRag,
  },
  {
    name: 'Analytics',
    icon: <PollOutlinedIcon />,
    pathName: Paths.adminAnalytics,
  },
];

const ListMap = {
  [USER_ROLES.MANAGER]: ManagerList,
  [USER_ROLES.POC]: POCList,
  [USER_ROLES.ADMIN]: AdminList,
};

const SecondaryLayout = ({ children }) => {
  let { user } = useContext(AuthContext);
  const [list, setList] = useState(ListMap[USER_ROLES.ADMIN]);
  const classes = useStyles();
  const location = useLocation();
  const history = useHistory();

  useEffect(() => {
    if (user && user.role) {
      setList(ListMap[user.role]);
    }
  }, [user]);

  return (
    <React.Fragment>
      <Drawer
        className={classes.drawer}
        variant="permanent"
        classes={{
          paper: classes.drawerPaper,
        }}>
        <List>
          {list.map((item, index) => (
            <ListItem
              button
              onClick={() => history.push(item.pathName)}
              key={index}
              style={
                item.pathName.includes(location.pathname)
                  ? {
                    backgroundImage:
                      'linear-gradient(to top, rgba(255, 255, 255, 0.45), rgba(140, 160, 234, 0.27))',
                  }
                  : {}
              }
              classes={{
                root: classes.listItem,
              }}>
              <ListItemIcon
                classes={{
                  root: classes.listItemIcon,
                }}>
                {item.icon}
              </ListItemIcon>
              <ListItemText
                classes={{
                  primary: classes.listItemText,
                }}
                primary={item.name}
              />
            </ListItem>
          ))}
        </List>
      </Drawer>
      <main className={classes.content}>{children}</main>
    </React.Fragment>
  );
};

export default SecondaryLayout;
