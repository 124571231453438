//* set auth token
export const setAuthToken = (token) => {
  return localStorage.setItem('token', JSON.stringify(token.payload));
};

//* get auth token
export const getAuthToken = () => {
  return localStorage.getItem('token');
};

//* api config - pass the headers
export const getApiConfig = (isFormData) => {
  let config = {};
  let tokenData = getAuthToken();
  tokenData = JSON.parse(tokenData);
  if (tokenData) {
    let headers = {};
    if (isFormData) {
      headers = {
        'Content-Type': `multipart/form-data`,
        'x-auth-token': tokenData.token,
      };
    } else {
      headers = {
        'x-auth-token': tokenData.token,
      };
    }

    config = {
      ...config,
      headers,
    };
  }
  return config;
};
