import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';
import TextWithDivider from '../TextWithDivider';
import { Typography } from '@material-ui/core';
import { getLiveLogsAdmin } from '../../operations/admin';
import LiveLog from '../LiveLog';
import PlaceholderImage from '../PlaceholderImage';
// import { AuthContext } from '../AuthContextProvider';
// import useFetch from '../../hooks/useFetch';
import Metrics from './../../assets/images/metrics.svg';


const ActivityContainer = ({ enterpriseId }) => {
  const [logs, setLogs] = useState([]);

  useEffect(() => {
    getLiveLogsAdmin(setLogs, enterpriseId);
    //eslint-disable-next-line
  }, []);

  return (
    <React.Fragment>
      <Grid className="mt-4">
        <TextWithDivider>
          <Typography variant="h3" color="textPrimary">
            Activities
          </Typography>
        </TextWithDivider>
        <div style={{ height: '250px', overflow: 'scroll' }} className="mt-3">
          {logs && logs.length
            ? logs.map((el, idx) => (
              <LiveLog key={idx} type={el.type} data={el} />
            ))
            : <PlaceholderImage image={Metrics} messsage="No activities logged" />}
        </div>
      </Grid>
    </React.Fragment>
  );
};

export default ActivityContainer;
