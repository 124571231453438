import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextWithDivider from '../../../components/TextWithDivider';
import { AuthContext } from '../../../components/AuthContextProvider';
import SecondaryLayout from '../../../components/SecondaryLayout';
import CustomTabs from '../../../components/CustomTabs';
import TableWrapper from '../components/TableWrapper';
import DepartmentChart from '../../../components/Charts/DepartmentChart';
import RagChart from '../../../components/Charts/RagChart';
import AnalyticsTableWrapper from '../../Manager/Analytics/AnalyticsTableWrapper';
import SelectEvents from '../../../components/SelectEvents';
import Switch from '@mui/material/Switch';
import {
  allManagerRagUtilization,
  allManagerUtilizationGraph,
  getAllManagerRagActivity,
  getAllManagerUtilization,
  getManagerGraphData,
  getManagerRAGChartData,
  getManagerRagActivity,
  getManagerUtilization,
} from '../../../helper/backendHelper/pocAnalytics';
import StatContainer from '../../Manager/components/StatContainer';
import PlaceholderImage from '../../../components/PlaceholderImage';
import board from '../../../assets/images/board.svg';
import {
  getPocEnterpriseReport,
  getPocRagReport,
} from '../../../helper/backendHelper/poc';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const POCAnalytics = () => {
  const { user } = useContext(AuthContext);
  const [actionType, setActionType] = useState('utilization');
  const [event, setEvent] = useState(null);
  const [utilizationData, setUtilizationData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chartTableValue, setChartTableValue] = useState('plotting');
  const [isAllEvent, setIsAllEvent] = useState(true);
  const [renderChart, setRenderChart] = useState(false);
  const [ragAnalysis, setRagAnalysis] = useState({});
  const [isChart, setIsChart] = useState(false);
  const [activityData, setActivityData] = useState([]);
  const [excelReport, setExcelReport] = useState([
    {
      sheet1: 'Enterprise Summary',
      sheet1Data: [],
    },
    {
      sheet2: 'Rag Summary',
      sheet2Data: [],
    },
    {
      sheet3: "Employee Utilisation",
      sheet3Data: []
    }
  ]);
  const [chartData, setChartData] = useState({
    lowerUpperData: null,
    RagData: null,
  });

  useEffect(() => {
    if (isAllEvent) {
      isAllOnChangeHandler();
    }

    if (event) {
      fetchAllManagersUtilization(event, 'utilization');
    }
  }, [chartTableValue]);

  //* fetch all manager utilization
  const fetchAllManagersUtilization = async (data, name) => {
    setActionType(name);
    setLoading(true);
    try {
      switch (name || actionType) {
        case 'utilization':
          //* analytics of manager by collection event id
          if (chartTableValue === 'plotting') {
            setIsChart(true);
            let getLowerUpperData = await getManagerGraphData(data._id, user);
            let getRagChartData = await getManagerRAGChartData(data._id, user);

            setChartData({
              lowerUpperData: getLowerUpperData,
              RagData: getRagChartData,
            });
            setRenderChart((preSave) => !preSave);
          } else {
            const utilization = await getManagerUtilization(data._id, user);
            setUtilizationData(utilization.payload.data);
          }
          break;
        default:
          break;
      }

      //* Rag utilization by collection event id
      const ragAnalysis = await getManagerRagActivity(data._id, user);
      setRagAnalysis(ragAnalysis.payload.data);
      setLoading(false);
    } catch (error) { }
  };

  //* all Rag utilization box
  useEffect(() => {
    fetchAllManagerRagUtilization();
  }, []);
  const fetchAllManagerRagUtilization = async () => {
    try {
      const ragAnalysis = await getAllManagerRagActivity(user);
      setRagAnalysis(ragAnalysis.payload.data);
    } catch (error) { }
  };
  useEffect(() => {
    fetchAllReport();
  }, [event]);

  //* fetch all report of manager
  const fetchAllReport = async () => {
    try {
      let enterpriseSummary = await getPocEnterpriseReport(
        user.pocId,
        event?._id
      );
      enterpriseSummary = enterpriseSummary.payload.map((item) => {
        return {
          'Event Name': item?.managers?.eventName,
          'Team Count': item?.teamCount,
          'Individual Employee Count': item?.individualEmployeeCount,
          'Identical Employee Count': item?.identicalEmployeeCount,
          'Individual Role Count': item?.individualRoleEmployeeCount,
          'Identical Role Count': item?.identicalRoleEmployeeCount,
          'General Shift Count': item?.employeeGeneralShiftCount,
          'Shift Count': item?.employeeShiftCount,
        };
      });

      let ragSummary = await getPocRagReport(user.pocId, event?._id);
      ragSummary = ragSummary.payload.map((item) => {
        return {
          'Team/Employee Name': item?.employeeType === "individual" ? item?.employeeName : item?.teamName,
          'Event Name': item?.eventName,
          'Activity Count': item?.activityCount,
          'Red Hours': item?.redHours,
          'Red Utilization': item?.redUtilization + "%",
          'Amber Hours': item?.amberHours,
          'Amber Utilization': item?.amberUtilization + "%",
          'Green Hours': item?.greenHours,
          'Green Utilization': item?.greenUtilization + "%",
          'Total Hours': item?.totalHours,
          'Total Utilization': item?.totalUtilization + "%",
        };
      });


      let allEmployeeUtilization = await getAllManagerUtilization(user);
      allEmployeeUtilization = allEmployeeUtilization?.payload?.data?.map((item) => {
        return {
          'Employee Name': item.name,
          'Employee Type': item.employeeType,
          'Event/Department': item.collectionEvent,
          "Pre RAG Red Hours": item.preRagDetails.redActivitiesHours,
          "Pre RAG Red FTE": item.preRagDetails.redActivitiesPer + "%",
          "Pre RAG Amber Hours": item.preRagDetails.amberActivitiesHours,
          "Pre RAG Amber FTE": item.preRagDetails.amberActivitiesPer + "%",
          "Pre RAG Green Hours": item.preRagDetails.greenActivitiesHours,
          "Pre RAG Green FTE": item.preRagDetails.greenActivitiesPer + "%",
          "Pre RAG Total Hours": item.preRagDetails.PreRAGValidationUtilizationHrs,
          "Pre RAG Total FTE%": item.preRagDetails.PreRAGValidationUtilizationInPer + "%",
          "Post RAG Red Hours": item.postRagDetails.postRedActivitiesHours,
          "Post RAG Red FTE": item.postRagDetails.postRedActivitiesPer + "%",
          "Post RAG Amber Hours": item.postRagDetails.postAmberActivitiesHours,
          "Post RAG Amber FTE": item.postRagDetails.postAmberActivitiesPer + "%",
          "Post RAG Green Hours": item.postRagDetails.postGreenActivitiesHours,
          "Post RAG Green FTE": item.postRagDetails.postGreenActivitiesPer + "%",
          "Post RAG Total Hours": item.postRagDetails.postRAGTotalHrs,
          "Post RAG Total FTE%": item.postRagDetails.postRAGActualUtilizationInPer + "%",
        }
      })



      excelReport[0].sheet1Data = enterpriseSummary;
      excelReport[1].sheet2Data = ragSummary;
      excelReport[2].sheet3Data = allEmployeeUtilization
      setExcelReport(excelReport);
    } catch (error) {
      console.log(error);
    }
  };

  // //*onchange handler of toggle and showed default all manager utilization
  const isAllOnChangeHandler = async () => {
    setLoading(true);
    try {
      if (chartTableValue === 'plotting') {
        const utilizationOfLowerUpper = await allManagerUtilizationGraph(user);
        const utilizationOfRag = await allManagerRagUtilization(user);
        setChartData({
          lowerUpperData: utilizationOfLowerUpper,
          RagData: utilizationOfRag,
        });
        setRenderChart((preSave) => !preSave);
        setIsChart(true);
        setChartTableValue('plotting');
      } else {
        const dataTable = await getAllManagerUtilization(user);
        setUtilizationData(dataTable.payload.data);
      }
      setLoading(false);
    } catch (error) { }
  };

  return (
    <Layout loading={loading}>
      <SecondaryLayout>
        <Grid container className="pl-4 pr-2">
          <Grid item lg={9} md={8} className="pr-4 pt-4">
            <TextWithDivider>
              <Typography variant="h3" color="textPrimary">
                Analytics - RAG
              </Typography>
            </TextWithDivider>
            <div className="d-flex align-items-center my-2 mx-2">
              <span>All</span>
              <div className=" h-100" style={{ marginRight: '20px' }}>
                <Switch
                  {...label}
                  defaultChecked
                  onChange={(e) => {
                    if (e.target.checked) {
                      setIsAllEvent(true);
                      isAllOnChangeHandler();
                      setEvent(null);
                    } else {
                      setChartData({
                        lowerUpperData: null,
                        RagData: null,
                      });
                      setIsChart(false);
                      setUtilizationData([]);
                      setIsAllEvent(false);
                    }
                  }}
                />
              </div>
              {!isAllEvent && (
                <SelectEvents
                  handleChangeEvents={fetchAllManagersUtilization}
                  setEvent={setEvent}
                  actionType={actionType}
                />
              )}
            </div>

            <Grid container justifyContent="space-between" className="mt-2">
              <Grid
                item
                container
                justifyContent="space-between"
                className="mt-3 pl-4 pr-4">
                <StatContainer
                  color={'red'}
                  value={ragAnalysis.redHours}
                  unit="total hours"
                  label={'• Red'}
                  handleClick={() => null}
                  theme="secondary"
                  additionalData={`${ragAnalysis.redActivities || 0}/${ragAnalysis.totalActivities || 0
                    } activities`}
                />
                <StatContainer
                  value={ragAnalysis.amberHours}
                  color={'#df840f'}
                  unit="total hours"
                  label={'• Amber'}
                  handleClick={() => null}
                  theme="secondary"
                  additionalData={`${ragAnalysis.amberActivities || 0}/${ragAnalysis.totalActivities || 0
                    } activities`}
                />
                <StatContainer
                  value={ragAnalysis.greenHours}
                  color={'green'}
                  unit="total hours"
                  label={'• Green'}
                  handleClick={() => null}
                  theme="secondary"
                  additionalData={`${ragAnalysis.greenActivities || 0}/${ragAnalysis.totalActivities || 0
                    } activities`}
                />
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" className="mt-2">
              <Grid
                item
                container
                justifyContent="space-between"
                className="mt-3 pl-4 pr-4"></Grid>
            </Grid>
            <Grid className="mt-3">
              <CustomTabs
                isCharts={true}
                setActionType={setActionType}
                excelReport={excelReport}
                csvFileName="poc-report"
                event={event}
                handleChangeTabWrapper={fetchAllManagersUtilization}
                setChartTableValue={setChartTableValue}
                chartTableValue={chartTableValue}

                tabs={[
                  {
                    label: 'Utilization',
                    children: (
                      <AnalyticsTableWrapper
                        data={utilizationData}
                        chartTableValue={chartTableValue}
                        id={'utilizationTable'}
                        totalTitle={"Total Employee:"}
                        tableHeads={[
                          { id: 'name', label: 'Name' },
                          // { id: 'subTeam', label: 'Sub Team' },
                          { id: 'employeeType', label: 'Identical/Individual' },
                          { id: 'collectionEvent', label: 'Event' },

                          {
                            id: 'redActivitiesHours',
                            label: 'Red',
                            align: 'center',
                          },
                          {
                            id: 'amberActivitiesHours',
                            label: 'Amber',
                            align: 'center',
                          },
                          {
                            id: 'greenActivitiesHours',
                            label: 'Green',
                            align: 'center',
                          },
                          {
                            id: 'PreRAGValidationUtilizationHrs',
                            label: 'Total Hours',
                            align: 'center',
                          },
                          {
                            id: 'PreRAGValidationUtilizationInPer',
                            label: 'Utilization %/FTE',
                            align: 'center',
                          },
                          {
                            id: 'postRedActivitiesHours',
                            label: 'Red',
                            align: 'center',
                          },
                          {
                            id: 'postAmberActivitiesHours',
                            label: 'Amber',
                            align: 'center',
                          },
                          {
                            id: 'postGreenActivitiesHours',
                            label: 'Green',
                            align: 'center',
                          },
                          {
                            id: 'postRAGTotalHrs',
                            label: 'Total Hours',
                            align: 'center',
                          },
                          {
                            id: 'postRAGActualUtilizationInPer',
                            label: 'Utilization %/FTE',
                            align: 'center',
                          },
                        ]}
                        dataKeys={[
                          { id: 'name' },
                          { id: 'employeeType' },
                          { id: 'collectionEvent' },
                          {
                            id: 'preRagDetails.redActivitiesHours',
                            align: 'center',
                          },
                          {
                            id: 'preRagDetails.amberActivitiesHours',
                            align: 'center',
                          },
                          {
                            id: 'preRagDetails.greenActivitiesHours',
                            align: 'center',
                          },
                          {
                            id: 'preRagDetails.PreRAGValidationUtilizationHrs',
                            align: 'center',
                          },
                          {
                            id: 'preRagDetails.PreRAGValidationUtilizationInPer',
                            align: 'center',
                          },
                          {
                            id: 'postRagDetails.postRedActivitiesHours',
                            align: 'center',
                          },
                          {
                            id: 'postRagDetails.postAmberActivitiesHours',
                            align: 'center',
                          },
                          {
                            id: 'postRagDetails.postGreenActivitiesHours',
                            align: 'center',
                          },
                          {
                            id: 'postRagDetails.postRAGTotalHrs',
                            align: 'center',
                          },
                          {
                            id: 'postRagDetails.postRAGActualUtilizationInPer',
                            align: 'center',
                          },
                        ]}
                        isSelectable={false}
                      />
                    ),
                  },
                  {
                    label: 'Activity',
                    children: (
                      <TableWrapper
                        data={activityData}
                        id={'activityAnalyticsTable'}
                        tableHeads={[
                          { id: 'activityName', label: 'Activity' },
                          { id: 'process', label: 'Process' },
                          { id: 'subProcess', label: 'Sub-Process' },
                          {
                            id: 'totalHours',
                            label: 'Total Hours',
                            align: 'center',
                          },
                        ]}
                        dataKeys={[
                          { id: 'activityName' },
                          { id: 'process' },
                          { id: 'subProcess' },
                          { id: 'totalHours', align: 'center' },
                        ]}
                        isSelectable={false}
                      />
                    ),
                  },
                ]}
              />
            </Grid>

            {!chartData.lowerUpperData &&
              !chartData.RagData &&
              chartTableValue === 'plotting' ? (
              <PlaceholderImage
                image={board}
                messsage={'No records found'}
                width="25%"
              />
            ) : chartTableValue === 'plotting' &&
              actionType === 'utilization' &&
              isChart ? (
              <>
                <DepartmentChart
                  setChartData={setChartData}
                  data={chartData?.lowerUpperData}
                  renderChart={renderChart}
                />
                <RagChart
                  data={chartData?.RagData}
                  renderChart={renderChart}
                  setChartData={setChartData}
                />
              </>
            ) : null}
          </Grid>
        </Grid>
      </SecondaryLayout>
    </Layout>
  );
};

export default POCAnalytics;
