import React, { useContext } from 'react';
import Grid from '@material-ui/core/Grid';
import TextWithDivider from '../TextWithDivider';
import { Typography } from '@material-ui/core';
import { AuthContext } from '../AuthContextProvider';
import { makeStyles } from '@material-ui/core/styles';
import useFetch from '../../hooks/useFetch';
import { fetchTeamById } from '../../operations/session';
import ActivityContainer from '../ActivityContainer';

const useStyles = makeStyles((theme) => ({
  container: {
    borderLeft: '4px solid #f6f7fa',
    height: '100vh',
  },
  verticalDivider: {
    width: '4px',
    borderRadius: '4px',
  },
  infoContainer: {
    backgroundColor: 'rgba(244, 245, 250, 0.4)',
    height: '300px',
    overflowY: 'scroll',
  },
}));

const userDataMap = [
  { label: 'NAME', key: 'name' },
  { label: 'COMPANY EMAIL', key: 'email' },
  { label: 'DESIGNATION', key: 'designation' },
  { label: 'QUALIFICATION', key: 'qualification' },
];

const teamDataMap = [
  { label: 'TEAM', key: 'name' },
  { label: 'MANAGER EMAIL', key: 'managerEmail' },
];

const ProfileContainer = () => {
  let { user } = useContext(AuthContext);
  const classes = useStyles();

  const { data: team = {} } = useFetch(fetchTeamById, user.teamId);

  return (
    <React.Fragment>
      <Grid item lg={3} className={`${classes.container} pl-3 pr-2 pt-5`}>
        <Grid>
          <TextWithDivider>
            <Typography variant="h3" color="textPrimary">
              Profile Information
            </Typography>
          </TextWithDivider>

          <Grid className={`${classes.infoContainer} p-4 mt-3`}>
            {userDataMap.map((item, index) => {
              return user[item.key] ? (
                <Grid key={index} className="mb-4">
                  <Typography variant="body2" color="textSecondary">
                    {item.label}
                  </Typography>
                  <Typography variant="body1">{user[item.key]}</Typography>
                </Grid>
              ) : null;
            })}
            {teamDataMap.map((item, index) => {
              return team[item.key] ? (
                <Grid key={index} className="mb-4">
                  <Typography variant="body2" color="textSecondary">
                    {item.label}
                  </Typography>
                  <Typography variant="body1">{team[item.key]}</Typography>
                </Grid>
              ) : null;
            })}
          </Grid>
        </Grid>

        <ActivityContainer enterpriseId={user.enterpriseId} />
      </Grid>
    </React.Fragment>
  );
};

export default ProfileContainer;
