import React from 'react';
import { Button, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import AddIcon from '@material-ui/icons/Add';
import EmptyLogEffortImage from '../../../../assets/images/EmptyLog.png';

const useStyles = makeStyles((theme) => ({
  container: {
    height: '75%',
  },
  emptyImage: {
    width: '255px',
    height: '255px',
  },
}));

const EmptyLogEffort = ({ handleCreateClick }) => {
  const classes = useStyles();
  return (
    <Grid container alignItems="center" className={classes.container}>
      <Grid container item alignItems="center" direction="column">
        <Typography variant="subtitle2" color="textSecondary">
          There’s no data to show!! Start adding now
        </Typography>
        <img
          src={EmptyLogEffortImage}
          alt="emptyLogEffort"
          className={`${classes.emptyImage} mt-4`}
        />
        <Button
          className="mt-4"
          variant="contained"
          disableElevation
          onClick={() => handleCreateClick()}
          color="primary"
          startIcon={<AddIcon />}>
          Log New Effort
        </Button>
      </Grid>
    </Grid>
  );
};

export default EmptyLogEffort;
