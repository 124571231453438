import React, { useEffect, useState } from 'react';

import { fetchActivityEffortsByUserId } from '../../operations/employee';
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';

function EmployeeEffortLogComponent({ userId, logs }) {
  const [effortLog, setEffortLog] = useState([]);
  useEffect(() => {
    getEffortLog();
  }, []);

  const getEffortLog = async () => {
    if (userId && !logs) {
      const data = await fetchActivityEffortsByUserId(userId);
      setEffortLog(data);
    }
  };
  return (
    <div style={{ maxHeight: 'calc(100vh - 100px)', overflow: 'auto' }}>
      <TableContainer>
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead>
            <TableRow>
              <TableCell>Process</TableCell>
              <TableCell>SubProcess</TableCell>
              <TableCell>Activity</TableCell>
              <TableCell>Application Used</TableCell>
              <TableCell>Frequency</TableCell>
              <TableCell>Hours</TableCell>
              <TableCell>Individual Volume</TableCell>
              <TableCell>Total Hours</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {effortLog.map((row) => (
              <TableRow
                key={row.activityId}
                sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                <TableCell scope="row">{row.process}</TableCell>
                <TableCell scope="row">{row.subProcess}</TableCell>
                <TableCell scope="row">{row.activityName}</TableCell>
                <TableCell scope="row">{row.applicationUsed}</TableCell>
                <TableCell scope="row">{row.frequency}</TableCell>
                <TableCell scope="row">{row.hours}</TableCell>
                <TableCell scope="row">{row.individualVolume}</TableCell>
                <TableCell scope="row">{row.totalHours}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}

export default EmployeeEffortLogComponent;
