import { makeStyles } from '@material-ui/core';
import React from 'react';
import NoData from './../../assets/images/nodata.svg';

const useStyles = makeStyles((theme) => ({
    container: {
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        gap: 20,
        height: '100%'
    },
    placeholderImage: {
      width: '70%',
    },
    messsage: {
      margin: '0px',
      textAlign: 'center',
      color: theme.palette.grey['600'],
    },
  }));

function PlaceholderImage({image, messsage, width = "70%"}) {
    const classes = useStyles();
    return (
        <div className={classes.container}>
            <img src={image || NoData} alt="PlaceHolderImage" width={width} />
            <h4 className={classes.messsage}>{messsage}</h4>
        </div>
    )
}

export default PlaceholderImage
