import React from 'react';
import { TextField } from '@material-ui/core';
import DialogWrapper from '../DialogWrapper';

const CommentDialog = ({
  open,
  handleClose,
  handleSubmit,
  title,
  setComment,
  comment,
}) => {
  return (
    <DialogWrapper
      open={open}
      handleClose={() => {
        setComment('');
        handleClose();
      }}
      title={title}
      handleSubmit={() => {
        handleSubmit(comment);
        setComment('');
      }}
      disabled={!comment}
      submitButtonTitle="Submit"
      cancelButtonTitle="Cancel"
      dialogId="alert-save">
      <TextField
        multiline={true}
        rows={3}
        fullWidth
        placeholder="Enter Comment"
        onChange={(evt) => {
          setComment(evt.target.value);
        }}
        value={comment}
      />
    </DialogWrapper>
  );
};

export default CommentDialog;
