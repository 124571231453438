import {
  Box,
  Button,
  Grid,
  IconButton,
  Tab,
  Tabs,
  Typography,
} from '@material-ui/core';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import SecondaryLayout from '../../../components/SecondaryLayout';
import TextWithDivider from '../../../components/TextWithDivider';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import { useHistory, useParams } from 'react-router';
import EventCard from '../components/EventCard';
import { Paths } from '../../../routes/paths';
import useFetch from '../../../hooks/useFetch';
import {
  fetchEventsByEnterpriseId,
  fetchPocsByEnterpriseId,
} from '../../../operations/poc';
import { fetchEnterpriseById } from '../../../operations/enterprise';
import TemplateUploader from '../../POC/components/TemplateUploader';
import { async } from 'q';
import Message from '../../../components/Message';
import {
  deleteEvents,
  getEventsOrganizationId,
} from '../../../helper/backendHelper/events';
import { getPocOrganizationId } from '../../../helper/backendHelper/poc';
import PlaceholderImage from '../../../components/PlaceholderImage';
import board from '../../../../src/assets/images/board.svg';

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}>
      {value === index && <Box p={3}>{children}</Box>}
    </div>
  );
}

const SelectedOrganisation = () => {
  const history = useHistory();
  const { organizationId } = useParams();
  const [tabIndex, setTabIndex] = useState(0);
  const [events, setEvents] = useState([]);
  const [save, setSave] = useState(false);

  const handleChange = (event, newValue) => {
    setTabIndex(newValue);
  };

  useEffect(() => {
    //eslint-disable-next-line
    if (organizationId) {
      fetchEventsOrganizationId(organizationId);
    }
  }, [organizationId, save]);

  //fetch events by organization id
  const fetchEventsOrganizationId = async (id) => {
    try {
      const response = await getEventsOrganizationId(id);
      setEvents(response.payload.data);
    } catch (error) {
      Message.error(error?.response?.data?.message);
    }
  };

  const handleDeleteEvents = async (id) => {
    try {
      const response = await deleteEvents(id);
      Message.success(response.message);
    } catch (error) {
      Message.error(error?.response?.data?.message);
    }
  };

  return (
    <Layout>
      <SecondaryLayout>
        <div className="mx-5">
          <Grid container justifyContent="space-between" className="p-4">
            <Grid container item xs={6}>
              <IconButton
                onClick={() => {
                  history.goBack();
                }}>
                <ArrowBackIcon />
              </IconButton>
              <Grid>
                <TextWithDivider>
                  <Typography variant="h3" color="textPrimary">
                    {/* {Object.keys(enterprise).length ? enterprise.name : ''} */}
                  </Typography>
                </TextWithDivider>
              </Grid>
            </Grid>
            {/* <Grid container justifyContent="flex-end" item xs={6}>
              <Button
                disableElevation
                startIcon={<AddCircleOutline />}
                color="primary"
                onClick={() => {
                  history.push(`${Paths.adminCreateEvent}/${organizationId}`);
                }}
                variant="contained">
                Add New Event
              </Button>
            </Grid> */}
          </Grid>

          <Tabs className="mx-5" value={tabIndex} onChange={handleChange}>
            {/* <Tab label="Organization Details" /> */}
            <Tab label="Uploaded Files" />
          </Tabs>
          <TabPanel value={tabIndex} index={0}>
            {/* {events.length == 0 && (
              <PlaceholderImage
                image={board}
                messsage="Collection events not found"
                width="30%"
              />
            )} */}
            {events.map((el, idx) => (
              <>
                <EventCard
                  save={setSave}
                  name={el.name}
                  startDate={el.startDate}
                  estimatedCompletionDate={el.endDate}
                  eventId={el._id}
                  key={idx}
                  status={el.status}
                  poc={el?.pocId?.email}
                  location={el?.enterpriseId?.location}
                />
              </>
            ))}
          </TabPanel>
          <TabPanel className="mx-5" value={tabIndex} index={1}>
            Uploaded Files
            {events.length !== 0 ? (
              events.map((item, index) => (
                <TemplateUploader
                  fileObj={item}
                  image={item.enterpriseId?.logoUrl}
                  key={index}
                  isStatic={true}
                  progress={0}
                  onDelete={handleDeleteEvents}
                />
              ))
            ) : (
              <Typography className="mt-5" variant="body2">
                No files uploaded{' '}
              </Typography>
            )}
          </TabPanel>
        </div>
      </SecondaryLayout>
    </Layout>
  );
};

export default SelectedOrganisation;
