import firebase from 'firebase/app';
import Message from '../components/Message';

export const currentServerTime = () =>
  firebase.firestore.FieldValue.serverTimestamp();

export const timestampFromDate = (inputDate) =>
  firebase.firestore.Timestamp.fromDate(inputDate);

export const currentUser = () => firebase.auth().currentUser;

/*
  MESSAGES
 */

/**
 * Generic error - form validation
 */
export const GenericErrorForm = () =>
  Message.error('Fields marked as * are required.');

/**
 * Generic error - form validation
 */
export const EnterValidEmail = () =>
  Message.error('Please enter a valid email address.');

/**
 * Generic success - Update
 */
export const GenericSuccessUpdate = () =>
  Message.success('Data updated successfully.');

/**
 * Generic error - Update
 */
export const UnableToUpdate = (e) =>
  Message.error('Unable to update data at this time. Please try again later.');

export const UnableToFetchData = () =>
  Message.error(`Unable to get data at the moment.`);

/**
 * Generic error - Create
 */
export const UnableToCreate = (e) => Message.error(e.message);

/**
 * Generic error - Create
 */
export const GenericError = (e) =>
  Message.error('Something went wrong. Please try again later.');

/**
 * Generic create - Success
 */
export const CreatedSuccessFully = () =>
  Message.success('Created successfully.');

/**
 * Generic delete - Success
 */
export const DeletedSuccessfully = () =>
  Message.success('Deleted successfully.');

/**
 * Generic delete - Error
 */
export const UnableToDelete = () =>
  Message.success('Unable to delete. Please try again later.');

/**
 * TransformFirebaseResponse
 * @param results
 * @returns {*}
 */
export const transformFirebaseResponse = (results = []) => {
  return results.docs.map((item) => {
    return { ...item.data(), id: item.id };
  });
};
