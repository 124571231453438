import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import { Button, CircularProgress, Grid, Typography } from '@material-ui/core';
import { AuthContext } from '../../../components/AuthContextProvider';
import SecondaryLayout from '../../../components/SecondaryLayout';
import { useHistory, useParams } from 'react-router';

import Question from '../components/Question';

import { colors } from '../../../theme/colors';
import {
  addAnswersToSurveyAnswers,
  fetchSurveyAnswerByUserId,
  fetchSurveyById,
} from '../../../operations/admin';
import TextWithDivider from '../../../components/TextWithDivider';

const ViewSurvey = () => {
  let { user } = useContext(AuthContext);

  const { surveyId } = useParams();
  const [survey, setSurvey] = useState('');
  const [saveSurveyLoading, setSaveSurveyLoading] = useState(false);
  const [questions, setQuestions] = useState([]);
  const history = useHistory();

  useEffect(() => {
    (async () => {
      const data = await fetchSurveyById(surveyId);
      // if (data.role !== user.role) {
      //   history.push('/poc/dashboard');
      //   Message.error('Access Denied.');
      // }
      if (data) {
        setSurvey(data);
        setQuestions(data.questions);
      }

      const answerAlready = await fetchSurveyAnswerByUserId('user.id');
      if (answerAlready) {
        //TODO:redirect back with message
      }
    })();
    //eslint-disable-next-line
  }, []);

  const submitSurvey = async () => {
    const sanitizedAnswers = JSON.parse(JSON.stringify([...questions]));

    sanitizedAnswers.forEach((el) => {
      delete el.options;
    });

    const surveyData = JSON.parse(JSON.stringify(survey));
    delete surveyData.questions;
    delete surveyData.isActive;

    const payload = {
      ...surveyData,
      answers: sanitizedAnswers,
      answeredById: user.id,
      answeredByName: user.name,
    };

    try {
      setSaveSurveyLoading(true);
      await addAnswersToSurveyAnswers(payload);
      history.push('/');
    } catch (e) {
    } finally {
      setSaveSurveyLoading(false);
    }
  };

  //   useEffect(() => {
  //     console.log(questions);
  //   }, [questions]);

  return (
    <Layout>
      <SecondaryLayout>
        <Grid
          className="px-5 py-3"
          container
          justifyContent="flex-end"
          style={{ position: 'sticky', top: '65px' }}
          alignItems="center">
          <Grid
            container
            justifyContent="flex-start"
            alignItems="center"
            item
            xs={6}>
            <div>
              <TextWithDivider>
                <Typography variant="h5">{`Survey  ${survey.eventName || ''}  ${
                  survey.enterpriseName || ''
                } ${survey.role}`}</Typography>
              </TextWithDivider>
            </div>
          </Grid>
          <Grid
            container
            justifyContent="flex-end"
            alignItems="center"
            item
            xs={6}>
            {' '}
            <Button
              disableElevation
              color="primary"
              variant="contained"
              disabled={saveSurveyLoading}
              onClick={submitSurvey}>
              {saveSurveyLoading ? (
                <CircularProgress
                  style={{ color: colors.common.black }}
                  size={20}
                />
              ) : (
                'Submit Survey'
              )}
            </Button>
          </Grid>
        </Grid>

        {questions && (
          <div
            style={{ maxHeight: '3000px', overflowY: 'scroll' }}
            className="ml-5">
            {questions.length &&
              questions.map((el, idx) => (
                <Question
                  isStatic={true}
                  key={el.id}
                  question={el}
                  index={idx}
                  allQuestions={questions}
                  setQuestions={setQuestions}
                />
              ))}
          </div>
        )}
      </SecondaryLayout>
    </Layout>
  );
};

export default ViewSurvey;
