import { useState, useEffect } from 'react';

const useFetch = (query, params) => {
  const [loading, setLoading] = useState(true);
  const [data, setData] = useState();

  const fetchData = async (params) => {
    try {
      setLoading(true);
      const result = await query(params);
      setData(result);
      setLoading(false);
    } catch (error) {
      console.error(error, "--=")
    }
  };

  useEffect(() => {
    if (!query) {
      return;
    }

    fetchData(params);

    //eslint-disable-next-line
  }, []);

  return { loading, data, fetchData };
};

export default useFetch;
