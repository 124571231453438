import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import {
  Button,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextWithDivider from '../../../components/TextWithDivider';
import SecondaryLayout from '../../../components/SecondaryLayout';
import CustomTabs from '../../../components/CustomTabs';
import TableWrapper from '../components/TableWrapper';

import IdenticalTableWrapper from '../components/IdenticalTableWrapper';
import DialogWrapper from '../../../components/DialogWrapper';
import Message from '../../../components/Message';
import {
  getIndividualEmployees,
  getIdenticalEmployees,
  resendCredentialsEmployee,
  deleteEmployeeById,
  createEmployeeBulk,
  updateEmployeeFlag,
} from '../../../helper/backendHelper/employee';
import ManagerUploadSurface from '../components/UploadSuface';
import { loginEmployee } from '../../../helper/backendHelper/sendEmails';
import { downloadFile } from '../../../utils/downloadFile';
import { EventContext } from '../../../components/EventContextProvider';
import board from '../../../../src/assets/images/board.svg';
import excelSheet from '../../../../src/assets/csv/EmployeeTemplate.csv';
import axios from 'axios';
import { getAllTeam } from '../../../helper/backendHelper/team';
import SelectEvents from '../../../components/SelectEvents';
import { Paths } from '../../../routes/paths';
import { useHistory } from 'react-router';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { InfoOutlined } from '@material-ui/icons';

const ManagerEmployees = () => {
  const { events } = useContext(EventContext);
  const [identicalEmployees, setIdenticalEmployees] = useState([]);
  const [loading, setLoading] = useState(false);
  const [isReset, setIsReset] = useState(false);
  const [erroredUsers, setErroredUsers] = useState([]);
  const [uploadMode, setUploadMode] = useState(false);
  const [employeesData, setEmployeesData] = useState([]);
  const [event, setEvent] = useState(null);
  const [team, setTeam] = useState([]);
  const [actionType, setActionType] = useState('');
  const [excelData, setExcelData] = useState(null);
  const [isConfirmDeleteOpen, setConfirmDeleteOpen] = useState(false);
  const [deleteId, setDeleteId] = useState(null);
  const [tooltip, setTooltip] = useState(false);
  // const [event, setEvent] = useState(null);

  const history = useHistory();

  //* read excel sheet and get data
  useEffect(() => {
    axios
      .get(excelSheet)
      .then((response) => {
        setExcelData(response.data);
      })
      .catch((error) => { });
  }, []);



  //* handle change employee data by event id
  const handleChangeEmployeesByEventId = async (data, name) => {



    if (!data?.isManagerExists) {
      setUploadMode(false)
    }


    setActionType(name);

    try {
      //* identical employees
      switch (name) {
        case 'identical':
          const identical = await getIdenticalEmployees(data?._id);
          setIdenticalEmployees(identical?.payload?.data);
          break;
        case 'individual':
          const individual = await getIndividualEmployees(data._id);
          setEmployeesData(individual?.payload?.data);
          break;
        default:
          const individualData = await getIndividualEmployees(data._id);
          setEmployeesData(individualData?.payload?.data);
          break;
      }
      //* fetch All team
      const response = await getAllTeam(data._id);
      setTeam(response?.payload?.data);
    } catch (error) {
      Message.error(error?.response?.data?.message);
    }
  };

  //* handle start work collection
  const handleStartWorkLoadCollection = async (
    selectedIdsMap = [],
    flaggedId
  ) => {
    try {
      setLoading(true);
      const response = await loginEmployee({ ids: selectedIdsMap });
      if (flaggedId) {
        await updateEmployeeFlag(flaggedId, {
          isFlaggedUser: true,
        });
      }
      handleChangeEmployeesByEventId(event, actionType);
      Message.success(response.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Message.error(error?.response?.data?.message);
    }
  };

  //* uploading Employee csv file
  const handleEmployeeUploads = async (data = []) => {

    // if employee code will be empty, we will not consider that or whole row.
    data = data.filter(row => row.email !== "")

    data = data.filter(row => row.employeeCode !== "")


    data = data.map((item) => {
      return (item = {
        ...item,
        employeeType: item.employeeType.toLowerCase(),
        email: item.email.toLowerCase(),
        collectionEventId: event._id,
        enterpriseId: event?.enterpriseId?._id,
      });
    });

    try {
      setLoading(true);
      const response = await createEmployeeBulk({
        employeeData: data,
      });
      Message.success(response.message);
      setUploadMode(false);
      handleChangeEmployeesByEventId(event, actionType);
      setIsReset(true);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      setIsReset(true);
      Message.error(error?.response?.data?.message);
    }
  };

  //* if collection started => select not actionable
  const isActionable = (data) => {
    return !data.isCollectionStarted;
  };


  const handleResendCredentials = async (employeeId) => {
    try {
      setLoading(true);
      const response = await resendCredentialsEmployee({ employeeId })
      Message.success(response.message);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Message.error(error?.response?.data?.message);
    }
  }

  const editEmployeById = (id) => {
    history.push({
      pathname: `${Paths.Employe}/edit/${event._id}/${id}`,
      state: { collectionEvent: event }
    });
  }
  const deleteEmploye = async () => {

    const res = await deleteEmployeeById(deleteId)
    if (res.success) {
      // fetchManagersByEvents(event)
      handleChangeEmployeesByEventId(event, actionType);
      setConfirmDeleteOpen(false);
      setDeleteId(null)
      Message.success('Meneger deleted successfully');
    }
  }

  return (
    <Layout loading={loading}>
      <SecondaryLayout>
        <Grid container className="pl-4 pr-2">
          <Grid item lg={9} md={8} className="pr-4 pt-4">
            <Grid container item justifyContent="space-between">
              <Grid>
                <TextWithDivider>
                  <Typography variant="h3" color="textPrimary">
                    Employees
                    <InfoOutlined color="primary" onClick={() => setTooltip(true)} className='ml-2' />
                  </Typography>
                </TextWithDivider>
              </Grid>
              {event?.isManagerExists ? <div><Button
                variant="contained"
                size="small"
                disableElevation
                onClick={() => setUploadMode(true)}
                color="primary">
                Upload Employee Data
              </Button>
                <Button
                  className='ml-3'
                  variant="outlined"
                  size="small"
                  disableElevation
                  disabled={event ? false : true}
                  onClick={() =>
                    history.push({
                      pathname: `${Paths.Employe}/add/${event._id}`,
                      state: { collectionEvent: event }
                    })
                  }
                  color="primary">
                  Add Employee
                </Button>
              </div>
                : null}
            </Grid>
            <SelectEvents
              handleChangeEvents={handleChangeEmployeesByEventId}
              setEvent={setEvent}
              actionType={actionType}
            />

            {!uploadMode ? (
              <Grid className="mt-3">
                <CustomTabs
                  setActionType={setActionType}
                  handleChangeTabWrapper={handleChangeEmployeesByEventId}
                  individualIdenticalWrap={true}
                  event={event}
                  tabs={[
                    {
                      label: 'Individual',
                      children: (
                        <TableWrapper
                          actionType={actionType}
                          handleChangeEmployeesByEventId={
                            handleChangeEmployeesByEventId
                          }
                          replace={true}
                          event={event}
                          uploadMode={uploadMode}
                          data={employeesData}
                          team={team}
                          id={'EmployeesTable'}
                          tableHeads={[
                            { id: 'employeeName', label: 'Employee Name' },
                            { id: 'email', label: 'Email Id' },
                            { id: 'designation', label: 'Designation' },
                            { id: 'level', label: 'Level' },
                            { id: 'shift', label: 'Shift' },
                            {
                              id: 'collectionStarted',
                              label: 'Collection Started',
                              align: 'center',
                            },
                            {
                              id: 'action',
                              label: 'Action',
                              align: 'center',
                            },
                          ]}
                          dataKeys={[
                            { id: 'employeeName' },
                            { id: 'email' },
                            { id: 'designation' },
                            { id: 'level' },
                            { id: 'shift' },
                            { id: 'collectionStarted', align: 'center' },
                          ]}
                          handlePrimaryButtonClick={
                            handleStartWorkLoadCollection
                          }
                          primaryButtonText="Start Workload Collection"
                          checkId={'_id'}
                          isActionable={isActionable}
                          disabled={loading}
                          otherActions={true}
                          resendCredentialsAction={handleResendCredentials}
                          editAction={editEmployeById}
                          deleteAction={(id) => {
                            setDeleteId(id)
                            setConfirmDeleteOpen(true)
                          }}
                        />
                      ),
                    },
                    {
                      label: 'Identical',
                      children: (
                        <IdenticalTableWrapper
                          actionType={actionType}
                          handleChangeEmployeesByEventId={
                            handleChangeEmployeesByEventId
                          }
                          event={event}
                          uploadMode={uploadMode}
                          data={identicalEmployees}
                          id={'identicalEmployeeTable'}
                          subHeads={[
                            { id: 'employeeName', label: 'Name' },
                            { id: 'email', label: 'Email Id' },
                            { id: 'designation', label: 'Designation' },
                            { id: 'level', label: 'Level' },
                            { id: 'shift', label: 'Shift', align: 'center' },
                            { id: 'action', label: 'Action', align: 'center' },
                          ]}
                          tableHeads={[{ id: 'subTeam', label: 'Sub Team' }]}
                          dataKeys={[
                            { id: 'employeeName' },
                            { id: 'email' },
                            { id: 'designation' },
                            { id: 'level' },
                            { id: 'shift', align: 'center' },
                          ]}
                          handlePrimaryButtonClick={
                            handleStartWorkLoadCollection
                          }
                          primaryButtonText="Start Workload Collection"
                          checkId={'_id'}
                          isActionable={isActionable}
                          disabled={loading}
                          otherActions={true}
                          resendCredentialsAction={handleResendCredentials}
                          editAction={editEmployeById}
                          deleteAction={(id) => {
                            setDeleteId(id)
                            setConfirmDeleteOpen(true)
                          }}
                        />
                      ),
                    },
                  ]}
                />
              </Grid>
            ) : null}

            {uploadMode ? (
              <>
                <ManagerUploadSurface
                  handleDownloadClick={async () => {
                    setLoading(true);
                    downloadFile(excelData, 'Employee-template.csv');
                    setLoading(false);
                  }}
                  handleBack={() => setUploadMode(false)}
                  eventId={event}
                  isData={Boolean(employeesData.length)}
                  isReset={isReset}
                  handleCreateClick={handleEmployeeUploads}
                />
              </>
            ) : null}
            <DialogWrapper
              open={Boolean(erroredUsers.length)}
              handleClose={() => setErroredUsers([])}
              title={'Errors with following users'}
              cancelButtonTitle="Close"
              dialogId="alert-errored-users">
              <Grid container direction="column">
                {erroredUsers.map((item, index) => (
                  <Typography variant="caption" key={index}>
                    Email: {item.email} at index {item.index}.
                  </Typography>
                ))}
              </Grid>
              <Typography variant="body2" className="mt-3">
                *Please check if email is present and is unique,
                Individual/Identical, Sub Team columns.
              </Typography>
            </DialogWrapper>
          </Grid>

          <ConfirmationDialog
            open={isConfirmDeleteOpen}
            handleClose={() => {
              setDeleteId(null)
              setConfirmDeleteOpen(false);
            }}
            title="Are you sure?"
            message="Are you sure you want to delete employee?"
            handleSubmit={(result) => {
              deleteEmploye()
            }}
          />
        </Grid>
      </SecondaryLayout>
      <DialogWrapper
        title="User Guide for Employee"
        open={tooltip}
        handleClose={() => setTooltip(false)}>
        <div className='overflow-auto'>
          <div>
            <b>1. Select Collection Events.</b>
            <ul>
              <li>In the top left corner, click on <b>“Collections Events”</b></li>
              <li>
                Choose the specific event related to <b>Business Development</b>,<b> Finance</b> or <b>Operations</b> that you are assigned to.
              </li>
            </ul>
          </div>
          <div className='mt-3'>
            <b>2. Upload Employee Data</b>
            <ul>
              <li>Click on <b>"Upload Employee Data"</b>.</li>
              <li>
                If it's your first time, select <b>"Download Template"</b> to obtain a
                CSV template.
              </li>
            </ul>
          </div>
          <div className='mt-3'>
            <b>3. Fill the Template</b>
            <ul>
              <li>Use the example data provided as a reference.</li>
              <li>
                Enter <b>email addresses</b>, <b>employee roles</b>, <b>departments</b>, etc.,
                relevant to your assigned event.
              </li>
            </ul>
          </div>
          <div className='mt-3'>
            <b>4. Upload Completed Template</b>
            <ul>
              <li>
                After filling out the template, upload it in the <b>"Upload Employee Data"</b>  section next to the <b>"Download Template"</b>
                option.
              </li>
              <li>
                Enter <b>email addresses</b>, <b>employee roles</b>, <b>departments</b>, etc.,
                relevant to your assigned event.
              </li>
            </ul>
          </div>
        </div>
      </DialogWrapper>
    </Layout>
  );
};

export default ManagerEmployees;
