import { get, post } from '../api_helper';
import * as url from '../url_helper';
import { getApiConfig } from '../auth_helper';

//* login manager and send email
export const loginManager = async (data) => {
  return await post(`${url.MANAGER}/sendEmails`, data, getApiConfig());
};

//* login employee and send email
export const loginEmployee = async (data) => {
  return await post(`${url.EMPLOYEE}/sendEmails`, data, getApiConfig());
};
