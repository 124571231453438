import React from 'react';
import Grid from '@material-ui/core/Grid';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles((theme) => ({
  statContainer: {
    width: 196,
    borderRadius: 4,
  },
  primary: {
    backgroundColor: theme.palette.primary.main,
    color: theme.palette.common.white,
  },
  secondary: {
    backgroundColor: '#f4f5ff',
    color: theme.palette.grey['600'],
  },
  outlined: {
    color: theme.palette.grey['600'],
  },
}));

const StatContainer = ({
  value,
  unit = '',
  color,
  label,
  handleClick = () => null,
  theme = 'secondary',
  additionalClasses = '',
  additionalData = '',
}) => {
  const classes = useStyles();
  return (
    <Grid
      className={`${classes.statContainer} ${classes[theme]} p-3 pt-2 pb-2 ${additionalClasses}`}
      container
      item
      alignItems="center"
      onClick={() => handleClick()}>
      <Grid>
        <Typography variant="h6">
          {value}
          <Typography component="span" variant="body2" className="ml-1">
            {unit}
          </Typography>
        </Typography>
        {additionalData && (
          <Typography variant="body2" className="mt-2">
            {additionalData}
          </Typography>
        )}
        <Typography style={{ color: color }} variant="body2" className="mt-2">
          {label}
        </Typography>
      </Grid>
    </Grid>
  );
};

export default StatContainer;
