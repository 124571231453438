import React from 'react';
import { STATUS_TEXT } from '../utils/utils';
import { Badge } from 'reactstrap';

const StatusBadge = (props) => {
  const GridComponent = ({ item, color }) => {
    return (
      <Badge color={color} style={{ fontSize: '12px' }}>
        {item}
      </Badge>
    );
  };

  const { row } = props;
  switch (row) {
    case STATUS_TEXT.NEW:
      return <GridComponent item={row} color="warning" />;
    case STATUS_TEXT.SUBMITTED:
      return <GridComponent item={row} color="primary" />;
    case STATUS_TEXT.APPROVED:
      return <GridComponent item={row} color="success" />;
    case STATUS_TEXT.COMPLETED:
      return <GridComponent item={row} color="info" />;
    case STATUS_TEXT.REJECTED:
      return <GridComponent item={row} color="danger" />;
    case STATUS_TEXT.IN_PROGRESS:
      return <GridComponent item={row} color="warning" />;
    case STATUS_TEXT.REJECTED_BY_POC:
      return <GridComponent item={row} color="danger" />;

    default:
      return <span>{row ? row : `-`}</span>;
  }
};

export default StatusBadge;
