// Remove all empty and null key
export const removeEmptyKey = function (obj) {
  Object.keys(obj).forEach((key) => {
    if (obj[key] === null || obj[key] === '') {
      delete obj[key];
    }
  });
  return obj;
};




// Remove all empty and null key
export const removeKeyByName = function (obj , keyArray= []) {
    Object.keys(obj).forEach((key) => {
      if (keyArray.includes(key)) {
        delete obj[key];
      }
    });
    return obj;
  };
  