import { get, post, put } from '../api_helper';
import * as url from '../url_helper';
import { getApiConfig } from '../auth_helper';

//* get all enterprise
export const getAllEnterprise = async () => {
  return await get(`${url.ENTERPRISE}`, getApiConfig());
};

//* get enterprise with id
export const getEnterprise = async (id) => {
  return await get(`${url.ENTERPRISE}?_id=${id}`, getApiConfig());
};

//* collection events limit
export const getLimitOfCollectionEvent = async (id) => {
  return await get(
    `${url.ENTERPRISE}/collection-event-limit?enterpriseId=${id}`,
    getApiConfig()
  );
};

//* add enterprise
export const createEnterprise = async (data) => {
  return await post(`${url.ENTERPRISE}`, data, getApiConfig());
};

//* update enterprise
export const updateEnterprise = async (id, data) => {
  return await put(`${url.ENTERPRISE}/${id}`, data, getApiConfig());
};
