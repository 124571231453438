import { firebaseFunctions, firestore } from '../../firebase';
import { COLLECTIONS, USER_ROLES } from '../constants';
import {
  currentServerTime,
  GenericError,
  transformFirebaseResponse,
  UnableToCreate,
  UnableToFetchData,
} from '../utils';
import { groupBy } from '../../utils/utils';
import { getRagData } from '../manager';

/**
 * Fetch All Events by enterpriseId
 * @param enterpriseId
 * @returns {Promise<*>}
 */
const fetchAllEvents = async (enterpriseId) => {
  try {
    const events = await firestore
      .collection(COLLECTIONS.EVENTS)
      .where('enterpriseId', '==', enterpriseId)
      .get();

    return transformFirebaseResponse(events);
  } catch (e) {
    // UnableToFetchData();
    return e;
  }
};

/**
 * Fetch Approval completion data by eventId
 * @param eventId
 * @returns {Promise<{}|firebase.functions.HttpsCallableResult>}
 */
const fetchApprovalCompletionStatus = async (eventId) => {
  try {
    const callable = firebaseFunctions.httpsCallable(
      'fetchApprovalCompletionStatus'
    );
    const res = await callable({ eventId });
    return res.data;
  } catch (e) {
    GenericError();
    return [];
  }
};

/**
 * Download Manager Report by eventId
 * @param eventId
 * @returns {Promise<{}|firebase.functions.HttpsCallableResult>}
 */
const downloadManagerReport = async (eventId, adminId, managerId) => {
  try {
    const callable = firebaseFunctions.httpsCallable('fetchManagerReports');
    const res = await callable({ eventId, adminId, managerId });
    return res.data;
  } catch (e) {
    GenericError();
    return [];
  }
};

/**
 * Download POC Report by eventId
 * @param eventId
 * @returns {Promise<{}|firebase.functions.HttpsCallableResult>}
 */
const downloadPocReport = async (eventId, adminId) => {
  try {
    const callable = firebaseFunctions.httpsCallable('fetchPocReports');
    const res = await callable({ eventId, adminId });
    return res.data;
  } catch (e) {
    GenericError();
    return [];
  }
};

/**
 * Fetch RAG completion data by eventId
 * @param eventId
 * @returns {Promise<{}|firebase.functions.HttpsCallableResult>}
 */
const fetchRAGAssignmentStatus = async (eventId) => {
  try {
    const callable = firebaseFunctions.httpsCallable(
      'fetchRAGAssignmentStatus'
    );
    const res = await callable({ eventId });
    return res.data;
  } catch (e) {
    GenericError();
    return [];
  }
};

/**
 * Fetch utilization data by eventId
 * @param eventId
 * @param enterpriseId
 * @returns {Promise<{}|firebase.functions.HttpsCallableResult>}
 */
const fetchUtilizationDataPOC = async ({ eventId, enterpriseId }) => {
  try {
    const callable = firebaseFunctions.httpsCallable('fetchUtilizationDataPOC');
    const res = await callable({ eventId, enterpriseId });
    return res.data;
  } catch (e) {
    GenericError();
    console.warn(e);
    return [];
  }
};

/**
 * Fetch managers by event Id
 */
const fetchAllManagers = async (eventId) => {
  try {
    const employees = await firestore
      .collection(COLLECTIONS.USERS)
      .where('role', '==', USER_ROLES.MANAGER)
      .where('eventId', '==', eventId)
      .get();
    return transformFirebaseResponse(employees);
  } catch (e) {
    console.warn(e);
    // UnableToFetchData();
    return e;
  }
};

/**
 * Update RAG Approval Status
 * @param activityIds
 * @param userId
 * @param ragStatus
 * @param comment
 * @returns {Promise<*>}
 */
const updateRAGApproval = async ({
  activityIds = [],
  userId,
  ragStatus = '',
  approvalComment,
}) => {
  try {
    console.warn('GERE', activityIds, ragStatus, userId);
    let batch = firestore.batch();

    activityIds.forEach((id) => {
      const ref = firestore.collection(COLLECTIONS.ACTIVITY).doc(id);
      batch.update(ref, {
        ragStatus,
        ...(approvalComment ? { approvalComment } : { approvalComment: '' }),
        updatedTime: currentServerTime(),
        updatedBy: userId,
        approvedBy: userId,
      });
    });

    await batch.commit();
  } catch (e) {
    UnableToCreate(e);
    return e;
  }
};

/**
 * Update enterprise details
 * @param id
 * @param userId
 * @param name
 * @param yearOfIncorporation
 * @param industry
 * @param location
 * @param annualRevenue
 * @param totalHours
 * @param employeeCostAnnualRevenue
 * @param totalWhiteCollaredEmployee
 * @param totalBlueCollaredEmployee
 * @param noOfWorkingDays
 * @param noOfHoursPerShift
 * @returns {Promise<boolean|*>}
 */
// const updateEnterpriseDetails = async ({
//   id,
//   userId,
//   name,
//   yearOfIncorporation,
//   industry,
//   location,
//   annualRevenue,
//   totalEmployeeCost,
//   employeeCostAnnualRevenue,
//   totalWhiteCollaredEmployee,
//   totalBlueCollaredEmployee,
//   shift,
//   general_shift,
//   isPOCDataAdded,
// }) => {
//   try {
//     await firestore
//       .collection(COLLECTIONS.ENTERPRISE)
//       .doc(id)
//       .update({
//         ...(name ? { name } : {}),
//         ...(yearOfIncorporation ? { yearOfIncorporation } : {}),
//         ...(industry ? { industry } : {}),
//         ...(location ? { location } : {}),
//         ...(annualRevenue ? { annualRevenue } : {}),
//         ...(totalEmployeeCost ? { totalEmployeeCost } : {}),
//         ...(employeeCostAnnualRevenue ? { employeeCostAnnualRevenue } : {}),
//         ...(totalWhiteCollaredEmployee ? { totalWhiteCollaredEmployee } : {}),
//         ...(totalBlueCollaredEmployee ? { totalBlueCollaredEmployee } : {}),
//         ...(shift ? { shift } : {}),
//         ...(general_shift ? { general_shift } : {}),
//         updatedTime: currentServerTime(),
//         updatedBy: userId,
//         isPOCDataAdded,
//       });
//     return true;
//   } catch (e) {
//     UnableToCreate(e);
//     return e;
//   }
// };

/**
 * Fetch Activity Efforts
 * @returns {Promise<null|*>}
 * @param eventId
 */
const fetchActivityEffortsByEventId = async (eventId) => {
  try {
    const activityEfforts = await firestore
      .collection(COLLECTIONS.ACTIVITY_EFFORT)
      .where('eventId', '==', eventId)
      .get();

    return transformFirebaseResponse(activityEfforts);
  } catch (e) {
    // UnableToFetchData();
    return null;
  }
};

/**
 * Fetch Activity
 * @returns {Promise<null|*>}
 * @param eventId
 */
const fetchActivitiesByEventId = async (eventId) => {
  try {
    const activities = await firestore
      .collection(COLLECTIONS.ACTIVITY)
      .where('eventId', '==', eventId)
      .get();

    return transformFirebaseResponse(activities);
  } catch (e) {
    // UnableToFetchData();
    return null;
  }
};

/**
 * Fetch activity analytics
 */
const fetchActivityAnalytics = async ({ eventId }) => {
  try {
    const activityEfforts = await fetchActivityEffortsByEventId(eventId);
    const activities = await fetchActivitiesByEventId(eventId);
    if (activityEfforts.length) {
      let activityArr = [];
      const groupedActivityEfforts = groupBy(activityEfforts, 'activityId');
      let ragMap = {};
      activities.forEach((item) => {
        ragMap = { ...ragMap, [item.id]: item.ragValue };
      });
      Object.keys(groupedActivityEfforts).forEach((item) => {
        const activityItem = groupedActivityEfforts[item];
        const totalHours = activityItem.reduce(
          (total, effort) => total + effort.totalHours,
          0
        );

        const activityDetails = groupedActivityEfforts[item][0];

        activityArr = [
          ...activityArr,
          {
            activityId: activityDetails.activityId,
            activityName: activityDetails.activityName,
            process: activityDetails.process,
            subProcess: activityDetails.subProcess,
            ragValue: ragMap[activityDetails.activityId],
            totalHours,
          },
        ];
      });
      return activityArr;
    }
    return [];
  } catch (e) {
    // UnableToFetchData();
  }
};

/**
 * Get Rag Data POC
 * @param teamId
 * @returns {Promise<*[]|*>}
 */
const getRagDataPOC = async ({ teamId }) => {
  try {
    let ragData = await getRagData({ teamId });
    ragData = ragData.filter((item) => item.ragValue);
    ragData = ragData.map((item) => ({
      ...item,
      ...(item.ragStatus
        ? { ragStatus: item.ragStatus }
        : { ragStatus: 'PENDING' }),
    }));
    return ragData;
  } catch (e) {
    return e;
  }
};

/**
 * Fetch company data
 * @param id
 * @returns {Promise<{[p: string]: any, id: string}>}
 */
const fetchEnterpriseDataById = async (id) => {
  try {
    const teamData = await firestore
      .collection(COLLECTIONS.ENTERPRISE)
      .doc(id)
      .get();
    if (!teamData.empty) {
      return { ...teamData.data(), id: teamData.id };
    }
  } catch (e) {
    // UnableToFetchData();
  }
};
/**
 * Fetch events data
 * @returns {Promise<{[p: string]: any, id: string}>}
 * @param enterpriseId
 */
const fetchEventsByEnterpriseId = async (enterpriseId) => {
  try {
    const teamData = await firestore
      .collection(COLLECTIONS.EVENTS)
      .where('enterpriseId', '==', enterpriseId)
      // .where('status', '==', 'INPROGRESS')
      .get();
    return transformFirebaseResponse(teamData);
  } catch (e) {
    console.warn(e);
    // UnableToFetchData();
  }
};

/**
 * Fetch pocs data
 * @returns {Promise<{[p: string]: any, id: string}>}
 * @param enterpriseId
 */
const fetchPocsByEnterpriseId = async (enterpriseId) => {
  try {
    const teamData = await firestore
      .collection(COLLECTIONS.USERS)
      .where('enterpriseId', '==', enterpriseId)
      .where('role', '==', 'POC')
      .get();
    return transformFirebaseResponse(teamData);
  } catch (e) {
    console.warn(e);
    // UnableToFetchData();
  }
};

/**
 * Fetch Activity Efforts By EnterpriseId
 * @returns {Promise<null|*>}
 * @param teamId
 */
const fetchActivityEffortsByEnterpriseIdEventId = async (
  enterpriseId,
  eventId
) => {
  try {
    const activityEfforts = await firestore
      .collection(COLLECTIONS.ACTIVITY_EFFORT)
      .where('enterpriseId', '==', enterpriseId)
      .where('eventId', '==', eventId)
      .get();

    return transformFirebaseResponse(activityEfforts);
  } catch (e) {
    // UnableToFetchData();
    return null;
  }
};

/**
 * Fetch Activities
 * @returns {Promise<null|*>}
 * @param teamId
 */
const fetchActivitiesByEnterpriseIdEventId = async (enterpriseId, eventId) => {
  try {
    const activities = await firestore
      .collection(COLLECTIONS.ACTIVITY)
      .where('enterpriseId', '==', enterpriseId)
      .where('eventId', '==', eventId)
      .get();

    return transformFirebaseResponse(activities);
  } catch (e) {
    // UnableToFetchData();
    return null;
  }
};

/**
 * Get RAG DATA - Merged Activities
 * @param enterpriseId
 * @returns {Promise<any[]|*>}
 */
const getRagDataPOCByEnterpriseIdEventId = async ({
  enterpriseId,
  eventId,
}) => {
  try {
    const activityEfforts = await fetchActivityEffortsByEnterpriseIdEventId(
      enterpriseId,
      eventId
    );

    if (activityEfforts.length) {
      const activities = await fetchActivitiesByEnterpriseIdEventId(
        enterpriseId,
        eventId
      );
      if (activities.length) {
        let activityCount = {};
        activityEfforts.forEach((item) => {
          if (activityCount[item.activityId]) {
            activityCount[item.activityId] += 1;
          } else {
            activityCount[item.activityId] = 1;
          }
        });
        return activities
          .map((item) => {
            return activityCount[item.id]
              ? {
                name: item.name,
                id: item.id,
                count: activityCount[item.id],
                ragValue: item.ragValue,
                comment: item.comment,
                approvalComment: item.approvalComment,
                ragStatus: item.ragStatus,
              }
              : null;
          })
          .filter((item) => item);
      }
    }
    return [];
  } catch (e) {
    return e;
  }
};

const getRagCompletionStatusByEnterpriseIdEventId = async ({
  enterpriseId,
  eventId,
}) => {
  try {
    const ragData = await getRagDataPOCByEnterpriseIdEventId({
      enterpriseId,
      eventId,
    });

    const assignedRagCount = ragData.filter((item) => item.ragValue).length;

    const totalRags = ragData.length;

    const assignRagPercentage = (assignedRagCount / totalRags) * 100;

    return { assignRagPercentage, ragData };
  } catch (e) {
    return {};
  }
};

const getLiveLogsByEnterpriseId = async (enterpriseId, setLogs) => {
  let query = firestore.collection(COLLECTIONS.LIVE_LOGS);

  query = query
    .where('enterpriseId', '==', enterpriseId)
    .orderBy('createdAt', 'desc')
    .limit(5)
    .onSnapshot((doc) => {
      setLogs(transformFirebaseResponse(doc));
    });
  await query.get();
  return transformFirebaseResponse(query);
};

const prepareTeamLogChartData = async (enterpriseId, eventId) => {
  try {
    let effortLogsQuery = await firestore.collection(COLLECTIONS.EFFORT_LOG);
    effortLogsQuery = effortLogsQuery.where('enterpriseId', '==', enterpriseId);
    effortLogsQuery = effortLogsQuery.where('eventId', '==', eventId);

    let teamsQuery = await firestore.collection(COLLECTIONS.TEAMS);
    teamsQuery = teamsQuery.where('eventId', '==', eventId);

    let effortLogs = transformFirebaseResponse(await effortLogsQuery.get());
    let teams = transformFirebaseResponse(await teamsQuery.get());

    let data = [];

    teams.forEach((team, t) => {
      if (!data.find((d) => d.teamId === team.id)) {
        data.push({
          teamId: team.id,
          teamName: team.name,
          totalLogs: effortLogs.filter((e) => e.teamId === team.id).length,
          submittedLogs: effortLogs.filter(
            (k) => k.teamId === team.id && k.status === 'SUBMITTED'
          ).length,
        });
      }
    });

    return data;
  } catch (e) { }
};

export {
  fetchAllEvents,
  fetchAllManagers,
  fetchApprovalCompletionStatus,
  fetchRAGAssignmentStatus,
  updateRAGApproval,
  // updateEnterpriseDetails,
  fetchUtilizationDataPOC,
  fetchActivityAnalytics,
  getRagDataPOC,
  getRagDataPOCByEnterpriseIdEventId,
  fetchEnterpriseDataById,
  fetchEventsByEnterpriseId,
  fetchPocsByEnterpriseId,
  getRagCompletionStatusByEnterpriseIdEventId,
  getLiveLogsByEnterpriseId,
  prepareTeamLogChartData,
  downloadManagerReport,
  downloadPocReport,
};
