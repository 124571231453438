export const managerArray = [
  {
    name: 'email',
    placeholder: 'Email',
    type: 'email',
    size: 3,
    validetion: [],
    inputControl: true,
    functionsHandel: {},
    label:"Email"
  },
  {
    name: 'employeeCode',
    placeholder: 'Employee Code',
    type: 'text',
    size: 3,
    validetion: [],
    inputControl: true,
    functionsHandel: {},
    label:"Employee Code"
  },
  {
    name: 'incumbentName',
    placeholder: 'Incumbent Name',
    type: 'text',
    size: 3,
    validetion: [],
    inputControl: true,
    functionsHandel: {},
    label:"Incumbent Name"
  },
  {
    name: 'category',
    placeholder: 'Category',
    type: 'text',
    size: 3,
    validetion: [],
    inputControl: true,
    functionsHandel: {},
    label:"Category"
  },
  {
    name: 'unitName',
    placeholder: 'Unit Name',
    type: 'text',
    size: 3,
    validetion: [],
    inputControl: true,
    functionsHandel: {},
    label:"Unit Name"
  },
  {
    name: 'location',
    placeholder: 'Location',
    type: 'text',
    size: 3,
    validetion: [],
    inputControl: true,
    functionsHandel: {},
    label:"Location"
  },
  {
    name: 'doj',
    placeholder: 'Date of join',
    type: 'text',
    size: 3,
    validetion: [],
    inputControl: true,
    functionsHandel: {},
    label:"Date of join"
  },

  {
    name: 'dob',
    placeholder: 'Date of Birth',
    type: 'text',
    size: 3,
    validetion: [],
    inputControl: true,
    functionsHandel: {},
    label:"Date of Birth"
  },
  {
    name: 'gender',
    placeholder: 'Gender',
    type: 'text',
    size: 3,
    validetion: [],
    inputControl: true,
    functionsHandel: {},
    label:"Gender"
  },
  {
    name: 'position',
    placeholder: 'Position',
    type: 'text',
    size: 3,
    validetion: [],
    inputControl: true,
    functionsHandel: {},
    label:"Position"
  },
  {
    name: 'grade',
    placeholder: 'Grade',
    type: 'text',
    size: 3,
    validetion: [],
    inputControl: true,
    functionsHandel: {},
    label:"Grade"
  },
  {
    name: 'jobTitle',
    placeholder: 'Job Title',
    type: 'text',
    size: 3,
    validetion: [],
    inputControl: true,
    functionsHandel: {},
    label:"Job Title"
  },
  {
    name: 'jobBand',
    placeholder: 'Job Band',
    type: 'text',
    size: 3,
    validetion: [],
    inputControl: true,
    functionsHandel: {},
    label:"Job Band"
  },
  {
    name: 'level',
    placeholder: 'Level',
    type: 'text',
    size: 3,
    validetion: [],
    inputControl: true,
    functionsHandel: {},
    label:"Level"
  },
  {
    name: 'designation',
    placeholder: 'Designation',
    type: 'text',
    size: 3,
    validetion: [],
    inputControl: true,
    functionsHandel: {},
    label:"Designation"
  },
  {
    name: 'role',
    placeholder: 'Role',
    type: 'text',
    size: 3,
    validetion: [],
    inputControl: true,
    functionsHandel: {},
    label:"Role"
  },

  {
    name: 'qualification',
    placeholder: 'Qualification',
    type: 'text',
    size: 3,
    validetion: [],
    inputControl: true,
    functionsHandel: {},
    label:"Qualification"
  },
  {
    name: 'businessUnit',
    placeholder: 'Business Unit',
    type: 'text',
    size: 3,
    validetion: [],
    inputControl: true,
    functionsHandel: {},
    label:"Business Unit"
  },
  {
    name: 'center',
    placeholder: 'Center',
    type: 'text',
    size: 3,
    validetion: [],
    inputControl: true,
    functionsHandel: {},
    label:"Center"
  },
  {
    name: 'teamName',
    placeholder: 'Team Name',
    type: 'text',
    size: 3,
    validetion: [],
    inputControl: true,
    functionsHandel: {},
    label:"Team Name"
  },
  {
    name: 'hodName',
    placeholder: 'HOD Name',
    type: 'text',
    size: 3,
    validetion: [],
    inputControl: true,
    functionsHandel: {},
    label:"HOD Name"
  },
  {
    name: 'hodTitle',
    placeholder: 'HOD Title',
    type: 'text',
    size: 3,
    validetion: [],
    inputControl: true,
    functionsHandel: {},
    label:"HOD Title"
  },
  {
    name: 'headOfUnitName',
    placeholder: 'Head Of Unit Name',
    type: 'text',
    size: 3,
    validetion: [],
    inputControl: true,
    functionsHandel: {},
    label:"Head Of Unit Name"
  },
  {
    name: 'headOfUnitTitle',
    placeholder: 'Head Of Unit Title',
    type: 'text',
    size: 3,
    validetion: [],
    inputControl: true,
    functionsHandel: {},
    label:"Head Of Unit Title"
  },
];
