import React, { useContext, useEffect, useState } from 'react';
import {
  Button,
  Checkbox,
  Collapse,
  Dialog,
  DialogActions,
  DialogTitle,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import { STATUS_TEXT, isEmpty } from '../../../../utils/utils';
import CheckCircleOutlineOutlinedIcon from '@material-ui/icons/CheckCircleOutlineOutlined';
import Message from '../../../../components/Message';
import PlaceholderImage from '../../../../components/PlaceholderImage';
import board from '../../../../../src/assets/images/board.svg';
import RadioIconButton from '../../Dashboard/RadioIconButton';
import { updateReplaceEmployees } from '../../../../helper/backendHelper/employee';
import { AuthContext } from '../../../../components/AuthContextProvider';
import { EmailOutlined } from '@material-ui/icons';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles((theme) => ({
  table: {
    maxHeight: '55vh',
    width: '100%',
  },
  inlineIconButtons: {
    padding: '4px',
  },
  tableCell: {
    borderBottom: `1px solid ${theme.palette.grey[100]}`,
  },
}));

const IdenticalTableWrapper = ({
  id,
  primaryButtonText = 'Approve',
  uploadMode,
  handlePrimaryButtonClick = () => null,
  handleSecondaryButtonClick = () => null,
  isActionable = () => null,
  tableHeads = [],
  subHeads = [],
  data = [],
  dataKeys = [],
  checkId = '',
  disabled = false,
  handleChangeEmployeesByEventId,
  actionType,
  event,
  otherActions,
  resendCredentialsAction = () => null,
  editAction = () => null,
  deleteAction = () => null,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [open, setOpen] = useState('');
  const [flaggedId, setFlaggedId] = useState('');
  const [selectedIcon, setSelectedIcon] = useState(null);
  const [isDialog, setIsDialog] = useState(false);
  const [isFlag, setIsFlag] = useState(true);
  const { user } = useContext(AuthContext);

  //* handle select all in one click
  const handleCheckAll = (teamData) => {
    let updated;
    const actionableData = teamData.filter((item) => isActionable(item));
    if (selected.length !== actionableData.length) {
      updated = actionableData.map((item) => item[checkId]);
    } else {
      updated = [];
    }
    setSelected([...updated]);
  };

  //* open field in sub team
  const handleOpen = (teamData) => {
    if (open !== teamData) {
      setOpen(teamData);
      setSelected([]);
    } else {
      setOpen('');
    }
  };

  //* check flagged user
  const getFlaggedUserLabel = (data) => {
    const flaggedUser = data.filter((item) => item.isFlaggedUser);
    if (flaggedUser.length > 0) {
      return flaggedUser.map((item) => item.employeeName).toString();
    }
    return '';
  };

  //* handle check in particular employees
  const handleCheck = (id) => {
    const index = selected.indexOf(id);
    let updated = selected;
    if (index > -1) {
      updated.splice(index, 1);
    } else {
      updated = [...selected, id];
    }
    setSelected([...updated]);
  };

  //* check all action in selected
  const getActionableLength = (data) => {
    const updatedData = data ? data.filter((item) => isActionable(item)) : [];
    return updatedData.length;
  };

  //* handle Add Identical To Individual
  const handleAddIdenticalToIndividual = async (id) => {
    try {
      const response = await updateReplaceEmployees({
        ids: [id],
        employeeType: 'individual',
      });
      handleChangeEmployeesByEventId(event, actionType);
      Message.error(response.message);
      setIsDialog(false);
    } catch (error) {
      Message.error(error?.response?.data?.message);
    }
  };

  return (
    <React.Fragment>
      <Paper className={`${classes.paper} my-3`}>
        <Grid
          container
          item
          justifyContent="space-between"
          alignItems="baseline"
          className="p-2">
          <Typography variant="body1">Total Items: {data.length}</Typography>
          <Grid>
            {/* {selected.length !== 0 && (
              <>
                <Button color="error" size="medium" variant="text">
                  Delete
                </Button>
                <Button
                  className="mx-3"
                  color="primary"
                  size="medium"
                  onClick={() => setIsDialog(true)}
                  variant="text">
                  Replace
                </Button>
              </>
            )} */}

            <Button
              variant="contained"
              size="small"
              disableElevation
              disabled={(flaggedId == '' && isFlag) || disabled}
              onClick={() => {
                handlePrimaryButtonClick(selected, flaggedId);
                setFlaggedId('');
                setSelected([]);
              }}
              color="primary">
              {primaryButtonText}
            </Button>
          </Grid>
        </Grid>
        <TableContainer
          classes={{
            root: classes.table,
          }}>
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size="medium"
            aria-label={id}>
            <TableHead>
              <TableRow>
                <TableCell colSpan={12}>
                  {data?.length !== 0 ? `Sub Team` : ''}
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {data &&
                data.map((teamData, index) => {
                  const flaggedUser = getFlaggedUserLabel(
                    teamData?.teamMembers
                  );
                  const subTeam = teamData.teamName;

                  return (
                    <React.Fragment key={index}>
                      <TableRow
                        hover
                        tabIndex={-1}
                        onClick={() => handleOpen(teamData)}>
                        <TableCell
                          classes={{
                            body: classes.tableCell,
                          }}>
                          <Grid
                            container
                            item
                            direction="row"
                            alignItems="baseline">
                            <Typography variant="subtitle2">
                              Sub Team : {subTeam?.toSentenceCase()}
                            </Typography>
                            {flaggedUser && (
                              <Typography
                                variant="subtitle2"
                                color="textSecondary"
                                className="ml-5 ">
                                {flaggedUser.toSentenceCase()}
                                <CheckCircleOutlineOutlinedIcon
                                  className="mx-2"
                                  color="primary"
                                  fontSize="small"
                                />
                              </Typography>
                            )}
                          </Grid>
                        </TableCell>
                        <TableCell
                          classes={{
                            body: classes.tableCell,
                          }}
                          align="right">
                          <IconButton
                            aria-label="expand row"
                            size="small"
                            onClick={() => handleOpen(teamData)}>
                            {teamData === open ? (
                              <KeyboardArrowUpIcon />
                            ) : (
                              <KeyboardArrowDownIcon />
                            )}
                          </IconButton>
                        </TableCell>
                      </TableRow>
                      <TableRow>
                        <TableCell colSpan={12} style={{ padding: 0 }}>
                          <Collapse
                            in={open === teamData}
                            timeout="auto"
                            unmountOnExit>
                            <TableContainer
                              classes={{
                                root: classes.table,
                              }}>
                              <Table>
                                <TableHead>
                                  <TableRow>
                                    <TableCell padding="checkbox">
                                      <Checkbox
                                        checked={
                                          selected.length &&
                                          selected.length ===
                                            getActionableLength(
                                              teamData?.teamMembers
                                            )
                                        }
                                        disabled={
                                          (user.role === 'MANAGER' &&
                                            !event.isManagerExists) ||
                                          disabled
                                        }
                                        onChange={() => {
                                          handleCheckAll(teamData.teamMembers);
                                        }}
                                        inputProps={{
                                          'aria-label': 'select all',
                                        }}
                                      />
                                    </TableCell>
                                    {subHeads.map((headCell1, index) => (
                                      <TableCell
                                        key={index}
                                        align={
                                          headCell1.align
                                            ? headCell1.align
                                            : 'left'
                                        }>
                                        {headCell1.label}
                                      </TableCell>
                                    ))}
                                    {otherActions && (
                                      <TableCell align="center">
                                        {'Other Actions'}
                                      </TableCell>
                                    )}
                                  </TableRow>
                                </TableHead>
                                <TableBody>
                                  {teamData?.teamMembers.map((row, index) => {
                                    const isSubTeamActionable =
                                      selected.includes(row[checkId]);
                                    const isAction = isActionable(row);

                                    if (row.isFlaggedUser) {
                                      teamData['isFlaggedUser'] = true;
                                    }
                                    return (
                                      <TableRow key={`nested-${index}`}>
                                        <TableCell padding="checkbox">
                                          <Checkbox
                                            checked={isSubTeamActionable}
                                            disabled={
                                              (user.role === 'MANAGER' &&
                                                !event.isManagerExists) ||
                                              !isAction
                                            }
                                            onChange={() => {
                                              if (
                                                teamData.isFlaggedUser == true
                                              ) {
                                                setIsFlag(false);
                                              } else {
                                                setIsFlag(true);
                                              }
                                              handleCheck(row[checkId]);
                                            }}
                                            // inputProps={{
                                            //   'aria-label': 'select all',
                                            // }}
                                          />
                                        </TableCell>
                                        {dataKeys.map((item, index) => {
                                          if (
                                            typeof row[item.id] === 'boolean'
                                          ) {
                                            row[item.id] = row[item.id]
                                              ? 'Yes'
                                              : 'No';
                                          }

                                          return (
                                            <TableCell
                                              key={index}
                                              align={item.align || 'left'}>
                                              <Grid
                                                container
                                                item
                                                alignItems="center"
                                                justifyContent={
                                                  item.align || 'flex-start'
                                                }>
                                                {item?.id
                                                  .toLowerCase()
                                                  .includes('status')
                                                  ? STATUS_TEXT[row[item.id]]
                                                  : item.id === 'email'
                                                  ? row[item.id]
                                                  : row[
                                                      item.id
                                                    ]?.toSentenceCase() || '-'}

                                                {row.isFlaggedUser &&
                                                teamData.isFlaggedUser &&
                                                item.id === 'employeeName' ? (
                                                  <CheckCircleOutlineOutlinedIcon
                                                    fontSize="small"
                                                    className={`ml-2
                                                        'pointer'
                                                    }`}
                                                    color={'primary'}
                                                  />
                                                ) : !teamData.isFlaggedUser &&
                                                  item.id === 'employeeName' ? (
                                                  <div
                                                    className="mx-2"
                                                    onClick={() => {
                                                      setSelectedIcon(row._id);
                                                      if (
                                                        !selected.includes(
                                                          row._id
                                                        )
                                                      ) {
                                                        setSelected([
                                                          ...selected,
                                                          row._id,
                                                        ]);
                                                      }
                                                      setFlaggedId(row._id);
                                                    }}>
                                                    {selectedIcon == row._id ? (
                                                      <CheckCircleOutlineOutlinedIcon
                                                        color="primary"
                                                        fontSize="small"
                                                      />
                                                    ) : (
                                                      <CheckCircleOutlineOutlinedIcon
                                                        color="disabled"
                                                        fontSize="small"
                                                      />
                                                    )}
                                                  </div>
                                                ) : null}
                                              </Grid>
                                            </TableCell>
                                          );
                                        })}
                                        <TableCell
                                          key={`actions-${id}`}
                                          style={{
                                            cursor: 'pointer',
                                            textAlign: 'center',
                                          }}>
                                          <Tooltip
                                            title="Replace from Identical to Individual"
                                            arrow
                                            placement="top">
                                            <Button
                                              className="mx-3"
                                              color="primary"
                                              size="medium"
                                              onClick={() =>
                                                handleAddIdenticalToIndividual(
                                                  row._id
                                                )
                                              }
                                              variant="outlined">
                                              Replace
                                            </Button>
                                          </Tooltip>
                                        </TableCell>
                                        {otherActions && (
                                          <TableCell>
                                            <Grid
                                              container
                                              justifyContent="center">
                                              <Grid>
                                                <IconButton
                                                  onClick={() => {
                                                    resendCredentialsAction(
                                                      row._id
                                                    );
                                                  }}
                                                  className="my-1"
                                                  color="primary"
                                                  aria-label="Resend Credentials">
                                                  <EmailOutlined />
                                                </IconButton>
                                              </Grid>
                                              <Grid>
                                                <IconButton
                                                  onClick={() => {
                                                    editAction(row._id);
                                                  }}
                                                  className="my-1"
                                                  color="primary"
                                                  aria-label="Resend Credentials">
                                                  <EditIcon />
                                                </IconButton>
                                              </Grid>
                                              <Grid>
                                                <IconButton
                                                  onClick={() => {
                                                    deleteAction(row._id);
                                                  }}
                                                  className="my-1"
                                                  style={{ color: '#bd0404' }} // Set custom color here
                                                  aria-label="Resend Credentials">
                                                  <DeleteIcon />
                                                </IconButton>
                                              </Grid>
                                            </Grid>
                                          </TableCell>
                                        )}
                                      </TableRow>
                                    );
                                    // <React.Fragment>
                                    // </React.Fragment>
                                  })}
                                </TableBody>
                              </Table>
                            </TableContainer>
                          </Collapse>
                        </TableCell>
                      </TableRow>
                    </React.Fragment>
                  );
                })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {data.length == 0 && !uploadMode && (
        <PlaceholderImage
          image={board}
          messsage={`No records found`}
          width="25%"
        />
      )}
    </React.Fragment>
  );
};

export default IdenticalTableWrapper;
