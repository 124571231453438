import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import {
  Button,
  CircularProgress,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import { AuthContext } from '../../../components/AuthContextProvider';
import SecondaryLayout from '../../../components/SecondaryLayout';
import { useHistory, useParams } from 'react-router';
import DialogWrapper from '../../../components/DialogWrapper';
import useFetch from '../../../hooks/useFetch';
import { fetchAllEnterprises } from '../../../operations/enterprise';
import { fetchEventsByEnterpriseId } from '../../../operations/poc';
import { USER_ROLES } from '../../../operations/constants';
import Message from '../../../components/Message';
import {
  addQuestionsToSurvey,
  createSurvey,
  fetchSurveyById,
} from '../../../operations/admin';
import { Paths } from '../../../routes/paths';
import Question from '../components/Question';
import { QUESTION_TYPE } from '../../../utils/utils';
import { colors } from '../../../theme/colors';
import TextWithDivider from '../../../components/TextWithDivider';

const roles = [
  {
    name: 'Employee',
    value: USER_ROLES.EMPLOYEE,
  },
  {
    name: 'Manager',
    value: USER_ROLES.MANAGER,
  },
  {
    name: 'Client POC',
    value: USER_ROLES.POC,
  },
];

const dummyQuestionTemplate = {
  id: Date.now(),
  type: QUESTION_TYPE.SINGLE,
  text: 'Enter Question Text',
  options: [
    {
      id: '1',
      label: 'One',
      value: 'One',
    },
    {
      id: '2',
      label: 'Two',
      value: 'Two',
    },
  ],
};

const CreateSurvey = () => {
  let { user } = useContext(AuthContext);

  const { surveyId } = useParams();
  const [companyId, setcompanyId] = useState('');
  const [event, setEvent] = useState('');
  const [role, setRole] = useState('');
  const [loading, setLoading] = useState(false);
  const [copySurveyDialog, setCopySurveyDialog] = useState(false);
  const [survey, setSurvey] = useState({});

  const history = useHistory();
  const [saveSurveyLoading, setSaveSurveyLoading] = useState(false);

  const [questions, setQuestions] = useState([dummyQuestionTemplate]);

  useEffect(() => {
    if (surveyId !== 'new') {
      (async () => {
        const data = await fetchSurveyById(surveyId);
        if (data.questions) {
          setQuestions([...data?.questions, dummyQuestionTemplate]);
          setSurvey(data);
        }
      })();
    }
    // eslint-disable-next-line
  }, []);

  const questionTemplate = {
    id: Date.now() + questions.length + 1,
    type: QUESTION_TYPE.SINGLE,
    text: 'Enter Question Text',
    options: [
      {
        id: '12',
        label: 'One',
        value: 'One',
      },
      {
        id: '121',
        label: 'Two',
        value: 'Two',
      },
    ],
  };

  const { data: enterprises = [], loading: enterpriseLoading } =
    useFetch(fetchAllEnterprises);
  const {
    data: events = [],
    loading: eventsLoading,
    fetchData: fetchDataEventsByEnterpriseId,
  } = useFetch(fetchEventsByEnterpriseId, user.enterpriseId || '');

  useEffect(() => {
    if (companyId) {
      const company = enterprises.find((el) => el.id === companyId);
      if (company) {
        (async () => {
          await fetchDataEventsByEnterpriseId(company.id);
        })();
      }
    }
    // eslint-disable-next-line
  }, [companyId]);

  const handleCompanyChange = (value) => {
    setcompanyId(value);
  };

  const handleEventChange = async (event) => {
    setEvent(event);
  };

  const handleRoleChange = async (role) => {
    setRole(role);
  };

  const handleCreateSurvey = async () => {
    if (role === '' || event === '' || companyId === '') {
      Message.error('All fields are required !');
      return;
    }

    try {
      setLoading(true);
      const companyName = enterprises.find((el) => el.id === companyId);
      const eventName = events.find((el) => el.id === event);
      let id;
      if (copySurveyDialog) {
        let sanitizedSurvey = [...questions].map((item) => {
          let temp = { ...item };
          if (temp.type === QUESTION_TYPE.TEXT) {
            if (temp?.options?.length) {
              delete temp.options;
            }
            return temp;
          }
          return item;
        });
        sanitizedSurvey.pop();
        id = await createSurvey({
          eventId: event,
          eventName: eventName.name,
          enterpriseId: companyId,
          enterpriseName: companyName.name,
          role: role,
          questions: sanitizedSurvey,
        });
      } else {
        id = await createSurvey({
          eventId: event,
          eventName: eventName.name,
          enterpriseId: companyId,
          enterpriseName: companyName.name,
          role: role,
        });
      }

      if (id) {
        setCopySurveyDialog(false);
        history.push(`${Paths.adminCreateSurvey}/${id}`);
      }
    } catch (e) {
    } finally {
      setLoading(false);
      setRole('');
      setEvent('');
      setcompanyId('');
    }
  };

  const addNewQuestion = () => {
    setQuestions([...questions, questionTemplate]);
  };

  const saveSurvey = async () => {
    let sanitizedSurvey = [...questions].map((item) => {
      let temp = { ...item };
      if (temp.type === QUESTION_TYPE.TEXT) {
        if (temp?.options?.length) {
          delete temp.options;
        }
        return temp;
      }
      return item;
    });
    sanitizedSurvey.pop();

    try {
      setSaveSurveyLoading(true);
      await addQuestionsToSurvey(surveyId, sanitizedSurvey);
      history.push('/admin/surveys');
    } catch (e) {
    } finally {
      setSaveSurveyLoading(false);
    }
  };

  const copySurvey = () => {
    setCopySurveyDialog(true);
  };

  return (
    <Layout loading={enterpriseLoading || eventsLoading}>
      <SecondaryLayout>
        <DialogWrapper
          title="Create survey"
          open={surveyId === 'new' || copySurveyDialog}
          loading={loading}
          submitButtonTitle={copySurveyDialog ? 'Copy Survey' : 'Create Survey'}
          handleSubmit={handleCreateSurvey}
          cancelButtonTitle="Cancel"
          handleClose={() => {
            if (copySurveyDialog) {
              setCopySurveyDialog(false);
            } else {
              history.goBack();
            }
          }}>
          <div style={{ width: '500px' }} className="p-4">
            {enterprises.length ? (
              <FormControl className="my-3" fullWidth>
                <InputLabel id="selectEnterprise">Select Enterprise</InputLabel>

                <Select
                  labelId="selectEnterprise"
                  value={companyId}
                  fullWidth
                  placeholder="Select Enterprise"
                  onChange={(evt) => handleCompanyChange(evt.target.value)}>
                  {enterprises.map((item) => (
                    <MenuItem value={item.id} key={item.name}>
                      {item.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            ) : (
              ''
            )}

            <FormControl className="my-3" fullWidth>
              <InputLabel id="selectEvent">Select Event</InputLabel>
              <Select
                fullWidth
                labelId="selectEvent"
                disabled={companyId === ''}
                value={event || ''}
                onChange={(evt) => handleEventChange(evt.target.value)}>
                <MenuItem disabled value={''} />
                {events.length === 0 && (
                  <MenuItem disabled value={'No Events present'} />
                )}
                {events.map((item) => (
                  <MenuItem value={item.id} key={item.id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>

            <FormControl className="my-3" fullWidth>
              <InputLabel id="selectUserRole">Select User Role</InputLabel>
              <Select
                fullWidth
                labelId="selectUserRole"
                value={role}
                onChange={(evt) => handleRoleChange(evt.target.value)}>
                <MenuItem disabled value={''} />
                {events.length === 0 && (
                  <MenuItem disabled value={'No Events present'} />
                )}

                {roles.map((el, idx) => (
                  <MenuItem value={el.value} key={idx}>
                    {el.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </DialogWrapper>
        <Grid
          className="px-5 py-3"
          container
          justifyContent="flex-end"
          style={{
            position: 'sticky',
            top: '65px',
            background: colors.background.default,
            zIndex: 99,
          }}
          alignItems="center">
          <Grid item xs={6}>
            <TextWithDivider>
              {Object.keys(survey).length ? (
                <Typography>{`Survey - ${survey.enterpriseName || ''} - ${
                  survey.eventName || ''
                } -  ${survey.role || ''}`}</Typography>
              ) : (
                ''
              )}
            </TextWithDivider>
          </Grid>
          <Grid
            container
            alignItems="center"
            justifyContent="flex-end"
            item
            xs={6}>
            <Button
              className="mr-2"
              disableElevation
              color="primary"
              variant="contained"
              onClick={copySurvey}>
              Copy Survey
            </Button>

            <Button
              disableElevation
              color="primary"
              variant="contained"
              disabled={saveSurveyLoading || questions.length < 2}
              onClick={saveSurvey}>
              {saveSurveyLoading ? (
                <CircularProgress
                  style={{ color: colors.common.black }}
                  size={20}
                />
              ) : (
                'Save Survey'
              )}
            </Button>
          </Grid>
        </Grid>

        {surveyId !== 'new' && (
          <div
            style={{ maxHeight: '3000px', overflowY: 'scroll' }}
            className="ml-5">
            {questions.map((el, idx) => (
              <Question
                key={el.id}
                question={el}
                index={idx}
                allQuestions={questions}
                setQuestions={setQuestions}
                addNewQuestion={addNewQuestion}
              />
            ))}
          </div>
        )}
      </SecondaryLayout>
    </Layout>
  );
};

export default CreateSurvey;
