import React, { useContext, useEffect, useMemo, useState } from 'react';
import Layout from '../../../components/Layout';
import { Button, Divider, IconButton, Paper, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core/styles';
import DialogWrapper from '../../../components/DialogWrapper';
import { AuthContext } from '../../../components/AuthContextProvider';
import NestedList from '../../../components/NestedList';
import { AddCircleOutline, ChevronRight, InfoOutlined } from '@material-ui/icons';
import LogEffortTable from '../components/LogEffortTable';
import CreatableSelect from '../../../components/CreatableSelect';
import { Paths } from '../../../routes/paths';
import { useHistory, useParams } from 'react-router-dom';
import { frequencyMappingValues, STATUS_TEXT } from '../../../utils/utils';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import Message from '../../../components/Message';
import {
  createProcess,
  getAllProcess,
  updateProcess,
} from '../../../helper/backendHelper/process';
import {
  createSubProcess,
  getAllSubProcess,
  updateSubProcess,
} from '../../../helper/backendHelper/subProcesss';
import {
  createUpdateBulkActivities,
  getActivitiesByProcessId,
} from '../../../helper/backendHelper/activities';
import { getEvents } from '../../../helper/backendHelper/events';
import { updateStatusSheet } from '../../../helper/backendHelper/sheet';

const useStyles = makeStyles(() => ({
  secondaryContainer: {
    height: '80vh',
    flexGrow: 1,
  },
}));

const CreateLog = () => {
  let { user } = useContext(AuthContext);
  const classes = useStyles();
  const [processDialogOpen, setProcessDialogOpen] = useState(false);
  const [selectedSubProcess, setSelectedSubProcess] = useState(null);
  const [newProcess, setNewProcess] = useState('');
  const [newSubProcess, setNewSubProcess] = useState('');
  const [loading, setLoading] = useState(false);
  const [activities, setActivities] = useState([]);
  const [saveAlert, setSaveAlert] = useState(false);
  const [tempSubProcess, setTempSubProcess] = useState(null);
  const [process, setProcess] = useState([]);
  const [subProcesses, setSubProcesses] = useState([]);
  const [save, setSave] = useState(false);
  const [isSubProcess, setIsSubProcess] = useState(false);
  const [employeeData, setEmployeeData] = useState({});
  const [deleteActivities, setDeleteActivities] = useState([]);
  const [editMode, setEditMode] = useState(false);
  const [editTarget, setEditTarget] = useState(null);
  const history = useHistory();
  const [tooltip, setTooltip] = useState(false);
  const { id: effortLogId } = useParams();

  useMemo(() => {
    let employee = localStorage.getItem('effortLog');
    employee = JSON.parse(employee);
    setEmployeeData(employee);
  }, [localStorage.getItem('effortLog')]);

  useEffect(() => {
    if (employeeData?.employeeId) {
      fetchAllProcessSubProcess();
    }
  }, [save]);

  //* fetch all process and sub processes
  const fetchAllProcessSubProcess = async () => {
    try {
      setLoading(true);

      //* get all process
      let process = await getAllProcess(employeeData.employeeId);
      process = process.payload?.data;

      //* get all subprocess
      let subProcess = await getAllSubProcess(employeeData.employeeId);
      subProcess = subProcess.payload?.data;

      //* get employee by events
      let employeeEnterprise = await getEvents(employeeData?.collectionEventId);
      employeeEnterprise = employeeEnterprise?.payload?.data[0];

      // maintain working days in shift wise
      if (employeeData.shift == 'General') {
        user['numberOfWorkingDays'] =
          employeeEnterprise?.generalShift?.numberOfWorkingDays;
      } else {
        user['numberOfWorkingDays'] =
          employeeEnterprise?.shift?.numberOfWorkingDays;
      }

      setSubProcesses(subProcess);
      setProcess(process);

      //* get first process when refresh the page
      let getFirstProcessSubProcess = process[0].subProcess[0];
      getFirstProcessSubProcess['process'] = process[0].name;
      getFirstProcessSubProcess['subProcessId'] = getFirstProcessSubProcess._id;

      await getActivitiesBySubProcessId(getFirstProcessSubProcess);

      setLoading(false);
    } catch (error) {
      setLoading(false);
      Message.error(error?.response?.data?.message);
    }
  };

  //* fetch all Activities
  const getActivitiesBySubProcessId = async (data) => {
    setSelectedSubProcess(data);
    try {
      setLoading(true);
      let activities = await getActivitiesByProcessId(data?.subProcessId);

      activities = activities?.payload?.data.map((val) => {
        return { ...val, operationType: 'update' };
      });

      setActivities(activities);
      setLoading(false);
    } catch (error) {
      Message.error(error?.response?.data?.message);
    }
  };

  //* submit process
  const submitProcess = async () => {
    try {
      setLoading(true);
      if (user.processName && isSubProcess) {
        return submitSubProcess(user.processName, newSubProcess);
      }
      const processResponse = await createProcess({
        name: newProcess,
        sheetId: effortLogId,
        enterpriseId: employeeData.enterpriseId,
        collectionEventId: employeeData.collectionEventId,
        ...(employeeData.teamId ? { teamId: employeeData.teamId } : {}),
        employeeId: employeeData?.employeeId,
      });
      if (newSubProcess) {
        await submitSubProcess(processResponse?.payload?.name, newSubProcess);
        setNewSubProcess('');
        setIsSubProcess(false);
      } else {
        setSave((preSaved) => !preSaved);
      }
      setNewProcess('');
      setProcessDialogOpen(false);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Message.error(error?.response?.data?.message);
    }
  };

  //* handle sub process
  const submitSubProcess = async (processName, subProcess) => {
    if (processName) {
      try {
        const response = await createSubProcess({
          processName: processName,
          name: subProcess,
          enterpriseId: employeeData.enterpriseId,
          collectionEventId: employeeData.collectionEventId,
          sheetId: effortLogId,
          employeeId: employeeData?.employeeId,
        });
        setSave((preSaved) => !preSaved);
        setNewSubProcess('');
        setProcessDialogOpen(false);
        Message.success(response?.message);
        setLoading(false);
      } catch (error) {
        setLoading(false);
        Message.error(error?.response?.data?.message);
      }
    }
  };

  //* set value in onchange
  const updateEffortData = async (index, updatedField) => {
    let updatedData = activities;

    let frequencyMapValues = {
      ...frequencyMappingValues,
      Daily: Number(user.numberOfWorkingDays) || 0,
    };
    if (
      updatedField.hasOwnProperty('type') &&
      updatedField['type'] === 'DELETE'
    ) {
      if (updatedData.length > 0) {
        updatedData.splice(index, 1);
      }
    } else {
      updatedData[index] = {
        ...updatedData[index],
        ...updatedField,
        ...(updatedField.id ? { updated: true } : {}),
      };
      let hours;
      if (
        updatedData[index]['hours'] == updatedField.hours ||
        updatedData[index]['frequency'] ||
        updatedData[index]['minutes']
      ) {
        hours =
          updatedData[index]['hours'] + updatedData[index]['minutes'] / 60;

        let freq;
        if (updatedField.hours == 0) {
          freq = 0;
        } else {
          // if (!updatedData[index]['frequency']) {
          //   console.log('freq if part');
          //   freq = user.numberOfWorkingDays;
          // } else {
          freq = frequencyMapValues[updatedData[index]['frequency']];
          // }
        }

        let totalHours = freq * hours;
        totalHours = totalHours.toFixed(2);
        updatedData[index] = {
          ...updatedData[index],
          totalHours,
        };
      }
      // }
    }
    setActivities([...updatedData]);
  };

  //* save activity
  const saveProgress = async () => {
    // eslint-disable-next-line array-callback-return
    activities.map((activity) => {
      activity.minutes = Number(activity.minutes);
    });

    const createPayload = {
      employeeId: employeeData?.employeeId,
      sheetId: effortLogId,
      subProcessName: selectedSubProcess.name,
      processId: selectedSubProcess.processId,
      activities:
        deleteActivities.length > 0
          ? activities.concat(...deleteActivities)
          : activities,
    };
    try {
      const response = await createUpdateBulkActivities(createPayload);

      setDeleteActivities([]);
      await getActivitiesBySubProcessId(selectedSubProcess);
      Message.success(response?.message);
      if (activities.length > 0) {
        await updateEffortLog(STATUS_TEXT.IN_PROGRESS);
      }
    } catch (error) { }
  };

  //* add new activity handle click
  const addNewActivity = () => {
    if (!selectedSubProcess) {
      return Message.error('please select process !');
    }
    setActivities([
      ...activities,
      {
        name: '',
        applicationUsed: '',
        frequency: 'Daily',
        individualVolume: 0,
        operationType: 'create',
        hours: 0,
        minutes: 0,
      },
    ]);
  };

  //* update status
  const updateEffortLog = async (status, managerStatus) => {
    try {
      setLoading(true);
      const response = await updateStatusSheet(employeeData._id, {
        status: status,
        employeeId: employeeData.employeeId,
        ...(managerStatus ? { managerApprovalStatus: managerStatus } : {}),
      });

      Message.success(response?.message);
      setEmployeeData((prevData) => ({
        ...prevData,
        status: status,
      }));
      // if click on submit button then remove local storage and redirect to employee home
      if (status === STATUS_TEXT.SUBMITTED) {
        localStorage.removeItem('effortLog');
        history.push(Paths.employeeHome);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const handleEditProcess = (process) => {
    setEditMode(true);
    setEditTarget({ type: 'process', data: process });
    setNewProcess(process.name); // Pre-fill dialog input
    setProcessDialogOpen(true);
  };

  const handleEditSubProcess = (subProcess) => {
    setEditMode(true);
    setEditTarget({ type: 'subprocess', data: subProcess });
    setNewSubProcess(subProcess.name);
    setProcessDialogOpen(true);
  };

  const submitEdit = async () => {
    try {
      setLoading(true);
      if (editTarget?.type === 'process') {
        // Update Process
        const response = await updateProcess(editTarget.data._id, {
          name: newProcess,
          sheetId: effortLogId,
          employeeId: employeeData?.employeeId,
        });
        Message.success(response?.message);
      } else if (editTarget?.type === 'subprocess') {
        // Update SubProcess
        const response = await updateSubProcess(editTarget.data._id, {
          name: newSubProcess,
          processName: editTarget.data.processName,
          sheetId: effortLogId,
          employeeId: employeeData?.employeeId,
        });
        Message.success(response?.message);
      }

      // Refresh data
      await fetchAllProcessSubProcess();
      setProcessDialogOpen(false);
      setEditMode(false);
      setEditTarget(null);
      setLoading(false);
      setNewProcess('');
      setNewSubProcess('');
      setIsSubProcess(false);
    } catch (error) {
      setLoading(false);
      Message.error(error?.response?.data?.message);
    }
  };
  return (
    <Layout loading={loading}>
      <Grid
        container
        justifyContent="space-between"
        className="pl-3 pr-4 mt-5 pt-3">
        <Typography className="mx-3" variant="h2" color="textPrimary">
          {employeeData?.collectionEventName} | Effort Log
          <Button
            variant="contained"
            disableElevation
            color="primary"
            className="mx-5"
            onClick={() => {
              localStorage.removeItem('effortLog');
              history.push(Paths.employeeHome);
            }}>
            Back
          </Button>
        </Typography>
        <Grid item>
          <React.Fragment>
            <Button
              variant="contained"
              disableElevation
              color="secondary"
              disabled={employeeData.status === STATUS_TEXT.SUBMITTED || false}
              onClick={() => saveProgress()}>
              Save Progress
            </Button>
            <Button
              className="ml-3"
              variant="contained"
              disableElevation
              disabled={
                employeeData.status === STATUS_TEXT.SUBMITTED ||
                employeeData.status === STATUS_TEXT.NEW ||
                false
              }
              onClick={() =>
                updateEffortLog(STATUS_TEXT.SUBMITTED, STATUS_TEXT.IN_PROGRESS)
              }
              color="primary">
              Submit
            </Button>
          </React.Fragment>
        </Grid>
      </Grid>

      <Grid container className={`${classes.secondaryContainer} pr-4 mt-3`}>
        <Grid
          item
          container
          lg={2}
          md={3}
          className="h-100"
          style={{ overflowY: 'auto' }}>
          <NestedList
            setIsSubProcess={setIsSubProcess}
            employeeData={employeeData}
            setProcessDialogOpen={setProcessDialogOpen}
            id="process-container"
            submitSubProcess={submitSubProcess}
            handleClick={async (subProcessObj) => {
              // setTempSubProcess(subProcessObj);
              await getActivitiesBySubProcessId(subProcessObj);
            }}
            data={process}
            nestedKey="subProcess"
            listHead="Log List"
            handleEditProcess={handleEditProcess}
            handleEditSubProcess={handleEditSubProcess}
          >
            {employeeData.employeeType === 'individual' ||
              employeeData.isFlaggedUser ? (
              <div className='d-flex align-items-center'>

                <InfoOutlined color="primary" onClick={() => setTooltip(true)} className='mt-3 mr-2' />

                {employeeData.status !== STATUS_TEXT.SUBMITTED && (
                  <Button
                    variant="contained"
                    disableElevation
                    color="primary"
                    size="small"
                    className="mt-3"
                    startIcon={<AddCircleOutline color="inherit" />}
                    onClick={() => {
                      setProcessDialogOpen(true);
                      setIsSubProcess(false);
                    }}>
                    Add New Process
                  </Button>
                )}
              </div>
            ) : null}
            {/* {employeeData.isFlaggedUser ||
            employeeData.employeeType == 'individual' ? ( */}
            {/* {employeeData.status !== STATUS_TEXT.SUBMITTED && (
              <Button
                variant="contained"
                disableElevation
                color="primary"
                size="small"
                className="mt-3"
                startIcon={<AddCircleOutline color="inherit" />}
                onClick={() => {
                  setProcessDialogOpen(true);
                  setIsSubProcess(false);
                }}>
                Add New Process
              </Button>
            )} */}
            {/* ) : null} */}
          </NestedList>
        </Grid>
        <Grid item xs>
          <Paper className="pl-3 pt-3 h-100">
            {selectedSubProcess && selectedSubProcess.process && (
              <React.Fragment>
                <Grid container className="pb-1">
                  <Typography variant="body1" color="textSecondary">
                    {selectedSubProcess.process}
                  </Typography>
                  <ChevronRight
                    color="disabled"
                    className="ml-1 mr-1"
                    style={{ marginTop: '2px' }}
                  />
                  <Typography variant="body1">
                    {selectedSubProcess.name}
                  </Typography>
                </Grid>
                <Divider />
              </React.Fragment>
            )}
            <LogEffortTable
              employeeData={employeeData}
              activities={activities}
              handleUpdateEffortData={updateEffortData}
              setDeleteActivities={setDeleteActivities}
              addNewRowToEffortData={addNewActivity}
              setActivities={setActivities}
            />
          </Paper>
        </Grid>
      </Grid>

      <DialogWrapper
        open={processDialogOpen}
        handleClose={() => {
          setProcessDialogOpen(false);
          setNewProcess('');
          setNewSubProcess('');
          setEditMode(false);
          setEditTarget(null);
          setIsSubProcess(false);

        }}
        disabled={
          !newProcess ||
          !newSubProcess ||
          (typeof newProcess !== 'string' && typeof newSubProcess !== 'string')
        }
        title={editMode
          ? isSubProcess ? 'Edit Sub Process' : 'Edit Process'
          : isSubProcess ? 'Add Sub Process' : 'Add Process'
        }
        handleSubmit={async () => {
          if (editMode) {
            await submitEdit();
            return;
          } else {
            await submitProcess();
          }
        }}
        submitButtonTitle="Add"
        dialogId="add-product">
        <React.Fragment>
          <div>
            {!isSubProcess && (
              <CreatableSelect
                label={editMode ? "Edit Process " : "Add Process"}
                setInput={setNewProcess}
                input={newProcess}
                id="process-selector"
                data={process}
                initValue={
                  typeof newProcess === 'string' ? newProcess : newProcess.name
                }
              />
            )}
          </div>
          <div className="mt-3">
            <CreatableSelect
              label={editMode ? "Edit Sub Process" : "Edit Sub Process"}
              setInput={setNewSubProcess}
              input={newSubProcess}
              id="sub-process-selector"
              data={subProcesses}
              initValue={
                typeof newSubProcess === 'string'
                  ? newSubProcess
                  : newSubProcess.name
              }
            />
          </div>
        </React.Fragment>
      </DialogWrapper>

      <ConfirmationDialog
        open={saveAlert}
        handleClose={() => {
          setSaveAlert(false);
          setTempSubProcess(null);
        }}
        title="Are you sure?"
        message="Changes that you made may not be saved."
        handleSubmit={async () => {
          setSaveAlert(false);
          await getActivitiesBySubProcessId(tempSubProcess);
          setTempSubProcess(null);
        }}
      />
      <DialogWrapper
        title="User Guide for Managing Processes, Subprocesses, and Activities"
        open={tooltip}
        handleClose={() => setTooltip(false)}>
        <div className='overflow-auto'>
          <div>
            <b>1. Managing Processes</b>
            <p className='mt-1 ml-3'>Processes are the top-level units of workflow. Here's how to add and manage them:</p>
            <b className='ml-2'>Adding a Process</b>
            <ol className='mt-1 ml-2'>
              <li>Click the <b>"Add Process"</b>button.</li>
              <li>Enter a <b>unique name</b> for the process in the input field.</li>
              <li>Click <b>Save</b> to create the process.</li>
            </ol>
            <b className='ml-2'>Editing a Process</b>
            <ol className='mt-1 ml-2'>
              <li>Click the <b>"Edit (pencil) icon"</b>next to the process name.</li>
              <li>Update the process details as needed.</li>
              <li>Click <b>Save</b> to apply your changes.
              </li>
            </ol>
          </div>
          <div>
            <b>2. Managing Subprocesses</b>
            <p className='mt-1 ml-3'>Subprocesses are nested under processes and are unique within each process. Here's how to add and manage them:</p>
            <b className='ml-2'>Adding a Subprocess</b>
            <ol className='mt-1 ml-2'>
              <li>Click the <b>"+" (Add Subprocess)</b>button below the process details.</li>
              <li>Enter a <b>unique name</b> for the subprocess within the selected process.</li>
              <li>Click <b>Save</b> to create the subprocess.</li>
            </ol>
            <b className='ml-2'>Editing a Subprocess</b>
            <ol className='mt-1 ml-2'>
              <li>Click the <b>"Edit (pencil) icon"</b>next to the subprocess name.</li>
              <li>Update the process details as needed.</li>
              <li>Click <b>Save</b> to apply your changes.
              </li>
            </ol>
          </div>
          <div>
            <b>3. Managing Activities</b>
            <p className='mt-1 ml-3'>Activities are specific tasks that can be added under a subprocess and must be unique within that subprocess. Here’s how to work with activities:</p>
            <b className='ml-2'>Adding an Activity</b>
            <ol className='mt-1 ml-2'>
              <li>Click on the <b>sub process</b> from sidebar and in the main screen you will see the form of <b>activities</b>.</li>
              <li>Enter a <b>Activity name, Application Used, frequency, Hours & Minutes and Individual Volume</b>.</li>
              <li>Click <b>Save Progress</b> which is located to top right corner.</li>
            </ol>
            <b className='ml-2'>Editing a Activity</b>
            <ul className='mt-1 ml-2'>
              <li>Follow the <b>same procedure</b> as like create activities.</li>
            </ul>
          </div>
          <div>
            <b>3.Save Progress</b>
            <p className='mt-1 ml-3'> Ensure you save your progress after entering
              each process.
            </p>
          </div>
          <div>
            <b>4.Submit to Manager</b>
            <p className='mt-1 ml-3'>
              Once all details are complete, submit
              your activities to the manager for review.
            </p>
          </div>
          <div>
            <b className='text-danger fw-semibold'>Tips to avoid errors</b>
            <div className='mt-1 ml-3'>
              Avoid overstating your work data
            </div>
            <div className='mt-1 ml-3'>
              Click <b>“Save progress”</b> each time you complete an activity
            </div>
          </div>
        </div>
      </DialogWrapper>
    </Layout>
  );
};

export default CreateLog;
