export const isPositiveWholeNumber = (value) => {
  return true;
};

export const isEmpty = (obj) => {
  return Object.keys(obj).length === 0;
};

export const STATUS_TEXT = {
  IN_PROGRESS: 'In Progress',
  SUBMITTED: 'Submitted',
  REJECTED: 'Rejected',
  APPROVED: 'Approved',
  COMPLETED: 'Completed',
  NEW: 'New',
  REJECTED_BY_POC: 'Rejected By POC',
};

export const RAG_VALUE = {
  RED: 'Red',
  AMBER: 'Amber',
  GREEN: 'Green',
};

export const INDUSTRY_VALUES = {
  IT: 'IT',
  MANUFACTURING: 'Manufacturing',
  LOGISTIC: 'Logistics',
};

export const mergeArrayOfObjects = (arr1, arr2, key1, key2) => {
  const map = new Map();
  arr1.forEach((item) => map.set(item[key1], item));
  arr2.forEach((item) =>
    map.set(item[key2], { ...map.get(item[key2]), ...item })
  );
  return Array.from(map.values());
};

export const groupBy = (arr = [], key = '') => {
  const grouped = {};

  arr.forEach((item) => {
    if (grouped[item[key]]) {
      grouped[item[key]] = [...grouped[item[key]], item];
    } else {
      grouped[item[key]] = [item];
    }
  });

  return grouped;
};

/**
 * Extensions
 * @returns {string}
 */
// eslint-disable-next-line no-extend-native
String.prototype.toSentenceCase = function () {
  return this.charAt(0).toUpperCase() + this.slice(1).toLowerCase();
};

// eslint-disable-next-line no-extend-native
String.prototype.clipText = function (length) {
  if (this.length > length) {
    return this.substr(0, length) + '...';
  }
  return this;
};

export const frequencyMappingValues = {
  Weekly: 52,
  Monthly: 12,
  Fortnightly: 26,
  Annually: 1,
  Quarterly: 4,
  HalfYearly: 2,
  // daily : in database,
  Daily: 365,
};

export const QUESTION_TYPE = {
  SINGLE: 'SINGLE_CHOICE',
  MULTIPLE: 'MULTIPLE_CHOICE',
  TEXT: 'TEXT',
};

export const LIVELOG_TYPES = {
  COMMENT: 'COMMENT',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  LOG_SUBMISSION: 'LOG_SUBMISSION',
  EVENT_PURGED: 'EVENT_PURGED',
  EVENT_FINISHED: 'EVENT_FINISHED',
};

export const EVENT_STATUS = {
  PURGED: 'PURGED',
  FINISHED: 'FINISHED',
  INPROGRESS: 'INPROGRESS',
};

export const EMAIL_REGEX =
  /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

export const generatePassword = () => {
  const length = 8,
    charset = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789';
  let retVal = '';
  for (let i = 0, n = charset.length; i < length; ++i) {
    retVal += charset.charAt(Math.floor(Math.random() * n));
  }
  return retVal;
};

//* remove csv file in key of space
export const handleCsvRead = (data) => {
  data = data.map((item) => {

    // formatting values of CSV
    for (let key in item?.data) {

      if (typeof item?.data[key] === 'string') {
        item.data[key] = item?.data[key].replace(/\r/g, '').trim();
      }
    }

    // formatting keys of CSV
    Object.keys(item.data).forEach((key) => {
      let replaceKey = key
        .trim()
        .replace(/\s+/g, '_')
        .split('_')
        .map((str) => str.toLowerCase())
        .join('_')
        .replace(/[-_](\w)/g, function (match, group) {
          return group.toUpperCase();
        });
      if (key !== replaceKey) {
        item.data[replaceKey] = item.data[key];
        delete item.data[key];
      }
    });
    return item.data;
  });
  return data;
};
