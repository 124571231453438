/* eslint-disable react-hooks/exhaustive-deps */
import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextWithDivider from '../../../components/TextWithDivider';
import { AuthContext } from '../../../components/AuthContextProvider';
import SecondaryLayout from '../../../components/SecondaryLayout';
import TableWrapper from '../../POC/components/TableWrapper';
import StatContainer from '../../POC/components/StatContainer';
import useFetch from '../../../hooks/useFetch';
import {
  fetchApprovalCompletionStatus,
  fetchEventsByEnterpriseId,
  fetchRAGAssignmentStatus,
  getRagCompletionStatusByEnterpriseIdEventId,
  prepareTeamLogChartData,
  downloadManagerReport,
  downloadPocReport,
  fetchAllManagers,
} from '../../../operations/poc';
import BarChartWrapper from '../../../components/Charts/BarChartWrapper';
import ChartContainer from '../../../components/ChartContainer';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import { fetchAllEnterprises } from '../../../operations/enterprise';
import { useHistory } from 'react-router';
import { Paths } from '../../../routes/paths';
import Message from '../../../components/Message';
import { EFFORT_LOG_STATUS } from '../../../operations/constants';
import { employeeEffortLogStatusByEnterpriseIdEventId } from '../../../operations/admin';
import { isEmpty } from '../../../utils/utils';
import Stats from './../../../assets/images/stats.svg';
import PlaceholderImage from '../../../components/PlaceholderImage';
import { CloudDownloadOutlined } from '@material-ui/icons';
import DialogWrapper from '../../../components/DialogWrapper';
import {
  getAllEnterprise,
  getLimitOfCollectionEvent,
} from '../../../helper/backendHelper/enterprise';
import { fetchAllProcesses } from '../../../operations/employee';
import { getEventsOrganizationId } from '../../../helper/backendHelper/events';

const AdminDashboard = () => {
  const { user } = useContext(AuthContext);
  // let { eventId, setEvent } = useContext(EventContext);
  const [eventId, setEvent] = useState('');
  const [eventData, setEventData] = useState({});
  // eslint-disable-next-line no-unused-vars
  const [stats, setStats] = useState({});
  const [selectedTab, setSelectedTab] = useState('orgSummary');
  const [companyId, setCompanyId] = useState({
    limitOfCollectionEvent: '',
    eventId: '',
  });
  const [employeeEffortLog, setEmployeeEffortLog] = useState([]);
  const [loading, setLoading] = useState(false);
  const [chartData, setChartData] = useState([]);
  const [ragStatus, setRagStatus] = useState([]);
  const [managerDialog, setManagerDialog] = useState({
    open: false,
    managerId: '',
    list: [],
  });
  const [enterprises, setEnterprises] = useState([]);
  const [events, setEvents] = useState([]);
  const [enterpriseLoading, setEnterpriseLoading] = useState(false);
  const history = useHistory();

  useEffect(() => {
    fetchAllEnterprises();
  }, []);

  //* all enterprise when select the dropdown
  const fetchAllEnterprises = async () => {
    try {
      setEnterpriseLoading(true);
      const response = await getAllEnterprise();
      setEnterprises(response?.payload?.data);
      setEnterpriseLoading(false);
    } catch (error) {
      setEnterpriseLoading(false);
      Message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    (async () => {
      if (eventId === '' || !eventId) return;
      setLoading(true);
      await fetchApprovalData(eventId);
      await fetchRagAssignmentData(eventId);

      const data = await employeeEffortLogStatusByEnterpriseIdEventId({
        enterpriseId: companyId,
        eventId: eventId,
      });

      const c = await prepareTeamLogChartData(companyId, eventId);
      const ragAsgCompletion =
        await getRagCompletionStatusByEnterpriseIdEventId({
          enterpriseId: companyId,
          eventId: eventId,
        });

      setRagStatus(ragAsgCompletion);
      setChartData(c);
      setEmployeeEffortLog(data);

      setLoading(false);
    })();
  }, [eventId]);

  useEffect(async () => {
    if (managerDialog.open && managerDialog.list?.length < 1) {
      const data = await fetchAllManagers(eventId);
      setManagerDialog({ ...managerDialog, list: data });
    }
  }, [managerDialog.open]);

  useEffect(() => {
    // if (employeeEffortLog.length) {
    const approvalCount = employeeEffortLog.filter((item) =>
      [EFFORT_LOG_STATUS.APPROVED, EFFORT_LOG_STATUS.REJECTED].includes(
        item.status
      )
    ).length;

    const data = {
      approvalCompletion: !!approvalCount
        ? (approvalCount / employeeEffortLog.length) * 100
        : 0,
    };

    setStats((stats) => ({ ...stats, ...data }));
    // }
  }, [employeeEffortLog]);

  const {
    data: approvalCompletionData = [],
    loading: approvalLoading,
    fetchData: fetchApprovalData,
  } = useFetch(fetchApprovalCompletionStatus, eventId || user.eventId);

  const {
    data: ragAssignmentData = [],
    loading: assignmentLoading,
    fetchData: fetchRagAssignmentData,
  } = useFetch(fetchRAGAssignmentStatus, eventId || user.eventId);

  const handleSelectTab = (tab) => {
    setSelectedTab(tab);
  };

  const handleEventChange = async (event) => {
    if (event !== eventId) {
      // setLoading(true);
      setEvent(event);
      setEventData(events.find((el) => el._id === event));
      // await fetchApprovalData(event);
      // await fetchRagAssignmentData(event);

      // if (event !== '') {
      //   const data = await employeeEffortLogStatusByEnterpriseIdEventId({
      //     enterpriseId: companyId,
      //     eventId: event,
      //   });

      //   const c = await prepareTeamLogChartData(companyId, event);
      //   const ragAsgCompletion =
      //     await getRagCompletionStatusByEnterpriseIdEventId({
      //       enterpriseId: companyId,
      //       eventId: event,
      //     });

      //   setRagStatus(ragAsgCompletion);
      //   setChartData(c);
      //   setEmployeeEffortLog(data);
      // }
      // setLoading(false);
    }
  };

  //* select enterprise onchange
  const handleCompanyChange = async (id) => {
    try {
      const response = await getEventsOrganizationId(id);
      const fetchLimitOfCollectionEvent = await getLimitOfCollectionEvent(id);
      setCompanyId({
        eventId: id,
        limitOfCollectionEvent: fetchLimitOfCollectionEvent.payload.data,
      });

      setEvents(response?.payload?.data);
    } catch (error) {
      Message.error(error?.response?.data?.message);
    }
  };


  const downloadManagerReportHandler = () => {
    downloadManagerReport(eventId, user.id, managerDialog.managerId);
    setManagerDialog({ ...managerDialog, open: false, managerId: '' });
  };

  const downloadPocReportHandler = () => {
    downloadPocReport(eventId, user.id);
  };

  useEffect(() => {
    if (!isEmpty(ragStatus)) {
      if (isNaN(ragStatus.assignRagPercentage))
        ragStatus.assignRagPercentage = 0;
      setStats((stats) => ({ ...stats, ...ragStatus }));
    }
  }, [ragStatus]);

  return (
    <Layout
      loading={
        approvalLoading || assignmentLoading || enterpriseLoading || loading
      }>
      <SecondaryLayout>
        <Grid container className="pl-4 pr-2">
          <Grid item lg={9} md={8} className="pr-4 pt-4">
            <TextWithDivider>
              <Grid
                container
                alignItems="center"
                justifyContent="space-between">
                <Typography variant="h3" color="textPrimary">
                  Summary
                </Typography>
                <div style={{ display: 'flex', gap: 10 }}>
                  {/* <Button
                    startIcon={<AddCircleOutlineIcon />}
                    variant="contained"
                    onClick={() => {
                      if (companyId.limitOfCollectionEvent === events.length) {
                        return Message.warn('Your Limit has exceed');
                      }
                      if (companyId.eventId !== '') {
                        history.push(
                          `${Paths?.adminCreateEvent?.split(':')[0]}/${
                            companyId.eventId
                          }`
                        );
                      } else {
                        Message.warn(
                          'Please select an Organization before you add any events.'
                        );
                      }
                    }}
                    color="primary"
                    disableElevation>
                    Add New Event
                  </Button> */}
                  {eventData?.status === 'FINISHED' ? (
                    <>
                      <Button
                        startIcon={<CloudDownloadOutlined />}
                        variant="contained"
                        onClick={() =>
                          setManagerDialog({ ...managerDialog, open: true })
                        }
                        // downloadManagerReportHandler
                        color="primary"
                        disableElevation>
                        Manager Report
                      </Button>
                      <Button
                        startIcon={<CloudDownloadOutlined />}
                        variant="contained"
                        onClick={downloadPocReportHandler}
                        color="primary"
                        disableElevation>
                        POC Report
                      </Button>
                    </>
                  ) : (
                    <></>
                  )}
                </div>
              </Grid>
            </TextWithDivider>
            <Grid container spacing={2} item md={8}>
              <Grid item xs={6}>
                {enterprises.length ? (
                  <FormControl fullWidth>
                    <InputLabel id="selectEnterprise">
                      Select Enterprise
                    </InputLabel>

                    <Select
                      labelId="selectEnterprise"
                      fullWidth
                      placeholder="Select Enterprise"
                      onChange={(evt) => handleCompanyChange(evt.target.value)}>
                      {enterprises?.map((item) => (
                        <MenuItem value={item._id} key={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  ''
                )}
              </Grid>
              <Grid item xs={6}>
                {events.length !== 0 ? (
                  <FormControl fullWidth>
                    <InputLabel id="selectEvent">Select Event</InputLabel>
                    <Select
                      fullWidth
                      labelId="selectEvent"
                      onChange={(evt) => handleEventChange(evt.target.value)}>
                      {events?.map((item) => (
                        <MenuItem
                          value={item._id}
                          key={item._id}
                          disabled={item.status === 'PURGED'}>
                          {item.name}
                          {item.status === 'PURGED' ? '(Purged)' : ''}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
            <Grid container justifyContent="space-between" className="mt-4">
              <Grid item container className="w-50">
                <Typography variant="subtitle2" className="ml-3">
                  Organizational Summary
                </Typography>
                <Grid
                  item
                  container
                  justifyContent="space-around"
                  className="mt-3">
                  <StatContainer
                    value={stats.approvalCompletion?.toFixed(2) || 0}
                    unit="%"
                    label={'Approval Completion Status'}
                    handleClick={() => handleSelectTab('orgSummary')}
                    theme={
                      selectedTab === 'orgSummary' ? 'primary' : 'secondary'
                    }
                    additionalClasses="pointer"
                  />
                  <StatContainer
                    value={stats.assignRagPercentage?.toFixed(2) || 0}
                    unit="%"
                    label={'RAG Assignment Completion'}
                    handleClick={() => handleSelectTab('ragAssign')}
                    theme={
                      selectedTab === 'ragAssign' ? 'primary' : 'secondary'
                    }
                    additionalClasses="pointer"
                  />
                </Grid>
              </Grid>
              <Grid item container className="w-50">
                <Typography variant="subtitle2" className="ml-3">
                  My Task Summary
                </Typography>
                <Grid item container className="mt-3">
                  <StatContainer
                    value={
                      stats?.ragData?.filter((e) => e.ragStatus === 'APPROVED')
                        .length || 0
                    }
                    unit={`/${stats?.ragData?.length || 0}`}
                    label={'RAG Approval Completion'}
                    theme="secondary"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid className="mt-4">
              {selectedTab === 'orgSummary' ? (
                <TableWrapper
                  data={approvalCompletionData}
                  id={'approvalCompletionStatusPOC'}
                  tableHeads={[
                    { id: 'name', label: 'Name' },
                    {
                      id: 'teamSize',
                      label: 'Team Size',
                      align: 'center',
                    },
                    {
                      id: 'totalEffortLogs',
                      label: 'Effort Log Submitted',
                      align: 'center',
                    },
                    {
                      id: 'completionStatus',
                      label: 'Approval Status',
                      align: 'center',
                    },
                    {
                      id: 'rejectedEffortLogs',
                      label: 'Rejected Records',
                      align: 'center',
                    },
                  ]}
                  dataKeys={[
                    { id: 'name' },
                    { id: 'teamSize', align: 'center' },
                    { id: 'totalEffortLogs', align: 'center' },
                    { id: 'completionStatus', align: 'center' },
                    { id: 'rejectedEffortLogs', align: 'center' },
                  ]}
                  isSelectable={false}
                />
              ) : (
                <TableWrapper
                  data={ragAssignmentData}
                  id={'ragCompletionStatusPOC'}
                  tableHeads={[
                    { id: 'name', label: 'Name' },
                    {
                      id: 'teamSize',
                      label: 'Team Size',
                      align: 'center',
                    },
                    {
                      id: 'totalActivitiesCount',
                      label: 'Activities Recorded',
                      align: 'center',
                    },
                    {
                      id: 'completionStatus',
                      label: 'RAG Assignment Status',
                      align: 'center',
                    },
                  ]}
                  dataKeys={[
                    { id: 'name' },
                    { id: 'teamSize', align: 'center' },
                    { id: 'totalActivitiesCount', align: 'center' },
                    { id: 'completionStatus', align: 'center' },
                  ]}
                  isSelectable={false}
                />
              )}
            </Grid>
          </Grid>
          <DialogWrapper
            title="Select Manager"
            open={managerDialog.open}
            submitButtonTitle="Submit"
            handleSubmit={downloadManagerReportHandler}
            handleClose={() =>
              setManagerDialog({ ...managerDialog, open: false })
            }>
            <RadioGroup
              name="manager-group"
              value={managerDialog.managerId}
              onChange={(event) =>
                setManagerDialog({
                  ...managerDialog,
                  managerId: event.target.value,
                })
              }>
              {(managerDialog.list || []).map((manager) => (
                <div>
                  <FormControlLabel
                    value={manager.id}
                    control={<Radio />}
                    label={manager?.name}
                  />
                </div>
              ))}
            </RadioGroup>
          </DialogWrapper>
          <ChartContainer title="Team Wise Log Submission">
            {!chartData.length ? (
              <PlaceholderImage image={Stats} messsage="No Logs available" />
            ) : (
              <BarChartWrapper
                data={chartData}
                keyBar1="totalLogs"
                nameBar1="totalLogs"
                keyBar2="submittedLogs"
                nameBar2="submittedLogs"
              />
            )}
          </ChartContainer>
        </Grid>
      </SecondaryLayout>
    </Layout>
  );
};

export default AdminDashboard;
