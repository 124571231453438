import axios from 'axios';
import { post } from '../api_helper';
import { getApiConfig, getAuthToken } from '../auth_helper';
import * as url from '../url_helper';

//* file upload
export const fileUpload = async (data) => {
  return await axios.post(
    `${url.API_URL}/${url.FILE_UPLOAD}`,
    data,
    getApiConfig(true)
  );
};
