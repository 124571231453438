import React, { useContext, useState } from 'react';
import {
  Typography,
  FormControl,
  InputBase,
  Button,
  Dialog,
  DialogTitle,
  Grid,
  DialogContent,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import { colors } from '../../theme/colors';

import { GenericErrorForm } from '../../operations/utils';
import Loader from '../../components/Loader';
import LoginSvg from '../../assets/images/login.svg';
import { useHistory } from 'react-router-dom';
import { setAuthToken } from '../../helper/auth_helper';
import { login } from '../../helper/backendHelper/login';
import Message from '../../components/Message';
import { AuthContext } from '../../components/AuthContextProvider';
import Routes from '../../routes';
import { EventContext } from '../../components/EventContextProvider';
import DialogWrapper from '../../components/DialogWrapper';
import { InfoOutlined } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
  root: {
    height: '100vh',
    width: '100%',
    display: 'flex',
    flexDirection: 'row',
    background: 'white',
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  loginInput: {
    backgroundColor: colors.common.white,
    // borderRadius: 10,
    border: `2px solid ${colors.primary.main}`,
    fontSize: '16px',
    '& input': {
      padding: '15px',
    },
    '& input::placeholder': {
      opacity: 1,
      color: colors.common.black,
    },
  },
}));
const Login = () => {
  const history = useHistory();
  const { setSave } = useContext(AuthContext);
  const { setSaveEvents } = useContext(EventContext);
  const classes = useStyles();
  const [form, setForm] = useState({
    email: '',
    password: '',
  });

  const [loading, setLoading] = useState(false);
  const [isOpen, setIsOpen] = useState(false);
  const [tooltip, setTooltip] = useState(false);

  const handleChange = (e) => {
    setForm({
      ...form,
      [e.target.name]: e.target.value.trim(),
    });
  };

  //handle login
  const handleLogin = async (e) => {
    e.preventDefault();
    if (!form.email || !form.password) {
      GenericErrorForm();
      return;
    }
    try {
      setLoading(true);
      const response = await login(form);
      if (response) {
        setAuthToken(response);
        history.push(`${response?.payload?.role}/dashboard`);
        setSave((preSaved) => !preSaved);
        setSaveEvents((preSaved) => !preSaved);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Message.error(error?.response?.data?.message);
    }
  };

  if (loading) return <Loader />;

  return (
    <div className={classes.root}>
      <div
        style={{
          position: 'relative',
          flex: 1,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}>
        <div
          style={{
            padding: '0 50px',
            flex: 1,
            display: 'flex',
            justifyContent: 'center',
          }}>
          <img style={{ width: '90%' }} src={LoginSvg} alt="login" />
        </div>
        <div
          style={{
            flex: 1,
            padding: '0 50px',
            display: 'flex',
            justifyContent: 'center',
          }}>
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              gap: 10,
              width: '80%',
            }}>
            <Typography
              style={{ fontWeight: 'normal', lineHeight: 1.5 }}
              variant="h1">
              Login to your account
              <InfoOutlined color="primary" onClick={() => setTooltip(true)} className='ml-2' />
            </Typography>
            <Typography
              style={{ fontWeight: 'normal', fontSize: '14px' }}
              variant="subtitle1">
              Please enter your login details
            </Typography>

            <form onSubmit={handleLogin}>
              <FormControl style={{ width: '100%' }} variant="outlined">
                <InputBase
                  name="email"
                  onChange={handleChange}
                  value={form.email}
                  className={classes.loginInput}
                  placeholder="email"
                />
              </FormControl>
              <FormControl
                style={{ width: '100%' }}
                variant="outlined"
                className="my-3">
                <InputBase
                  name="password"
                  value={form.password}
                  className={classes.loginInput}
                  type="password"
                  placeholder="Password *"
                  onChange={handleChange}
                />
              </FormControl>

              <Button
                style={{
                  width: '100%',
                  fontSize: '16px',
                }}
                type="submit"
                variant="contained"
                disableElevation
                color="primary">
                Login
              </Button>
            </form>

            <Button
              variant="text"
              color="primary"
              style={{ width: 'fit-content' }}
              onClick={() => setIsOpen(true)}>
              {' '}
              <span style={{ marginTop: 10, textDecoration: 'underline' }}>
                Why Workload? Know the platform
              </span>
            </Button>
          </div>
        </div>
      </div>
      <ShowWorkloadDialog open={isOpen} handleClose={() => setIsOpen(false)} />
      <DialogWrapper
        title="Guide for Login"
        open={tooltip}
        handleClose={() => setTooltip(false)}>
        <div className='overflow-auto'>
          Use your credentials to access the system and land on
          the dashboard.
        </div>
      </DialogWrapper>
    </div>
  );
};

export default Login;

const ShowWorkloadDialog = ({ open, handleClose }) => {
  return (
    <Dialog open={open} onClose={handleClose}>
      <DialogTitle>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={10}>
            <Typography variant="h6" style={{ textTransform: 'capitalize' }}>
              Why Workload?
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Button variant="text" color="primary" onClick={handleClose}>
              Close
            </Button>
          </Grid>
        </Grid>
      </DialogTitle>
      <DialogContent>
        <p>
          <b>Summary –</b> Work-load.com is an industry agnostic workforce
          planning platform that offers customers actionable organization and
          talent insights at scale.
        </p>
        <p>
          <b>Background -</b> Our solution is backed by nearly 7 years of deep
          research on workforce planning that demonstrates a need for workforce
          planning tools to help organizations perform self-service planning to
          monitor demand and supply, in keeping with changing the talent
          landscape of automation, open source learning, reskilling, remote /
          hybrid working.
        </p>
        <p>
          <b>How do we define and measure success -</b> Our plan is to build an
          industry agnostic platform that allows decision makers (leaders,
          managers / team leaders) to input, measure organizational, team level
          workload data.
        </p>
        <p>
          We envisage that the platform will offer solutions to aid talent
          workforce planning and budgeting based on internal workload data.
        </p>
        <p>
          Further, at a later stage (in 12-18 months) accelerate efforts in job
          design, estimate skills demand to plan scenarios (enhanced
          outsourcing, usage of ERP, digital, on-site, remote, hybrid, DEI etc).
        </p>
        <p>
          <b>Platform users – </b>There are 3 distinct users{' '}
        </p>
        <ol>
          <li>
            Client SPOC (leadership level decision makers) - MD, CEO, CFO, CHRO
          </li>
          <li>Managers (team leaders)</li>
          <li>Employees</li>
        </ol>
        <p>
          <b>Key User Actions – </b>
        </p>
        <ul>
          <li>
            <b>Employee</b> inputs workload information based on job
            descriptions, key objectives, standard operating procedures. Data
            inputs provided by the employee include individual or identical /
            similar role based “individual workload” data. This data is captured
            in a standard format that includes activity, sub-activity, tools,
            frequency, time taken and volumes by activity, to calculate
            individual workload against “standard available hours in a given
            year”.
          </li>
          <li>
            <b>Manager or Team leader</b> validates data provided by the
            employee and classifies activities into Red, Amber and Green,
            defined as Non-value adding, Necessary but Non-value adding and
            Value adding based on definitions / own judgement, adding Remarks.
            The Manager is notified of team members who are under-utilized or
            over-utilized.
          </li>
          <li>
            <b>Client SPOC (leadership level decision makers)</b> receives
            organization-wide data and a summary of collective Red, Amber and
            Green data by teams. Further perform analytics of workforce and
            workload data.
          </li>
        </ul>
      </DialogContent>
    </Dialog>
  );
};
