import axios from 'axios';
// import accessToken from "./jwt-token-access/accessToken"
import { API_URL } from './url_helper';
import Message from '../components/Message';

//pass new generated access token here
// const token = accessToken;

const axiosApi = axios.create({
  baseURL: API_URL,
});

// axiosApi.interceptors.response.use(
//   (response) => response,
//   (error) => Promise.reject(error)
// );

//* get
export async function get(url, config = {}) {
  return await axiosApi
    .get(url, { ...config })
    .then((response) => response.data)
    .catch((error) => {
      Message.error(error?.response?.data?.message);
      throw error;
    });
}

//* post
export async function post(url, data, config = {}) {
  return axiosApi
    .post(url, { ...data }, { ...config })
    .then((response) => response.data)
    .catch((error) => {
      Message.error(error?.response?.data?.message);
      throw error;
    });
}

//* update
export async function put(url, data, config = {}) {
  return axiosApi
    .put(url, { ...data }, { ...config })
    .then((response) => response.data)
    .catch((error) => {
      Message.error(error?.response?.data?.message);
      throw error;
    });
}

//* delete
export async function del(url, config = {}) {
  return await axiosApi
    .delete(url, { ...config })
    .then((response) => response.data)
    .catch((error) => {
      Message.error(error?.response?.data?.message);
      throw error;
    });
}
