import React, { useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextWithDivider from '../../../components/TextWithDivider';
import SecondaryLayout from '../../../components/SecondaryLayout';
import TableWrapper from '../../POC/components/TableWrapper';
import useFetch from '../../../hooks/useFetch';
import {
  fetchEventsByEnterpriseId,
  fetchRAGAssignmentStatus,
} from '../../../operations/poc';
import { useHistory } from 'react-router-dom';
import { Paths } from '../../../routes/paths';
import { fetchAllEnterprises } from '../../../operations/enterprise';

const AdminRAG = () => {
  const [eventId, setEvent] = useState('');
  const [events, setEvents] = useState([]);

  const [loading, setLoading] = useState('');
  const [companyId, setCompanyId] = useState('');
  const { data: enterprises = [] } = useFetch(fetchAllEnterprises);
  const [ragAssignmentData, setRagAssignmentData] = useState([]);

  //   const {
  //     data: events = [],
  //     loading: eventsLoading,
  //     fetchData: fetchDataEventsByEnterpriseId,
  //   } = useFetch(fetchEventsByEnterpriseId, user.enterpriseId || '');

  const history = useHistory();

  //   const { data: ragAssignmentData = [], loading: ragAssignLoading } = useFetch(
  //     fetchRAGAssignmentStatus,
  //     eventId
  //   );

  useEffect(() => {
    if (companyId) {
      (async () => {
        const data = await fetchEventsByEnterpriseId(companyId);
        setEvents(data);
      })();
    }
    // eslint-disable-next-line
  }, [companyId]);

  const ragAssignmentStatusSetter = async (eventId) => {
    return new Promise(async (resolve, reject) => {
      const data = await fetchRAGAssignmentStatus(eventId);
      setRagAssignmentData(data);
      resolve();
    });
  };

  const handleEventChange = async (event) => {
    if (event !== eventId) {
      setLoading(true);
      setEvent(event);

      if (event !== '') {
        await ragAssignmentStatusSetter(event);
      }
      setLoading(false);
    }
  };

  const handleCompanyChange = (value) => {
    setCompanyId(value);
  };

  return (
    <Layout loading={loading}>
      <SecondaryLayout>
        <Grid container className="pl-4 pr-2">
          <Grid item lg={9} md={8} className="pr-4 pt-4">
            <TextWithDivider>
              <Typography variant="h3" color="textPrimary">
                RAG
              </Typography>
            </TextWithDivider>

            <Grid className="mx-2 my-3" container spacing={2} item md={8}>
              <Grid item xs={6}>
                {enterprises.length ? (
                  <FormControl fullWidth>
                    <InputLabel id="selectEnterprise">
                      Select Enterprise
                    </InputLabel>

                    <Select
                      labelId="selectEnterprise"
                      value={companyId}
                      fullWidth
                      placeholder="Select Enterprise"
                      onChange={(evt) => handleCompanyChange(evt.target.value)}>
                      {enterprises.map((item) => (
                        <MenuItem value={item.id} key={item.name}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  ''
                )}
              </Grid>
              <Grid item xs={6}>
                {companyId ? (
                  <FormControl fullWidth>
                    <InputLabel id="selectEvent">Select Event</InputLabel>
                    <Select
                      fullWidth
                      labelId="selectEvent"
                      disabled={companyId === ''}
                      value={eventId || ''}
                      onChange={(evt) => handleEventChange(evt.target.value)}>
                      <MenuItem disabled value={''} />
                      {events.length === 0 && (
                        <MenuItem disabled value={'No Events present'} />
                      )}
                      {events.map((item) => (
                        <MenuItem value={item.id} key={item.id}>
                          {item.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                ) : (
                  ''
                )}
              </Grid>
            </Grid>
            <Grid className="mt-4">
              <TableWrapper
                data={ragAssignmentData}
                id={'ragPOC'}
                tableHeads={[
                  { id: 'name', label: 'Name' },
                  {
                    id: 'teamSize',
                    label: 'Team Size',
                    align: 'center',
                  },
                  {
                    id: 'totalActivitiesCount',
                    label: 'Activities Recorded',
                    align: 'center',
                  },
                  {
                    id: 'completionStatus',
                    label: 'RAG Assignment Status',
                    align: 'center',
                  },
                ]}
                dataKeys={[
                  { id: 'name' },
                  { id: 'teamSize', align: 'center' },
                  { id: 'totalActivitiesCount', align: 'center' },
                  { id: 'completionStatus', align: 'center' },
                ]}
                isSelectable={false}
                handleRowClick={(id) => {
                  history.push(
                    `${Paths.adminRagTeam}/${id}/${companyId}/${eventId}`
                  );
                }}
              />
            </Grid>
          </Grid>
        </Grid>
      </SecondaryLayout>
    </Layout>
  );
};

export default AdminRAG;
