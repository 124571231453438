import { useMemo, useState, createContext } from 'react';
import { getAuthToken } from '../../helper/auth_helper';

export const AuthContext = createContext(null);

const AuthContextProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [save, setSave] = useState(false);

  useMemo(() => {
    if (localStorage.getItem('token')) {
      let token = getAuthToken();
      token = JSON.parse(token);
      token['role'] = token?.role?.toUpperCase();
      setUser(token);
      setSave(false);
    } else {
      setUser({});
    }
    getAuthToken();
  }, [save]);

  return (
    <AuthContext.Provider value={{ user, setSave }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;
