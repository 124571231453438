import React from 'react';
import { Button } from 'reactstrap';

import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
// import { CSVLink } from "react-csv";
import dayjs from 'dayjs';
import Message from './Message';

export const ExportToExcel = ({ data, fileName }) => {
  const fileType =
    'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
  const fileExtension = '.xlsx';

  const exportToExcel = (data, fileName) => {
    const wb = { Sheets: {}, SheetNames: [] };

    data.forEach((sheetObj, index) => {
      let sheetName = `sheet${index + 1}`;
      let sheetData = sheetObj[`sheet${index + 1}Data`];

      if (sheetData.length === 0) {
        Message('No data to export', 'error');
        return;
      }

      sheetData = XLSX.utils.json_to_sheet(sheetData);
      sheetName = sheetObj[sheetName];

      wb.Sheets[sheetName] = sheetData;
      wb.SheetNames.push(sheetName);
    });

    // Write the workbook to an Excel buffer
    const excelBuffer = XLSX.write(wb, { bookType: 'xlsx', type: 'array' });

    // Create a Blob from the buffer
    const excelData = new Blob([excelBuffer], { type: fileType });

    // Save the Blob as a file using FileSaver
    FileSaver.saveAs(
      excelData,
      fileName + ' - ' + dayjs().format('DD/MM/YYYY') + fileExtension
    );
  };

  return (
    <div className="float-end mb-2 ">
      <Button
        className="btn-md cursor"
        color=""
        style={{
          border: '2px solid #0080ff',
          fontWeight: 'bolder',
          fontSize: '14px',
          padding: '8px 12px',
        }}
        onClick={() => {
          exportToExcel(data, fileName);
        }}>
        <img
          className="mx-1"
          src="https://img.icons8.com/color/48/000000/ms-excel.png"
          width={20}
          alt="Excel"
        />
        {/* <CSVLink {...csvreport}>Export to CSV</CSVLink> */}
        Export
      </Button>
    </div>
  );
};
