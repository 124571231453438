import { Grid, Typography } from '@material-ui/core';
import React from 'react';
import { LIVELOG_TYPES } from '../../utils/utils';
import BorderColorOutlinedIcon from '@material-ui/icons/BorderColorOutlined';
import { colors } from '../../theme/colors';

const Space = () => <span>&nbsp;</span>;

const LiveLog = ({ type, data }) => {
  return (
    <div className="my-3">
      {(() => {
        switch (type) {
          case LIVELOG_TYPES.COMMENT: {
            return (
              <Grid container>
                <Grid container alignItems="center" item xs={2}>
                  <BorderColorOutlinedIcon
                    color="primary"
                    style={{
                      background: colors.primary.light,
                      padding: '3px',
                      fontSize: '18px',
                      borderRadius: '4px',
                    }}
                  />
                </Grid>
                <Grid container item xs={7}>
                  <Typography style={{ fontWeight: 'bold' }} variant="body2">
                    Comment sent
                  </Typography>
                  <Typography style={{ fontWeight: 'normal' }} variant="body2">
                    <Space />
                    for
                    <Space />
                  </Typography>

                  <Typography style={{ fontWeight: 'bold' }} variant="body2">
                    {data.createdForName} on
                  </Typography>
                  <Space />
                  <Typography variant="body2">
                    {data?.createdAt?.toDate().toLocaleDateString()}
                  </Typography>
                </Grid>
              </Grid>
            );
          }

          case LIVELOG_TYPES.REJECTED:
          case LIVELOG_TYPES.APPROVED: {
            return (
              <Grid container>
                <Grid container alignItems="center" item xs={2}>
                  <BorderColorOutlinedIcon
                    color="primary"
                    style={{
                      background: colors.primary.light,
                      padding: '3px',
                      fontSize: '18px',
                      borderRadius: '4px',
                    }}
                  />
                </Grid>
                <Grid container item xs={7}>
                  <Typography style={{ fontWeight: 'bold' }} variant="body2">
                    {`Effort Logs ${type}`}
                  </Typography>
                  <Typography style={{ fontWeight: 'normal' }} variant="body2">
                    <Space />
                    for
                    <Space />
                  </Typography>

                  <Typography variant="body2">
                    {data.createdForName} on
                  </Typography>
                  <Space />
                  <Typography variant="body2">
                    {data?.createdAt?.toDate().toLocaleDateString()}
                  </Typography>
                </Grid>
              </Grid>
            );
          }

          case LIVELOG_TYPES.LOG_SUBMISSION: {
            return (
              <Grid container>
                <Grid container alignItems="center" item xs={2}>
                  <BorderColorOutlinedIcon
                    color="primary"
                    style={{
                      background: colors.primary.light,
                      padding: '3px',
                      fontSize: '18px',
                      borderRadius: '4px',
                    }}
                  />
                </Grid>
                <Grid container item xs={7}>
                  <Typography style={{ fontWeight: 'bold' }} variant="body2">
                    {`${data.createdByName} submitted his effort log`}
                  </Typography>
                  <Typography style={{ fontWeight: 'normal' }} variant="body2">
                    <Space />
                    to
                    <Space />
                  </Typography>

                  <Typography variant="body2">his manager on</Typography>
                  <Space />
                  <Typography variant="body2">
                    {data?.createdAt?.toDate().toLocaleDateString()}
                  </Typography>
                </Grid>
              </Grid>
            );
          }
          default: {
            return;
          }
        }
      })()}
    </div>
  );
};

export default LiveLog;
