import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Divider } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';

const useStyles = makeStyles((theme) => ({
  highlight: {
    height: '6px',
    background: theme.palette.primary.light,
    width: '60px',
    borderRadius: '6px',
  },
}));

const TextWithDivider = ({ children }) => {
  const classes = useStyles();

  return (
    <React.Fragment>
      {children}
      <Grid className="mt-1">
        <div className={classes.highlight} />
        <Divider />
      </Grid>
    </React.Fragment>
  );
};

export default TextWithDivider;
