//* download file
export const downloadFile = async (data, fileName) => {
  // Create a blob and object URL to trigger the download
  const blob = new Blob([data], { type: 'text/csv' });
  const url = URL.createObjectURL(blob);
  // // Create a hidden anchor element and trigger the download
  const a = document.createElement('a');
  a.href = url;
  a.download = fileName;
  a.style.display = 'none';
  document.body.appendChild(a);
  a.click();
  // Clean up
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
};
