import React from 'react';
import Grid from '@material-ui/core/Grid';
import TextWithDivider from '../TextWithDivider';
import { Typography } from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import ActivityContainer from '../ActivityContainer';

const useStyles = makeStyles((theme) => ({
  container: {
    borderLeft: '4px solid #f6f7fa',
    height: 'calc(100vh - 65px)',
    display: 'flex',
    flexDirection: 'column'
  },
  verticalDivider: {
    width: '4px',
    borderRadius: '4px',
  },
  infoContainer: {
    backgroundColor: 'rgba(244, 245, 250, 0.4)',
    height: '300px',
    overflowY: 'scroll',
  },
}));

const ChartContainer = ({ children, title, enterpriseId }) => {
  const classes = useStyles();
  return (
    <React.Fragment>
      <Grid item lg={3} className={`${classes.container} pl-3 pr-2 pt-4`}>
        <Grid style={{flex: 1}}>
          <TextWithDivider>
            <Typography variant="h3" color="textPrimary">
              {title}
            </Typography>
          </TextWithDivider>
          <Grid className={`${classes.infoContainer} mt-3`}>{children}</Grid>
        </Grid>
       <Grid style={{flex: 1}}>
        <ActivityContainer  enterpriseId={enterpriseId} />
      </Grid>
      </Grid>
    </React.Fragment>
  );
};

export default ChartContainer;
