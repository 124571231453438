import React, { useState } from 'react';
import {
  Button,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import EmptyLogEffortImage from '../../../../assets/images/EmptyLog.png';
import { makeStyles } from '@material-ui/core/styles';
import GetAppOutlinedIcon from '@material-ui/icons/GetAppOutlined';
import PublishOutlinedIcon from '@material-ui/icons/PublishOutlined';
import { CSVReader } from 'react-papaparse';
import CloseIcon from '@material-ui/icons/Close';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import DialogWrapper from '../../../../components/DialogWrapper';
import { colors } from '../../../../theme/colors';
import { handleCsvRead } from '../../../../utils/utils';
import Message from '../../../../components/Message';

const useStyles = makeStyles((theme) => ({
  emptyImage: {
    width: '255px',
    height: '255px',
  },
}));

const buttonRef = React.createRef();

const ManagerUploadSurface = ({
  handleDownloadClick = () => null,
  handleCreateClick = () => null,
  handleBack = () => null,
  eventId,
  isReset = false,
  isData = false,
}) => {
  const classes = useStyles();
  const [tooltip, setTooltip] = useState(false);

  const handleOnFileLoad = (data) => {
    handleCreateClick(handleCsvRead(data));
  };

  const handleOnError = (err, file, inputElem, reason) => {};

  const handleOpenDialog = (e) => {
    if (!eventId) {
      return Message.error('Please select collection events !');
    }
    if (buttonRef.current) {
      buttonRef.current.open(e);
    }
  };

  return (
    <Paper className="p-4 mt-4 ml-5">
      <Grid container>
        <Grid container item alignItems="center" direction="column">
          <Grid container justifyContent="space-between">
            <Typography variant="body1" color="textSecondary">
              Upload your employee list
            </Typography>

            <div>
              <IconButton onClick={() => setTooltip(true)}>
                <InfoOutlinedIcon color="primary" />
              </IconButton>

              <IconButton
                size="small"
                aria-label="approve"
                onClick={() => handleBack()}>
                <CloseIcon color="primary" />
              </IconButton>
            </div>
          </Grid>
          <img
            src={EmptyLogEffortImage}
            alt="emptyLogEffort"
            className={`${classes.emptyImage} mt-4`}
          />
          <Grid
            container
            className="mt-3"
            alignItems="center"
            justifyContent="center">
            <Grid item>
              <Button
                variant="outlined"
                disableElevation
                onClick={() => handleDownloadClick()}
                color="primary"
                startIcon={<GetAppOutlinedIcon />}>
                Download Template
              </Button>
            </Grid>
            <Grid item className="ml-3">
              <CSVReader
                ref={buttonRef}
                onFileLoad={handleOnFileLoad}
                onError={handleOnError}
                noClick
                isReset={isReset}
                accept={'text/csv, .csv'}
                config={{
                  chunkSize: 2,
                  header: true,
                }}
                noDrag>
                <Button
                  variant="contained"
                  disableElevation
                  onClick={(e) => handleOpenDialog(e)}
                  color="primary"
                  startIcon={<PublishOutlinedIcon />}>
                  Upload Employee Data
                </Button>
              </CSVReader>
            </Grid>
          </Grid>
        </Grid>
      </Grid>

      <DialogWrapper
        title="Fields and description for template"
        open={tooltip}
        handleClose={() => setTooltip(false)}>
        <DescriptionTable />
      </DialogWrapper>
    </Paper>
  );
};

const DescriptionTable = () => {
  const data = [
    {
      Field: 'Employee code',
      Description: 'Unique employee code',
      REMARKS: 'HRIS',
    },
    {
      Field: 'Employee Name',
      Description:
        'Name of Team Member (NOTE - workload analysis is performed for levels below Managers with observable frequency given routine nature of work)',
      REMARKS: 'HRIS',
    },
    {
      Field: 'Category',
      Description:
        'Represents Employment Category in Indirect Workforce - Executives, Staff or Contract Employee',
      REMARKS: 'HRIS',
    },
    {
      Field: 'Unit Name',
      Description: 'Unit, Plant, Branch Name as applicable',
      REMARKS: 'HRIS',
    },
    {
      Field: 'Location',
      Description: 'Location',
      REMARKS: 'HRIS',
    },
    {
      Field: 'DOJ',
      Description: 'Date of Joining',
      REMARKS: 'HRIS',
    },
    {
      Field: 'DOB',
      Description: 'Date of Birth',
      REMARKS: 'HRIS',
    },
    {
      Field: 'Gender',
      Description:
        'Male, Female, Others (Please mention to track Diversity targets or mark as NA)',
      REMARKS: 'HRIS',
    },
    {
      Field: 'Qualification',
      Description: 'Highest qualification',
      REMARKS: 'HRIS',
    },
    {
      Field: 'Job Title',
      Description: 'Represents job profile',
      REMARKS: 'Job Architecture',
    },
    {
      Field: 'Grade',
      Description: 'Grade as applicable',
      REMARKS: 'Job Architecture',
    },
    {
      Field: 'Job Band',
      Description: 'Job Band or Pay Band classification',
      REMARKS: 'Job Architecture',
    },
    {
      Field: 'Level',
      Description:
        'Level as applicable - Senior, Middle and Junior Management or Unit Head, Department Head, Team Leader',
      REMARKS: 'Job Architecture',
    },
    {
      Field: 'Designation',
      Description:
        'Component of Job Profile that represents expertise and qualifications',
      REMARKS: 'Job Architecture',
    },
    {
      Field: 'Position',
      Description: 'Job hierarchy classification',
      REMARKS: 'Job Architecture',
    },
    {
      Field: 'Role Description',
      Description:
        'Discipline or area of specialization and basis for configuration of Sub-Team (specialization, customer, geography, product, manufacturing area, supplier or commodity)',
      REMARKS: 'Operational Organogram',
    },
    {
      Field: 'Role',
      Description: 'Role assigned to Job Title as per organization structure',
      REMARKS: 'Organization Structure',
    },
    {
      Field: 'Business Unit',
      Description:
        'Name of division, business unit or classification by corporate, centralized, and decentralized organizations',
      REMARKS: 'Organization Structure',
    },
    {
      Field: 'Center',
      Description:
        'Name of sub-division, sub-unit - R&D, Operations, Supply Chain as applicable',
      REMARKS: 'Organization Structure',
    },
    {
      Field: 'Department',
      Description: 'Name of department within Business Unit',
      REMARKS: 'Organization Structure',
    },

    {
      Field: 'Sub-Team',
      Description:
        'Name of Sub-Team that indicates further classification of identical roles (NOTE - applicable ONLY in the case of identical roles)',
      REMARKS: 'Organization Structure',
    },

    {
      Field: 'HOD Name',
      Description: 'Head of Department (Reporting Manager Name)',
      REMARKS: 'HRIS',
    },
    {
      Field: 'HOD Title',
      Description: 'Reporting Manager Title',
      REMARKS: 'Organization Structure',
    },
    {
      Field: 'Head of Unit Name',
      Description:
        'Head of Division, Unit, Plant, or Branch as applicable (Name of Unit or Division Head)',
      REMARKS: 'HRIS',
    },
    {
      Field: 'Head of Unit Title',
      Description: 'Head of Division, Unit, Plant, or Branch as applicable',
      REMARKS: 'Organization Structure',
    },
    {
      Field: 'Shift',
      Description:
        'Shift (in case of Plant Managers) or General Shift (in case of Corporate or Central Functions)',
      REMARKS: 'Linked to Deployment',
    },
    {
      Field: 'Identical / Individual Roles',
      Description:
        'Identical or Similar Roles are Team members who perform similar work within the team, with similar backgrounds and skills and Individual roles are  team members who perform different work within the team as individual contributors, with different backgrounds and skills (NOTE - there are 2 different templates for these roles)',
      REMARKS: 'Job Families',
    },
    {
      Field: 'Email',
      Description: 'organizational email address',
      REMARKS: 'HRIS',
    },
  ];

  return (
    <Table>
      <TableHead>
        <TableRow>
          <TableCell>Field</TableCell>
          <TableCell>Description</TableCell>
        </TableRow>
      </TableHead>
      <TableBody>
        {data.map((el, index) => (
          <TableRow
            style={{ borderBottom: `solid 1px ${colors.grey['100']}` }}
            key={index}>
            <TableCell style={{ fontWeight: 'bold' }}>{el.Field}</TableCell>
            <TableCell>{el.Description}</TableCell>
          </TableRow>
        ))}
      </TableBody>
    </Table>
  );
};

export default ManagerUploadSurface;
