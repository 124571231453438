import React, { useContext } from 'react';
import {
  Button,
  FormControl,
  IconButton,
  MenuItem,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TextField,
  Typography,
} from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { makeStyles } from '@material-ui/core/styles';
import { AddCircleOutline } from '@material-ui/icons';
import { STATUS_TEXT, isPositiveWholeNumber } from '../../../../utils/utils';
import { AuthContext } from '../../../../components/AuthContextProvider';
import { deleteActivity } from '../../../../helper/backendHelper/activities';

const useStyles = makeStyles(() => ({
  columnSmall: {
    width: '13%',
  },
  columnHHMM: {
    display: 'flex',
    alignItems: 'baseline',
    paddingBottom: 0,
    paddingTop: 12,
  },
  textRoot: {
    width: '22px',
  },
}));

// handle delete activity
const handleDeleteActivity = async (activity) => {
  await deleteActivity(activity._id)
}

const LogEffortTable = ({
  activities = [],
  setActivities,
  setDeleteActivities,
  employeeData,
  handleUpdateEffortData,
  addNewRowToEffortData,
  isSubmitted = false,
}) => {
  const classes = useStyles();
  let { user } = useContext(AuthContext);

  const getInitValue = (key, index, value) => {
    if (activities && activities[index] && activities[index][key]) {
      return activities[index][key];
    }
    return '';
  };

  return (
    <TableContainer className="mt-3">
      <Table
        aria-label="log-effort-table"
        size={isSubmitted ? 'medium' : 'small'}>
        <TableHead>
          <TableRow>
            <TableCell>Activity</TableCell>
            <TableCell>Application Used</TableCell>
            <TableCell>frequency</TableCell>
            <TableCell>HH:MM</TableCell>
            <TableCell>Individual Volume</TableCell>
            <TableCell align="center">Total Hours</TableCell>
            {!isSubmitted ? <TableCell>Actions</TableCell> : null}
          </TableRow>
        </TableHead>
        <TableBody>
          {activities.map((row, index) => {
            if (!row.frequency && !row.hours && !row.minutes) {
              row['frequency'] = 'Daily';
              row['hours'] = 0;
              row['minutes'] = 0;
            }

            return (
              <TableRow key={row._id || index} className="employee-activity">
                <TableCell style={{ width: '25%' }}>
                  <TextField
                    onChange={(evt) =>
                      handleUpdateEffortData(index, {
                        name: evt.target.value,
                      })
                    }
                    disabled={
                      employeeData.status == STATUS_TEXT.SUBMITTED || false
                    }
                    value={row.name}
                  />
                </TableCell>
                <TableCell>
                  <TextField
                    onChange={(evt) =>
                      handleUpdateEffortData(index, {
                        applicationUsed: evt.target.value,
                      })
                    }
                    disabled={
                      employeeData.status == STATUS_TEXT.SUBMITTED || false
                    }
                    value={row.applicationUsed}
                  />
                </TableCell>
                <TableCell>
                  <FormControl>
                    <Select
                      disabled={
                        employeeData.status == STATUS_TEXT.SUBMITTED || false
                      }
                      value={getInitValue('frequency', index) || 'Daily'}
                      onChange={(evt) =>
                        handleUpdateEffortData(index, {
                          frequency: evt.target.value,
                        })
                      }>
                      <MenuItem value={'Daily'}>Daily</MenuItem>
                      <MenuItem value={'Weekly'}>Weekly</MenuItem>
                      <MenuItem value={'Fortnightly'}>Fortnightly</MenuItem>
                      <MenuItem value={'Monthly'}>Monthly</MenuItem>
                      <MenuItem value={'Quarterly'}>Quarterly</MenuItem>
                      <MenuItem value={'HalfYearly'}>Half Yearly</MenuItem>
                      <MenuItem value={'Annually'}>Annually</MenuItem>
                    </Select>
                  </FormControl>
                </TableCell>
                <TableCell
                  classes={{
                    root: classes.columnHHMM,
                  }}>
                  <TextField
                    classes={{
                      root: classes.textRoot,
                    }}
                    disabled={
                      employeeData.status == STATUS_TEXT.SUBMITTED || false
                    }
                    onChange={(evt) => {
                      const hours = parseInt(evt.target.value) || 0;

                      if (row.frequency == 'Daily') {
                        if (hours <= 24) {
                          handleUpdateEffortData(index, {
                            hours: hours,
                          });
                        }
                      } else {
                        handleUpdateEffortData(index, {
                          hours: hours,
                        });
                      }
                    }}
                    value={row.hours || '00'}
                  />
                  <Typography
                    component="span"
                    variant="body1"
                    className="ml-1 mr-1">
                    :
                  </Typography>
                  <TextField
                    classes={{
                      root: classes.textRoot,
                    }}
                    disabled={
                      employeeData.status == STATUS_TEXT.SUBMITTED || false
                    }
                    onChange={(evt) => {
                      if (
                        isPositiveWholeNumber(evt.target.value) ||
                        evt.target.value === ''
                      ) {
                        let minutes = parseInt(evt.target.value) || '';
                        minutes =
                          getInitValue('hours', index) === 24 ? 0 : minutes;
                        if (minutes < 60) {
                          handleUpdateEffortData(index, {
                            minutes: minutes,
                            ...(row.id ? { id: row.id } : {}),
                          });
                        }
                      }
                    }}
                    value={getInitValue('minutes', index) || '00'}
                  />
                </TableCell>
                <TableCell className={classes.columnSmall}>
                  <TextField
                    disabled={
                      employeeData.status == STATUS_TEXT.SUBMITTED || false
                    }
                    type="number"
                    onChange={(evt) => {
                      handleUpdateEffortData(index, {
                        individualVolume: parseInt(evt.target.value),
                      });
                    }}
                    value={row.individualVolume}
                  />
                </TableCell>
                <TableCell className={classes.columnSmall} align="center">
                  <Typography component="span" variant="body1">
                    {row.totalHours || getInitValue('totalHours', index) || 0}
                  </Typography>
                </TableCell>
                <TableCell>
                  <IconButton
                    aria-label="delete"
                    disabled={
                      employeeData.status == STATUS_TEXT.SUBMITTED || false
                    }
                    onClick={async () => {
                      handleUpdateEffortData(index, {
                        type: 'DELETE',
                      });

                      if (row._id) {
                        await handleDeleteActivity(row)
                        // row.operationType = 'delete';
                        // setDeleteActivities((prevState) => [...prevState, row]);
                      }
                    }}>
                    <DeleteIcon fontSize="small" />
                  </IconButton>
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>
      {/* {employeeData.isFlaggedUser ||
      employeeData.employeeType == 'individual' ? ( */}
      <Button
        variant="outlined"
        disableElevation
        color="primary"
        disabled={employeeData.status == STATUS_TEXT.SUBMITTED || false}
        className="mt-3"
        startIcon={<AddCircleOutline color="inherit" />}
        onClick={() => {
          addNewRowToEffortData();
        }}>
        Add Activity
      </Button>
      {/* ) : null} */}
    </TableContainer>
  );
};

export default LogEffortTable;
