import React, { useContext } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { Paths } from './paths';
import Login from '../containers/Login';
import PrivateRoute from './PrivateRoute';
import EmployeeHome from '../containers/Employee/Home';
import { USER_ROLES } from '../operations/constants';
import { AuthContext } from '../components/AuthContextProvider';
import Loader from '../components/Loader';
import CreateLog from '../containers/Employee/CreateLog';
import ManagerDashboard from '../containers/Manager/Dashboard';
import ManagerEmployees from '../containers/Manager/Employees';
import AssignRAG from '../containers/Manager/AssignRAG';
import ManagerAnalytics from '../containers/Manager/Analytics';
import POCDashboard from '../containers/POC/Dashboard';
import POCAnalytics from '../containers/POC/Analytics';
import POCRAG from '../containers/POC/RAG';
import Team from '../containers/POC/RAG/Team';
import Settings from '../containers/POC/Settings';
import AdminDashboard from '../containers/Admin/Dashboard';
import OrganisationList from '../containers/Admin/Organization';
import SelectedOrganisation from '../containers/Admin/SelectedOrganisation';
import CreateEvent from '../containers/Admin/CreateEvent';
import AdminAnalytics from '../containers/Admin/Analytics';
import SurveyList from '../containers/Admin/SurveyList';
import CreateSurvey from '../containers/Admin/CreateSurvey';
import ViewSurvey from '../containers/Admin/ViewSurvey';
import AdminRAG from '../containers/Admin/RAG';
import AdminRagTeam from '../containers/Admin/RAG/Team';
import { useHistory } from 'react-router-dom';
import CreateOrganization from '../containers/Admin/Organization/CreateOrganization';
import POCManagers from '../containers/POC/Managers';
import RoleActivity from '../components/RoleActivity';
import Events from '../components/Events';
import AddEditManagers from '../containers/POC/Managers/AddEditManagers';
import AddEditEmployees from '../containers/Manager/Employees/AddEditEmployees';

const PrivateRoutesArr = [
  {
    role: USER_ROLES.EMPLOYEE,
    path: Paths.employeeHome,
    component: EmployeeHome,
  },
  {
    role: USER_ROLES.EMPLOYEE,
    path: `${Paths.logEffort}/:id`,
    component: CreateLog,
  },
  {
    role: USER_ROLES.MANAGER,
    path: `${Paths.managerDashboard}`,
    component: ManagerDashboard,
  },
  {
    role: USER_ROLES.MANAGER,
    path: `${Paths.managerEmployees}`,
    component: ManagerEmployees,
  },
  {
    role: USER_ROLES.MANAGER,
    path: `${Paths.Employe}/:type/:collectionEventId/:id?`,
    component: AddEditEmployees,
  },
  {
    role: USER_ROLES.MANAGER,
    path: `${Paths.managerAssignRag}`,
    component: AssignRAG,
  },
  {
    role: USER_ROLES.MANAGER,
    path: `${Paths.managerEvents}`,
    component: Events,
  },
  {
    role: USER_ROLES.MANAGER,
    path: `${Paths.managerAssignRagSelected}/:id`,
    component: RoleActivity,
  },
  {
    role: USER_ROLES.MANAGER,
    path: `${Paths.managerCreateEvents}/:organizationId/:collectionEventId?`,
    component: CreateEvent,
  },
  {
    role: USER_ROLES.MANAGER,
    path: `${Paths.managerAnalytics}`,
    component: ManagerAnalytics,
  },
  {
    role: USER_ROLES.POC,
    path: `${Paths.pocDashboard}`,
    component: POCDashboard,
  },
  {
    role: USER_ROLES.POC,
    path: `${Paths.pocManagers}/:type/:collectionEventId/:id?`,
    component: AddEditManagers,
  },
  {
    role: USER_ROLES.POC,
    path: `${Paths.pocManagers}`,
    component: POCManagers,
  },
  {
    role: USER_ROLES.POC,
    path: `${Paths.pocEvents}`,
    component: Events,
  },
  {
    role: USER_ROLES.POC,
    path: `${Paths.pocRag}/:id`,
    component: RoleActivity,
  },
  {
    role: USER_ROLES.POC,
    path: `${Paths.pocRag}`,
    component: POCRAG,
  },
  {
    role: USER_ROLES.POC,
    path: `${Paths.pocCreateEvents}/:organizationId/:collectionEventId?`,
    component: CreateEvent,
  },

  {
    role: USER_ROLES.POC,
    path: `${Paths.pocAnalytics}`,
    component: POCAnalytics,
  },
  {
    role: USER_ROLES.POC,
    path: `${Paths.ragTeam}/:id/:organisationId/:eventId`,
    component: Team,
  },
  {
    role: USER_ROLES.POC,
    path: `${Paths.pocSettings}`,
    component: Settings,
  },

  //admin
  {
    role: USER_ROLES.ADMIN,
    path: `${Paths.adminDashboard}`,
    component: AdminDashboard,
  },
  {
    role: USER_ROLES.ADMIN,
    path: `${Paths.adminOrganisation}`,
    component: OrganisationList,
  },
  {
    role: USER_ROLES.ADMIN,
    path: `${Paths.adminOrganisationSelectedRoute}`,
    component: SelectedOrganisation,
  },
  {
    role: USER_ROLES.ADMIN,
    path: `${Paths.organization}/:id?`,
    component: CreateOrganization,
  },
  {
    role: USER_ROLES.ADMIN,
    path: `${Paths.adminEvents}`,
    component: Events,
  },
  // {
  //   role: USER_ROLES.ADMIN,
  //   path: `${Paths.adminCreateEventRoute}/:collectionEventId?`,
  //   component: CreateEvent,
  // },

  {
    role: USER_ROLES.ADMIN,
    path: `${Paths.adminAnalytics}`,
    component: AdminAnalytics,
  },
  {
    role: USER_ROLES.ADMIN,
    path: `${Paths.adminSurveys}`,
    component: SurveyList,
  },
  {
    role: USER_ROLES.ADMIN,
    path: `${Paths.adminCreateSurveyRoute}`,
    component: CreateSurvey,
  },
  {
    role: USER_ROLES.ADMIN,
    path: `${Paths.adminRag}`,
    component: AdminRAG,
  },
  {
    role: USER_ROLES.ADMIN,
    path: `${Paths.adminRagTeam}/:id/:organisationId/:eventId`,
    component: AdminRagTeam,
  },
];

const HomePaths = {
  EMPLOYEE: Paths.employeeHome,
  MANAGER: Paths.managerDashboard,
  POC: Paths.pocDashboard,
  ADMIN: Paths.adminDashboard,
};

const Routes = () => {
  const history = useHistory();
  const { user } = useContext(AuthContext);

  return (
    <Switch>
      <Route exact={true} path={Paths.login}>
        {user.role ? (
          <Redirect
            to={{
              pathname:
                user.role && HomePaths[user.role]
                  ? HomePaths[user.role]
                  : Paths.login,
              state: { from: Paths.login },
            }}
          />
        ) : (
          <Login />
        )}
      </Route>

      <Route path={Paths.viewSurvey} exact component={ViewSurvey} />

      {PrivateRoutesArr.map((item, index) => (
        <PrivateRoute
          key={index}
          role={item.role}
          path={item.path}
          component={item.component}
        />
      ))}
    </Switch>
  );
};
export default Routes;
