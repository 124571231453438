import { del, get, post, put } from '../api_helper';
import * as url from '../url_helper';
import { getApiConfig } from '../auth_helper';

//* add manager
export const createManager = async (data) => {
  return await post(`${url.MANAGER}`, data, getApiConfig());
};

//* get all manager
export const getAllManager = async (id) => {
  return await get(`${url.MANAGER}?collectionEventId=${id}`, getApiConfig());
};

export const getManagerById = async (id) => {
  return await get(`${url.MANAGER}/${id}`, getApiConfig());
};

export const createSingleManager = async (data) => {
  return await post(`${url.MANAGER}/single`, data, getApiConfig());
};

export const updateSingleManager = async (data, id) => {
  return await put(`${url.MANAGER}/${id}`, data, getApiConfig());
};

//* get poc report
export const getManagerReport = async (managerId, collectionEventId) => {
  if (collectionEventId) {
    collectionEventId = `&collectionEventId=${collectionEventId}`;
  } else {
    collectionEventId = '';
  }
  return await get(
    `${url.MANAGER}/getEmployeeReports?managerId=${managerId}${collectionEventId}`,
    getApiConfig()
  );
};

export const resendCredentialsManager = async (data) => {
  return await post(`${url.MANAGER}/resendCredentials`, data, getApiConfig());

}

// delete meneger
export const deleteManagerById = async (id) => {
  return await del(`${url.MANAGER}/${id}`, getApiConfig());
};
