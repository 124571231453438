import { IconButton, TableCell, TableRow } from "@material-ui/core";
import KeyboardArrowDownIcon from '@material-ui/icons/KeyboardArrowDown';
import KeyboardArrowUpIcon from '@material-ui/icons/KeyboardArrowUp';
import React from "react";

const ExpandableTableRow = ({ isExpandable, children, expandComponent, ...otherProps }) => {
    const [isExpanded, setIsExpanded] = React.useState(false);
    return (
        <>
            <TableRow {...otherProps}>
                {children}
                {isExpandable && <TableCell padding="checkbox">
                    <IconButton onClick={() => setIsExpanded(!isExpanded)}>
                        {isExpanded ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                    </IconButton>
                </TableCell>}
            </TableRow>
            {isExpanded && (
                <TableRow>
                    {/* <TableCell padding="checkbox" /> */}
                    {expandComponent}
                </TableRow>
            )}
        </>
    );
};

export default ExpandableTableRow;