import React, { useContext, useEffect, useState } from 'react';
import {
  Box,
  Button,
  ButtonGroup,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  IconButton,
  InputLabel,
  MenuItem,
  Modal,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import { RAG_VALUE, STATUS_TEXT } from '../../../../utils/utils';
import EmployeeEffortLogComponent from '../../../../components/EfforLogTable';
import { useHistory } from 'react-router-dom';
import ExpandableTableRow from '../../../../components/ExpandableTableRow';
import PlaceholderImage from '../../../../components/PlaceholderImage';
import board from '../../../../../src/assets/images/board.svg';
import {
  updateEmployee,
  updateReplaceEmployees,
} from '../../../../helper/backendHelper/employee';
import Message from '../../../../components/Message';
import { AuthContext } from '../../../../components/AuthContextProvider';
import { EmailOutlined } from '@material-ui/icons';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
const useStyles = makeStyles((theme) => ({
  table: {
    maxHeight: '50vh',
    width: '100%',
  },
  tableLong: {
    maxHeight: '70vh',
    width: '100%',
  },
  inlineIconButtons: {
    padding: '4px',
  },
  groupedButtons: {
    padding: '4px',
    fontWeight: '300',
    textTransform: 'uppercase',
    width: '60px',
  },
  disabledButton: {
    backgroundColor: theme.palette.primary.main,
  },
  expandableRow: {
    backgroundColor: theme.palette.grey[100],
  },
}));

const TableWrapper = ({
  id,
  primaryButtonText,
  secondaryButtonText,
  handlePrimaryButtonClick = () => null,
  handleSecondaryButtonClick = () => null,
  isActionable = () => null,
  tableHeads = [],
  uploadMode,
  replace = false,
  team = [],
  data = [],
  dataKeys = [],
  checkId = '',
  isSelectable = true,
  variant = 'table',
  disabled = false,
  isExpandable = false,
  handleChangeEmployeesByEventId,
  actionType,
  event,
  otherActions = false,
  resendCredentialsAction = () => null,
  editAction = () => null,
  deleteAction = () => null,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);
  const [isDialog, setIsDialog] = useState({
    id: '',
    isOpen: false,
  });
  const [teamId, setTeamId] = useState('');
  const { user } = useContext(AuthContext);

  //* handle check in particular employees
  const handleCheck = (id) => {
    const index = selected.indexOf(id);
    let updated = selected;
    if (index > -1) {
      updated.splice(index, 1);
    } else {
      updated = [...selected, id];
    }

    setSelected([...updated]);
  };

  //* handle select all in one click
  const handleCheckAll = () => {
    let updated;
    const actionableData = data.filter((item) => isActionable(item));
    if (selected.length !== actionableData.length) {
      updated = actionableData.map((item) => item[checkId]);
    } else {
      updated = [];
    }
    setSelected([...updated]);
  };

  const isActiveRAGButton = (btnVal, ragVal) => {
    return btnVal === ragVal;
  };

  //* check all action in selected
  const getActionableLength = () => {
    const updatedData = data ? data.filter((item) => isActionable(item)) : [];
    return updatedData.length;
  };


  //* handle Add Individual to Identical
  const handleAddIndividualToIdentical = async () => {
    try {
      const response = await updateReplaceEmployees({
        ids: [isDialog.id],
        employeeType: 'identical',
        teamId: teamId,
      });
      handleChangeEmployeesByEventId(event, actionType);
      Message.error(response.message);
      setIsDialog(false);
    } catch (error) {
      Message.error(error?.response?.data?.message);
    }
  };

  const getNestedValue = (object, key) => {
    // Split the key into parts
    const keys = key.split('.');
    // Iterate through the keys to access the nested value
    return keys.reduce((acc, currentKey) => acc && acc[currentKey], object);
  };

  return (
    <React.Fragment>
      <Paper className={`${classes.paper} my-3`}>
        <Grid
          container
          item
          justifyContent="space-between"
          alignItems="baseline"
          className="p-2">
          <Typography variant="body1">Total Items: {data.length}</Typography>
          <Grid>
            {/* {selected.length !== 0 && (
              <>
                <Button color="error" size="medium" variant="text">
                  Delete
                </Button>
                <Button
                  className="mx-3"
                  color="primary"
                  size="medium"
                  onClick={() => setIsDialog(true)}
                  variant="text">
                  Replace
                </Button>
              </>
            )} */}

            {/* individual to identical Dialog box */}

            <Dialog
              open={isDialog.isOpen}
              onClose={() => setIsDialog({ isOpen: false })}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description">
              <DialogTitle id="alert-dialog-title">
                {'Replace the identical'}
              </DialogTitle>
              <DialogContent>
                <DialogContentText id="alert-dialog-description">
                  <InputLabel id="demo-simple-select-label">Team</InputLabel>
                  <Select
                    placeholder="Team"
                    style={{ width: '330px' }}
                    onChange={(evt) => {
                      setTeamId(evt.target.value);
                    }}>
                    {team.map((val) => (
                      <MenuItem value={val._id}>{val.teamName}</MenuItem>
                    ))}
                  </Select>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleAddIndividualToIdentical}>
                  Agree
                </Button>
              </DialogActions>
            </Dialog>

            {primaryButtonText && (
              <Button
                variant="contained"
                size="small"
                disableElevation
                disabled={!selected.length || disabled}
                onClick={() => {
                  handlePrimaryButtonClick(selected);
                  setSelected([]);
                }}
                color="primary">
                {primaryButtonText}
              </Button>
            )}
            {secondaryButtonText && (
              <Button
                className="ml-3"
                variant="outlined"
                disableElevation
                color="secondary"
                size="small"
                disabled={!selected || !selected.length || disabled}
                onClick={() => {
                  handleSecondaryButtonClick(selected);
                  setSelected([]);
                }}>
                {secondaryButtonText}
              </Button>
            )}
            <RAGActions
              id={id}
              classes={classes}
              handleButtonClick={(ragValue) => {
                handlePrimaryButtonClick(selected, ragValue);
              }}
              row={{}}
              isActiveRAGButton={isActiveRAGButton}
              disabled={selected.length < 2}
            />
          </Grid>
        </Grid>
        <TableContainer
          classes={{
            root: classes[variant],
          }}>
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size="medium"
            aria-label={id}>
            <TableHead>
              <TableRow>
                {isSelectable && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        getActionableLength() &&
                        selected.length === getActionableLength()
                      }
                      onChange={() => handleCheckAll()}
                      disabled={
                        (user.role === 'MANAGER' && !event?.isManagerExists) ||
                        disabled
                      }
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                  </TableCell>
                )}
                {tableHeads.map((headCell, index) => (
                  <TableCell
                    key={index}
                    align={headCell.align ? headCell.align : 'left'}>
                    {headCell.label}
                  </TableCell>
                ))}
                {otherActions && <TableCell align="center">{'Other Actions'}</TableCell>}
              </TableRow>
            </TableHead>
            <TableBody>
              {data.map((row, index) => {
                const isItemSelected = selected.includes(row[checkId]);
                row.isCollectionStarted
                  ? (row['collectionStarted'] = true)
                  : (row['collectionStarted'] = false);
                const isActionableItem = isActionable(row);

                return (
                  <ExpandableTableRow
                    isExpandable={isExpandable}
                    expandComponent={
                      isExpandable && (
                        <TableCell
                          colSpan={6}
                          className={classes.expandableRow}>
                          <EmployeeEffortLogComponent userId={row['_id']} />
                        </TableCell>
                      )
                    }
                    hover
                    aria-checked={isItemSelected}
                    tabIndex={-1}
                    key={index}
                    selected={isItemSelected}>
                    {isSelectable && (
                      <TableCell padding="checkbox">
                        <Checkbox
                          disabled={
                            !event?.isManagerExists ? true
                              : !isActionableItem
                                ? !disabled
                                : false
                          }
                          checked={isItemSelected}
                          onClick={(event) => handleCheck(row[checkId])}
                        />
                      </TableCell>
                    )}
                    {dataKeys.map((item, index) => {
                      if (typeof row[item.id] === 'boolean') {
                        row[item.id] = row[item.id] ? 'Yes' : 'No';
                      }

                      return (
                        <TableCell
                          key={index}
                          // onClick={() => history.push('/manager/employee/' + row['userId'])}
                          align={
                            item.align
                              ? item.align
                              : typeof row[item.id] !== 'string'
                                ? 'center'
                                : 'left'
                          }>
                          {getNestedValue(row, item.id)}
                        </TableCell>
                      );
                    })}

                    <React.Fragment>
                      {replace && (
                        <TableCell
                          key={`actions-${id}`}
                          style={{ cursor: 'pointer', textAlign: 'center' }}>
                          <Tooltip
                            title={
                              <span>Replace from Individual to Identical</span>
                            }
                            arrow
                            placement="top">
                            <Button
                              className="mx-3"
                              color="primary"
                              disabled={
                                (user.role === 'MANAGER' &&
                                  !event?.isManagerExists) ||
                                false
                              }
                              size="medium"
                              onClick={() => {
                                setIsDialog({
                                  isOpen: true,
                                  id: row._id,
                                });
                              }}
                              variant="outlined">
                              Replace
                            </Button>
                          </Tooltip>
                        </TableCell>
                      )}
                    </React.Fragment>
                    {otherActions && (
                      <TableCell>
                        <Grid container justifyContent="center">
                          <Grid>
                            <IconButton
                              onClick={() => {
                                resendCredentialsAction(row._id);
                              }}
                              disabled={
                                (user.role === 'MANAGER' &&
                                  !event?.isManagerExists) ||
                                false
                              }
                              className="my-1"
                              color="primary"
                              aria-label="Resend Credentials">
                              <EmailOutlined />
                            </IconButton>
                          </Grid>
                          <Grid>
                            <IconButton
                              onClick={() => {
                                editAction(row._id);
                              }}
                              disabled={
                                (user.role === 'MANAGER' &&
                                  !event?.isManagerExists) ||
                                false
                              }
                              className="my-1"
                              color="primary"
                              aria-label="Resend Credentials">
                              <EditIcon />
                            </IconButton>
                          </Grid>
                          <Grid>
                            <IconButton
                              onClick={() => {
                                deleteAction(row._id);
                              }}
                              disabled={
                                (user.role === 'MANAGER' &&
                                  !event?.isManagerExists) ||
                                false
                              }
                              className="my-1"
                              style={{ color: '#bd0404' }} // Set custom color here
                              aria-label="Resend Credentials">
                              <DeleteIcon />
                            </IconButton>
                          </Grid>
                        </Grid>
                      </TableCell>
                    )}
                  </ExpandableTableRow>
                );
              })}
            </TableBody>
          </Table>
        </TableContainer>
      </Paper>
      {data?.length == 0 && !uploadMode ? (
        <PlaceholderImage
          image={board}
          messsage={'No records found'}
          width="25%"
        />
      ) : null}
    </React.Fragment>
  );
};

const RAGActions = ({
  row,
  id,
  classes,
  handleButtonClick,
  isActiveRAGButton,
  disabled = false,
}) => {
  const [currentSelected, setCurrentSelected] = useState(row.ragValue);
  const isPreset = row.ragValue ? true : false;
  return ['AssignRAGTable'].includes(id) ? (
    <React.Fragment>
      <div style={{ display: 'flex', alignItems: 'center', gap: 10 }}>
        <ButtonGroup
          size="small"
          variant="contained"
          disableElevation
          aria-label="RAG button group">
          <Button
            variant="contained"
            size="small"
            disableElevation
            classes={{
              root: classes.groupedButtons,
            }}
            color={
              isActiveRAGButton(RAG_VALUE.RED, currentSelected)
                ? 'primary'
                : 'default'
            }
            onClick={() =>
              isActiveRAGButton(RAG_VALUE.RED, currentSelected) ||
                disabled ||
                isPreset
                ? null
                : setCurrentSelected(RAG_VALUE.RED)
            }>
            Red
          </Button>
          <Button
            variant="contained"
            disableElevation
            size="small"
            classes={{
              root: classes.groupedButtons,
            }}
            color={
              isActiveRAGButton(RAG_VALUE.AMBER, currentSelected)
                ? 'primary'
                : 'default'
            }
            onClick={() =>
              isActiveRAGButton(RAG_VALUE.AMBER, currentSelected) ||
                disabled ||
                isPreset
                ? null
                : setCurrentSelected(RAG_VALUE.AMBER)
            }>
            Amber
          </Button>
          <Button
            variant="contained"
            disableElevation
            size="small"
            classes={{
              root: classes.groupedButtons,
            }}
            color={
              isActiveRAGButton(RAG_VALUE.GREEN, currentSelected)
                ? 'primary'
                : 'default'
            }
            onClick={() =>
              isActiveRAGButton(RAG_VALUE.GREEN, currentSelected) ||
                disabled ||
                isPreset
                ? null
                : setCurrentSelected(RAG_VALUE.GREEN)
            }>
            Green
          </Button>
        </ButtonGroup>
        {disabled || isPreset ? null : (
          <Button
            variant="contained"
            disableElevation
            size="small"
            // classes={{
            //   root: classes.groupedButtons,
            // }}
            color={currentSelected ? 'primary' : 'default'}
            onClick={() =>
              !currentSelected ? null : handleButtonClick(currentSelected)
            }>
            Save
          </Button>
        )}
      </div>
    </React.Fragment>
  ) : null;
};

export default TableWrapper;
