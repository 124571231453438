import { firebaseFunctions, firestore } from '../../firebase';
import {
  CreatedSuccessFully,
  currentServerTime,
  DeletedSuccessfully,
  GenericSuccessUpdate,
  transformFirebaseResponse,
  UnableToCreate,
  UnableToFetchData,
  UnableToUpdate,
} from '../utils';
import { COLLECTIONS, EFFORT_LOG_STATUS, USER_TYPE } from '../constants';
import { isEmpty } from '../../utils/utils';

/**
 * Create Effort log
 * @returns {Promise<string>}
 * @param userId
 * @param enterpriseId
 * @param teamId
 * @param subTeamId
 * @param eventId
 */
const createEffortLog = async ({
  userId,
  enterpriseId,
  teamId,
  subTeamId,
  eventId,
}) => {
  try {
    const res = await firestore.collection(COLLECTIONS.EFFORT_LOG).add({
      userId,
      enterpriseId,
      teamId,
      eventId,
      ...(subTeamId ? { subTeamId } : {}),
      createdTime: currentServerTime(),
      createdBy: userId,
      status: EFFORT_LOG_STATUS.IN_PROGRESS,
    });
    return res.id;
  } catch (e) {
    UnableToCreate(e);
  }
};

/**
 * Update Effort log
 * @returns {Promise<boolean>}
 * @param id
 * @param userId
 * @param status
 * @param userType
 * @param subTeamId
 * @param actAsIndividual
 */
const updateEffortLog = async ({
  id,
  userId,
  status = '',
  userType,
  subTeamId = '',
  actAsIndividual = false,
  effortLog,
  effortData,
}) => {
  try {
    if ('isAutoGenerated' in effortLog) {
      delete effortData.updated;
      await firestore.collection(COLLECTIONS.EFFORT_LOG).doc(id).update({
        isAutoGenerated: false,
        updatedTime: currentServerTime(),
        updatedBy: userId,
      });
      return true;
    } else {
      if (userType === USER_TYPE.IDENTICAL && !actAsIndividual) {
        const callable = firebaseFunctions.httpsCallable('syncIdenticalData');
        callable({ userId, subTeamId, effortLogId: id });
      }

      await firestore
        .collection(COLLECTIONS.EFFORT_LOG)
        .doc(id)
        .update({
          ...(status ? { status } : {}),
          updatedTime: currentServerTime(),
          updatedBy: userId,
        });
      return true;
    }
  } catch (e) {
    UnableToCreate(e);
    return e;
  }
};

/**
 * Fetch effort log details
 * @param userId
 * @param subTeamId
 * @param userType
 * @returns {Promise<{[p: string]: any, id: string}>}
 */
const fetchEffortLog = async ({ userId, subTeamId, userType }) => {
  try {
    let query = firestore.collection(COLLECTIONS.EFFORT_LOG);
    query = query.where('userId', '==', userId);
    const res = await query.get();
    if (!res.empty) {
      const result = res.docs && res.docs[0];
      if (result) {
        return { ...result.data(), id: result.id };
      }
    }
    return null;
  } catch (e) {
    console.warn('ER', e);
    // UnableToFetchData();
    return null;
  }
};

/**
 * Delete Effort Log and ActivityEffort associated by id
 * @param id
 * @param userType
 * @param actAsIndividual
 * @param userId
 * @param subTeamId
 * @returns {Promise<boolean>}
 */
const deleteEffortLog = async (
  id,
  userType = '',
  actAsIndividual = false,
  userId,
  subTeamId = ''
) => {
  try {
    await firestore.collection(COLLECTIONS.EFFORT_LOG).doc(id).delete();
    const activityEffortsLog = await fetchActivityEffortByEffortLogId({
      id,
      getRef: true,
    });
    let processes = [];
    let activities = [];

    if (userType === USER_TYPE.INDIVIDUAL || actAsIndividual) {
      processes = await fetchProcessesEffortByEffortLogId({
        id,
        getRef: true,
      });

      activities = await fetchActivitiesEffortByEffortLogId({
        id,
        getRef: true,
      });
    } else if (userType === USER_TYPE.IDENTICAL && !actAsIndividual) {
      const callable = firebaseFunctions.httpsCallable(
        'syncIdenticalDataOnDelete'
      );
      callable({ userId, subTeamId });
    }

    const batch = firestore.batch();
    if (activityEffortsLog) {
      activityEffortsLog.forEach((doc) => {
        batch.delete(doc.ref);
      });
    }
    processes.forEach((doc) => {
      batch.delete(doc.ref);
    });
    activities.forEach((doc) => {
      batch.delete(doc.ref);
    });
    await batch.commit();
    DeletedSuccessfully();
    return true;
  } catch (e) {
    console.error(e);
    // UnableToFetchData();
  }
};

/**
 * Fetch ActivityEfforts by effortLogId
 * @param id
 * @param getRef
 * @returns {Promise<*|null|firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>}
 */
const fetchActivityEffortByEffortLogId = async ({ id, getRef = false }) => {
  try {
    const activityEfforts = await firestore
      .collection(COLLECTIONS.ACTIVITY_EFFORT)
      .where('effortLogId', '==', id)
      .get();

    if (getRef) {
      return activityEfforts;
    }

    return transformFirebaseResponse(activityEfforts);
  } catch (e) {
    // UnableToFetchData();
    return null;
  }
};

/**
 * Fetch Processes by effortLogId
 * @param id
 * @param getRef
 * @returns {Promise<*|null|firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>}
 */
const fetchProcessesEffortByEffortLogId = async ({ id, getRef = false }) => {
  try {
    const processes = await firestore
      .collection(COLLECTIONS.PROCESS)
      .where('effortLogId', '==', id)
      .get();

    if (getRef) {
      return processes;
    }

    return transformFirebaseResponse(processes);
  } catch (e) {
    // UnableToFetchData();
    return null;
  }
};

/**
 * Fetch Activities by effortLogId
 * @param id
 * @param getRef
 * @returns {Promise<*|null|firebase.firestore.QuerySnapshot<firebase.firestore.DocumentData>>}
 */
const fetchActivitiesEffortByEffortLogId = async ({ id, getRef = false }) => {
  try {
    const activities = await firestore
      .collection(COLLECTIONS.ACTIVITY)
      .where('effortLogId', '==', id)
      .get();

    if (getRef) {
      return activities;
    }

    return transformFirebaseResponse(activities);
  } catch (e) {
    // UnableToFetchData();
    return null;
  }
};

/**
 * Create Process
 * @param userId
 * @param enterpriseId
 * @param name
 * @param subTeamId
 * @param eventId
 * @param userType
 * @param effortLogId
 */
// const createProcess = async ({
//   userId,
//   enterpriseId,
//   name,
//   subTeamId,
//   eventId,
//   userType,
//   effortLogId,
// }) => {
//   try {
//     const res = await firestore.collection(COLLECTIONS.PROCESS).add({
//       enterpriseId,
//       name,
//       ...(USER_TYPE.IDENTICAL === userType
//         ? { subTeamId }
//         : { userId, effortLogId }),
//       eventId,
//       createdTime: currentServerTime(),
//       createdBy: userId,
//     });
//     return res.id;
//   } catch (e) {
//     UnableToCreate(e);
//     return e;
//   }
// };

/**
 * Create Sub Process
 * @returns {Promise<boolean>}
 * @param userId
 * @param enterpriseId
 * @param name
 */
const createSubProcess = async ({ processId, userId, name }) => {
  try {
    const subProcessId = firestore.collection('tmp').doc().id;

    await firestore
      .collection(COLLECTIONS.PROCESS)
      .doc(processId)
      .update({
        [`subProcesses.${subProcessId}`]: {
          name,
          createdTime: currentServerTime(),
          createdBy: userId,
        },
      });
  } catch (e) {
    UnableToCreate(e);
    return e;
  }
};

/**
 * Fetch all processes and subProcesses
 * @param userId
 * @param subTeamId
 * @param userType
 * @returns {Promise<null|*>}
 */
const fetchAllProcesses = async ({ userId = '', subTeamId = '', userType }) => {
  try {
    let query = firestore.collection(COLLECTIONS.PROCESS);

    if (userType === USER_TYPE.INDIVIDUAL) {
      query = query.where('userId', '==', userId);
    } else if (userType === USER_TYPE.IDENTICAL) {
      query = query.where('subTeamId', '==', subTeamId);
    } else {
      return [];
    }

    query = await query.orderBy('createdTime').get();

    let processes = transformFirebaseResponse(query);

    processes.forEach((item) => {
      if (item.subProcesses) {
        const keys = Object.keys(item.subProcesses);
        item.subProcesses = keys.map((subItemKey) => {
          return {
            id: subItemKey,
            ...item.subProcesses[subItemKey],
          };
        });
      }
    });

    return processes;
  } catch (e) {
    // UnableToFetchData();
    return [];
  }
};

/**
 * Create Activity
 * @param userId
 * @param enterpriseId
 * @param name
 * @param subTeamId
 * @param eventId
 * @param userType
 * @param subProcessId
 * @param effortLogId
 * @param teamId
 */
const createActivity = async ({
  userId,
  enterpriseId,
  name,
  subTeamId,
  eventId,
  userType,
  subProcessId,
  effortLogId,
  teamId,
}) => {
  try {
    const res = await firestore.collection(COLLECTIONS.ACTIVITY).add({
      enterpriseId,
      name,
      ...(USER_TYPE.IDENTICAL === userType
        ? { subTeamId }
        : { userId, effortLogId }),
      eventId,
      teamId,
      subProcessId,
      createdTime: currentServerTime(),
      createdBy: userId,
    });
    CreatedSuccessFully();
    return res.id;
  } catch (e) {
    UnableToCreate(e);
    return e;
  }
};

/**
 * Fetch activities by subProcessId
 * @returns {Promise<null|*>}
 * @param subProcessId
 */
const fetchActivitiesBySubProcessId = async (subProcessId) => {
  try {
    const activities = await firestore
      .collection(COLLECTIONS.ACTIVITY)
      .where('subProcessId', '==', subProcessId)
      .get();

    return transformFirebaseResponse(activities);
  } catch (e) {
    // UnableToFetchData();
    return null;
  }
};

/**
 * Fetch activityEfforts by subProcessId
 * @returns {Promise<null|*>}
 * @param subProcessId
 * @param userId
 * @param subTeamId
 * @param userType
 */
const fetchActivityEffortsBySubProcessId = async (
  subProcessId,
  userId = '',
  subTeamId = '',
  userType
) => {
  try {
    let query = firestore.collection(COLLECTIONS.ACTIVITY_EFFORT);
    query = query.where('userId', '==', userId);
    query = query.where('subProcessId', '==', subProcessId);
    const activities = await query.get();
    return transformFirebaseResponse(activities);
  } catch (e) {
    console.warn('e', e);
    // UnableToFetchData();
    return null;
  }
};

/**
 * Fetch activityEfforts by subProcessId
 * @returns {Promise<null|*>}
 * @param subProcessId
 * @param userId
 * @param subTeamId
 * @param userType
 */
const fetchActivityEffortsByUserId = async (userId = '') => {
  try {
    let query = firestore.collection(COLLECTIONS.ACTIVITY_EFFORT);
    query = query.where('userId', '==', userId);
    const activities = await query.get();
    return transformFirebaseResponse(activities);
  } catch (e) {
    console.warn('e', e);
    // UnableToFetchData();
    return null;
  }
};

/**
 * Create Activity Effort
 * @param userId
 * @param enterpriseId
 * @param eventId
 * @param userType
 * @param effortLogId
 * @param subProcessId
 * @param subTeamId
 * @param teamId
 * @param processId
 * @param process
 * @param subProcess
 * @param effortData : [{
   activityId,
   applicationUsed,
   frequency,
   hours,
   minutes,
   individualVolume,
   totalNoOfHours}]
 * @returns {Promise<*>}
 */
const createActivityEffort = async ({
  userId,
  enterpriseId,
  eventId,
  userType,
  effortLogId,
  subProcessId,
  subTeamId,
  teamId,
  processId,
  process,
  subProcess,
  effortData = [],
}) => {
  try {
    const batch = firestore.batch();
    for (let effort of effortData) {
      const activityEffortRef = firestore
        .collection(COLLECTIONS.ACTIVITY_EFFORT)
        .doc();

      if (!isEmpty(effort)) {
        batch.set(activityEffortRef, {
          enterpriseId,
          ...(USER_TYPE.IDENTICAL === userType ? { subTeamId } : {}),
          userId,
          eventId,
          subProcessId,
          processId,
          effortLogId,
          process,
          subProcess,
          teamId,
          ...effort,
          createdTime: currentServerTime(),
          createdBy: userId,
        });
      }
    }
    await batch.commit();
    CreatedSuccessFully();
    return true;
  } catch (e) {
    UnableToCreate(e);
    return e;
  }
};

/**
 * Update Activity Effort
 * @param userId
 * @param enterpriseId
 * @param effortData : [{subTeamId,
   processId,
   activityId,
   toolUsed,
   frequency,
   noOfHours,
   totalNoOfHours}]
 * @returns {Promise<*>}
 */
const updateActivityEffort = async ({ userId, effortData = [] }) => {
  try {
    const batch = firestore.batch();
    for (let effort of effortData) {
      const activityEffortRef = firestore
        .collection(COLLECTIONS.ACTIVITY_EFFORT)
        .doc(effort.id);

      delete effort.updated;

      batch.update(activityEffortRef, {
        ...effort,
        updatedTime: currentServerTime(),
        updatedBy: userId,
      });
    }
    await batch.commit();
    GenericSuccessUpdate();

    await firestore.collection(COLLECTIONS.EFFORT_LOG).doc(userId).update({
      status: 'IN_PROGRESS',
      isAutoGenerated: false,
      updatedTime: currentServerTime(),
      updatedBy: userId,
    });

    return true;
  } catch (e) {
    UnableToUpdate(e);
  }
};

/**
 * Delete ActivityEffort by id
 * @param id
 * @returns {Promise<boolean>}
 */
const deleteActivityEffort = async (id) => {
  try {
    await firestore.collection(COLLECTIONS.ACTIVITY_EFFORT).doc(id).delete();
    DeletedSuccessfully();
    return true;
  } catch (e) {
    // UnableToFetchData();
  }
};

const fetchEffortLogCount = async ({ effortLogId }) => {
  try {
    const callable = firebaseFunctions.httpsCallable('fetchEffortLogCounts');
    return await callable({ effortLogId });
  } catch (e) {
    return {};
  }
};

export {
  createEffortLog,
  updateEffortLog,
  deleteEffortLog,
  fetchEffortLog,
  // createProcess,
  createSubProcess,
  fetchAllProcesses,
  createActivity,
  fetchActivitiesBySubProcessId,
  fetchActivityEffortsBySubProcessId,
  createActivityEffort,
  updateActivityEffort,
  deleteActivityEffort,
  fetchEffortLogCount,
  fetchActivityEffortsByUserId,
};
