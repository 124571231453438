import React from 'react';
import { CSVLink } from 'react-csv';

const ExportToCsv = ({ csvData = [], fileName, children, csvLinkRef }) => {
  return (
    <div>
      {children}

      <CSVLink data={csvData} filename={fileName} ref={csvLinkRef}></CSVLink>
    </div>
  );
};

export default ExportToCsv;
