import React, { useContext } from 'react';
import {
  Button,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Grid,
} from '@material-ui/core';
import { EventContext } from './EventContextProvider';
import { Badge } from 'reactstrap';
import { AuthContext } from './AuthContextProvider';

const SelectEvents = ({
  handleChangeEvents = () => null,
  setEvent,
  actionType,
}) => {
  const { events } = useContext(EventContext);
  const { user } = useContext(AuthContext);
  return (
    <Grid container spacing={2} item md={6} className="my-2">
      <Grid item xs={6}>
        <FormControl fullWidth>
          <InputLabel id="selectEnterprise" style={{ top: '-20px' }}>
            Select Collection Events
          </InputLabel>

          <Select
            labelId="selectEnterprise"
            fullWidth
            className="m-0"
            placeholder="Select Enterprise"
            onChange={(e) => {
              setEvent(e.target.value);
              handleChangeEvents(e.target.value, actionType);
            }}>
            {events?.map((item) => (
              <MenuItem value={item} key={item.name}>
                {item.name}
                {user.role === 'MANAGER' && (
                  <Badge
                    style={{ fontSize: '12px', marginLeft: '10px' }}
                    color={'success'}>
                    {item.isManagerExists && 'In'}
                  </Badge>
                )}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>
    </Grid>
  );
};

export default SelectEvents;
