import { BrowserRouter } from 'react-router-dom';
import customTheme from './theme';
import { MuiThemeProvider } from '@material-ui/core';
import Routes from './routes';
import AuthContextProvider from './components/AuthContextProvider';
import EventContextProvider from './components/EventContextProvider';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';

function App() {
  return (
    <BrowserRouter>
      <MuiThemeProvider theme={customTheme}>
        <AuthContextProvider>
          <EventContextProvider>
            <Routes />
          </EventContextProvider>
        </AuthContextProvider>
      </MuiThemeProvider>
    </BrowserRouter>
  );
}

export default App;
