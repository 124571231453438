import { get, post } from '../api_helper';
import * as url from '../url_helper';
import { getApiConfig } from '../auth_helper';

//* get all events
export const getAllTeam = async (collectionEventId) => {
  return await get(
    `${url.TEAM}?collectionEventId=${collectionEventId}`,
    getApiConfig()
  );
};

export const createTeam = async (data) => {
  return await post(`${url.TEAM}`, data, getApiConfig());
};