import { get, post, put, del } from '../api_helper';
import * as url from '../url_helper';
import { getApiConfig } from '../auth_helper';

//* get activities by sub process id
export const getActivitiesByProcessId = async (subProcessId) => {
  return await get(
    `${url.ACTIVITY}?subProcessId=${subProcessId}`,
    getApiConfig()
  );
};

//* bulk edit Activities of rag value
export const updateAssignRag = async (data) => {
  return await put(`${url.ACTIVITY}/bulk-edit`, data, getApiConfig());
};

//* add activities
export const createUpdateBulkActivities = async (data) => {
  return await post(`${url.ACTIVITY}/bulk-create`, data, getApiConfig());
};

//* update activities
export const updateActivities = async (id, data) => {
  return await put(`${url.ACTIVITY}/${id}`, data, getApiConfig());
};

//* delete activities
export const deleteActivity = async (id) => {
  return await del(`${url.ACTIVITY}/${id}`, getApiConfig());
};
