import {
  Grid,
  IconButton,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core';
import { DeleteOutlined } from '@material-ui/icons';
import React from 'react';
import { colors } from '../../../../theme/colors';

const useStyles = makeStyles((theme) => ({
  root: {
    padding: '1rem',
    borderRadius: '8px',
    background: colors.grey[100],
  },
}));

const UploadedFileCard = ({ result, onDelete }) => {
  const classes = useStyles();
  return (
    <Paper className={classes.root} elevation={0}>
      <Grid spacing={2} container>
        <Grid item xs={3}>
          <img
            style={{ objectFit: 'contain', borderRadius: '8px' }}
            src={result}
            className="w-100 h-100"
            alt="Company logo"
          />
        </Grid>
        <Grid item xs={7}>
          <Typography variant="body1">{result.name}</Typography>
          <Typography style={{ color: colors.grey[200] }} variant="body2">
            {new Date().toLocaleDateString()}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <IconButton onClick={onDelete}>
            <DeleteOutlined style={{ color: colors.error.main }} />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default UploadedFileCard;
