import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextWithDivider from '../../../components/TextWithDivider';
import { AuthContext } from '../../../components/AuthContextProvider';
import SecondaryLayout from '../../../components/SecondaryLayout';
import StatContainer from '../components/StatContainer';
import Switch from '@mui/material/Switch';
import useFetch from '../../../hooks/useFetch';
import { fetchActivityAnalytics } from '../../../operations/manager';
import CustomTabs from '../../../components/CustomTabs';
import TableWrapper from '../components/TableWrapper';
import SelectEvents from '../../../components/SelectEvents';
import {
  allEmployeeRagUtilization,
  allEmployeeUtilizationGraph,
  getEmployeeUtilization,
  getEmployeeGraphData,
  getEmployeeRAGChartData,
  getEmployeeRagUtilization,
  getAllEmployeeUtilization,
  getAllEmployeeRagUtilization,
} from '../../../helper/backendHelper/managerAnalytics';
import AnalyticsTableWrapper from './AnalyticsTableWrapper';
import DepartmentChart from '../../../components/Charts/DepartmentChart';
import RagChart from '../../../components/Charts/RagChart';
import PlaceholderImage from '../../../components/PlaceholderImage';
import board from '../../../../src/assets/images/board.svg';
import { getManagerReport } from '../../../helper/backendHelper/manager';
import Message from '../../../components/Message';

const label = { inputProps: { 'aria-label': 'Switch demo' } };

const ManagerAnalytics = () => {
  let { user } = useContext(AuthContext);
  const [actionType, setActionType] = useState('utilization');
  const [event, setEvent] = useState(null);
  const [loading, setLoading] = useState(false);
  const [utilizationData, setUtilizationData] = useState([]);
  const [ragAnalysis, setRagAnalysis] = useState({});
  const [chartTableValue, setChartTableValue] = useState('plotting');
  const [isAllEvent, setIsAllEvent] = useState(true);
  const [renderChart, setRenderChart] = useState(false);
  const [isChart, setIsChart] = useState(false);
  const [excelReport, setExcelReport] = useState([]);
  const [chartData, setChartData] = useState({
    lowerUpperData: null,
    RagData: null,
  });

  const { data: activityData = [], loading: activityLoading } = useFetch(
    fetchActivityAnalytics,
    {
      teamId: user.teamId,
    }
  );

  useEffect(() => {
    if (isAllEvent) {
      isAllOnChangeHandler();
    }

    if (event) {
      fetchAllEmployeeUtilizations(event, 'utilization');
    }
  }, [chartTableValue]);

  //* fetch all employee utilization
  const fetchAllEmployeeUtilizations = async (data, name) => {
    setActionType(name);
    setLoading(true);
    try {
      switch (name || actionType) {
        case 'utilization':
          //* analytics of employee by collection event id
          if (chartTableValue === 'plotting') {
            setIsChart(true);
            const getLowerUpperData = await getEmployeeGraphData(
              data._id,
              user
            );
            const getRagChartData = await getEmployeeRAGChartData(
              data._id,
              user
            );
            setChartData({
              lowerUpperData: getLowerUpperData,
              RagData: getRagChartData,
            });
            setRenderChart((preSave) => !preSave);
          } else {
            const utilization = await getEmployeeUtilization(data._id, user);
            setUtilizationData(utilization.payload.data);
          }
          break;
        default:
          break;
      }

      //* Rag utilization by collection event id
      const ragAnalysis = await getEmployeeRagUtilization(data._id, user);
      setRagAnalysis(ragAnalysis.payload.data);

      setLoading(false);
    } catch (error) {
      Message.error(error?.response?.data?.message);
    }
  };

  useEffect(() => {
    fetchAllEmployeeRagUtilization();
  }, []);

  //* all Rag utilization box
  const fetchAllEmployeeRagUtilization = async () => {
    try {
      const ragAnalysis = await getAllEmployeeRagUtilization(user);
      setRagAnalysis(ragAnalysis.payload.data);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    fetchAllReport();
  }, [event]);

  //* fetch all report of employee
  const fetchAllReport = async () => {
    try {
      let response = await getManagerReport(user.managerId, event?._id);
      setExcelReport([response.payload]);
    } catch (error) {
      console.log(error);
    }
  };

  //*onchange handler of toggle and showed default all employee utilization
  const isAllOnChangeHandler = async () => {
    setLoading(true);
    try {
      if (chartTableValue === 'plotting') {
        const utilizationOfLowerUpper = await allEmployeeUtilizationGraph(user);
        const utilizationOfRag = await allEmployeeRagUtilization(user);

        setChartData({
          lowerUpperData: utilizationOfLowerUpper,
          RagData: utilizationOfRag,
        });
        setIsChart(true);
        setRenderChart((preSave) => !preSave);
      } else {
        const dataTable = await getAllEmployeeUtilization(user);
        setUtilizationData(dataTable.payload.data);
      }
      setLoading(false);
    } catch (error) {
      console.log(error);
    }
  };

  return (
    <Layout loading={loading}>
      <SecondaryLayout>
        <Grid container className="pl-4 pr-2">
          <Grid item lg={8} md={8} className="pr-4 pt-4">
            <TextWithDivider>
              <Typography variant="h3" color="textPrimary">
                Analytics - RAG
              </Typography>
            </TextWithDivider>
            <div className="d-flex align-items-center my-2 mx-2">
              <span>All</span>
              <div className=" h-100" style={{ marginRight: '20px' }}>
                <Switch
                  {...label}
                  defaultChecked
                  onChange={(e) => {
                    if (e.target.checked) {
                      setIsAllEvent(true);
                      isAllOnChangeHandler();
                      setEvent(null);
                    } else {
                      setChartData({
                        lowerUpperData: null,
                        RagData: null,
                      });
                      setIsChart(false);
                      setUtilizationData([]);
                      setIsAllEvent(false);
                    }
                  }}
                />
              </div>
              {!isAllEvent && (
                <SelectEvents
                  handleChangeEvents={fetchAllEmployeeUtilizations}
                  setEvent={setEvent}
                  actionType={actionType}
                />
              )}
            </div>
            <Grid container justifyContent="space-between" className="mt-2">
              <Grid
                item
                container
                justifyContent="space-between"
                className="mt-3 pl-4 pr-4">
                <StatContainer
                  color={'red'}
                  value={ragAnalysis.redHours}
                  unit="total hours"
                  label={'• Red'}
                  handleClick={() => null}
                  theme="secondary"
                  additionalData={`${ragAnalysis.redActivities || 0}/${ragAnalysis.totalActivities || 0
                    } activities`}
                />
                <StatContainer
                  value={ragAnalysis.amberHours}
                  color={'#df840f'}
                  unit="total hours"
                  label={'• Amber'}
                  handleClick={() => null}
                  theme="secondary"
                  additionalData={`${ragAnalysis.amberActivities || 0}/${ragAnalysis.totalActivities || 0
                    } activities`}
                />
                <StatContainer
                  value={ragAnalysis.greenHours}
                  color={'green'}
                  unit="total hours"
                  label={'• Green'}
                  handleClick={() => null}
                  theme="secondary"
                  additionalData={`${ragAnalysis.greenActivities || 0}/${ragAnalysis.totalActivities || 0
                    } activities`}
                />
              </Grid>
            </Grid>
            <Grid className="mt-3">
              <CustomTabs
                excelReport={excelReport}
                isCharts={true}
                csvFileName="manager-report"
                setActionType={setActionType}
                event={event}
                handleChangeTabWrapper={fetchAllEmployeeUtilizations}
                setChartTableValue={setChartTableValue}
                chartTableValue={chartTableValue}
                tabs={[
                  {
                    label: 'Utilization',
                    children: (
                      <AnalyticsTableWrapper
                        data={utilizationData}
                        chartTableValue={chartTableValue}
                        id={'utilizationTable'}
                        tableHeads={[
                          { id: 'name', label: 'Name' },
                          // { id: 'subTeam', label: 'Sub Team' },
                          { id: 'employeeType', label: 'Identical/Individual' },
                          { id: 'collectionEvent', label: 'Event' },
                          {
                            id: 'redActivitiesHours',
                            label: 'Red',
                            align: 'center',
                          },
                          {
                            id: 'amberActivitiesHours',
                            label: 'Amber',
                            align: 'center',
                          },
                          {
                            id: 'greenActivitiesHours',
                            label: 'Green',
                            align: 'center',
                          },
                          {
                            id: 'PreRAGValidationUtilizationHrs',
                            label: 'Total Man Hours',
                            align: 'center',
                          },
                          {
                            id: 'PreRAGValidationUtilizationInPer',
                            label: 'Utilization',
                            align: 'center',
                          },
                          {
                            id: 'postRedActivitiesHours',
                            label: 'Red',
                            align: 'center',
                          },
                          {
                            id: 'postAmberActivitiesHours',
                            label: 'Amber',
                            align: 'center',
                          },
                          {
                            id: 'postGreenActivitiesHours',
                            label: 'Green',
                            align: 'center',
                          },
                          {
                            id: 'postRAGTotalHrs',
                            label: 'Total Man Hours',
                            align: 'center',
                          },
                          {
                            id: 'postRAGActualUtilizationInPer',
                            label: 'Utilization',
                            align: 'center',
                          },
                        ]}
                        dataKeys={[
                          { id: 'name' },
                          // { id: 'subTeam' },
                          { id: 'employeeType' },
                          { id: 'collectionEvent' },
                          {
                            id: 'preRagDetails.redActivitiesHours',
                            align: 'center',
                          },
                          {
                            id: 'preRagDetails.amberActivitiesHours',
                            align: 'center',
                          },
                          {
                            id: 'preRagDetails.greenActivitiesHours',
                            align: 'center',
                          },
                          {
                            id: 'preRagDetails.PreRAGValidationUtilizationHrs',
                            align: 'center',
                          },
                          {
                            id: 'preRagDetails.PreRAGValidationUtilizationInPer',
                            align: 'center',
                          },
                          {
                            id: 'postRagDetails.postRedActivitiesHours',
                            align: 'center',
                          },
                          {
                            id: 'postRagDetails.postAmberActivitiesHours',
                            align: 'center',
                          },
                          {
                            id: 'postRagDetails.postGreenActivitiesHours',
                            align: 'center',
                          },
                          {
                            id: 'postRagDetails.postRAGTotalHrs',
                            align: 'center',
                          },
                          {
                            id: 'postRagDetails.postRAGActualUtilizationInPer',
                            align: 'center',
                          },
                        ]}
                        isSelectable={false}
                      />
                    ),
                  },
                  {
                    label: 'Activity',
                    children: (
                      <TableWrapper
                        data={activityData}
                        id={'activityAnalyticsTable'}
                        tableHeads={[
                          { id: 'activityName', label: 'Activity' },
                          { id: 'process', label: 'Process' },
                          { id: 'subProcess', label: 'Sub-Process' },
                          {
                            id: 'totalHours',
                            label: 'Total Hours',
                            align: 'center',
                          },
                        ]}
                        dataKeys={[
                          { id: 'activityName' },
                          { id: 'process' },
                          { id: 'subProcess' },
                          { id: 'totalHours', align: 'center' },
                        ]}
                        isSelectable={false}
                      />
                    ),
                  },
                ]}
              />
            </Grid>

            {!chartData.lowerUpperData &&
              !chartData.RagData &&
              chartTableValue === 'plotting' ? (
              <PlaceholderImage
                image={board}
                messsage={'No records found'}
                width="25%"
              />
            ) : chartTableValue === 'plotting' &&
              actionType === 'utilization' &&
              isChart ? (
              <>
                <DepartmentChart
                  data={chartData?.lowerUpperData}
                  renderChart={renderChart}
                  setChartData={setChartData}
                />
                <RagChart
                  data={chartData?.RagData}
                  renderChart={renderChart}
                  setChartData={setChartData}
                />
              </>
            ) : null}
          </Grid>
        </Grid>
      </SecondaryLayout>
    </Layout>
  );
};

export default ManagerAnalytics;
