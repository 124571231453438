import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';

const RagChart = ({ data, renderChart, setChartData }) => {
  const [chart, setChart] = useState({});

  //* Calculate the maximum number in each array and set the maximum value for the y-axis
  useEffect(() => {
    if (data) {
      data = data?.payload?.data;
      for (let key in data) {
        if (data[key].length === 0 || data[key] === null) {
          setChartData({
            lowerUpperData: null,
            RagData: null,
          });
          return;
        }
      }
      const findMax = (arr) => Math.max(...arr);

      // Find the maximum number in each array
      const red = findMax(data?.red);
      const amber = findMax(data?.amber);
      const green = findMax(data?.green);

      let sumOfNumber = red + amber + green;
      sumOfNumber = Number(sumOfNumber.toFixed(0));
      data['sumOfNumber'] = sumOfNumber || 0;

      setChart(data);
    } else {
      setChart({});
    }
  }, [renderChart]);

  //* Set the options for the chart
  const option = {
    title: {
      text: 'Stacked Bar Chart',
    },
    tooltip: {
      trigger: 'axis',
      axisPointer: {
        type: 'shadow',
      },
    },
    legend: {
      data: ['Red', 'Amber', 'Green'],
    },
    xAxis: {
      type: 'category',
      data: chart.departMentName,
    },
    yAxis: {
      type: 'value',
    },
    series: [
      {
        name: 'Red',
        type: 'bar',
        stack: 'total',
        data: chart.red,
        itemStyle: {
          color: '#FF0000',
        },
      },
      {
        name: 'Amber',
        type: 'bar',
        stack: 'total',
        data: chart.amber,
        itemStyle: {
          color: '#ffcb00e3',
        },
      },
      {
        name: 'Green',
        type: 'bar',
        stack: 'total',
        data: chart.green,
        itemStyle: {
          color: '#8dc14a',
        },
      },
    ],
  };

  return (
    <>
      <h6 className="my-3">RAG Utilization</h6>
      <div className="border p-2">
        <ReactEcharts style={{ height: '500px' }} option={option} />
      </div>
    </>
  );
};

export default RagChart;
