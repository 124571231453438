import { get } from '../api_helper';
import * as url from '../url_helper';
import { getApiConfig, getAuthToken } from '../auth_helper';

//* get employee utilization
export const getEmployeeUtilization = async (collectionEventId, user) => {
  return await get(
    `${url.MANAGER}/getEmployeeUtilizations?collectionEventId=${collectionEventId}&managerId=${user.managerId}`,
    getApiConfig()
  );
};

export const getAllEmployeeUtilization = async (user) => {
  return await get(
    `${url.MANAGER}/getEmployeeUtilizations?managerId=${user.managerId}`,
    getApiConfig()
  );
};

//* get employee utilization
export const getEmployeeRagUtilization = async (collectionEventId, user) => {
  return await get(
    `${url.MANAGER}/getEmployeeRAGAnalysis?collectionEventId=${collectionEventId}&managerId=${user.managerId}`,
    getApiConfig()
  );
};

//* get all employee utilization
export const getAllEmployeeRagUtilization = async (user) => {
  return await get(
    `${url.MANAGER}/getEmployeeRAGAnalysis?managerId=${user.managerId}`,
    getApiConfig()
  );
};

//* get graph utilization data
export const getEmployeeGraphData = async (collectionEventId, user) => {
  return await get(
    `${url.MANAGER}/getEmployeeUtilizationGraph?collectionEventId=${collectionEventId}&managerId=${user.managerId}`,
    getApiConfig()
  );
};

//* All utilization graph of employee
export const allEmployeeUtilizationGraph = async (user) => {
  return await get(
    `${url.MANAGER}/getEmployeeUtilizationGraph?managerId=${user.managerId}`,
    getApiConfig()
  );
};

//* all rag utilization of employee
export const allEmployeeRagUtilization = async (user) => {
  return await get(
    `${url.MANAGER}/getEmployeeRAGGraph?managerId=${user.managerId}`,
    getApiConfig()
  );
};

//* get Rag chart utilization data
export const getEmployeeRAGChartData = async (collectionEventId, user) => {
  return await get(
    `${url.MANAGER}/getEmployeeRAGGraph?collectionEventId=${collectionEventId}&managerId=${user.managerId}`,
    getApiConfig()
  );
};
