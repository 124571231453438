import React, { useContext, useState } from 'react';
import Layout from '../../../components/Layout';
import {
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextWithDivider from '../../../components/TextWithDivider';
import { AuthContext } from '../../../components/AuthContextProvider';
import SecondaryLayout from '../../../components/SecondaryLayout';
import TableWrapper from '../components/TableWrapper';
import useFetch from '../../../hooks/useFetch';
import { fetchRAGAssignmentStatus } from '../../../operations/poc';
import { useHistory } from 'react-router-dom';
import { Paths } from '../../../routes/paths';
import { EventContext } from '../../../components/EventContextProvider';
import {
  getSheetByCollectionId,
  handleApproveRejectActivity,
} from '../../../helper/backendHelper/sheet';
import TableAssignRag from '../../Manager/AssignRAG/TableAssignRag';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { STATUS_TEXT } from '../../../utils/utils';
import CommentDialog from '../../../components/CommentDialog';
import Message from '../../../components/Message';
import { InfoOutlined } from '@material-ui/icons';
import DialogWrapper from '../../../components/DialogWrapper';

const POCRAG = () => {
  let { user } = useContext(AuthContext);
  const { events } = useContext(EventContext);
  const [loading, setLoading] = useState(false);
  const [allSheet, setAllSheet] = useState([]);
  const [commentDialogOpen, setCommentDialogOpen] = useState({
    isComment: false,
    value: '',
  });
  const [comment, setComment] = useState('');
  const [selectedIds, setSelectedIds] = useState([]);
  const [tooltip, setTooltip] = useState(false);
  const history = useHistory();

  //* fetch All sheet by collection event id
  const fetchAllActivitiesOnSheet = async (id) => {
    try {
      setLoading(true);
      const response = await getSheetByCollectionId(id);
      setAllSheet(response?.payload?.data);
      setLoading(false);
    } catch (error) {
      setLoading(false);
    }
  };

  const isActionable = (row) => {
    return row.managerSubmissionStatus == STATUS_TEXT.SUBMITTED;
  };

  //* handle approve and reject Reject
  const handleApproveReject = async (approveReject) => {
    try {
      const response = await handleApproveRejectActivity({
        ids: selectedIds,
        ...(approveReject == STATUS_TEXT.REJECTED
          ? { managerSubmissionStatus: STATUS_TEXT.REJECTED_BY_POC }
          : {}),
        pocApprovalStatus: approveReject,
        ...(commentDialogOpen.value == STATUS_TEXT.APPROVED
          ? { pocApprovalComment: comment }
          : { pocRejectedComment: comment }),
      });
      Message.success(response.message);
      fetchAllActivitiesOnSheet(user.eventId);
      setSelectedIds([]);
      setCommentDialogOpen(false);
    } catch (error) {
      Message.error(error?.response?.data?.message);
    }
  };

  return (
    <Layout loading={loading}>
      <SecondaryLayout>
        <Grid container className="pl-4 pr-2">
          <Grid item lg={9} md={8} className="pr-4 pt-4">
            <TextWithDivider>
              <Typography variant="h3" color="textPrimary">
                RAG
                <InfoOutlined color="primary" onClick={() => setTooltip(true)} className='ml-2' />
              </Typography>
            </TextWithDivider>
            <Grid container spacing={2} item md={6}>
              <Grid item xs={6}>
                <FormControl fullWidth>
                  <InputLabel id="selectEnterprise">
                    Select Collection Of Events
                  </InputLabel>

                  <Select
                    labelId="selectEnterprise"
                    fullWidth
                    placeholder="Select Enterprise"
                    onChange={(e) => {
                      fetchAllActivitiesOnSheet(e.target.value);
                      user['eventId'] = e.target.value;
                    }}>
                    {events?.map((item) => (
                      <MenuItem value={item._id} key={item.name}>
                        {item.name}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Grid>
            </Grid>
            <Grid className="mt-4">
              <TableAssignRag
                data={allSheet}
                isApproveReject={true}
                setCommentDialogOpen={setCommentDialogOpen}
                isActionable={isActionable}
                selected={selectedIds}
                setSelected={setSelectedIds}
                handleApproveReject={handleApproveReject}
                id={'AssignRAGTable'}
                tableHeads={[
                  { id: 'name', label: 'Name' },
                  {
                    id: 'subProcessCount',
                    label: 'No Of Sub Process',
                  },
                  { id: 'activityCount', label: 'No Of Activities' },
                  { id: 'status', label: 'workload Status', align: 'center' },
                  {
                    id: 'managerApprovalStatus',
                    label: 'Manager Approval Status',
                    align: 'center',
                  },
                  {
                    id: 'managerSubmissionStatus',
                    label: 'Manager Submission Status',
                    align: 'center',
                  },
                  {
                    id: 'pocApprovalStatus',
                    label: 'Poc Approval Status',
                    align: 'center',
                  },
                  // {
                  //   id: 'comment',
                  //   label: 'Comment',
                  // },
                  {
                    id: 'actions',
                    label: 'Actions',
                  },
                ]}
                dataKeys={[
                  { id: 'name' },
                  { id: 'subProcessCount' },
                  { id: 'activityCount' },
                  { id: 'status' },
                  { id: 'managerApprovalStatus' },
                  { id: 'managerSubmissionStatus' },
                  { id: 'pocApprovalStatus' },
                  // { id: 'comment' },
                ]}
                checkId={'_id'}
                variant={'tableLong'}
                disabled={loading}
              />
            </Grid>
          </Grid>
        </Grid>
        <CommentDialog
          open={commentDialogOpen.isComment}
          setComment={setComment}
          comment={comment}
          handleClose={() => {
            setCommentDialogOpen(false);
            setSelectedIds([]);
          }}
          handleSubmit={() => {
            if (commentDialogOpen.value == STATUS_TEXT.APPROVED) {
              handleApproveReject(STATUS_TEXT.APPROVED);
            } else {
              handleApproveReject(STATUS_TEXT.REJECTED);
            }
          }}
          title="Add comment"
        />
      </SecondaryLayout>
      <DialogWrapper
        title="User Guide for RAG"
        open={tooltip}
        handleClose={() => setTooltip(false)}>
        <div className='overflow-auto'>
          <div>
            <div>1. Select the <b>Event</b>.</div>
          </div>
          <div className='mt-3'>
            <b>2. Approval Review</b>
            <div className='ml-2'>
              Check manager approvals and rejections for
              effort logs specific to each event.
            </div>
          </div>
          <div className='mt-3'>
            <b>3. Data Validation</b>
            <div className='ml-2'>
              Validate and classify the data using RAG
              (Red, Amber, Green) based on workload assumptions.
            </div>
          </div>
          <div className='mt-3'>
            <b>4. Final Approval</b>
            <div className='ml-2'>
              Ensure all data is accurate and complete before
              generating a report through the <b>"Analytics"</b> icon.
            </div>
          </div>
          <div className='mt-3'>
            <b className='text-danger'>Tip for avoiding errors</b>
            <div className='ml-2'>
              When adding managers in small qualities, use the <b>“add manager”</b> button to add the Managers by simply filling in three columns
            </div>
          </div>
        </div>
      </DialogWrapper>
    </Layout>
  );
};

export default POCRAG;
