import React from 'react';
import { Typography } from '@material-ui/core';
import DialogWrapper from '../DialogWrapper';

const ConfirmationDialog = ({
  open,
  handleClose,
  handleSubmit,
  message,
  title,
}) => (
  <DialogWrapper
    open={open}
    handleClose={handleClose}
    title={title}
    handleSubmit={handleSubmit}
    submitButtonTitle="Yes"
    cancelButtonTitle="No"
    dialogId="alert-save">
    <Typography variant="body1">{message}</Typography>
  </DialogWrapper>
);

export default ConfirmationDialog;
