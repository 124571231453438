import React, { useEffect, useState } from 'react';
import ReactEcharts from 'echarts-for-react';

const DepartmentChart = ({ data, renderChart, setChartData }) => {
  const [chart, setChart] = useState({});

  //* Calculate the maximum number in each array and set the maximum value for the y-axis
  useEffect(() => {
    if (data) {
      data = data?.payload?.data;
      for (let key in data) {
        if (data[key].length === 0) {
          setChartData({
            lowerUpperData: null,
            RagData: null,
          });
          return;
        }
      }
      const findMax = (arr) => Math.max(...arr);

      // Find the maximum number in each array
      const lower = findMax(data?.lower);
      const mean = findMax(data?.mean);
      const upper = findMax(data?.upper);

      let sumOfNumber = lower + mean + upper;
      sumOfNumber = Number(sumOfNumber.toFixed(0));
      data['sumOfNumber'] = sumOfNumber || 0;
      setChart(data);
    } else {
      setChart({});
    }
  }, [renderChart]);

  //* Set the options for the chart
  const options = {
    grid: { top: 20, right: 40, bottom: 20, left: 40 },

    xAxis: {
      type: 'category',
      data: chart.departMentName,
    },
    yAxis: {
      type: 'value',
      min: 0,
      max: chart.sumOfNumber, // Set the maximum value
    },
    legend: {
      data: ['Pre RAG', 'Post Rag', 'mean'],
      textStyle: {
        color: ['#74788d'],
      },
    },
    series: [
      {
        data: chart.lower,
        type: 'bar',
        smooth: true,
        name: 'Pre RAG',
      },
      {
        data: chart.upper,
        type: 'bar',
        smooth: true,
        name: 'Post Rag',
      },
      {
        data: chart.mean,
        type: 'line',
        smooth: true,
        name: 'Mean',
      },
    ],
    tooltip: {
      trigger: 'axis',
    },
  };

  return (
    <>
      <h6 className="my-3">Department Utilization</h6>
      <div className="border p-2">
        <ReactEcharts style={{ height: '350px' }} option={options} />
      </div>
    </>
  );
};

export default DepartmentChart;
