import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import { Button, MenuItem, Select, Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextWithDivider from '../../../components/TextWithDivider';
import { AuthContext } from '../../../components/AuthContextProvider';
import SecondaryLayout from '../../../components/SecondaryLayout';
import TableWrapper from '../components/TableWrapper';
import StatContainer from '../components/StatContainer';
import {
  fetchApprovalCompletionStatus,
  fetchRAGAssignmentStatus,
} from '../../../operations/poc';
import BarChartWrapper from '../../../components/Charts/BarChartWrapper';
import ChartContainer from '../../../components/ChartContainer';
import { EFFORT_LOG_STATUS } from '../../../operations/constants';
import { isEmpty } from '../../../utils/utils';
import PlaceholderImage from '../../../components/PlaceholderImage';
import Stats from './../../../assets/images/stats.svg';
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline';
import Message from '../../../components/Message';
import { Paths } from '../../../routes/paths';
import { useHistory } from 'react-router-dom';
import { getEnterpriseDataById } from '../../../helper/backendHelper/poc';
import { EventContext } from '../../../components/EventContextProvider';
import DialogWrapper from '../../../components/DialogWrapper';
import { InfoOutlined } from '@material-ui/icons';

const POCDashboard = () => {
  let { user } = useContext(AuthContext);
  let { events } = useContext(EventContext);

  // eslint-disable-next-line no-unused-vars
  const [stats, setStats] = useState({});
  const [eventId, setEvent] = useState('');
  const [loading, setLoading] = useState(false);
  const [selectedTab, setSelectedTab] = useState('orgSummary');
  const [employeeEffortLog, setEmployeeEffortLog] = useState([]);
  const [approvalCompletionData, setApprovalCompletionData] = useState([]);
  const [ragStatus, setRagStatus] = useState('');
  const [chartData, setChartData] = useState([]);
  const [ragAssignmentData, setRagAssignmentData] = useState([]);
  const [enterPriseId, setEnterPriseId] = useState('');
  const history = useHistory();
  const [tooltip, setTooltip] = useState(false);
  useEffect(() => {
    fetchAllCollectionEvents();
  }, []);

  //* fetch enterpriseData by pocId
  const fetchAllCollectionEvents = async () => {
    try {
      setLoading(true);
      const response = await getEnterpriseDataById(user._id);
      user['limitOfCollectionEvent'] =
        response?.payload?.enterpriseId?.limitOfCollectionEvent;
      setEnterPriseId(response?.payload?.enterpriseId?._id);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Message.success(error?.response?.data?.message);
    }
  };

  const approvalCompletionStatusSetter = async (eventId) => {
    return new Promise(async (resolve, reject) => {
      const data = await fetchApprovalCompletionStatus(eventId);
      setApprovalCompletionData(data);
      resolve();
    });
  };

  const ragAssignmentDataSetter = async (eventId) => {
    return new Promise(async (resolve, reject) => {
      const data = await fetchRAGAssignmentStatus(eventId);
      setRagAssignmentData(data);
      resolve();
    });
  };

  useEffect(() => {
    if (eventId === null) {
      setEvent(user.eventId);

      (async () => {
        await approvalCompletionStatusSetter();
      })();
    }
    // eslint-disable-next-line
  }, [eventId, setEvent]);

  // const {
  //   data: approvalCompletionData = [],
  //   loading: approvalLoading,
  //   fetchData: fetchApprovalData,
  // } = useFetch(fetchApprovalCompletionStatus, eventId || user.eventId);

  // const {
  //   data: ragAssignmentData = [],
  //   loading: assignmentLoading,
  //   fetchData: fetchRagAssignmentData,
  // } = useFetch(fetchRAGAssignmentStatus, eventId || user.eventId);

  useEffect(() => {
    (async () => {
      if (eventId !== '') {
      }
    })();

    //  const individualEffortReceived = employeeEffortLog.filter(
    //    (item) =>
    //      [EFFORT_LOG_STATUS.APPROVED, EFFORT_LOG_STATUS.SUBMITTED].includes(
    //        item.status
    //      ) && item.type === USER_TYPE.INDIVIDUAL
    //  ).length;

    //  const identicalEffortReceived = employeeEffortLog.filter(
    //    (item) =>
    //      [EFFORT_LOG_STATUS.APPROVED, EFFORT_LOG_STATUS.SUBMITTED].includes(
    //        item.status
    //      ) && item.type === USER_TYPE.IDENTICAL
    //  ).length;

    //  const totalIndividual = employeeEffortLog.filter(
    //    (item) => item.type === USER_TYPE.INDIVIDUAL
    //  ).length;
    //  const totalIdentical = employeeEffortLog.filter(
    //    (item) => item.type === USER_TYPE.IDENTICAL
    //  ).length;
    // }
    // eslint-disable-next-line
  }, [eventId]);

  useEffect(() => {
    if (!isEmpty(ragStatus)) {
      setStats((stats) => ({ ...stats, ...ragStatus }));
    }
  }, [ragStatus]);

  useEffect(() => {
    // if (employeeEffortLog.length) {
    const approvalCount = employeeEffortLog.filter((item) =>
      [EFFORT_LOG_STATUS.APPROVED, EFFORT_LOG_STATUS.REJECTED].includes(
        item.status
      )
    ).length;

    const data = {
      approvalCompletion: !!approvalCount
        ? (approvalCount / employeeEffortLog.length) * 100
        : 0,
      //  individualEffortReceived,
      //  totalIndividual,
      //  totalIdentical,
      //  identicalEffortReceived,
    };

    setStats((stats) => ({ ...stats, ...data }));
    // }
  }, [employeeEffortLog]);

  const handleSelectTab = (tab) => {
    setSelectedTab(tab);
  };

  const handleEventChange = async (event) => {
    setEvent(event);
    // if (event !== eventId) {
    //   setLoading(true);
    //   setEvent(event);
    //   await approvalCompletionStatusSetter(event);
    //   await ragAssignmentDataSetter(event);

    //   const data = await employeeEffortLogStatusByEnterpriseIdEventId({
    //     enterpriseId: user.enterpriseId,
    //     eventId,
    //   });

    //   const ragAsgCompletion =
    //     await getRagCompletionStatusByEnterpriseIdEventId({
    //       enterpriseId: user.enterpriseId,
    //       eventId: eventId,
    //     });

    //   const c = await prepareTeamLogChartData(user.enterpriseId, event);
    //   setChartData(c);
    //   setRagStatus(ragAsgCompletion);
    //   setEmployeeEffortLog(data);
    //   setLoading(false);
    // }
  };
  return (
    <Layout loading={loading}>
      <SecondaryLayout>
        <Grid container className="pl-4 pr-2">
          <Grid item lg={9} md={8} className="pr-4 pt-4">
            <TextWithDivider>
              <div className="d-flex justify-content-between">
                <Typography variant="h3" color="textPrimary">
                  Summary
                  <InfoOutlined color="primary" onClick={() => setTooltip(true)} className='ml-2' />
                </Typography>
                <Button
                  startIcon={<AddCircleOutlineIcon />}
                  variant="contained"
                  onClick={() => {
                    if (user.limitOfCollectionEvent == events.length) {
                      return Message.warn('Your Limit has exceed');
                    }
                    history.push(`${Paths.pocCreateEvents}/${enterPriseId}`);
                  }}
                  color="primary"
                  disableElevation>
                  Add New Event
                </Button>
              </div>
            </TextWithDivider>
            <Grid item md={6}>
              <Select
                onChange={(evt) => handleEventChange(evt.target.value)}
                className="w-50">
                <MenuItem disabled value={''} />
                {events?.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </Grid>
            <Grid container justifyContent="space-between" className="mt-4">
              <Grid item container className="w-50">
                <Typography variant="subtitle2" className="ml-3">
                  Organizational Summary
                </Typography>
                <Grid
                  item
                  container
                  justifyContent="space-around"
                  className="mt-3">
                  <StatContainer
                    value={
                      Boolean(stats.approvalCompletion?.toFixed(2))
                        ? stats.approvalCompletion?.toFixed(2)
                        : 0
                    }
                    unit="%"
                    label={'Approval Completion Status'}
                    handleClick={() => handleSelectTab('orgSummary')}
                    theme={
                      selectedTab === 'orgSummary' ? 'primary' : 'secondary'
                    }
                    additionalClasses="pointer"
                  />
                  <StatContainer
                    value={
                      isNaN(stats.assignRagPercentage)
                        ? 0
                        : stats.assignRagPercentage?.toFixed(2) || 0
                    }
                    unit="%"
                    label={'RAG Assignment Completion'}
                    handleClick={() => handleSelectTab('ragAssign')}
                    theme={
                      selectedTab === 'ragAssign' ? 'primary' : 'secondary'
                    }
                    additionalClasses="pointer"
                  />
                </Grid>
              </Grid>
              <Grid item container className="w-50">
                <Typography variant="subtitle2" className="ml-3">
                  My Task Summary
                </Typography>
                <Grid item container className="mt-3">
                  <StatContainer
                    value={
                      stats?.ragData?.filter((e) => e.ragStatus === 'APPROVED')
                        ?.length || 0
                    }
                    unit={`/${stats.ragData?.length || 0}`}
                    label={'RAG Approval Completion'}
                    theme="secondary"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid className="mt-4">
              {selectedTab === 'orgSummary' ? (
                <TableWrapper
                  data={approvalCompletionData}
                  id={'approvalCompletionStatusPOC'}
                  tableHeads={[
                    { id: 'name', label: 'Name' },
                    {
                      id: 'teamSize',
                      label: 'Team Size',
                      align: 'center',
                    },
                    {
                      id: 'totalEffortLogs',
                      label: 'Effort Log Submitted',
                      align: 'center',
                    },
                    {
                      id: 'completionStatus',
                      label: 'Approval Status',
                      align: 'center',
                    },
                    {
                      id: 'rejectedEffortLogs',
                      label: 'Rejected Records',
                      align: 'center',
                    },
                  ]}
                  dataKeys={[
                    { id: 'name' },
                    { id: 'teamSize', align: 'center' },
                    { id: 'totalEffortLogs', align: 'center' },
                    { id: 'completionStatus', align: 'center' },
                    { id: 'rejectedEffortLogs', align: 'center' },
                  ]}
                  isSelectable={false}
                />
              ) : (
                <TableWrapper
                  data={ragAssignmentData}
                  id={'ragCompletionStatusPOC'}
                  tableHeads={[
                    { id: 'name', label: 'Name' },
                    {
                      id: 'teamSize',
                      label: 'Team Size',
                      align: 'center',
                    },
                    {
                      id: 'totalActivitiesCount',
                      label: 'Activities Recorded',
                      align: 'center',
                    },
                    {
                      id: 'completionStatus',
                      label: 'RAG Assignment Status',
                      align: 'center',
                    },
                  ]}
                  dataKeys={[
                    { id: 'name' },
                    { id: 'teamSize', align: 'center' },
                    { id: 'totalActivitiesCount', align: 'center' },
                    { id: 'completionStatus', align: 'center' },
                  ]}
                  isSelectable={false}
                />
              )}
            </Grid>
          </Grid>
          {user.enterpriseId && (
            <ChartContainer
              enterpriseId={user.enterpriseId}
              title="Team Wise Log Submission">
              {!chartData.length ? (
                <PlaceholderImage image={Stats} messsage="No Logs available" />
              ) : (
                <BarChartWrapper
                  data={chartData}
                  keyBar1="totalLogs"
                  nameBar1="totalLogs"
                  keyBar2="submittedLogs"
                  nameBar2="submittedLogs"
                />
              )}
            </ChartContainer>
          )}
        </Grid>
      </SecondaryLayout>
      <DialogWrapper
        title="Guide for Event Creation"
        open={tooltip}
        handleClose={() => setTooltip(false)}>
        <div className='overflow-auto'>
          Add new events by clicking the <b>"Add new
            event"</b> button and filling in the event details.
        </div>
      </DialogWrapper>
    </Layout>
  );
};

export default POCDashboard;
