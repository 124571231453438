import React, { useEffect, useState } from 'react';
import {
  Checkbox,
  Paper,
  Select,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import ExpandableTableRow from '../../../components/ExpandableTableRow';
import PlaceholderImage from '../../../components/PlaceholderImage';
import board from '../../../../src/assets/images/board.svg';
import EmployeeEffortLogComponent from '../../../components/EfforLogTable';

const useStyles = makeStyles((theme) => ({
  table: {
    maxHeight: '50vh',
    width: '100%',
  },
  tableLong: {
    maxHeight: '70vh',
    width: '100%',
  },
  inlineIconButtons: {
    padding: '4px',
  },
  groupedButtons: {
    padding: '4px',
    fontWeight: '300',
    textTransform: 'uppercase',
    width: '60px',
  },
  disabledButton: {
    backgroundColor: theme.palette.primary.main,
  },
  expandableRow: {
    backgroundColor: theme.palette.grey[100],
  },
}));

const AnalyticsTableWrapper = ({
  id,
  handlePrimaryButtonClick = () => null,
  handleSecondaryButtonClick = () => null,
  isActionable = () => null,
  tableHeads = [],
  totalTitle = "Total Items:",
  data = [],
  dataKeys = [],
  checkId = '',
  isSelectable = true,
  variant = 'table',
  disabled = false,
  isExpandable = false,
  chartTableValue,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);

  //* handle check in particular employees
  const handleCheck = (id) => {
    const index = selected.indexOf(id);
    let updated = selected;
    if (index > -1) {
      updated.splice(index, 1);
    } else {
      updated = [...selected, id];
    }

    setSelected([...updated]);
  };

  const isActiveRAGButton = (btnVal, ragVal) => {
    return btnVal === ragVal;
  };

  const getNestedValue = (object, key) => {
    // Split the key into parts
    const keys = key.split('.');
    // Iterate through the keys to access the nested value
    return keys.reduce((acc, currentKey) => acc && acc[currentKey], object);
  };

  return (
    <React.Fragment>
      {chartTableValue === 'dataTable' && data.length !== 0 && (
        <Paper className={`${classes.paper} my-3`}>
          <Grid
            container
            item
            justifyContent="space-between"
            alignItems="baseline"
            className="p-2">
            <Typography variant="body1">{totalTitle} {data.length}</Typography>
          </Grid>
          <TableContainer
            classes={{
              root: classes[variant],
            }}>
            <Table
              stickyHeader
              aria-labelledby="tableTitle"
              size="medium"
              aria-label={id}>
              <TableHead style={{ border: '2px solid' }}>
                <TableRow style={{ border: '2px solid' }}>
                  <TableCell rowSpan={2} style={{ border: '1px solid' }}>
                    Name
                  </TableCell>
                  <TableCell rowSpan={2} style={{ border: '1px solid' }}>
                    Identical/Individual
                  </TableCell>
                  <TableCell rowSpan={2} style={{ border: '1px solid' }}>
                    Event
                  </TableCell>

                  <TableCell colSpan={5} style={{ border: '1px solid' }} >Pre Red Amber Green(RAG) Total / Raw Utilization</TableCell>

                  <TableCell colSpan={5} style={{ border: '1px solid' }}>Post Red Amber Green(RAG) Total / Utilization Post Manager Validation</TableCell>

                </TableRow>
                <TableRow>
                  {tableHeads
                    .filter(
                      (headCell) =>
                        !(
                          headCell.id === 'name' ||
                          headCell.id === 'employeeType' ||
                          headCell.id === 'collectionEvent'
                        )
                    )
                    .map((filteredHeadCell, index) => (
                      // Render your JSX for each remaining head cell
                      <>
                        <TableCell
                          key={index}
                          style={{ border: '1px solid' }}
                          align={
                            filteredHeadCell.align
                              ? filteredHeadCell.align
                              : 'left'
                          }>
                          {filteredHeadCell.label}
                        </TableCell>
                      </>
                    ))}
                  {/* {tableHeads.map((headCell, index) => {
                
                  return (
                  
                  );
                })} */}
                </TableRow>
              </TableHead>
              <TableBody>
                {data.map((row, index) => {
                  const isItemSelected = selected.includes(row[checkId]);
                  row.isCollectionStarted
                    ? (row['collectionStarted'] = true)
                    : (row['collectionStarted'] = false);
                  const isActionableItem = isActionable(row);

                  return (
                    <ExpandableTableRow
                      isExpandable={isExpandable}
                      expandComponent={
                        isExpandable && (
                          <TableCell
                            colSpan={6}
                            className={classes.expandableRow}>
                            <EmployeeEffortLogComponent userId={row['_id']} />
                          </TableCell>
                        )
                      }
                      hover
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      selected={isItemSelected}>
                      {isSelectable && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            disabled={!isActionableItem || disabled}
                            checked={isItemSelected}
                            onClick={(event) => handleCheck(row[checkId])}
                          />
                        </TableCell>
                      )}

                      {dataKeys.map((item, index) => {
                        let cellValue = null
                        if (typeof row[item.id] === 'boolean') {
                          row[item.id] = row[item.id] ? 'Yes' : 'No';
                        }

                        switch (item.id) {
                          case "preRagDetails.redActivitiesHours":
                            cellValue = `(${row["preRagDetails"]["redActivitiesPer"]}%) ${row["preRagDetails"]["redActivitiesHours"]}`;
                            break;
                          case "preRagDetails.amberActivitiesHours":
                            cellValue = `(${row["preRagDetails"]["amberActivitiesPer"]}%) ${row["preRagDetails"]["amberActivitiesHours"]}`;
                            break;
                          case "preRagDetails.greenActivitiesHours":
                            cellValue = `(${row["preRagDetails"]["greenActivitiesPer"]}%) ${row["preRagDetails"]["greenActivitiesHours"]}`;
                            break;
                          case "postRagDetails.postRedActivitiesHours":
                            cellValue = `(${row["postRagDetails"]["postRedActivitiesPer"]}%) ${row["postRagDetails"]["postRedActivitiesHours"]}`;
                            break;
                          case "postRagDetails.postAmberActivitiesHours":
                            cellValue = `(${row["postRagDetails"]["postAmberActivitiesPer"]}%) ${row["postRagDetails"]["postAmberActivitiesHours"]}`;
                            break;
                          case "postRagDetails.postGreenActivitiesHours":
                            cellValue = `(${row["postRagDetails"]["postGreenActivitiesPer"]}%) ${row["postRagDetails"]["postGreenActivitiesHours"]}`;
                            break;
                          default:
                            cellValue = null;
                            break;
                        }

                        return (
                          <TableCell
                            key={index}
                            // onClick={() => history.push('/manager/employee/' + row['userId'])}
                            align={
                              item.align
                                ? item.align
                                : typeof row[item.id] !== 'string'
                                  ? 'center'
                                  : 'left'
                            }>
                            {cellValue ? cellValue : getNestedValue(row, item.id)}
                          </TableCell>
                        );
                      })}
                      <React.Fragment></React.Fragment>
                    </ExpandableTableRow>
                  );
                })}
              </TableBody>
            </Table>
          </TableContainer>
        </Paper>
      )}
      {data?.length == 0 && chartTableValue == 'dataTable' ? (
        <PlaceholderImage
          image={board}
          messsage={'No records found'}
          width="25%"
        />
      ) : null}
    </React.Fragment>
  );
};

export default AnalyticsTableWrapper;
