import { firebaseFunctions, firestore } from '../../firebase';
import { generatePassword, mergeArrayOfObjects } from '../../utils/utils';
import { COLLECTIONS, USER_ROLES } from '../constants';
import { registerAndCreateUsers } from '../session';
import {
  CreatedSuccessFully,
  GenericError,
  GenericSuccessUpdate,
  transformFirebaseResponse,
  UnableToFetchData,
  UnableToUpdate,
} from '../utils';

/**
 * Delete bulk users
 * @param usersData
 * @returns {Promise<{}|firebase.functions.HttpsCallableResult>}
 */
const deleteBulkUsersFromAuth = async (usersData = []) => {
  try {
    const callable = firebaseFunctions.httpsCallable('deleteBulkUsers');
    await callable(usersData);
    return callable;
  } catch (e) {
    console.log(e);
    GenericError();
  }
};

const fetchUsersByEventId = async (eventId) => {
  try {
    let query = firestore.collection(COLLECTIONS.USERS);
    query = query.where('eventId', '==', eventId);

    let usersData = await query.get();
    return transformFirebaseResponse(usersData);
  } catch (e) {
    console.log(e);
    GenericError();
  }
};

/**
 * Delete bulk users
 * @param eventId
 * @returns {Promise<{}|firebase.functions.HttpsCallableResult>}
 */
const deleteBulkUsersFromFirestoreByEventId = async (eventId) => {
  try {
    const usersData = await firestore
      .collection(COLLECTIONS.USERS)
      .where('eventId', '==', eventId)
      .get();
    let batch = firestore.batch();

    usersData.forEach((user) => {
      batch.delete(user.ref);
    });

    await batch.commit();
  } catch (e) {
    console.log(e);
    GenericError();
  }
};

/**
 * Delete Effort logs
 * @param eventId
 * @returns {Promise<{}|firebase.functions.HttpsCallableResult>}
 */
const deleteAllEffortLogsByEventId = async (eventId) => {
  try {
    const effortLogs = await firestore
      .collection(COLLECTIONS.EFFORT_LOG)
      .where('eventId', '==', eventId)
      .get();
    let batch = firestore.batch();

    effortLogs.forEach((log) => {
      batch.delete(log.ref);
    });

    await batch.commit();
  } catch (e) {
    console.log(e);
    GenericError();
  }
};

/**
 * Delete Activities
 * @param eventId
 * @returns {Promise<{}|firebase.functions.HttpsCallableResult>}
 */
const deleteAllActivitiesByEventId = async (eventId) => {
  try {
    const effortLogs = await firestore
      .collection(COLLECTIONS.ACTIVITY)
      .where('eventId', '==', eventId)
      .get();
    let batch = firestore.batch();

    effortLogs.forEach((log) => {
      batch.delete(log.ref);
    });

    await batch.commit();
  } catch (e) {
    console.log(e);
    GenericError();
  }
};

/**
 * Delete Activity Effort
 * @param eventId
 * @returns {Promise<{}|firebase.functions.HttpsCallableResult>}
 */
const deleteAllActivityEffortsByEventId = async (eventId) => {
  try {
    const effortLogs = await firestore
      .collection(COLLECTIONS.ACTIVITY_EFFORT)
      .where('eventId', '==', eventId)
      .get();
    let batch = firestore.batch();

    effortLogs.forEach((log) => {
      batch.delete(log.ref);
    });

    await batch.commit();
  } catch (e) {
    console.log(e);
    GenericError();
  }
};

/**
 * Update Event by event id
 * @param eventId
 * @param eventData
 * @returns {Promise<{}|firebase.functions.HttpsCallableResult>}
 */
const updateEventById = async (eventId, eventData) => {
  try {
    await firestore
      .collection(COLLECTIONS.EVENTS)
      .doc(eventId)
      .update({ ...eventData });

    GenericSuccessUpdate();
  } catch (e) {
    // UnableToUpdate;
    console.log(e);
  }
};

/**
 * Delete Event by event id
 * @param eventId
 * @returns {Promise<{}|firebase.functions.HttpsCallableResult>}
 */
const deleteEventByEventId = async (eventId) => {
  try {
    await firestore.collection(COLLECTIONS.EVENTS).doc(eventId).delete();
  } catch (e) {
    // UnableToUpdate;
    console.log(e);
  }
};

/**
 * Delete bulk users
 * @param usersData
 * @returns {Promise<{}|firebase.functions.HttpsCallableResult>}
 */
const deactivateUsersByEventId = async (eventId) => {
  try {
    const usersData = await firestore
      .collection(COLLECTIONS.USERS)
      .where('eventId', '==', eventId)
      .get();
    let batch = firestore.batch();

    if (usersData.length) {
      usersData.forEach((user) => {
        const ref = firestore.collection('Users').doc(user.uid);
        batch.update(ref, {
          isActive: false,
        });
      });

      await batch.commit();
    }
  } catch (e) {
    console.log(e);
    GenericError();
  }
};

/**
 * Create Survey
 * @param surveyData { name, eventId, enterpriseId, role  }
 * @returns {Promise<{}|firebase.functions.HttpsCallableResult>}
 */
const createSurvey = async (surveyData) => {
  try {
    const uid = firestore.collection('tmp').doc().id;
    await firestore
      .collection('Survey')
      .doc(uid)
      .set({
        ...surveyData,
        isActive: true,
      });
    CreatedSuccessFully();
    return uid;
  } catch (e) {
    console.log(e);
    GenericError();
  }
};

/**
 * Fetch All Surveys
 * @returns {Promise<{}|firebase.functions.HttpsCallableResult>}
 */
const fetchAllSurveys = async () => {
  try {
    const surveys = await firestore.collection('Survey').get();

    return transformFirebaseResponse(surveys);
  } catch (e) {
    console.log(e);
    GenericError();
  }
};

/**
 * Fetch Survey By id
 * @param surveyId
 * @returns {Promise<{}|firebase.functions.HttpsCallableResult>}
 */
const fetchSurveyById = async (surveyId) => {
  try {
    const survey = await firestore
      .collection(COLLECTIONS.SURVEY)
      .doc(surveyId)
      .get();

    return survey.data();
  } catch (e) {
    console.log(e);
    GenericError();
  }
};

/**
 * Update Survey
 * @param surveyData { questions  }
 * @returns {Promise<{}|firebase.functions.HttpsCallableResult>}
 */
const addQuestionsToSurvey = async (surveyId, questions) => {
  try {
    await firestore
      .collection('Survey')
      .doc(surveyId)
      .update({
        [`questions`]: [...questions],
      });
    GenericSuccessUpdate();
    return true;
  } catch (e) {
    console.log(e);
    // UnableToUpdate;
  }
};

/**
 * Add Survey asnwer
 * @param answerData { questions  }
 * @returns {Promise<{}|firebase.functions.HttpsCallableResult>}
 */
const addAnswersToSurveyAnswers = async (answerData) => {
  try {
    await firestore.collection('SurveyAnswers').add({ ...answerData });
    GenericSuccessUpdate();
    return true;
  } catch (e) {
    console.log(e);
    // UnableToUpdate;
  }
};

const fetchSurveyAnswerByUserId = async (userId) => {
  try {
    let query = firestore.collection('SurveyAnswers');
    query = query.where('answeredById', '==', userId);

    const answer = await query.get();
    return transformFirebaseResponse(answer);
  } catch (e) {
    console.log(e);
    // UnableToUpdate;
  }
};

/**
 * Fetch employees by Enterprise Id
 */
const fetchAllEmployeesByEnterpriseId = async ({ enterpriseId, eventId }) => {
  try {
    const employees = await firestore
      .collection(COLLECTIONS.USERS)
      .where('role', '==', USER_ROLES.EMPLOYEE)
      .where('enterpriseId', '==', enterpriseId)
      .where('eventId', '==', eventId)
      .get();

    return transformFirebaseResponse(employees);
  } catch (e) {
    // UnableToFetchData();
    return e;
  }
};

/**
 * Fetch effortLog by Team Id
 */
const fetchEffortLogByEnterpriseId = async ({ enterpriseId, eventId }) => {
  try {
    const logs = await firestore
      .collection(COLLECTIONS.EFFORT_LOG)
      .where('enterpriseId', '==', enterpriseId)
      .where('eventId', '==', eventId)
      .get();

    return transformFirebaseResponse(logs);
  } catch (e) {
    // UnableToFetchData();
    return e;
  }
};

/**
 * Fetch effortLog by Team Id
 */
const employeeEffortLogStatusByEnterpriseIdEventId = async ({
  enterpriseId,
  eventId,
}) => {
  try {
    const employees = await fetchAllEmployeesByEnterpriseId({
      enterpriseId,
      eventId,
    });
    if (employees && Array.isArray(employees) && employees.length) {
      const effortLogs = await fetchEffortLogByEnterpriseId({
        enterpriseId,
        eventId,
      });
      if (effortLogs && Array.isArray(effortLogs) && effortLogs.length) {
        return mergeArrayOfObjects(employees, effortLogs, 'id', 'userId');
      }
    }
    return [];
  } catch (e) {
    // UnableToFetchData();
    return e;
  }
};

const getLiveLogsAdmin = async (setLogs, enterpriseId = null) => {
  let query = firestore.collection(COLLECTIONS.LIVE_LOGS);
  if (enterpriseId) {
    query = query
      .orderBy('createdAt', 'desc')
      .where('enterpriseId', '==', enterpriseId)
      .limit(5)
      .onSnapshot((doc) => {
        setLogs(transformFirebaseResponse(doc));
      });
  } else {
    // eslint-disable-next-line
    query = query
      .orderBy('createdAt', 'desc')
      .limit(5)
      .onSnapshot((doc) => {
        setLogs(transformFirebaseResponse(doc));
      });
  }
};

const createEventAndAddPOCs = async ({
  name,
  description,
  startDate,
  estimatedCompletionDate,
  allEmails,
  enterpriseId,
  createdBy,
}) => {
  // console.log( name,
  //   description,
  //   startDate,
  //   estimatedCompletionDate,
  //   allEmails,
  //   enterpriseId,
  //   createdBy);
  let emails = JSON.parse(JSON.stringify(allEmails));
  let existingEmailUsers = transformFirebaseResponse(
    await firestore.collection('Users').where('email', 'in', allEmails).get()
  );
  emails = emails.filter(
    (f) => !Boolean(existingEmailUsers.find((e) => e.email === f))
  );
  let sendGridEmailsArr = [];
  let usersData = emails.map((el, idx) => {
    let tmp_password = generatePassword();

    sendGridEmailsArr[idx] = {
      to: el,
      from: 'noreply@work-load.com',
      subject: 'Added as a POC',
      text: `Hello, you have been added as a POC for workload collection in an organisation. Use the credentials below to login : \n Email: ${el} \n Password: ${tmp_password}`,
    };

    return {
      email: el,
      uid: firestore.collection('tmp').doc().id,
      name: el,
      isActive: true,
      createdBy,
      designation: 'POC',
      role: 'POC',
      level: '',
      qualification: '',
      isAuth: true,
      teamId: '',
      shift: '',
      password: tmp_password,
    };
  });

  try {
    const event = await firestore.collection(COLLECTIONS.EVENTS).add({
      isActive: true,
      status: 'INPROGRESS',
      name,
      description,
      startDate,
      estimatedCompletionDate,
      enterpriseId,
    });

    //add new users
    await registerAndCreateUsers({
      usersData,
      createdBy,
      eventId: event.id,
      enterpriseId,
    });

    //update existing users eventId
    const batch = firestore.batch();
    existingEmailUsers.forEach((user) => {
      const userRef = firestore.collection(COLLECTIONS.USERS).doc(user.uid);
      batch.update(userRef, {
        eventId: event.id,
      });
    });

    await batch.commit();
    const callable = firebaseFunctions.httpsCallable('sendBulkEmail');
    await callable(sendGridEmailsArr)
      .then((_res) => {
        console.log(_res);
      })
      .catch((err) => {
        console.error(err);
      });

    CreatedSuccessFully();
  } catch (e) {
    console.log(e);
    GenericError();
  }
};

export {
  deleteBulkUsersFromFirestoreByEventId,
  deleteBulkUsersFromAuth,
  createSurvey,
  addQuestionsToSurvey,
  fetchAllSurveys,
  deactivateUsersByEventId,
  fetchSurveyById,
  employeeEffortLogStatusByEnterpriseIdEventId,
  getLiveLogsAdmin,
  addAnswersToSurveyAnswers,
  fetchSurveyAnswerByUserId,
  createEventAndAddPOCs,
  fetchUsersByEventId,
  deleteEventByEventId,
  updateEventById,
  deleteAllActivitiesByEventId,
  deleteAllEffortLogsByEventId,
  deleteAllActivityEffortsByEventId,
};
