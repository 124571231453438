import { firebaseFunctions, firestore } from '../../firebase';
import {
  currentServerTime,
  GenericError,
  GenericSuccessUpdate,
  transformFirebaseResponse,
  UnableToCreate,
  UnableToFetchData,
  UnableToUpdate,
} from '../utils';
import { COLLECTIONS, USER_ROLES, USER_TYPE } from '../constants';
import { groupBy, isEmpty, mergeArrayOfObjects } from '../../utils/utils';
import { fetchTeamById, registerAndCreateUsers } from '../session';

/**
 * Fetch employees by Team Id
 */
const fetchAllEmployees = async ({ teamId }) => {
  try {
    const employees = await firestore
      .collection(COLLECTIONS.USERS)
      .where('role', '==', USER_ROLES.EMPLOYEE)
      .where('teamId', '==', teamId)
      .get();

    return transformFirebaseResponse(employees);
  } catch (e) {
    // UnableToFetchData();
    return e;
  }
};

/**
 * Fetch effortLog by Team Id
 */
const fetchEffortLog = async ({ teamId }) => {
  try {
    const logs = await firestore
      .collection(COLLECTIONS.EFFORT_LOG)
      .where('teamId', '==', teamId)
      .get();

    return transformFirebaseResponse(logs);
  } catch (e) {
    // UnableToFetchData();
    return e;
  }
};

/**
 * Fetch effortLog by Team Id
 */
const employeeEffortLogStatus = async ({ teamId }) => {
  try {
    if (teamId) {
      const employees = await fetchAllEmployees({ teamId });
      if (employees && Array.isArray(employees) && employees.length) {
        const effortLogs = await fetchEffortLog({ teamId });
        if (effortLogs && Array.isArray(effortLogs) && effortLogs.length) {
          return mergeArrayOfObjects(employees, effortLogs, 'id', 'userId');
        }
      }
      return [];
    }
  } catch (e) {
    // UnableToFetchData();
    return e;
  }
};

/**
 * Update Effort log
 * @returns {Promise<boolean>}
 * @param userId
 * @param effortLogIdArray
 * @param status
 * @param comment
 * @param employees
 */
const updateEffortLogBulk = async ({
  userId,
  effortLogIdArray = [],
  status,
  comment,
  employees = [],
}) => {
  try {
    const batch = firestore.batch();
    for (let effortLog of effortLogIdArray) {
      const effortLogRef = firestore
        .collection(COLLECTIONS.EFFORT_LOG)
        .doc(effortLog);

      batch.update(effortLogRef, {
        status,
        ...(comment ? { comment: comment } : {}),
        updatedTime: currentServerTime(),
        updatedBy: userId,
      });
    }
    await batch.commit();
    if (employees.length) {
      startWorkLoadCollection({ usersData: employees, isReject: true });
    }
    GenericSuccessUpdate();
    return true;
  } catch (e) {
    // UnableToUpdate;
    return e;
  }
};

/**
 * Start workload collection
 * @param usersData
 * @param isReject
 * @returns {Promise<{}|firebase.functions.HttpsCallableResult>}
 */
const startWorkLoadCollection = async ({ usersData, isReject = false }) => {
  try {
    const callable = firebaseFunctions.httpsCallable('startWorkLoadCollection');
    const result = await callable({ usersData, isReject });
    console.warn('Result', result);
  } catch (e) {
    // UnableToUpdate;
  }
};

/**
 * Fetch Activity Efforts
 * @returns {Promise<null|*>}
 * @param teamId
 */
const fetchActivityEffortsByTeamId = async (teamId) => {
  try {
    const activityEfforts = await firestore
      .collection(COLLECTIONS.ACTIVITY_EFFORT)
      .where('teamId', '==', teamId)
      .get();

    return transformFirebaseResponse(activityEfforts);
  } catch (e) {
    // UnableToFetchData();
    return null;
  }
};

/**
 * Fetch Activities
 * @returns {Promise<null|*>}
 * @param teamId
 */
const fetchActivitiesByTeamId = async (teamId) => {
  try {
    const activities = await firestore
      .collection(COLLECTIONS.ACTIVITY)
      .where('teamId', '==', teamId)
      .get();

    return transformFirebaseResponse(activities);
  } catch (e) {
    // UnableToFetchData();
    return null;
  }
};

/**
 * Get RAG DATA - Merged Activities
 * @param teamId
 * @returns {Promise<any[]|*>}
 */
const getRagData = async ({ teamId }) => {
  try {
    const activityEfforts = await fetchActivityEffortsByTeamId(teamId);
    if (activityEfforts.length) {
      const activities = await fetchActivitiesByTeamId(teamId);
      if (activities.length) {
        let activityCount = {};
        activityEfforts.forEach((item) => {
          if (activityCount[item.activityId]) {
            activityCount[item.activityId] += 1;
          } else {
            activityCount[item.activityId] = 1;
          }
        });
        return activities
          .map((item) => {
            return activityCount[item.id]
              ? {
                name: item.name,
                id: item.id,
                count: activityCount[item.id],
                ragValue: item.ragValue,
                comment: item.comment,
                approvalComment: item.approvalComment,
                ragStatus: item.ragStatus,
                userId: item.userId,
              }
              : null;
          })
          .filter((item) => item);
      }
    }
    return [];
  } catch (e) {
    return e;
  }
};

/**
 * Update RAG Value
 * @param activityIds
 * @param userId
 * @param ragValue
 * @param comment
 * @returns {Promise<*>}
 */
const updateRAGStatus = async ({
  activityIds = [],
  userId,
  ragValue = '',
  comment,
}) => {
  try {
    let batch = firestore.batch();

    activityIds.forEach((id) => {
      const ref = firestore.collection(COLLECTIONS.ACTIVITY).doc(id);
      batch.update(ref, {
        ragValue,
        ...(comment ? { comment } : { comment: '' }),
        updatedTime: currentServerTime(),
        updatedBy: userId,
      });
    });

    await batch.commit();
  } catch (e) {
    UnableToCreate(e);
    return e;
  }
};

/**
 * Fetch analytics Data - utilization
 */
const fetchUtilizationData = async ({ teamId, enterpriseId }) => {
  try {
    const callable = firebaseFunctions.httpsCallable('fetchUtilizationData');
    const result = await callable({ teamId, enterpriseId });
    if (result && result.data) {
      return result.data;
    }
    return [];
  } catch (e) {
    console.error('err', e);
    // UnableToFetchData();
  }
};

/**
 * Fetch activity analytics
 */

const fetchActivityAnalytics = async ({ teamId }) => {
  try {
    const activityEfforts = await fetchActivityEffortsByTeamId(teamId);
    if (activityEfforts.length) {
      let activityArr = [];
      const groupedActivityEfforts = groupBy(activityEfforts, 'activityId');
      Object.keys(groupedActivityEfforts).forEach((item) => {
        const activityItem = groupedActivityEfforts[item];
        const totalHours = activityItem.reduce(
          (total, effort) => total + effort.totalHours,
          0
        );

        const activityDetails = groupedActivityEfforts[item][0];

        activityArr = [
          ...activityArr,
          {
            activityId: activityDetails.activityId,
            activityName: activityDetails.activityName,
            process: activityDetails.process,
            subProcess: activityDetails.subProcess,
            totalHours,
          },
        ];
      });
      return activityArr;
    }
    return [];
  } catch (e) {
    // UnableToFetchData();
  }
};

const getRagCompletionStatus = async ({ teamId }) => {
  try {
    if (teamId) {
      const ragData = await getRagData({ teamId });

      const assignedRagCount = ragData.filter((item) => item.ragValue).length;

      const totalRags = ragData.length;

      const assignRagPercentage = (assignedRagCount / totalRags) * 100;

      return { assignRagPercentage };
    }
  } catch (e) {
    return {};
  }
};

/**
 * Get RAG stats - Analytics page
 * @param teamId
 * @param enterpriseId
 * @returns {Promise<{}|firebase.functions.HttpsCallableResult>}
 */
const getRAGStats = async ({ teamId, enterpriseId }) => {
  try {
    const callable = firebaseFunctions.httpsCallable('fetchUtilizationStats');
    const res = await callable({ teamId, enterpriseId });
    if (res && res.data) {
      return res.data;
    }
    return res;
  } catch (e) {
    return {};
  }
};

const uploadEmployees = async (usersData, subTeams, user) => {
  try {
    if (subTeams) {
      const subTeamsData = await getSubTeamData(user.teamId);

      subTeams = subTeams.filter((item) => !subTeamsData[item]);

      let updatedSubTeams = subTeamsData;

      if (subTeams.length) {
        await createSubTeams(subTeams, user.teamId);
        updatedSubTeams = await getSubTeamData(user.teamId);
      }

      usersData = usersData.map((item) =>
        item.type === USER_TYPE.IDENTICAL
          ? {
            ...item,
            subTeamId: updatedSubTeams[item.subTeamName],
          }
          : item
      );
    }

    usersData = usersData.map((e) => ({ ...e, isActive: true }));

    await registerAndCreateUsers({
      usersData,
      createdBy: user.uid,
      eventId: user.eventId,
      enterpriseId: user.enterpriseId,
    });
  } catch (e) {
    console.warn(e);
    GenericError();
  }
};

const getUsersByEmails = async (userEmailArr) => {
  try {

    const batchList = [];
    while (userEmailArr.length) {
      batchList.push(userEmailArr.splice(0, 10));
    }

    const existingUsers = [];
    for (let i = 0; i < batchList.length; i++) {
      let usersQuery = await firestore.collection(COLLECTIONS.USERS);
      usersQuery = await usersQuery.where('email', 'in', batchList[i]).get();
      const transformedUsers = await transformFirebaseResponse(usersQuery);
      existingUsers.push(transformedUsers)
    }

    return existingUsers.flat();
  } catch (e) {
    console.log(e);
  }
};

const getSubTeamData = async (teamId) => {
  try {
    const teamData = await fetchTeamById(teamId);

    let subTeamsData =
      teamData.subTeams && !isEmpty(teamData.subTeams)
        ? Object.values(teamData.subTeams)
        : [];

    let updatedSubTeamsData = {};

    subTeamsData.forEach((item) => {
      updatedSubTeamsData[item.name] = item.id;
    });

    return updatedSubTeamsData;
  } catch (e) { }
};

const createSubTeams = async (subTeams, teamId) => {
  try {
    let batch = firestore.batch();
    const ref = firestore.collection(COLLECTIONS.TEAMS).doc(teamId);
    subTeams.forEach((item) => {
      const subTeamId = firestore.collection('tmp').doc().id;
      batch.update(ref, {
        [`subTeams.${subTeamId}`]: {
          name: item,
          id: subTeamId,
          createdTime: currentServerTime(),
        },
      });
    });
    await batch.commit();
  } catch (e) { }
};

const insertLiveLogByBulk = async ({
  selectedLogs,
  type,
  createdBy,
  createdByName,
  enterpriseId,
  eventId,
  comment = '',
  typeOfObject,
}) => {
  try {
    let batch = firestore.batch();
    let userData = selectedLogs.map((user) => {
      return {
        createdAt: currentServerTime(),
        type,
        createdBy,
        createdByName,
        createdFor: user.uid,
        createdForName: user.name,
        enterpriseId,
        eventId,
        comment,
        typeOfObject,
      };
    });

    userData.forEach((item) => {
      let ref = firestore.collection(COLLECTIONS.LIVE_LOGS).doc();
      batch.set(ref, item);
    });
    await batch.commit();

    GenericSuccessUpdate();
    return true;
  } catch (e) {
    // UnableToUpdate;
    return e;
  }
};

const createTeamAndAddToManager = async ({
  eventId,
  managerId,
  managerName,
  managerEmail,
  name,
}) => {
  try {
    const data = await firestore.collection('Teams').add({
      eventId,
      managerId,
      managerName,
      managerEmail,
      name,
    });

    if (data) {
      await firestore.collection('Users').doc(managerId).update({
        teamId: data.id,
      });
    }
  } catch (e) {
    console.log(e);
  }
};

const getActivityEffortHoursByEffortLogIds = async (selectedIds) => {
  try {
    let activityEffortsQuery = firestore.collection(
      COLLECTIONS.ACTIVITY_EFFORT
    );
    activityEffortsQuery = activityEffortsQuery.where(
      'effortLogId',
      'in',
      selectedIds
    );

    let activityEfforts = transformFirebaseResponse(
      await activityEffortsQuery.get()
    );

    return activityEfforts.reduce((a, v) => a + v.totalHours, 0);
  } catch (e) {
    console.log(e);
  }
};

export {
  employeeEffortLogStatus,
  updateEffortLogBulk,
  fetchAllEmployees,
  startWorkLoadCollection,
  getRagData,
  updateRAGStatus,
  fetchUtilizationData,
  fetchActivityAnalytics,
  getRagCompletionStatus,
  getRAGStats,
  uploadEmployees,
  insertLiveLogByBulk,
  createTeamAndAddToManager,
  getActivityEffortHoursByEffortLogIds,
  getUsersByEmails,
};
