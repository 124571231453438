import React, { useContext } from 'react';
import { Redirect, Route } from 'react-router-dom';
import Loader from '../components/Loader';
import { AuthContext } from '../components/AuthContextProvider';

const PrivateRoute = (props) => {
  const { user } = useContext(AuthContext);
  const { component: Component, role, ...rest } = props;

  if (user === null) return <Loader />;

  return (
    <Route
      {...rest}
      render={({ location }) =>
        user?.role === role ? (
          <Component />
        ) : (
          <>
            <Redirect
              to={{
                pathname: '/',
                state: { from: location },
              }}
            />
          </>
        )
      }
    />
  );
};

export default PrivateRoute;
