const COLLECTIONS = {
  ENTERPRISE: 'Enterprise',
  USERS: 'Users',
  TEAMS: 'Teams',
  ROLES: 'Roles',
  EVENTS: 'Events',
  PROCESS: 'Process',
  ACTIVITY: 'Activity',
  ACTIVITY_EFFORT: 'ActivityEffort',
  EFFORT_LOG: 'EffortLog',
  EVENT_LOG: 'EventLog',
  SURVEY: 'Survey',
  LIVE_LOGS: 'LiveLogs',
};

const EFFORT_LOG_STATUS = {
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED',
  PENDING: 'PENDING',
  IN_PROGRESS: 'IN_PROGRESS',
  SUBMITTED: 'SUBMITTED',
  NEW: 'New',
};

const USER_TYPE = {
  IDENTICAL: 'IDENTICAL',
  INDIVIDUAL: 'INDIVIDUAL',
};

const USER_ROLES = {
  EMPLOYEE: 'EMPLOYEE',
  MANAGER: 'MANAGER',
  POC: 'POC',
  ADMIN: 'ADMIN',
};

const EMPLOYEE_KEYS = {
  'Business Unit': 'businessUnit',
  Branch: 'branch',
  Category: 'category',
  Center: 'center',
  DIRECT_INDIRECT: 'directIndirect',
  DOB: 'dob',
  DOJ: 'doj',
  Department: 'department',
  Designation: 'designation',
  'Employee Name': 'name',
  'Employee code': 'employeeCode',
  Gender: 'gender',
  Grade: 'grade',
  'HOD Name': 'hodName',
  'HOD Title': 'hodTitle',
  'Head of Unit Name': 'headOfUnitName',
  'Head of Unit Title': 'headOfUnitTitle',
  'Identical/Individual Roles': 'type',
  Level: 'level',
  Position: 'position',
  Qualification: 'qualification',
  'Reporting Manager': 'reportingManager',
  'Reporting Manager Code': 'reportingManagerCode',
  Shift: 'shift',
  'Sub Team': 'subTeamName',
  Email: 'email',
  'Unit Name': 'unitName',
  Location: 'location',
  'Job Title': 'jobTitle',
  'Job Band': 'jobBand',
  Role: 'role',
  'Role Description': 'roleDescription',
  'Team Leader Name': 'teamLeaderName',
  'Team Leader Employee Code': 'teamLeaderEmployeeCode',
};

const MANAGER_KEYS = {
  'Business Unit': 'businessUnit',
  Branch: 'branch',
  Category: 'category',
  Center: 'center',
  DIRECT_INDIRECT: 'directIndirect',
  DOB: 'dob',
  DOJ: 'doj',
  Department: 'department',
  Designation: 'designation',
  'Employee Name': 'name',
  'Employee code': 'employeeCode',
  'Incumbent Name': 'incumbentName',
  Gender: 'gender',
  Grade: 'grade',
  'HOD Name': 'hodName',
  'HOD Title': 'hodTitle',
  'Head of Unit Name': 'headOfUnitName',
  'Head of Unit Title': 'headOfUnitTitle',
  HOM: 'hom',
  Level: 'level',
  Position: 'position',
  Qualification: 'qualification',
  'Job Title': 'jobTitle',
  Shift: 'shift',
  Email: 'email',
  'Unit Name': 'unitName',
  Location: 'location',
  'Job Band': 'jobBand',
  Role: 'role',
  Team: 'team',
};

export {
  COLLECTIONS,
  EFFORT_LOG_STATUS,
  USER_TYPE,
  USER_ROLES,
  EMPLOYEE_KEYS,
  MANAGER_KEYS,
};
