import {
  Button,
  Card,
  CircularProgress,
  Grid,
  makeStyles,
  Typography,
} from '@material-ui/core';
import React, { useContext, useEffect, useState } from 'react';
import SystemUpdateAltIcon from '@material-ui/icons/SystemUpdateAlt';
import { colors } from '../../../../theme/colors';
import TemplateUploader from '../../components/TemplateUploader';
import Message from '../../../../components/Message';
import { AuthContext } from '../../../../components/AuthContextProvider';
import { storage } from '../../../../firebase';
import {
  addFileToEnterprise,
  fetchEnterpriseFiles,
} from '../../../../operations/enterprise';
import { fileUpload } from '../../../../helper/backendHelper/fileupload';
import { updateEnterprise } from '../../../../helper/backendHelper/enterprise';
import { useHistory } from 'react-router-dom';
import { Paths } from '../../../../routes/paths';

const useStyles = makeStyles((theme) => ({
  card: {
    border: `solid 1px ${colors.primary.main}`,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
}));

const UploadTemplates = ({ id, enterpriseData, setLoading }) => {
  const classes = useStyles();
  const [files, setFiles] = useState([]);
  const history = useHistory();

  useEffect(() => {
    if (enterpriseData?.enterpriseId?.templates)
      setFiles(enterpriseData?.enterpriseId?.templates);
  }, []);

  //* handle file change when uploading the file
  const handleFileChange = async (e) => {
    let file = e.target.files[0];

    // if (file?.size < 2200000) {
    //   return Message.warn('Some files have size greater than 2Mb');
    // }
    try {
      setLoading(true);
      let formData = new FormData();
      formData.append('file', e.target.files[0]);
      let response = await fileUpload(formData);
      response = response?.data?.payload;
      response['documents'] = response.url;
      response['fileName'] = file?.name;
      setFiles([...files, response]);
      setLoading(false);
    } catch (error) {
      Message.error(error?.response?.data?.message);
    }
  };

  //* handle template name
  const handleTemplateNameChange = (value, file) => {
    let newArr = [...files].map((el, idx) => {
      if (el.fileName === file) {
        return {
          ...el,
          name: value,
        };
      } else return el;
    });

    setFiles(newArr);
  };

  //* handle file upload on onsubmit
  const handleUpload = async () => {
    setLoading(true);
    const data = files.map((item) => {
      return (item = { name: item.name, documents: item.url });
    });
    try {
      const response = await updateEnterprise(id, { templates: data });
      Message.success(response.message);
      history.push(Paths.pocManagers);
    } catch (error) {
      Message.error(error?.response?.data?.message);
    }
  };

  const onDelete = (id) => {
    setFiles(files.filter((el, i) => el._id !== id));
  };

  return (
    <div>
      <Grid
        container
        justifyContent="center"
        alignItems="center"
        style={{ width: '100%' }}>
        <label htmlFor="contained-button-file">
          <Card
            classes={{ root: classes.card }}
            style={{ padding: 64, borderRadius: 16 }}
            variant="outlined">
            <Grid
              style={{ width: '100%' }}
              container
              justifyContent="center"
              alignItems="center">
              <SystemUpdateAltIcon
                style={{ transform: 'rotate(180deg)', marginRight: 16 }}
              />
              <Typography variant="h6">
                Upload your company details here
              </Typography>
            </Grid>
            <div style={{ marginTop: 32 }}>
              <Typography
                style={{ color: colors.grey[200] }}
                variant="subtitle2">
                Please upload on in the following file formats
              </Typography>
              <Typography
                style={{ color: colors.grey[200] }}
                variant="subtitle2">
                .xls, .png, .jpg, .jpeg, .pdf and file size maximum of 2MB
              </Typography>
            </div>
          </Card>
        </label>

        <input
          accept="image/*,.pdf,.xls,.xlsx"
          style={{ display: 'none' }}
          id="contained-button-file"
          onChange={handleFileChange}
          type="file"
          multiple
        />
      </Grid>

      <div style={{}}></div>

      {/* {uploadedFiles.map((el, idx) => (
        <TemplateUploader
          key={idx + el.name}
          fileObj={{ file: { name: el.name }, templateName: el.templateName }}
          progress={0}
          isStatic
          fileId={el.id}
          enterpriseId={user?.enterpriseId}
          fetchUploadedFiles={fetchUploadedFiles}
        />
      ))} */}

      {files.map(
        (el, idx) => (
          (
            <TemplateUploader
              // key={idx + el.file.name}
              fileObj={el}
              image={el.documents}
              key={idx}
              index={idx}
              // templateName={el.templateName ? el.templateName : el.file.name}
              // extension={el.file.name.split('.')[1]}
              onDelete={onDelete}
              progress={el.progress}
              handleTemplateNameChange={handleTemplateNameChange}
            />
          )
        )
      )}

      {files.length > 0 && (
        <Grid className="mt-5" container justifyContent="flex-end">
          {/* {loading ? (
            <CircularProgress color="primary" />
          ) : ( */}
          <Button
            onClick={() => {
              handleUpload();
            }}
            disableElevation
            color="primary"
            variant="contained">
            Submit Files
          </Button>
          {/* )} */}
        </Grid>
      )}
    </div>
  );
};

export default UploadTemplates;
