import React, { useEffect, useState } from 'react';
import Grid from '@material-ui/core/Grid';

import {
  Button,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { INDUSTRY_VALUES, isPositiveWholeNumber } from '../../../utils/utils';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '80%',
  },
}));

const CompanyDetails = ({ handleCompanyDetailsUpdates, data = null }) => {
  const [values, setValues] = useState({});
  const [isDirty, setIsDirty] = useState(false);
  const styles = useStyles();

  useEffect(() => {
    if (data) {
      setValues({ ...data });
    }
  }, [data]);

  const handleChangeValue = (name, value, shift = '') => {
    if (value !== values[name]) {
      setIsDirty(true);
    }

    if (shift) {
      setValues({
        ...values,
        [shift]: {
          ...(values[shift] || {}),
          [name]: value,
        },
      });
    } else {
      setValues({ ...values, [name]: value });
    }
  };
  return (
    <Grid container className="pl-2 pr-2 mt-3">
      <Grid container item md={4} direction="column">
        <Typography variant="subtitle2" color="textPrimary">
          Company Details
        </Typography>
        <Grid className="mt-3">
          <Grid>
            <label className="text-xs">Name</label>
            <br />
            <TextField
              className={styles.textField}
              placeholder="Name"
              onChange={(evt) => {
                handleChangeValue('name', evt.target.value);
              }}
              value={values.name}
            />
          </Grid>
          <Grid className="mt-3">
            <label className="text-xs">Year of Incorporation</label>
            <br />
            <TextField
              className={styles.textField}
              placeholder="Year of Incorporation"
              onChange={(evt) => {
                if (
                  isPositiveWholeNumber(evt.target.value) ||
                  evt.target.value === ''
                ) {
                  handleChangeValue('yearOfIncorporation', evt.target.value);
                }
              }}
              value={values.yearOfIncorporation}
            />
          </Grid>
          <Grid className="mt-3">
            <br />
            <InputLabel id="demo-simple-select-label">Industry</InputLabel>
            <Select
              className={styles.textField}
              placeholder="Industry"
              onChange={(evt) => {
                handleChangeValue('industry', evt.target.value);
              }}
              value={values.industry}>
              <MenuItem value={INDUSTRY_VALUES.IT}>
                {INDUSTRY_VALUES.IT}
              </MenuItem>
              <MenuItem value={INDUSTRY_VALUES.LOGISTIC}>
                {INDUSTRY_VALUES.LOGISTIC}
              </MenuItem>
              <MenuItem value={INDUSTRY_VALUES.MANUFACTURING}>
                {INDUSTRY_VALUES.MANUFACTURING}
              </MenuItem>
            </Select>
          </Grid>
          <Grid className="mt-3">
            <label className="text-xs">Location</label>
            <br />
            <TextField
              className={styles.textField}
              placeholder="Location"
              onChange={(evt) => {
                handleChangeValue('location', evt.target.value);
              }}
              value={values?.location}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item md={4} direction="column">
        <Typography variant="subtitle2" color="textPrimary">
          Financial Information
        </Typography>
        <Grid className="mt-3">
          <Grid>
            <label className="text-xs">Annual Revenue (in INR Crores)</label>
            <br />
            <TextField
              className={styles.textField}
              placeholder="Annual Revenue"
              type="number"
              onChange={(evt) => {
                handleChangeValue('annualRevenue', evt.target.value);
              }}
              value={values.annualRevenue}
            />
          </Grid>
          <Grid className="mt-3">
            <label className="text-xs">
              Total Employee Cost (in INR Crores)
            </label>
            <br />
            <TextField
              className={styles.textField}
              placeholder="Total Employee Cost"
              type="number"
              onChange={(evt) => {
                handleChangeValue('totalEmployeeCost', evt.target.value);
              }}
              value={values?.totalEmployeeCost}
            />
          </Grid>
          <Grid className="mt-3">
            <label className="text-xs">
              Employee Cost Divided By Annual Revenue (represented as percentage
              value)
            </label>
            <br />
            <TextField
              className={styles.textField}
              type="number"
              placeholder="Employee Cost % Annual Revenue"
              onChange={(evt) => {
                handleChangeValue(
                  'employeeCostAnnualRevenue',
                  evt.target.value
                );
              }}
              value={values?.employeeCostAnnualRevenue}
            />
          </Grid>
        </Grid>
      </Grid>
      <Grid container item md={4} direction="column">
        <Typography variant="subtitle2" color="textPrimary">
          Employee Summary
        </Typography>
        <Grid className="mt-3">
          <Grid>
            <label className="text-xs">Total White Collared Employees</label>
            <br />
            <TextField
              className={styles.textField}
              placeholder="Total White Collared Employees"
              onChange={(evt) => {
                if (
                  isPositiveWholeNumber(evt.target.value) ||
                  evt.target.value === ''
                ) {
                  handleChangeValue(
                    'whiteCollaredEmployeeCount',
                    evt.target.value
                  );
                }
              }}
              value={values?.whiteCollaredEmployeeCount}
            />
          </Grid>
          <Grid className="mt-3">
            <label className="text-xs">Total Blue Collared Employees</label>
            <br />
            <TextField
              className={styles.textField}
              placeholder="Total Blue Collared Employees"
              onChange={(evt) => {
                if (
                  isPositiveWholeNumber(evt.target.value) ||
                  evt.target.value === ''
                ) {
                  handleChangeValue(
                    'blueCollaredEmployeeCount',
                    evt.target.value
                  );
                }
              }}
              value={values.blueCollaredEmployeeCount}
            />
          </Grid>
        </Grid>
      </Grid>

      <Grid container item justifyContent="flex-end" className="mt-5 mr-5">
        <Button
          variant="contained"
          disableElevation
          disabled={!isDirty}
          onClick={() => handleCompanyDetailsUpdates(values)}
          color="primary">
          Submit
        </Button>
      </Grid>
    </Grid>
  );
};

export default CompanyDetails;
