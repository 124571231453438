import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from '@material-ui/core';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory } from 'react-router';
import Layout from '../../../components/Layout';
import SecondaryLayout from '../../../components/SecondaryLayout';
import TextWithDivider from '../../../components/TextWithDivider';
import useFetch from '../../../hooks/useFetch';
import { fetchAllSurveys } from '../../../operations/admin';
import { Paths } from '../../../routes/paths';
import SurveyCard from '../components/SurveyCard';
import { EventContext } from '../../../components/EventContextProvider';

const SurveyList = () => {
  const [companyName, setCompanyName] = useState('');
  const [enterprises, setEnterprises] = useState([]);
  const { events } = useContext(EventContext);
  const history = useHistory();
  const [loading, setLoading] = useState(false);

  const { data: surveys = [], loading: surveyLoading = false } =
    useFetch(fetchAllSurveys);

  const handleCompanyChange = (value) => {
    setCompanyName(value);
  };

  return (
    <Layout loading={loading}>
      <SecondaryLayout>
        <div className="mx-5">
          <Grid container justifyContent="space-between" className="p-4">
            <Grid>
              <TextWithDivider>
                <Typography variant="h3" color="textPrimary">
                  Surveys
                </Typography>
              </TextWithDivider>
            </Grid>
            <Button
              onClick={() => {
                history.push(Paths.adminCreateSurvey + '/new');
              }}
              disableElevation
              startIcon={<AddCircleOutline />}
              color="primary"
              variant="contained">
              Create Survey
            </Button>
          </Grid>

          <FormControl className="ml-4" style={{ width: '300px' }}>
            <InputLabel id="selectEnterprise">Select Events</InputLabel>
            <Select
              labelId="selectEnterprise"
              value={companyName}
              placeholder="Select Events"
              onChange={(evt) => handleCompanyChange(evt.target.value)}>
              <MenuItem value={''}> All</MenuItem>
              {events.map((item) => (
                <MenuItem value={item._id} key={item.name}>
                  {item.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>

          {surveys
            .filter((item) =>
              companyName === '' ? true : item.enterpriseId === companyName
            )
            .map((el, idx) => (
              <SurveyCard
                id={el.id}
                eventName={el.eventName}
                name={el.name}
                createdAt={el?.createdAt?.toDate()?.toLocaleDateString()}
                key={idx}
              />
            ))}
        </div>
      </SecondaryLayout>
    </Layout>
  );
};

export default SurveyList;
