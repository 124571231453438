import React from 'react';
import Appbar from '../Appbar';
import Grid from '@material-ui/core/Grid';
import LinearLoader from '../Loader/LinearProgress';

const Layout = ({ children, loading }) => {
  return (
    <React.Fragment>
      <Appbar />
      {loading ? <LinearLoader /> : null}
      <Grid style={{ marginTop: '65px', height: 'calc(100vh - 65px)' }}>{children}</Grid>
    </React.Fragment>
  );
};

export default Layout;
