import React, { useEffect, useState } from 'react';
import {
  Button,
  ButtonGroup,
  Checkbox,
  IconButton,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Tooltip,
  Typography,
} from '@material-ui/core';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Grid from '@material-ui/core/Grid';
import CheckOutlinedIcon from '@material-ui/icons/CheckOutlined';
import ClearOutlinedIcon from '@material-ui/icons/ClearOutlined';
import ChatOutlinedIcon from '@material-ui/icons/ChatOutlined';
import { RAG_VALUE, STATUS_TEXT } from '../../../../utils/utils';
import PlaceholderImage from '../../../../components/PlaceholderImage';
import Board from './../../../../assets/images/board.svg';
import StatusBadge from '../../../../components/Badge';
import { EmailOutlined } from '@material-ui/icons';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

const useStyles = makeStyles((theme) => ({
  table: {
    maxHeight: '50vh',
    width: '100%',
  },
  tableLong: {
    maxHeight: '70vh',
    width: '100%',
  },
  inlineIconButtons: {
    padding: '4px',
  },
  groupedButtons: {
    padding: '4px',
    fontWeight: '300',
    textTransform: 'uppercase',
    width: '60px',
  },
  disabledButton: {
    backgroundColor: theme.palette.primary.main,
  },
  placeHolderContainer: {
    maxHeight: 300,
    padding: 50,
  },
}));

const TableWrapper = ({
  id,
  primaryButtonText,
  secondaryButtonText,
  handlePrimaryButtonClick = () => null,
  handleSecondaryButtonClick = () => null,
  handleRowClick = () => null,
  isActionable = () => null,
  tableHeads = [],
  data = [],
  dataKeys = [],
  checkId = '',
  resetSelected = false,
  isSelectable = true,
  variant = 'table',
  disabled = false,
  otherActions = false,
  resendCredentialsAction = () => null,
  editAction = () => null,
  deleteAction = () => null,
}) => {
  const classes = useStyles();
  const [selected, setSelected] = useState([]);

  const handleCheck = (id) => {
    const index = selected.indexOf(id);

    let updated = selected;
    if (index > -1) {
      updated.splice(index, 1);
    } else {
      updated = [...selected, id];
    }

    setSelected([...updated]);
  };

  const handleCheckAll = () => {
    let updated;
    const actionableData = data.filter((item) => isActionable(item));
    if (selected.length !== actionableData.length) {
      updated = actionableData.map((item) => item[checkId]);
    } else {
      updated = [];
    }
    setSelected([...updated]);
  };

  useEffect(() => {
    if (resetSelected) {
      setSelected([]);
    }
  }, [resetSelected]);

  const isActiveRAGButton = (btnVal, ragVal) => {
    return btnVal === ragVal;
  };

  const getActionableLength = () => {
    const updatedData = data ? data.filter((item) => isActionable(item)) : [];
    return updatedData.length;
  };

  return (
    <React.Fragment>
      <Paper className={classes.paper}>
        <Grid
          container
          item
          justifyContent="space-between"
          alignItems="baseline"
          className="p-2">
          <Typography variant="body1">Total Items: {data.length}</Typography>
          <Grid>
            {primaryButtonText && (
              <Button
                variant="contained"
                size="small"
                disableElevation
                disabled={!selected || !selected.length || disabled}
                onClick={async () => {
                  await handlePrimaryButtonClick(selected);
                  setSelected([]);
                }}
                color="primary">
                {primaryButtonText}
              </Button>
            )}
            {secondaryButtonText && (
              <Button
                className="ml-3"
                variant="outlined"
                disableElevation
                color="secondary"
                size="small"
                // disabled={!selected || !selected.length || disabled}
                onClick={handleSecondaryButtonClick}>
                {secondaryButtonText}
              </Button>
            )}
            <RAGActions
              id={id}
              classes={classes}
              handleButtonClick={(ragValue) => {
                handlePrimaryButtonClick(selected, ragValue);
              }}
              row={{}}
              isActiveRAGButton={() => null}
              disabled={selected.length < 2}
            />
          </Grid>
        </Grid>
        <TableContainer
          classes={{
            root: classes[variant],
          }}>
          <Table
            stickyHeader
            aria-labelledby="tableTitle"
            size="medium"
            aria-label={id}>
            <TableHead>
              <TableRow>
                {isSelectable && (
                  <TableCell padding="checkbox">
                    <Checkbox
                      checked={
                        !!(
                          getActionableLength() &&
                          selected.length === getActionableLength()
                        )
                      }
                      onChange={() => handleCheckAll()}
                      inputProps={{ 'aria-label': 'select all' }}
                    />
                  </TableCell>
                )}
                {tableHeads.map((headCell, index) => (
                  <TableCell
                    key={index}
                    align={headCell.align ? headCell.align : 'left'}>
                    {headCell.label}
                  </TableCell>
                ))}
                {otherActions && (
                  <TableCell align="center">{'Action'}</TableCell>
                )}
              </TableRow>
            </TableHead>
            {data.length ? (
              <TableBody>
                {data.map((row, index) => {
                  row['startDate'] = new Date(
                    row.startDate
                  ).toLocaleDateString();
                  row['endDate'] = new Date(row.endDate).toLocaleDateString();
                  const isItemSelected = selected.includes(row[checkId]);

                  const isActionableItem = isActionable(row);

                  return (
                    <TableRow
                      hover
                      aria-checked={isItemSelected}
                      tabIndex={-1}
                      key={index}
                      onClick={() => handleRowClick(row.id)}
                      selected={isItemSelected}>
                      {isSelectable && (
                        <TableCell padding="checkbox">
                          <Checkbox
                            disabled={!isActionableItem}
                            checked={isItemSelected}
                            onClick={(event) => handleCheck(row[checkId])}
                          />
                        </TableCell>
                      )}

                      {dataKeys.map((item, index) => {
                        if (typeof row[item.id] === 'boolean') {
                          row[item.id] = row[item.id] ? 'Yes' : 'No';
                        }

                        return (
                          <TableCell
                            key={index}
                            align={
                              item.align
                                ? item.align
                                : typeof row[item.id] !== 'string'
                                ? 'center'
                                : 'left'
                            }>
                            {item?.id.toLowerCase().includes('status') ? (
                              <StatusBadge row={row[item.id]} />
                            ) : item.id === 'email' ||
                              typeof row[item.id] !== 'string' ? (
                              row[item.id]
                            ) : (
                              row[item.id]?.toSentenceCase() || '-'
                            )}
                          </TableCell>
                        );
                      })}
                      {['EmployeeEffortLogStatus'].includes(id) ? (
                        <TableCell key={`actions-${id}`} align="center">
                          {isActionableItem ? (
                            <Grid>
                              <IconButton
                                classes={{
                                  root: classes.inlineIconButtons,
                                }}
                                aria-label="approve"
                                onClick={() =>
                                  handlePrimaryButtonClick([row.id])
                                }>
                                <CheckOutlinedIcon
                                  fontSize="small"
                                  color="primary"
                                />
                              </IconButton>
                              <IconButton
                                className="ml-1"
                                aria-label="reject"
                                classes={{
                                  root: classes.inlineIconButtons,
                                }}
                                onClick={() =>
                                  handleSecondaryButtonClick([row.id])
                                }>
                                <ClearOutlinedIcon
                                  color="primary"
                                  fontSize="small"
                                />
                              </IconButton>
                            </Grid>
                          ) : row.comment ? (
                            <Tooltip title={row.comment}>
                              <ChatOutlinedIcon
                                fontSize="small"
                                color="primary"
                              />
                            </Tooltip>
                          ) : null}
                        </TableCell>
                      ) : null}

                      {['AssignRAGTable'].includes(id) ? (
                        <React.Fragment>
                          <TableCell>
                            {row.comment ? (
                              <Tooltip title={row.comment}>
                                <Typography variant="caption">
                                  {row.comment?.clipText(24)}
                                </Typography>
                              </Tooltip>
                            ) : null}
                          </TableCell>
                          <TableCell key={`actions-${id}`} align="center">
                            <RAGActions
                              row={row}
                              id={id}
                              classes={classes}
                              handleButtonClick={(ragValue) => {
                                handlePrimaryButtonClick([row.id], ragValue);
                              }}
                              isActiveRAGButton={isActiveRAGButton}
                            />
                          </TableCell>
                        </React.Fragment>
                      ) : null}

                      {otherActions && (
                        <TableCell>
                          <Grid container justifyContent="center">
                            <Grid>
                              <IconButton
                                onClick={() => {
                                  resendCredentialsAction(row._id);
                                }}
                                className="my-1"
                                color="primary"
                                aria-label="Resend Credentials">
                                <EmailOutlined />
                              </IconButton>
                            </Grid>
                            <Grid>
                              <IconButton
                                onClick={() => {
                                  editAction(row._id);
                                }}
                                className="my-1"
                                color="primary"
                                aria-label="Resend Credentials">
                                <EditIcon />
                              </IconButton>
                            </Grid>
                            <Grid>
                              <IconButton
                                onClick={() => {
                                  deleteAction(row._id);
                                }}
                                className="my-1"
                                style={{ color: '#bd0404' }} // Set custom color here
                                aria-label="Resend Credentials">
                                <DeleteIcon />
                              </IconButton>
                            </Grid>
                          </Grid> 
                        </TableCell>
                      )}
                    </TableRow>
                  );
                })}
              </TableBody>
            ) : null}
          </Table>
        </TableContainer>
      </Paper>
      {!data.length ? (
        <div className={classes.placeHolderContainer}>
          <PlaceholderImage
            image={Board}
            messsage="No records available"
            width="300px"
          />
        </div>
      ) : null}
    </React.Fragment>
  );
};

const RAGActions = ({
  row,
  id,
  classes,
  handleButtonClick,
  isActiveRAGButton,
  disabled = false,
}) => {
  return ['AssignRAGTable'].includes(id) ? (
    <React.Fragment>
      <ButtonGroup
        disabled={disabled}
        size="small"
        variant="contained"
        disableElevation
        aria-label="RAG button group">
        <Button
          variant="contained"
          size="small"
          disableElevation
          classes={{
            root: classes.groupedButtons,
          }}
          color={
            isActiveRAGButton(RAG_VALUE.RED, row.ragValue)
              ? 'primary'
              : 'default'
          }
          onClick={() =>
            isActiveRAGButton(RAG_VALUE.RED, row.ragValue)
              ? null
              : handleButtonClick(RAG_VALUE.RED)
          }>
          Red
        </Button>
        <Button
          variant="contained"
          disableElevation
          size="small"
          classes={{
            root: classes.groupedButtons,
          }}
          color={
            isActiveRAGButton(RAG_VALUE.AMBER, row.ragValue)
              ? 'primary'
              : 'default'
          }
          onClick={() =>
            isActiveRAGButton(RAG_VALUE.AMBER, row.ragValue)
              ? null
              : handleButtonClick(RAG_VALUE.AMBER)
          }>
          Amber
        </Button>
        <Button
          variant="contained"
          disableElevation
          size="small"
          classes={{
            root: classes.groupedButtons,
          }}
          color={
            isActiveRAGButton(RAG_VALUE.GREEN, row.ragValue)
              ? 'primary'
              : 'default'
          }
          onClick={() =>
            isActiveRAGButton(RAG_VALUE.GREEN, row.ragValue)
              ? null
              : handleButtonClick(RAG_VALUE.GREEN)
          }>
          Green
        </Button>
      </ButtonGroup>
    </React.Fragment>
  ) : null;
};

export default TableWrapper;
