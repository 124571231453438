import React, { useEffect, useState } from 'react';
import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Box, Divider, Tab, Tabs } from '@material-ui/core';
import { Radio, RadioGroup, FormControlLabel } from '@mui/material';
import { ExportToExcel } from '../ExportToExcel';

const useStyles = makeStyles((theme) => ({
  inactiveTab: {
    backgroundColor: theme.palette.grey[100],
  },
  activeTab: {
    backgroundColor: theme.palette.primary.main,
  },
}));

const CustomTabs = ({
  tabs = [],
  activeIndex = 0,
  csvFileName,
  excelReport = [],
  isCharts = false,
  setActionType,
  handleChangeTabWrapper = () => null,
  individualIdenticalWrap = false,
  setChartTableValue,
  event,
}) => {
  const [value, setValue] = useState(0);
  const classes = useStyles();
  const theme = useTheme();

  useEffect(() => {
    setValue(activeIndex);
  }, [activeIndex]);

  const handleChange = async (d, newValue) => {
    if (newValue === 1) {
      setActionType(individualIdenticalWrap ? 'identical' : 'activity');
      if (event) {
        handleChangeTabWrapper(
          event,
          individualIdenticalWrap ? 'identical' : 'activity'
        );
      }
    } else {
      setActionType(individualIdenticalWrap ? 'individual' : 'utilization');
      if (event) {
        handleChangeTabWrapper(
          event,
          individualIdenticalWrap ? 'individual' : 'utilization'
        );
      }
    }
    setValue(newValue);
  };

  const handleChartChange = (e) => {
    const value = e.target.value;

    setChartTableValue(value);
  };

  return (
    <>
      <Tabs
        value={value}
        onChange={handleChange}
        indicatorColor="primary"
        textColor="primary">
        {tabs.length &&
          tabs.map((tab, tabIndex) => (
            <Tab
              label={tab.label}
              id={`tab-${tabIndex}`}
              key={tabIndex}
              className={
                value === tabIndex ? classes.activeTab : classes.inactiveTab
              }
              color="secondary"
            />
          ))}
      </Tabs>
      {isCharts && (
        <div className="d-flex justify-content-between">
          <RadioGroup
            aria-labelledby="demo-controlled-radio-buttons-group"
            name="controlled-radio-buttons-group"
            className="flex-row mt-2"
            defaultValue={'plotting'}
            onChange={handleChartChange}>
            <FormControlLabel
              value="plotting"
              control={<Radio />}
              label="Plotting"
            />
            <FormControlLabel
              value="dataTable"
              control={<Radio />}
              label="Data table"
            />
          </RadioGroup>

          <ExportToExcel data={excelReport} fileName={csvFileName} />
        </div>
      )}
      <Divider variant={'fullWidth'} absolute style={{ bottom: 'auto' }} />
      {tabs.length &&
        tabs.map((tab, tabIndex) => (
          <TabPanel
            value={value}
            index={tabIndex}
            dir={theme.direction}
            key={tabIndex}>
            {tab.children}
          </TabPanel>
        ))}
    </>
  );
};

export default CustomTabs;

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`tabpanel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...other}>
      {value === index && <Box className="mt-3">{children}</Box>}
    </div>
  );
}
