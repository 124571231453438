import { createTheme } from '@material-ui/core/styles';
import { colors } from './colors';
import { typography } from './typography';

const defaultTheme = createTheme();

const customTheme = createTheme({
  palette: {
    ...colors,
  },
  typography: {
    ...typography,
  },
  shape: {
    borderRadius: 1,
  },

  // Component style overrides
  overrides: {
    MuiAppBar: {
      root: {
        paddingLeft: defaultTheme.spacing(5),
        paddingRight: defaultTheme.spacing(5),
        boxShadow: '0 0 15px 0 rgba(0, 0, 0, 0.1)',
      },
      colorPrimary: {
        backgroundColor: colors.common.white,
      },
    },
    MuiDivider: {
      root: {
        height: '2px',
        backgroundColor: '#f6f7fa',
      },
    },
    MuiButton: {
      root: {
        borderRadius: '4px',
        textTransform: 'none',
        fontSize: '1rem',
      },
      containedSecondary: {
        backgroundColor: defaultTheme.palette.common.white,
        color: defaultTheme.palette.primary.main,
        '&:hover': {
          backgroundColor: defaultTheme.palette.common.white,
        },
      },
    },
    MuiDialogActions: {
      root: {
        padding: '16px',
      },
    },
    MuiListItem: {
      root: {
        '&$selected': {
          backgroundColor: defaultTheme.palette.primary.main,
          color: defaultTheme.palette.common.white,
          fontWeight: 'bold',
          '&:hover': {
            backgroundColor: defaultTheme.palette.primary.main,
          },
        },
      },
    },
    MuiListItemText: {
      primary: {
        fontSize: '14px',
      },
    },
    MuiTableHead: {
      root: {
        backgroundColor: defaultTheme.palette.background.default,
      },
    },
    MuiTableCell: {
      head: {
        fontWeight: 'bold',
      },
      body: {
        borderBottom: 'none',
      },
      root: {
        padding: '12px 16px',
      },
    },
    MuiListSubheader: {
      root: {
        display: 'flex',
        justifyContent: 'space-between',
        fontSize: '1rem',
        fontWeight: 'bold',
        alignItems: 'baseline',
      },
    },
    MuiTab: {
      root: {
        minHeight: '44px',
        height: '44px',
        '@media (min-width:600px)': {
          minWidth: '120px',
          width: '120px',
        },
      },
      textColorPrimary: {
        '&$selected': {
          color: defaultTheme.palette.common.white,
        },
      },
      wrapper: {
        textTransform: 'none',
      },
    },
    MuiTabs: {
      root: {
        borderRadius: 8,
        maxWidth: 'fit-content',
        height: '44px',
        minHeight: '44px',
      },
    },
  },
});

export default customTheme;
