const BASE_URL = process.env.REACT_APP_API_URL || 'workload.com';
export const API_URL = `${BASE_URL}/api/v1`;

export const LOGIN = '/user';

export const FILE_UPLOAD = '/uploads';

export const ENTERPRISE = '/enterprise';

export const EVENTS = '/collection-event';

export const TEAM = '/team';

export const POC = '/poc';

export const MANAGER = '/manager';

export const EMPLOYEE = '/employee';

export const SHEET = '/sheet';

export const PROCESS = '/process';

export const SUB_PROCESS = '/sub-process';

export const ACTIVITY = '/activity';
