import { firestore, storage } from '../../firebase';
import Message from '../../components/Message';
import {
  CreatedSuccessFully,
  currentServerTime,
  UnableToCreate,
  UnableToDelete,
} from '../utils';
import { COLLECTIONS } from '../constants';

const downloadFile = (path, fileName) => {
  console.log('path', path);
  console.log('fileName', fileName);
  const link = document.createElement('a');
  link.style.display = 'none';

  // Set the href and download attributes for the anchor element
  link.href = path; // Replace with the actual file path
  link.download = fileName; // Replace with the desired file name
  console.log(link);

  // Add the anchor element to the document
  document.body.appendChild(link);

  // Trigger the click event on the anchor element
  link.click();

  // Remove the anchor element
  document.body.removeChild(link);
};

const uploadFile = async (folder, file, setProgress = () => { }) => {
  return new Promise((resolve, reject) => {
    const storageRef = storage.ref(`${folder}/` + file.name);
    storageRef.put(file).on(
      'state_changed',
      (snap) => {
        let percentage = (snap.bytesTransferred / snap.totalBytes) * 100;
        setProgress(percentage);
      },
      (err) => {
        console.log(err);
        reject(err);
      },
      async () => {
        const url = await storageRef.getDownloadURL();
        resolve(url);
      }
    );
  });
};

const deleteFile = async (path) => {
  try {
    const fileRef = storage.ref(path);
    await fileRef.delete();
    return true;
  } catch (e) {
    UnableToDelete();
    console.log(e);
  }
};

const addALiveLog = async (liveLogData) => {
  try {
    const data = await firestore.collection(COLLECTIONS.LIVE_LOGS).add({
      ...liveLogData,
      createdAt: currentServerTime(),
    });
    CreatedSuccessFully();
    return data;
  } catch (e) {
    // UnableToCreate();
    console.log(e);
  }
};

export { downloadFile, uploadFile, deleteFile, addALiveLog };
