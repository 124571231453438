import {
  Button,
  Grid,
  IconButton,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import { ArrowBack, InfoOutlined } from '@material-ui/icons';
// import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import React, { useContext, useEffect, useState } from 'react';
import { useHistory, useParams } from 'react-router';
import Layout from '../../../components/Layout';
import SecondaryLayout from '../../../components/SecondaryLayout';
import TextWithDivider from '../../../components/TextWithDivider';
import { Paths } from '../../../routes/paths';
import { colors } from '../../../theme/colors';
import Message from '../../../components/Message';
import { DemoContainer } from '@mui/x-date-pickers/internals/demo';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';

import {
  createEvents,
  getEvents,
  updateEvents,
} from '../../../helper/backendHelper/events';

import { getEnterprise } from '../../../helper/backendHelper/enterprise';
import dayjs from 'dayjs';
import { EventContext } from '../../../components/EventContextProvider';
import makeStyles from '@material-ui/core/styles/makeStyles';
import DialogWrapper from '../../../components/DialogWrapper';

const useStyles = makeStyles((theme) => ({
  textField: {
    width: '80%',
  },
}));

const CreateEvent = () => {
  const history = useHistory();
  const { setSaveEvents } = useContext(EventContext);
  const styles = useStyles();

  const [form, setForm] = useState({
    name: '',
    description: '',
    startDate: dayjs(new Date()),
    endDate: '',
  });

  const [loading, setLoading] = useState(false);
  const [events, setEvents] = useState({});
  const { organizationId, collectionEventId } = useParams();
  const [tooltip, setTooltip] = useState(false);

  useEffect(() => {
    if (organizationId) {
      fetchEventsOrganizationId(organizationId);
    }
  }, [organizationId]);

  //* fetch events by organization id
  const fetchEventsOrganizationId = async (id) => {
    try {
      setLoading(true);
      const response = await getEnterprise(id);
      setEvents(response.payload.data[0]);

      if (collectionEventId) {
        const events = await getEvents(collectionEventId);
        setForm(events.payload.data[0]);
      }
      setLoading(false);
    } catch (error) {
      setLoading(false);
      Message.error(error?.response?.data?.message);
    }
  };

  //* handle change
  const handleFormChange = (name, e, shift = '') => {
    const value = e.target.value;

    //* calculation of number of productive hours
    let totalProductiveHours = 0;
    if (name === 'numberOfHoursPerShift') {
      totalProductiveHours = form[shift]?.numberOfWorkingDays * Number(value);
    } else if (name === 'numberOfWorkingDays') {
      totalProductiveHours = form[shift]?.numberOfHoursPerShift * Number(value);
    }
    // if (name === 'yearOfIncorporation' && isNaN(value)) return;
    if (shift !== '') {
      setForm({
        ...form,
        [shift]: {
          ...(form[shift] || {}),
          [name]: Number(value),
          numberOfProductiveHoursPerShift: totalProductiveHours,
        },
      });
    } else {
      setForm({
        ...form,
        [name]: value,
      });
    }
  };

  const isValid = () => {
    const { name, description, startDate, endDate } = form;
    return (
      name === '' || description === '' || startDate === '' || endDate === ''
    );
  };

  //* handle submit when create the event
  const handleSubmit = async () => {
    if (new Date(form.startDate) > new Date(form.endDate)) {
      Message.error('Estimated Date cannot be less than start date.');
      return;
    }

    try {
      setLoading(true);

      form['enterpriseId'] = organizationId;
      if (collectionEventId) {
        form['pocId'] = form.pocId._id;
        await updateEvents(collectionEventId, form);
      } else {
        const createEvent = await createEvents(form);
        Message.success(createEvent.message);
      }
      setSaveEvents((preSaved) => !preSaved);
      history.push('/admin/dashboard');
      setLoading(false);
    } catch (error) {
      console.log('error', error);
      Message.error(error?.response?.data?.message);
    }
  };

  // check completion date if current date > completion date
  const checkDate = () => {
    return new Date() > new Date(form.endDate);
  };
  return (
    <Layout loading={loading}>
      <SecondaryLayout>
        <div className="ml-4">
          <Grid container>
            <Grid
              className="px-3"
              container
              alignItems="flex-start"
              direction="column"
              item
              xs={8}>
              <Grid
                style={{ height: 80 }}
                container
                justifyContent="space-between"
                className="py-4">
                <Grid container alignItems="center" item xs={6}>
                  <IconButton
                    onClick={() => {
                      history.goBack();
                    }}>
                    <ArrowBack />
                  </IconButton>
                  <Grid>
                    <TextWithDivider>
                      <Typography variant="h3" color="textPrimary">
                        {collectionEventId ? "Update " : "Add New"} Event
                      </Typography>
                    </TextWithDivider>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
        </div>
        <div className="p-5 ml-2">
          <Typography variant="h3">Organisation Details
            <InfoOutlined color="primary" onClick={() => setTooltip(true)} className='ml-2' />
          </Typography>

          <Paper className="p-4 my-4" elevation={0} style={{ width: '60%' }}>
            <Grid container>
              <Grid item xs={4}>
                <img
                  style={{
                    width: '80px',
                    height: 'auto',
                    objectFit: 'contain',
                  }}
                  src={events?.logoUrl}
                  alt="company logo"
                />
                <Typography
                  style={{ color: colors.grey[`200`] }}
                  variant="body2">
                  {events?.name}
                </Typography>
              </Grid>
              <Grid
                container
                direction="column"
                justifyContent="center"
                item
                xs={4}>
                <Typography variant="h6">
                  {events?.yearOfIncorporation}
                </Typography>
                <Typography
                  style={{ color: colors.grey[`200`] }}
                  variant="body2">
                  Year of Incorporation
                </Typography>
              </Grid>
              <Grid
                container
                direction="column"
                justifyContent="center"
                item
                xs={4}>
                <Typography variant="h6">{events?.location}</Typography>
                <Typography
                  style={{ color: colors.grey[`200`] }}
                  variant="body2">
                  Location
                </Typography>
              </Grid>
            </Grid>
          </Paper>

          <Grid spacing={3} container style={{ width: '80%' }}>
            <Grid item xs={6}>
              <TextField
                className="my-3"
                fullWidth
                InputProps={{
                  readOnly: checkDate(),
                }}
                value={form.name}
                placeholder="Event Name"
                onChange={(e) => handleFormChange('name', e)}
              />

              <TextField
                className="my-3"
                value={form.description}
                placeholder="Event Description"
                InputProps={{
                  readOnly: checkDate(),
                }}
                onChange={(e) => handleFormChange('description', e)}
                fullWidth
                multiline
                maxRows={6}
              />
            </Grid>

            <Grid item xs={6}>
              <div className="my-3">
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  {/* <DemoContainer components={['DatePicker']}> */}
                  <DatePicker
                    format="DD/MM/YYYY"
                    label="start Date"
                    slotProps={{
                      textField: {
                        size: 'small',
                        InputLabelProps: { shrink: true },
                        fullWidth: true,
                        name: 'startDate',
                      },
                    }}
                    readOnly={checkDate()}
                    value={dayjs(form.startDate)}
                    onChange={(e) =>
                      setForm({ ...form, startDate: dayjs(e).format() })
                    }
                  />
                  {/* </DemoContainer> */}
                </LocalizationProvider>

                <div className="my-3">
                  <LocalizationProvider dateAdapter={AdapterDayjs}>
                    <DemoContainer components={['DatePicker']}>
                      <DatePicker
                        format="DD/MM/YYYY"
                        label="Estimated Completion Date"
                        value={dayjs(form.endDate)}
                        slotProps={{
                          textField: {
                            size: 'small',
                            InputLabelProps: { shrink: true },
                            fullWidth: true,
                            name: 'endDate',
                          },
                        }}
                        onChange={(e) =>
                          setForm({ ...form, endDate: dayjs(e).format() })
                        }
                      />
                    </DemoContainer>
                  </LocalizationProvider>
                </div>
              </div>
            </Grid>

            <Grid xs={12} container spacing={2} className="mt-3">
              <Grid item xs={4}>
                <Typography>
                  <b>Total Number of Productive Days in a Year</b>
                </Typography>
                <Grid item className="mt-3">
                  <label className="text-xs">General Shift</label>
                  <br />
                  <TextField
                    value={form?.generalShift?.numberOfWorkingDays}
                    className={styles.textField}
                    placeholder="Working Days in General Shift"
                    type="number"
                    InputProps={{
                      readOnly: checkDate(),
                    }}
                    onChange={(evt) => {
                      handleFormChange(
                        'numberOfWorkingDays',
                        evt,
                        'generalShift'
                      );
                    }}
                  />
                </Grid>
                <Grid item className="mt-3">
                  <label className="text-xs">Shift</label>
                  <br />
                  <TextField
                    value={form?.shift?.numberOfWorkingDays}
                    className={styles.textField}
                    InputProps={{
                      readOnly: checkDate(),
                    }}
                    placeholder="Working Days in Shift"
                    type="number"
                    onChange={(evt) => {
                      handleFormChange('numberOfWorkingDays', evt, 'shift');
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  <b>Productive hours per day per shift (as per standards)</b>
                </Typography>
                <Grid item className="mt-3">
                  <label className="text-xs">General Shift</label>
                  <br />
                  <TextField
                    className={styles.textField}
                    value={form?.generalShift?.numberOfHoursPerShift}
                    placeholder="Hours per General shift"
                    type="number"
                    InputProps={{
                      readOnly: checkDate(),
                    }}
                    onChange={(evt) => {
                      handleFormChange(
                        'numberOfHoursPerShift',
                        evt,
                        'generalShift'
                      );
                    }}
                  />
                </Grid>
                <Grid item className="mt-3">
                  <label className="text-xs"> Shift</label>
                  <br />
                  <TextField
                    InputProps={{
                      readOnly: checkDate(),
                    }}
                    className={styles.textField}
                    placeholder="Hours per shift"
                    value={form?.shift?.numberOfHoursPerShift}
                    type="number"
                    onChange={(evt) => {
                      handleFormChange('numberOfHoursPerShift', evt, 'shift');
                    }}
                  />
                </Grid>
              </Grid>
              <Grid item xs={4}>
                <Typography>
                  <b>Total number of productive hours per year</b>
                </Typography>
                <Grid item className="mt-3">
                  <label className="text-xs">General Shift</label>
                  <br />
                  <TextField
                    className={styles.textField}
                    InputProps={{
                      readOnly: checkDate(),
                    }}
                    placeholder="Productive hours per General shift"
                    type="number"
                    value={form?.generalShift?.numberOfProductiveHoursPerShift}
                  />
                </Grid>
                <Grid item className="mt-3">
                  <label className="text-xs">Shift</label>
                  <br />
                  <TextField
                    className={styles.textField}
                    InputProps={{
                      readOnly: checkDate(),
                    }}
                    placeholder="Productive hours per shift"
                    type="number"
                    value={form?.shift?.numberOfProductiveHoursPerShift}
                  />
                </Grid>
              </Grid>
            </Grid>
            <Button
              className="mt-3"
              disabled={isValid()}
              disableElevation
              color="primary"
              onClick={handleSubmit}
              variant="contained">
              {collectionEventId ? 'Update' : 'Create'} Event
            </Button>
          </Grid>
        </div>
      </SecondaryLayout>
      <DialogWrapper
        title="User Guide for the Organization Details."
        open={tooltip}
        handleClose={() => setTooltip(false)}>
        <div className='overflow-auto'>
          <div>
            <b>Productive Days & Hours</b>
            <div className='ml-2'>Calculate total productive hours
              using the following assumptions:</div>
            <ul className='mt-1 ml-2'>
              <li>General Shift: 199 days, 6.5 hours per shift.</li>
              <li>Shift Work: 168 days, 8.5 hours per shift.</li>
            </ul>
          </div>
        </div>
      </DialogWrapper>
    </Layout>
  );
};

export default CreateEvent;
