import { useState, createContext, useEffect } from 'react';
import { getAllEvents } from '../../helper/backendHelper/events';
import Message from '../Message';

export const EventContext = createContext(null);

const EventContextProvider = ({ children }) => {
  const [events, setEvents] = useState([]);
  const [saveEvents, setSaveEvents] = useState(false);

  useEffect(() => {
    if (localStorage.getItem('token')) {
      fetchAllEvents();
      setSaveEvents(false);
    }
  }, [saveEvents]);

  const fetchAllEvents = async () => {
    try {
      const response = await getAllEvents();
      setEvents(response?.payload?.data);
    } catch (error) {
      Message.error(error?.response?.data?.message);
    }
  };

  return (
    <EventContext.Provider value={{ events, setSaveEvents }}>
      {children}
    </EventContext.Provider>
  );
};

export default EventContextProvider;
