import { del, get, post, put } from '../api_helper';
import * as url from '../url_helper';
import { getApiConfig } from '../auth_helper';

//* create employee
export const createEmployeeBulk = async (data) => {
  return await post(`${url.EMPLOYEE}`, data, getApiConfig());
};

export const createEmployee = async (data) => {
  return await post(`${url.EMPLOYEE}/single`, data, getApiConfig());
};

//* update employee
export const updateEmployee = async (id, data) => {
  return await put(`${url.EMPLOYEE}/${id}`, data, getApiConfig());
};

export const updateEmployeeFlag = async (id, data) => {
  return await put(`${url.EMPLOYEE}/flag-employee/${id}`, data, getApiConfig());
};

//* Get All Employees
export const getAllEmployee = async () => {
  return await get(`${url.EMPLOYEE}?employeeType=individual`, getApiConfig());
};

//* get data by event id
export const getIndividualEmployees = async (id) => {
  return await get(
    `${url.EMPLOYEE}?collectionEventId=${id}&employeeType=individual`,
    getApiConfig()
  );
};

//* Replace the emplopyees
export const updateReplaceEmployees = async (data) => {
  return await put(`${url.EMPLOYEE}/replace-employee`, data, getApiConfig());
};

//* get employee by user id
export const getEmployee = async (userId) => {
  return await get(`${url.EMPLOYEE}?userId=${userId}`, getApiConfig());
};
export const getEmployeeById = async (id) => {
  return await get(`${url.EMPLOYEE}/employee/${id}`, getApiConfig());
};

//* get identical employee
export const getIdenticalEmployees = async (id) => {
  return await get(
    `${url.EMPLOYEE}/team-employees?collectionEventId=${id}`,
    getApiConfig()
  );
};

//* get employee by enterprise
export const getEmployeeEnterprise = async () => {
  return await get(`${url.EMPLOYEE}/enterprise`, getApiConfig());
};

export const resendCredentialsEmployee = async (data) => {
  return await post(`${url.EMPLOYEE}/resendCredentials`, data, getApiConfig());
};

// delete employee
export const deleteEmployeeById = async (id) => {
  return await del(`${url.EMPLOYEE}/${id}`, getApiConfig());
};