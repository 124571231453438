import { Button, Grid, Paper, Typography } from '@material-ui/core';
import React, { useContext, useState } from 'react';
import DialogWrapper from '../../../../components/DialogWrapper';
import Message from '../../../../components/Message';
import {
  deactivateUsersByEventId,
  deleteAllActivitiesByEventId,
  deleteAllActivityEffortsByEventId,
  deleteAllEffortLogsByEventId,
  deleteBulkUsersFromAuth,
  deleteBulkUsersFromFirestoreByEventId,
  fetchUsersByEventId,
  updateEventById,
} from '../../../../operations/admin';
import { colors } from '../../../../theme/colors';
import { addALiveLog } from '../../../../operations/misc';
import { EVENT_STATUS, LIVELOG_TYPES } from '../../../../utils/utils';
import { AuthContext } from '../../../../components/AuthContextProvider';
import { updateEvents } from '../../../../helper/backendHelper/events';

const EventCard = ({
  save,
  name,
  eventId,
  startDate = '-',
  estimatedCompletionDate = '-',
  eventsSetter,
  status,
  location,
  poc,
}) => {
  const [purgeDialogOpen, setPurgeDialogOpen] = useState(false);
  const [finishDialogOpen, setFinishDialogOpen] = useState(false);

  const handlePurgeEvent = () => {
    setPurgeDialogOpen(true);
  };

  const handleFinishEvent = () => {
    setFinishDialogOpen(true);
  };
  const purgeEvent = async () => {
    try {
      await updateEvents(eventId, { status: 'Deleted' });
      Message.success('Event Purged Successfully !');
      setPurgeDialogOpen(false)
      save((preSaved) => !preSaved);
      setFinishDialogOpen(false);
    } catch (e) {
      Message.error(e?.response?.data?.message);
    }
  };

  const finishEvent = async () => {
    try {
      await updateEvents(eventId, { status: 'Completed' });
      Message.success('Event Finished Successfully !');
      save((preSaved) => !preSaved);
      setFinishDialogOpen(false);
    } catch (e) {
      Message.error(e?.response?.data?.message);
    }
  };

  return (
    <Paper className="mx-4 p-3 my-4" elevation={0}>
      <div>
        <Grid container>
          <Grid alignItems="center" item xs={2}>
            <Typography align="left" variant="body1">
              {name}
            </Typography>
            <Typography
              align="left"
              style={{ color: colors.grey[200] }}
              variant="body2">
              Poc Details : {poc}
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography align="left" variant="body1">
              {new Date(startDate).toLocaleDateString()}
            </Typography>
            <Typography
              align="left"
              style={{ color: colors.grey[200] }}
              variant="body2">
              Start Date
            </Typography>
          </Grid>
          <Grid item xs={2}>
            <Typography align="left" variant="body1">
              {new Date(estimatedCompletionDate).toLocaleDateString()}
            </Typography>
            <Typography
              align="left"
              style={{ color: colors.grey[200] }}
              variant="body2">
              Estimated completion date
            </Typography>
          </Grid>
          <Grid container direction="column" alignItems="center" item xs={2}>
            <Typography align="left" variant="body1">
              {location}
            </Typography>
            <Typography
              align="left"
              style={{ color: colors.grey[200] }}
              variant="body2">
              Location
            </Typography>
          </Grid>
          <Grid container justifyContent="flex-end" item xs={3}>
            {status === 'Completed' ? (
              <Button
                className="mr-3"
                disableElevation
                color="error"
                style={{ background: '#4caf50', color: '#fff' }}
                onClick={() => {}}
                variant="contained">
                Completed
              </Button>
            ) : status === 'Deleted' ? (
              <Button
                className="mr-3"
                disableElevation
                color="error"
                style={{ background: '#ffc107', color: '#fff' }}
                onClick={() => {}}
                variant="contained">
                Purged
              </Button>
            ) : (
              <>
                <Button
                  className="mr-3"
                  disableElevation
                  color="secondary"
                  onClick={handlePurgeEvent}
                  variant="contained">
                  Purge Event
                </Button>
                <Button
                  onClick={handleFinishEvent}
                  disableElevation
                  color="primary"
                  variant="contained">
                  Finish Event
                </Button>{' '}
              </>
            )}
          </Grid>
        </Grid>
        {/* <div style={{ display: 'flex', gap: 5 }}>
          <Typography
            align="left"
            style={{ color: colors.grey[200] }}
            variant="body2">
            POC Details:{' '}
          </Typography>
          <Typography align="left" variant="body2">
            {
              poc?.map(((el, idx) => <span>{el.email}{idx !== poc?.length - 1 ? ',' : ''}</span>))
            }
            {poc}
          </Typography>
        </div> */}
      </div>

      <DialogWrapper
        open={purgeDialogOpen}
        title="Purge this Event"
        submitButtonTitle="Purge"
        cancelButtonTitle="Cancel"
        handleClose={() => setPurgeDialogOpen(false)}
        handleSubmit={purgeEvent}>
        <Typography>Are you sure you want to purge this event ?</Typography>
      </DialogWrapper>

      <DialogWrapper
        open={finishDialogOpen}
        title="Finish this Event"
        submitButtonTitle="Finish"
        cancelButtonTitle="Cancel"
        handleClose={() => setFinishDialogOpen(false)}
        handleSubmit={finishEvent}>
        <Typography>Are you sure you want to finish this event ?</Typography>
      </DialogWrapper>
    </Paper>
  );
};

export default EventCard;
