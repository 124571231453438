import * as React from 'react';
import Grid from '@material-ui/core/Grid';
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  Tooltip,
  LabelList,
  Cell,
} from 'recharts';
import { colors } from '../../theme/colors';

function BarChartWrapper({
  data,
  nameBar1,
  nameBar2,
  keyBar1,
  keyBar2,
  barSize = 20,
}) {
  // const dummyData = [
  //   {
  //     teamId: 'wegw',
  //     teamName: 'One',
  //     totalLogs: 10,
  //     submittedLogs: 5,
  //   },
  //   {
  //     teamId: 'erg r er',
  //     teamName: 'Team Jonathan',
  //     totalLogs: 20,
  //     submittedLogs: 5,
  //   },
  // ];


  return (
    <Grid container item>
      <BarChart
        // barGap={-22}
        width={300}
        layout="vertical"
        height={200}
        data={data}>
        <XAxis type="number" tickSize={2} stroke={colors.grey[600]} />
        <YAxis
          hide={true}
          type="category"
          tickSize={2}
          dataKey="teamName"
          stroke={colors.grey[600]}
        />
        <Tooltip cursor={{ fill: 'none' }} />
        <Bar
          fill={'transparent'}
          radius={4}
          offset={30}
          width={300}>
          <LabelList
            width={300}
            className="label-bar"
            fontSize={12}
            dataKey="teamName"
          />
        </Bar>
        <Bar
          name={nameBar2}
          dataKey={keyBar2}
          fill={colors.primary.light}
          radius={4}
          maxBarSize={barSize}>
          {data.map((el, idx) => (
            <Cell key={idx} fill={colors.primary.light} />
          ))}
        </Bar>
        <Bar
          name={nameBar1}
          dataKey={keyBar1}
          radius={4}
          fill={colors.primary.main}
          maxBarSize={barSize}>
          {data.map((el, idx) => (
            <Cell key={idx} fill={colors.primary.main} />
          ))}
        </Bar>
      </BarChart>
    </Grid>
  );
}

export default BarChartWrapper;
