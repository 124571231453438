import { Grid, IconButton, Paper, Typography } from '@material-ui/core';
import React from 'react';
import { colors } from '../../../../theme/colors';
import ChevronRightOutlinedIcon from '@material-ui/icons/ChevronRightOutlined';
import { useHistory } from 'react-router';
import { Paths } from '../../../../routes/paths';

const SurveyCard = ({
  name,
  organisationId,
  createdAt,
  eventName = '',
  id,
}) => {
  const history = useHistory();

  return (
    <Paper className="mx-4 p-3 my-4" elevation={0}>
      <Grid container>
        <Grid item xs={2}>
          <Typography align="left" variant="h6">
            {name || 'Survey'}
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography className="font-weight-bold" align="left" variant="body1">
            {createdAt}
          </Typography>
          <Typography
            align="left"
            style={{ color: colors.grey[200] }}
            variant="subtitle2">
            # Created On
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography align="left" variant="body1">
            {eventName}
          </Typography>
          <Typography
            align="left"
            style={{ color: colors.grey[200] }}
            variant="subtitle2">
            Event Name
          </Typography>
        </Grid>
        <Grid item xs={2}>
          <Typography align="left" variant="body1">
            Manager
          </Typography>
          <Typography
            align="left"
            style={{ color: colors.grey[200] }}
            variant="subtitle2">
            Assigned to
          </Typography>
        </Grid>

        <Grid item xs={2}>
          <img src="" alt="organisation name" />
          <Typography
            align="left"
            style={{ color: colors.grey[200] }}
            variant="subtitle2">
            Organisation Name
          </Typography>
        </Grid>
        {/* <Grid item xs={2}>
          <Typography variant="body1">{location}</Typography>
          <Typography style={{ color: colors.grey[200] }} variant="subtitle2">
            Location
          </Typography>
        </Grid> */}
        <Grid container justifyContent="flex-end" item xs={1}>
          <IconButton
            onClick={() => {
              history.push(`${Paths.adminCreateSurvey}/${id}`, {
                state: { name },
              });
            }}>
            <ChevronRightOutlinedIcon />
          </IconButton>
        </Grid>
      </Grid>
    </Paper>
  );
};

export default SurveyCard;
