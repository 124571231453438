import { del, get, post, put } from '../api_helper';
import * as url from '../url_helper';
import { getApiConfig } from '../auth_helper';

//* get all events
export const getAllEvents = async () => {
  return await get(`${url.EVENTS}`, getApiConfig());
};

//* get events by organization id
export const getEventsOrganizationId = async (id) => {
  return await get(`${url.EVENTS}?enterpriseId=${id}`, getApiConfig());
};

//* get event by id
export const getEvents = async (id) => {
  return await get(`${url.EVENTS}?_id=${id}`, getApiConfig());
};

//* add events
export const createEvents = async (data) => {
  return await post(`${url.EVENTS}`, data, getApiConfig());
};

//* update events
export const updateEvents = async (id, data) => {
  return await put(`${url.EVENTS}/${id}`, data, getApiConfig());
};

//* delete events
export const deleteEvents = async (id) => {
  return await del(`${url.EVENTS}/${id}`, getApiConfig());
};
