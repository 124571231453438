import React, { useContext, useEffect, useState } from 'react';
import Layout from '../../../components/Layout';
import { Typography } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import TextWithDivider from '../../../components/TextWithDivider';
import ProfileContainer from '../../../components/ProfileContainer';
import { AuthContext } from '../../../components/AuthContextProvider';
import SecondaryLayout from '../../../components/SecondaryLayout';
import TableWrapper from '../components/TableWrapper';
import StatContainer from '../components/StatContainer';
import useFetch from '../../../hooks/useFetch';
import {
  createTeamAndAddToManager,
  employeeEffortLogStatus,
  getActivityEffortHoursByEffortLogIds,
  getRagCompletionStatus,
  insertLiveLogByBulk,
  updateEffortLogBulk,
} from '../../../operations/manager';
import CommentDialog from '../../../components/CommentDialog';
import ConfirmationDialog from '../../../components/ConfirmationDialog';
import { EFFORT_LOG_STATUS, USER_TYPE } from '../../../operations/constants';
import { isEmpty } from '../../../utils/utils';
import { GenericError } from '../../../operations/utils';
import { updateTotalHoursEnterprise } from '../../../operations/enterprise';

const ManagerDashboard = () => {
  let { user } = useContext(AuthContext);
  const [isCommentDialogOpen, setCommentDialogOpen] = useState(false);
  const [isConfirmDialogOpen, setConfirmDialogOpen] = useState(false);
  const [selectedIds, setSelectedIds] = useState([]);
  const [loading, setLoading] = useState(false);
  const [resetSelected, setResetSelected] = useState(false);
  const [stats, setStats] = useState({});

  const {
    data: employeeEffortLog = [],
    loading: effortLogLoading,
    fetchData,
  } = useFetch(employeeEffortLogStatus, {
    teamId: user.teamId,
  });

  const { data: ragStatus = {}, loading: ragLoading } = useFetch(
    getRagCompletionStatus,
    {
      teamId: user.teamId,
    }
  );

  useEffect(() => {
    if (!effortLogLoading && employeeEffortLog.length) {
      const approvalCount = employeeEffortLog.filter((item) =>
        [EFFORT_LOG_STATUS.APPROVED, EFFORT_LOG_STATUS.REJECTED].includes(
          item.status
        )
      ).length;

      const individualEffortReceived = employeeEffortLog.filter(
        (item) =>
          [EFFORT_LOG_STATUS.APPROVED, EFFORT_LOG_STATUS.SUBMITTED].includes(
            item.status
          ) && item.type === USER_TYPE.INDIVIDUAL
      ).length;

      const identicalEffortReceived = employeeEffortLog.filter(
        (item) =>
          [EFFORT_LOG_STATUS.APPROVED, EFFORT_LOG_STATUS.SUBMITTED].includes(
            item.status
          ) && item.type === USER_TYPE.IDENTICAL
      ).length;

      const totalIndividual = employeeEffortLog.filter(
        (item) => item.type === USER_TYPE.INDIVIDUAL
      ).length;
      const totalIdentical = employeeEffortLog.filter(
        (item) => item.type === USER_TYPE.IDENTICAL
      ).length;

      const data = {
        approvalCompletion: (approvalCount / employeeEffortLog.length) * 100,
        individualEffortReceived,
        totalIndividual,
        totalIdentical,
        identicalEffortReceived,
      };

      setStats((stats) => ({ ...stats, ...data }));
    }
  }, [effortLogLoading, employeeEffortLog]);

  useEffect(() => {
    if (!ragLoading && !isEmpty(ragStatus)) {
      setStats((stats) => ({ ...stats, ...ragStatus }));
    }
  }, [ragStatus, ragLoading]);

  useEffect(() => {
    if (user && Object.keys(user).length) {
      if (!user.teamId) {
        (async () => {
          await createTeamAndAddToManager({
            eventId: user.eventId,
            managerId: user.id,
            managerName: user.name,
            managerEmail: user.email,
            name: `${user.team ? user.team : user.name + "'s Team"}`,
          });
          // window.location.reload();
        })();
      }
    }
  }, [user]);

  const updateApprovalStatus = async (
    effortLogIdArray,
    approvalStatus,
    comment,
    employees = []
  ) => {
    await updateEffortLogBulk({
      userId: user.id,
      effortLogIdArray,
      status: approvalStatus,
      comment,
      employees,
    });
    await fetchData({ teamId: user.teamId });
  };

  const handleApproveAllClick = (selectedIds) => {
    setSelectedIds(selectedIds);
    setConfirmDialogOpen(true);
  };

  const handleApproveMultiple = async () => {
    setLoading(true);
    setConfirmDialogOpen(false);
    try {
      let userArr = employeeEffortLog.filter((el) =>
        selectedIds.includes(el.id)
      );
      await updateApprovalStatus(selectedIds, EFFORT_LOG_STATUS.APPROVED);
      insertLiveLogByBulk({
        selectedLogs: userArr,
        type: 'APPROVED',
        createdBy: user.uid,
        createdByName: user.name,
        enterpriseId: user.enterpriseId,
        eventId: user.eventId,
        typeOfObject: 'Effort log',
      });

      const totalHoursOfActivityEfforts =
        await getActivityEffortHoursByEffortLogIds(selectedIds);

      await updateTotalHoursEnterprise(
        user.enterpriseId,
        totalHoursOfActivityEfforts
      );

      setResetSelected(true);
      setSelectedIds([]);
      setLoading(false);
      setResetSelected(false);
    } catch (e) {
      GenericError();
    }
  };

  const handleRejectMultiple = async (comment) => {
    if (comment) {
      try {
        setLoading(true);
        setCommentDialogOpen(false);

        let userArr = employeeEffortLog.filter((el) =>
          selectedIds.includes(el.id)
        );
        const employees = employeeEffortLog
          .filter(
            (item) =>
              item.type === USER_TYPE.IDENTICAL &&
              !item.actAsIndividual &&
              !item.isCollectionStarted &&
              selectedIds.includes(item.id)
          )
          .map((item) => ({
            uid: item.uid,
            email: item.email,
          }));
        await updateApprovalStatus(
          selectedIds,
          EFFORT_LOG_STATUS.REJECTED,
          comment,
          employees
        );

        insertLiveLogByBulk({
          selectedLogs: userArr,
          type: 'REJECTED',
          createdBy: user.uid,
          createdByName: user.name,
          enterpriseId: user.enterpriseId,
          eventId: user.eventId,
          comment: comment,
          typeOfObject: 'Effort log',
        });

        setResetSelected(true);
        setSelectedIds([]);
        setLoading(false);
        setResetSelected(false);
      } catch (e) {
        GenericError();
      }
    }
  };

  const handleRejectAllClick = (selectedIds) => {
    setSelectedIds(selectedIds);
    setCommentDialogOpen(true);
  };

  const isActionable = (row) => {
    return row.status === EFFORT_LOG_STATUS.SUBMITTED;
  };

  return (
    <Layout loading={effortLogLoading || loading}>
      <SecondaryLayout>
        <Grid container className="pl-4 pr-2">
          <Grid item lg={9} md={8} className="pr-4 pt-4">
            <TextWithDivider>
              <Typography variant="h3" color="textPrimary">
                Summary
              </Typography>
            </TextWithDivider>
            <Grid container justifyContent="space-between" className="mt-4">
              <Grid item container className="w-50">
                <Typography variant="subtitle2" className="ml-3">
                  LOG APPROVAL - RAG COMPLETION STATUS
                </Typography>
                <Grid
                  item
                  container
                  justifyContent="space-around"
                  className="mt-3">
                  <StatContainer
                    value={stats.approvalCompletion?.toFixed(2) || 0}
                    unit="%"
                    label={'Approval Completion Status'}
                    handleClick={() => null}
                    theme="secondary"
                  />
                  <StatContainer
                    value={
                      isNaN(stats.assignRagPercentage)
                        ? 0
                        : stats.assignRagPercentage?.toFixed(2) || 0
                    }
                    unit="%"
                    label={'RAG Completion Status'}
                    handleClick={() => null}
                    theme="secondary"
                  />
                </Grid>
              </Grid>
              <Grid item container className="w-50">
                <Typography variant="subtitle2" className="ml-3">
                  MY REPORTEE LOG SUBMISSION STATUS
                </Typography>
                <Grid
                  item
                  container
                  justifyContent="space-around"
                  className="mt-3">
                  <StatContainer
                    value={stats.individualEffortReceived || 0}
                    unit={`/${stats.totalIndividual || 0}`}
                    label={'Individual Effort Received'}
                    theme="secondary"
                  />
                  <StatContainer
                    value={stats.identicalEffortReceived || 0}
                    unit={`/${stats.totalIdentical || 0}`}
                    label={'Identical Effort Received'}
                    theme="secondary"
                  />
                </Grid>
              </Grid>
            </Grid>
            <Grid className="mt-4">
              <TableWrapper
                data={employeeEffortLog}
                id={'EmployeeEffortLogStatus'}
                tableHeads={[
                  { id: 'name', label: 'Name' },
                  {
                    id: 'type',
                    label: 'Individual/Identical',
                    align: 'center',
                  },
                  { id: 'status', label: 'Status', align: 'center' },
                  { id: 'actions', label: 'Actions', align: 'center' },
                ]}
                dataKeys={[
                  { id: 'name' },
                  { id: 'type', align: 'center' },
                  { id: 'status', align: 'center' },
                ]}
                handlePrimaryButtonClick={handleApproveAllClick}
                handleSecondaryButtonClick={handleRejectAllClick}
                primaryButtonText="Approve"
                secondaryButtonText="Reject"
                checkId={'id'}
                resetSelected={resetSelected}
                isActionable={isActionable}
                isExpandable
              />
            </Grid>
          </Grid>
          <ProfileContainer />
        </Grid>

        <CommentDialog
          open={isCommentDialogOpen}
          handleClose={() => {
            setCommentDialogOpen(false);
            setSelectedIds([]);
          }}
          handleSubmit={handleRejectMultiple}
          title="Rejection comment"
        />

        <ConfirmationDialog
          open={isConfirmDialogOpen}
          handleClose={() => {
            setConfirmDialogOpen(false);
            setSelectedIds([]);
          }}
          title="Are you sure?"
          message="Are you sure you want to approve the selected logs?"
          handleSubmit={handleApproveMultiple}
        />
      </SecondaryLayout>
    </Layout>
  );
};

export default ManagerDashboard;
