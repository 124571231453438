import React from 'react';
import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  Grid,
  IconButton,
} from '@material-ui/core';
import { makeStyles } from '@material-ui/core/styles';
import CloseIcon from '@material-ui/icons/Close';


const useStyles = makeStyles((theme) => ({
  closeButton: {
    position: 'absolute',
    right: theme.spacing(1),
    top: theme.spacing(1),
    color: theme.palette.grey[500],
  },
}));

const DialogWrapper = ({
  open,
  handleClose,
  title,
  children,
  handleSubmit,
  isSubProcess,
  submitButtonTitle = 'Submit',
  cancelButtonTitle,
  dialogId,
  disabled = false,
  loading,
  style,
}) => {
  const classes = useStyles();

  return (
    <Dialog
      PaperProps={{
        style: { borderRadius: 8, minWidth: '340px' },
      }}
      open={open}
      style={style}
      keepMounted
      onClose={handleClose}
      aria-labelledby={dialogId}
      aria-describedby={dialogId}>
      <DialogTitle id={`${dialogId}-title`}>
        {title}
        <IconButton
          aria-label="close"
          className={classes.closeButton}
          onClick={handleClose}>
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <Divider />
      <DialogContent id={`${dialogId}-description`}>{children}</DialogContent>
      <DialogActions>
        {handleSubmit ? (
          <>
            {loading ? (
              <Grid
                container
                justifyContent="center"
                alignItems="center"
                style={{ width: '100px' }}>
                <CircularProgress size={20} />
              </Grid>
            ) : (
              <Button
                onClick={handleSubmit}
                color="primary"
                variant="contained">
                {submitButtonTitle}
              </Button>
            )}
          </>
        ) : null}
      </DialogActions>
    </Dialog>
  );
};

export default DialogWrapper;
