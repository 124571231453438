const Employee = '/employee';
const Manager = '/manager';
const poc = '/poc';
const admin = '/admin';

const EmployeePaths = {
  employeeHome: `${Employee}/dashboard`,
  logEffort: `${Employee}/log-effort`,
};

const ManagerPaths = {
  managerDashboard: `${Manager}/dashboard`,
  managerEmployees: `${Manager}/employees`,
  Employe: `${Manager}/employe`,
  managerAssignRag: `${Manager}/assignRAG`,
  managerAssignRagSelected: `${Manager}/RAG`,
  managerAnalytics: `${Manager}/analytics`,
  managerEvents: `${Manager}/events`,
  managerCreateEvents: `${Manager}/create-event`,
};

const POCPaths = {
  pocDashboard: `${poc}/dashboard`,
  pocManagers: `${poc}/managers`,
  pocRag: `${poc}/RAG`,
  ragTeam: `${poc}/rag-team`,
  pocAnalytics: `${poc}/analytics`,
  pocEvents: `${poc}/events`,
  pocSettings: `${poc}/settings`,
  pocCreateEvents: `${poc}/create-event`,
};

const AdminPaths = {
  adminDashboard: `${admin}/dashboard`,
  adminCreateEvent: `${admin}/create-event`,
  adminSurvey: `${admin}/survey`,
  adminOrganisation: `${admin}/organisations`,
  adminSurveys: `${admin}/surveys`,
  adminOrganisationSelected: `${admin}/organisation/id/:organizationId`,
  adminAnalytics: `${admin}/analytics`,
  organization: `${admin}/organisation`,
  adminOrganisationSelectedRoute: `${admin}/organisation/id/:organizationId`,
  adminOrganisationId: `${admin}/organisation/id`,
  adminCreateEventRoute: `${admin}/create-event/:organizationId`,
  adminCreateSurvey: `${admin}/create-survey`,
  adminCreateSurveyRoute: `${admin}/create-survey/:surveyId`,
  adminRag: `${admin}/rag`,
  adminRagTeam: `${admin}/rag-team`,
  adminEvents: `${admin}/events`,
};

const Paths = {
  login: '/',
  viewSurvey: '/survey/:surveyId',
  ...EmployeePaths,
  ...ManagerPaths,
  ...POCPaths,
  ...AdminPaths,
};

export { Paths };
