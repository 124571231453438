import {
  Button,
  Checkbox,
  FormControl,
  FormControlLabel,
  Grid,
  IconButton,
  InputLabel,
  MenuItem,
  Paper,
  Radio,
  RadioGroup,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import React from 'react';
import { QUESTION_TYPE } from '../../../../utils/utils';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleOutline from '@material-ui/icons/AddCircleOutline';
import { colors } from '../../../../theme/colors';

const questionTypes = [
  {
    name: 'Single Choice Question',
    value: QUESTION_TYPE.SINGLE,
  },
  {
    name: 'Multiple Choice Question',
    value: QUESTION_TYPE.MULTIPLE,
  },
  {
    name: 'Text',
    value: QUESTION_TYPE.TEXT,
  },
];

const Question = ({
  question,
  allQuestions,
  index,
  isStatic = false,
  setQuestions,
  onChange = () => { },
  addNewQuestion = () => { },
}) => {
  const handleTypeChange = (value) => {
    let newArr = [...allQuestions];
    newArr[index].type = value;
    setQuestions(newArr);
  };

  const handleAnswerChange = (index, type, value) => {
    let newArr = [...allQuestions];
    switch (type) {
      case QUESTION_TYPE.TEXT:
      case QUESTION_TYPE.SINGLE: {
        newArr[index] = {
          ...question,
          answer: value,
        };
        break;
      }
      case QUESTION_TYPE.MULTIPLE: {
        newArr[index] = {
          ...question,
          answer: [
            ...(newArr[index].answer ? newArr[index].answer : []),
            value,
          ],
        };
        break;
      }
      default: {
        break;
      }
    }
    setQuestions(newArr);
  };

  return (
    <Grid container className="my-3">
      <Paper className="p-4 " style={{ width: '500px' }}>
        <Grid container spacing={2} alignItems="center">
          <Grid item xs={isStatic ? 12 : 9}>
            {isStatic ? (
              <Typography variant="h6">{question.text}</Typography>
            ) : (
              <TextField
                fullWidth
                style={{ color: `${colors.common.black} !important` }}
                value={question.text}
                onChange={(e) => {
                  let newArr = [...allQuestions];
                  newArr[index].text = e.target.value;
                  setQuestions(newArr);
                }}
                className="mt-3"
                placeholder="Enter question here"
              />
            )}
          </Grid>
          {!isStatic && (
            <Grid item xs={3}>
              <FormControl className="m-0" fullWidth>
                <InputLabel id="questionType">Question Type</InputLabel>
                <Select
                  fullWidth
                  labelId="questionType"
                  value={question.type}
                  onChange={(evt) => handleTypeChange(evt.target.value)}>
                  <MenuItem disabled value={''} />

                  {questionTypes.map((el, idx) => (
                    <MenuItem value={el.value} key={idx}>
                      {el.name}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Grid>
          )}
        </Grid>

        {/* Answer */}
        <div className="mt-4">
          {(() => {
            switch (question.type) {
              case QUESTION_TYPE.MULTIPLE:
              case QUESTION_TYPE.SINGLE: {
                return (
                  <>
                    <RadioGroup
                    // defaultValue={isStatic ? question.answer : 'a'}
                    >
                      {question.options.length
                        ? question.options.map((el, idx) => (
                          <Grid container key={el.id}>
                            <FormControlLabel
                              label={isStatic ? el.label : ''}
                              disabled={!isStatic}
                              onChange={(e) =>
                                handleAnswerChange(
                                  index,
                                  question.type,
                                  e.target.value
                                )
                              }
                              value={el.value}
                              control={
                                question.type === QUESTION_TYPE.MULTIPLE ? (
                                  <Checkbox />
                                ) : (
                                  <Radio />
                                )
                              }
                            />
                            {!isStatic && (
                              //TextField for option
                              <>
                                <TextField
                                  size="small"
                                  onChange={(e) => {
                                    let value = e.target.value;
                                    let newArr = [...allQuestions];
                                    newArr[index].options[idx].label = value;
                                    setQuestions(newArr);
                                  }}
                                  value={el.label}
                                />

                                <IconButton
                                  onClick={() => {
                                    // let newArr = [...allQuestions][
                                    //   index
                                    // ].options.filter(
                                    //   (item) => el.label !== item.label
                                    // );
                                    let newArr = [...allQuestions];
                                    newArr[index].options = newArr[
                                      index
                                    ].options.filter(
                                      (item) => el.id !== item.id
                                    );
                                    setQuestions(newArr);
                                  }}>
                                  <CloseIcon />
                                </IconButton>
                              </>
                            )}
                          </Grid>
                        ))
                        : ''}
                    </RadioGroup>
                    {!isStatic && (
                      <Button
                        size="small"
                        onClick={() => {
                          let newArr = [...allQuestions];
                          newArr[index].options.push({
                            label: '',
                            value: '',
                            id:
                              Date.now() +
                              allQuestions[index].options.length +
                              1,
                          });
                          setQuestions(newArr);
                        }}>
                        Add option
                      </Button>
                    )}
                  </>
                );
              }
              case QUESTION_TYPE.TEXT: {
                return (
                  <TextField
                    disabled={!isStatic}
                    fullWidth
                    multiline
                    rows={4}
                    placeholder="Enter answer here"
                    onChange={(e) => {
                      handleAnswerChange(index, question.type, e.target.value);
                    }}
                  />
                );
              }
              default: {
                return <Typography>Select a question type </Typography>;
              }
            }
          })()}
        </div>
      </Paper>
      {!isStatic && index === allQuestions.length - 1 && (
        <div>
          <Button
            style={{ float: 'right' }}
            color="primary"
            variant="contained"
            disableElevation
            size="small"
            onClick={addNewQuestion}
            startIcon={<AddCircleOutline />}>
            Add
          </Button>
        </div>
      )}
    </Grid>
  );
};

export default Question;
