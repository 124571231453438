import { get } from '../api_helper';
import * as url from '../url_helper';
import { getApiConfig, getAuthToken } from '../auth_helper';

//* get manager utilization
export const getManagerUtilization = async (collectionEventId, user) => {
  return await get(
    `${url.POC}/getManagerUtilizations?collectionEventId=${collectionEventId}&pocId=${user.pocId}`,
    getApiConfig()
  );
};

export const getAllManagerUtilization = async (user) => {
  return await get(
    `${url.POC}/getManagerUtilizations?pocId=${user.pocId}`,
    getApiConfig()
  );
};

//* get manager utilization
export const getManagerRagActivity = async (collectionEventId, user) => {
  return await get(
    `${url.POC}/getManagerRAGAnalysis?collectionEventId=${collectionEventId}&pocId=${user.pocId}`,
    getApiConfig()
  );
};

//* get manager utilization
export const getAllManagerRagActivity = async (user) => {
  return await get(
    `${url.POC}/getManagerRAGAnalysis?pocId=${user.pocId}`,
    getApiConfig()
  );
};

//* all rag utilization of manager
export const allManagerRagUtilization = async (user) => {
  return await get(
    `${url.POC}/getManagerRAGGraph?pocId=${user.pocId}`,
    getApiConfig()
  );
};

//* All utilization graph of manager
export const allManagerUtilizationGraph = async (user) => {
  return await get(
    `${url.POC}/getManagerUtilizationGraph?pocId=${user.pocId}`,
    getApiConfig()
  );
};

//* get Rag chart manager by collection event id
export const getManagerRAGChartData = async (collectionEventId, user) => {
  return await get(
    `${url.POC}/getManagerRAGGraph?collectionEventId=${collectionEventId}&pocId=${user.pocId}`,
    getApiConfig()
  );
};

//* get utilization chart data by collection event id
export const getManagerGraphData = async (collectionEventId, user) => {
  return await get(
    `${url.POC}/getManagerUtilizationGraph?collectionEventId=${collectionEventId}&pocId=${user.pocId}`,
    getApiConfig()
  );
};
