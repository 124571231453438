import React from 'react';
import { Grid } from '@mui/material';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import FormControl from '@mui/material/FormControl';
import Select from '@mui/material/Select';
import { FormHelperText, TextField } from '@material-ui/core';
import { DatePicker, LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import ControlPointIcon from '@mui/icons-material/ControlPoint';
import dayjs from 'dayjs';
const CommonForm = ({
  FormArray,
  FormikGroup,
  functionCallByName = () => null,
  handleOpen,
  isAddTeamFieldInDropDown
}) => {
  const functionCall = (functionName, value) => {
    if (functionName) {
      functionCallByName(functionName, value);
    }
  };
  return (
    <Grid spacing={3} container xs={12}>
      {FormArray.map((element, index) => {
        const errorText = FormikGroup.errors[element.name];
        const touched = FormikGroup.touched[element.name];
        const hasError = touched && !!errorText;
        switch (element.type) {
          case 'Select':
            return element.inputControl ? (
              <Grid key={index} item xs={element.size || 3}>
                <FormControl
                  variant="standard"
                  error={hasError}
                  fullWidth
                  required={element?.required || false}>
                  <InputLabel
                    style={
                      hasError
                        ? { color: 'rgba(255, 50, 50,0.8)', fontWeight: '500' }
                        : { fontWeight: '500' }
                    }>
                    {element.label}
                  </InputLabel>
                  <Select
                    value={FormikGroup?.values?.[element.name] || ''}
                    onChange={(e) => {
                      FormikGroup?.handleChange(e);
                      functionCall(
                        element?.functionsHandel?.onChange,
                        e.target.value
                      );
                    }}
                    onBlur={(e) => {
                      FormikGroup?.handleBlur(e);
                      functionCall(
                        element?.functionsHandel?.onBlur,
                        e.target.value
                      );
                    }}
                    name={element.name}
                    label={element.label}
                    disabled={element.disabled || false}
                    fullWidth>
                    {/* Default placeholder item */}
                    {isAddTeamFieldInDropDown && element.label === "Team" && <MenuItem value="" onClick={handleOpen}>
                      <ControlPointIcon className='pr-1' /> {`Add ${element.label}`}
                    </MenuItem>}
                    {element.options.map((option, optIndex) => (
                      <MenuItem key={optIndex} value={option.value}>
                        {option.label}
                      </MenuItem>
                    ))}
                  </Select>
                  {hasError && (
                    <FormHelperText
                      style={{
                        color: 'rgba(255, 50, 50,0.8)',
                        fontWeight: '500',
                      }}>
                      {errorText}
                    </FormHelperText>
                  )}
                </FormControl>
              </Grid>
            ) : (
              <></>
            )

          case 'date':
            return element.inputControl ? (
              <Grid key={index} style={{ width: '100%' }} item xs={element.size || 3} >
                <LocalizationProvider style={{ width: '100%' }} dateAdapter={AdapterDayjs} >
                  <DatePicker
                    label={element.label}
                    value={[null, ""].includes(FormikGroup?.values?.[element.name]) ? null : dayjs(FormikGroup?.values?.[element.name]) || null}
                    format="DD/MM/YYYY"
                    onChange={(date) => {
                      FormikGroup.setFieldValue(element.name, date);
                      functionCall(element?.functionsHandel?.onChange, date);
                    }}
                    renderInput={(params) => (
                      <TextField
                        {...params}
                        fullWidth
                        required={element?.required || false}
                        variant='standard'
                        style={{ width: '100%' }}
                        size='small'
                        error={hasError}
                        helperText={hasError ? errorText : ''}
                        disabled={element.disabled || false}
                      />
                    )}
                  />
                </LocalizationProvider>
              </Grid>
            ) : (
              <></>
            );

          default:
            return element.inputControl ? (
              <Grid key={index} item xs={element.size || 3}>
                <TextField
                  name={element.name}
                  label={element.label}
                  type={element.type}
                  placeholder={element?.placeholder}
                  onChange={(e) => {
                    FormikGroup?.handleChange(e);
                    functionCall(
                      element?.functionsHandel?.onChange,
                      e.target.value
                    );
                  }}
                  onBlur={(e) => {
                    FormikGroup?.handleBlur(e);
                  }}
                  value={FormikGroup?.values?.[element.name] || ''}
                  fullWidth
                  variant="standard"
                  required={element?.required || false}
                  error={hasError}
                  helperText={hasError ? errorText : ''}
                  disabled={element.disabled || false}
                />
              </Grid>
            ) : (
              <></>
            );
        }
      })}
    </Grid>
  );
};

export default CommonForm;
