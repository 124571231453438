export const EmployeeCount = [
    {
        label:"0-1",
        value:{
            min:0,
            max:1
        }
    },
    {
        label:"2-10",
        value:{
            min:2,
            max:10
        }
    },
    {
        label:"11-50",
        value:{
            min:11,
            max:50
        }
    },
    {
        label:"51-200",
        value:{
            min:51,
            max:200
        }
    },
    {
        label:"201-500",
        value:{
            min:201,
            max:500
        }
    },
    {
        label:"501-1,000",
        value:{
            min:501,
            max:1000
        }
    },
    {
        label:"1,001-5,000",
        value:{
            min:1001,
            max:5000
        }
    },
    {
        label:"5,001-10,000",
        value:{
            min:5001,
            max:10000
        }
    },
    {
        label:"10,001 +",
        value:{
            min:10001,
            max:"+"
        }
    },
];
  
