import React, { useContext } from 'react';
import Layout from './Layout';
import SecondaryLayout from './SecondaryLayout';
import Grid from '@material-ui/core/Grid';
import TextWithDivider from './TextWithDivider';
import {
  Typography,
} from '@material-ui/core';
import { EventContext } from './EventContextProvider';
import TableAssignRag from '../containers/Manager/AssignRAG/TableAssignRag';

const Events = () => {
  const { events } = useContext(EventContext);

  return (
    <Layout>
      <SecondaryLayout>
        <Grid className="pl-4 pr-2">
          <Grid item lg={12} md={12} className="pr-4 pt-4 mb-3">
            <TextWithDivider>
              <div className="d-flex justify-content-between">
                <Typography variant="h3" color="textPrimary">
                  Events
                </Typography>
                {/* <Button
                  startIcon={<AddCircleOutlineIcon />}
                  variant="contained"
                  onClick={() => {
                    if (user.limitOfCollectionEvent == events.length) {
                      return Message.warn('Your Limit has exceed');
                    }
                    history.push(
                      `${Paths.pocCreateEvents.split(':')[0]}${enterPriseId}`
                    );
                  }}
                  color="primary"
                  disableElevation>
                  Add New Event
                     </Button> */}
              </div>
            </TextWithDivider>
          </Grid>
          <TableAssignRag
            data={events}
            id={'approvalCompletionStatusPOC'}
            isEditAction={true}
            tableHeads={[
              { id: 'name', label: 'Name', align: 'center' },
              {
                id: 'startDate',
                label: 'Start Date',
                align: 'center',
              },
              {
                id: 'endDate',
                label: 'End Date',
                align: 'center',
              },

              {
                id: 'status',
                label: 'Status',
                align: 'center',
              },
              {
                id: 'description',
                label: 'Description',
                align: 'center',
              },
              {
                id: 'actions',
                label: 'Actions',
                align: 'center',
              },
            ]}
            dataKeys={[
              { id: 'name' },
              { id: 'startDate', align: 'center' },
              { id: 'endDate', align: 'center' },
              { id: 'status', align: 'center' },
              { id: 'description', align: 'center' },
            ]}
            isSelectable={false}
          />
        </Grid>
      </SecondaryLayout>
    </Layout>
  );
};

export default Events;
