import {
  CircularProgress,
  Grid,
  IconButton,
  LinearProgress,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import React, { useState } from 'react';
import DeleteOutlineOutlinedIcon from '@material-ui/icons/DeleteOutlineOutlined';
import { colors } from '../../../../theme/colors';
import { deleteFile } from '../../../../operations/misc';
import { deleteAFileFromEnterprise } from '../../../../operations/enterprise';

const TemplateUploader = ({
  image,
  fileObj,
  index,
  onDelete = () => {},
  progress,
  handleTemplateNameChange = () => {},
  isStatic,
  fileId = undefined,
  enterpriseId = undefined,
  fetchUploadedFiles = () => {},
}) => {
  const [loading, setLoading] = useState(false);

  const handleDelete = async () => {
    if (isStatic) {
      setLoading(true);
      try {
        await deleteFile(`EnterpriseFiles/${fileObj.file.name}`);
        await deleteAFileFromEnterprise(enterpriseId, fileId);
        fetchUploadedFiles();
      } catch (e) {
      } finally {
        setLoading(false);
      }
    } else {
      onDelete(fileObj.id);
    }
  };

  return (
    <Paper
      className="my-3"
      style={{ padding: 10, borderRadius: '8px' }}
      elevation={0}>
      <Grid container alignItems="center" justifyContent="space-between">
        <Grid item xs={2}>
          {image.endsWith('.pdf') ? (
            <a
              href={image}
              target="_blank"
              rel="noreferrer"
              style={{ color: 'inherit' }}>
              <i
                class="fa-sharp fa-solid fa-file-pdf fa-fade"
                style={{ fontSize: '50px' }}></i>
            </a>
          ) : (
            <img src={image} width={48} alt="" />
          )}
        </Grid>
        <Grid container justifyContent="flex-end" item xs={3}>
          {/* {!isStatic && ( */}
          <TextField
            disabled={isStatic}
            fullWidth
            value={fileObj.name}
            placeholder={isStatic ? '' : 'Enter template name here'}
            onChange={(e) => {
              handleTemplateNameChange(e.target.value, fileObj.fileName);
            }}
          />
          {/* )} */}
        </Grid>
        <Grid container justifyContent="flex-end" item xs={1}>
          {loading ? (
            <CircularProgress className="mr-4" size={18} color="secondary" />
          ) : (
            <IconButton onClick={() => onDelete(fileObj._id)}>
              <DeleteOutlineOutlinedIcon style={{ color: colors.error.main }} />
            </IconButton>
          )}
        </Grid>
      </Grid>
    </Paper>
  );
};

export default TemplateUploader;
