import * as React from 'react';
import { LinearProgress } from '@material-ui/core';

const LinearLoader = () => {
  return (
    <LinearProgress
      style={{
        position: 'fixed',
        top: '65px',
        width: '100%',
      }}
    />
  );
};

export default LinearLoader;
